import { useState } from "react";
import { getAddress } from "viem";
import NextLink from "next/link";
import { useRouter } from "next/router";
import groupBy from "lodash/groupBy";
import { useAccount } from "wagmi";
import { useTranslation } from "next-i18next";
import { Box, Flex, IconButton, Link, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import {
  ClockIcon,
  ErrorIcon,
  LaunchOpenInNewIcon,
  ProfileAvatar,
  SectionLoader,
  TableFooter,
  Text,
  useGameSupportedNetworks,
  UsernameDisplay,
} from "@looksrare/uikit";
import {
  formatNumberToLocale,
  formatTimestampAsDateString,
  formatToSignificant,
  getBigIntishPercent,
  getChainIdFromSupportedNetwork,
  getExplorerLink,
  isAddressEqual,
} from "@looksrare/utils";
import { formatPercentage } from "@looksrare/utils/formatting/formatPercentage";
import { getNetworkFromYoloContractName, getYoloContractVersionFromName, ITEMS_PER_PAGE } from "../../utils";
import { ContractVersion, RoundStatus, SortRoundBy } from "../../types";
import { useYoloQueryParams, usePastRounds } from "../../utils";
import { TabNetworkSelector } from "../round/NetworkSelector";
import { useYoloConfig } from "../../config";
import { Amount } from "../Amount";
import { Header } from "./Header";
import { StatusFilter, useFilters } from "./useFilters";
import { EmptyCell, IconWrapper, ThSortable } from "./helpers";

export const History = () => {
  const { t } = useTranslation();
  const canChooseNetwork = useGameSupportedNetworks().length > 1;
  const router = useRouter();
  const { basePath } = useYoloConfig();
  const { address } = useAccount();

  const [page, setPage] = useState(1);
  const { network } = useYoloQueryParams();
  const { statusFilter, setStatusFilter, userFilter, sortBy, setSortBy } = useFilters();

  const {
    data: history,
    isLoading: isHistoryLoading,
    isRefetching,
  } = usePastRounds(network, page - 1, sortBy, statusFilter?.value, userFilter[0] ? address : undefined);

  return (
    <>
      <Box>
        {canChooseNetwork && (
          <TabNetworkSelector
            currentNetwork={network}
            hrefBuilder={(availableNetwork) => `/yolo/${availableNetwork}/history`}
            mb={8}
          />
        )}

        <Header
          activeStatusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          toggleUserFilter={userFilter[1].toggle}
        />
        <TableContainer
          border="solid 1px"
          borderColor="border-01"
          bgGradient="linear-gradient(180deg, ui-gradient-02-b 0%, ui-gradient-02-a 100%)"
          borderRadius="dialog"
        >
          <Table size="lg" opacity={isRefetching ? 0.5 : 1} transition="opacity 0.1s ease-in">
            <Thead>
              <Tr>
                <ThSortable
                  minWidth="92px"
                  isUp={sortBy === SortRoundBy.OLDEST}
                  onClick={() =>
                    setSortBy((prev) => (prev === SortRoundBy.OLDEST ? SortRoundBy.NEWEST : SortRoundBy.OLDEST))
                  }
                >
                  {t("yolo::Round")}
                </ThSortable>
                <Th textAlign="left" minWidth="232px">
                  {t("yolo::Winner")}
                </Th>
                <ThSortable
                  textAlign="left"
                  isUp={sortBy === SortRoundBy.ROUND_VALUE_ASC}
                  onClick={() => {
                    setSortBy((prev) =>
                      prev === SortRoundBy.ROUND_VALUE_ASC ? SortRoundBy.ROUND_VALUE_DESC : SortRoundBy.ROUND_VALUE_ASC
                    );
                    setStatusFilter(StatusFilter.COMPLETED);
                  }}
                >
                  {t("yolo::Prize Pool")}
                </ThSortable>
                <Th textAlign="left">{t("yolo::Winner's Entries")}</Th>
                <ThSortable
                  textAlign="left"
                  isUp={sortBy === SortRoundBy.WIN_MULTIPLE_ASC}
                  onClick={() => {
                    setSortBy((prev) =>
                      prev === SortRoundBy.WIN_MULTIPLE_ASC
                        ? SortRoundBy.WIN_MULTIPLE_DESC
                        : SortRoundBy.WIN_MULTIPLE_ASC
                    );
                    setStatusFilter(StatusFilter.COMPLETED);
                  }}
                >
                  {t("yolo::Win")}
                </ThSortable>
                <Th textAlign="left">{t("yolo::Your Entries")}</Th>
                <Th textAlign="left">{t("yolo::Players")}</Th>
                <Th textAlign="left">{t("yolo::Finish")}</Th>
                <Th textAlign="left">{t("yolo::Verify")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {history?.rounds &&
                history?.rounds.map((round, index) => {
                  const valuePerEntryBi = BigInt(round.valuePerEntry.toString());
                  const usersDeposits = groupBy(round.deposits, "depositor.address");

                  const winnerDeposits = round.winner && usersDeposits[round.winner.address];
                  const winnerNumberOfEntries = winnerDeposits
                    ? winnerDeposits.reduce((acc, { numberOfEntries }) => acc + BigInt(numberOfEntries), 0n)
                    : null;
                  const winnerDepositsValue = winnerNumberOfEntries ? valuePerEntryBi * winnerNumberOfEntries : null;

                  const userSharePercentDisplay = (() => {
                    if (!winnerDepositsValue) {
                      return "";
                    }
                    const userShare = getBigIntishPercent(round.potValue, winnerDepositsValue);
                    return formatPercentage(userShare);
                  })();

                  const connectedUserDeposit = address && usersDeposits[address];
                  const connectedUserNumberOfEntries = connectedUserDeposit
                    ? connectedUserDeposit.reduce((acc, { numberOfEntries }) => acc + BigInt(numberOfEntries), 0n)
                    : null;
                  const connectedUserDepositsValue = connectedUserNumberOfEntries
                    ? valuePerEntryBi * connectedUserNumberOfEntries
                    : null;

                  const isWinnerCurrentUser = !!address && isAddressEqual(address, round.winner?.address);
                  const isCurrentRound = round.status === RoundStatus.Open;

                  const roundNetwork = getNetworkFromYoloContractName(round.contract);

                  const roundChainId = getChainIdFromSupportedNetwork(roundNetwork);
                  // V1 is ethereum only
                  const isV1Round = getYoloContractVersionFromName(round.contract) === ContractVersion.V1;
                  const pastRoundsSegment = isV1Round ? "ethereum/v1" : `${network}`;
                  const roundLink =
                    "/" +
                    [basePath, pastRoundsSegment, isCurrentRound ? undefined : round.onChainId]
                      .filter(Boolean)
                      .join("/");

                  return (
                    <Tr
                      key={round.onChainId.toString()}
                      height="64px"
                      fontSize="14px"
                      borderBottom={index < history.rounds.length - 1 ? "solid 1px" : 0}
                      borderBottomColor="border-01"
                      borderLeft="solid 4px"
                      borderLeftColor={isWinnerCurrentUser ? "interactive-03" : isCurrentRound ? "ui-02" : "ui-01"}
                      bg={isCurrentRound ? "ui-02" : "ui-01"}
                      cursor="pointer"
                      onClick={() => router.push(roundLink)}
                      _active={{ bg: "ui-02", borderLeftColor: "ui-02" }}
                    >
                      {/* Round ID */}
                      <Td fontWeight="bold" color="text-03">
                        <NextLink href={roundLink}>
                          {round.onChainId}
                          <Text as="span" textStyle="caption" color="text-03">
                            {isV1Round && " (v1)"}
                          </Text>
                        </NextLink>
                      </Td>

                      {/* Winner */}
                      <Td fontWeight="bold" padding="12px 16px !important">
                        {isCurrentRound && (
                          <Flex alignItems="center">
                            <IconWrapper bg="ui-01">
                              <ClockIcon color="text-03" boxSize={5} />
                            </IconWrapper>
                            <Text textStyle="detail">{t("yolo::Current Round")}</Text>
                          </Flex>
                        )}
                        {round.status === RoundStatus.Cancelled && (
                          <Flex alignItems="center">
                            <IconWrapper bg="ui-02">
                              <ErrorIcon color="text-error" boxSize={5} />
                            </IconWrapper>
                            <Text textStyle="detail">{t("yolo::Canceled")}</Text>
                          </Flex>
                        )}
                        {round.status === RoundStatus.Drawn && round.winner && (
                          <Flex alignItems="center">
                            <ProfileAvatar
                              src={round.winner.avatar?.image.src}
                              address={getAddress(round.winner.address)}
                              size={40}
                              mr={4}
                            />
                            {isWinnerCurrentUser ? (
                              <Text color="link-01" textStyle="detail">
                                {t("yolo::You")}
                              </Text>
                            ) : (
                              <UsernameDisplay
                                address={getAddress(round.winner.address)}
                                name={round.winner.name}
                                isVerified={round.winner.isVerified}
                                textStyle="detail"
                                verifiedIconProps={{ boxSize: 3 }}
                              />
                            )}
                          </Flex>
                        )}
                      </Td>

                      {/* Total value */}
                      <Td fontWeight="bold">
                        <Amount network={network} amount={formatToSignificant(round.potValue)} />
                      </Td>

                      {/* Winner's entries */}
                      <Td padding="12px 16px !important">
                        {winnerDepositsValue ? (
                          <>
                            <Amount network={network} amount={formatToSignificant(winnerDepositsValue)} />
                            <Text color="text-03" textStyle="detail">
                              {userSharePercentDisplay}
                            </Text>
                          </>
                        ) : (
                          <EmptyCell />
                        )}
                      </Td>

                      {/* ROI */}
                      <Td>
                        {round.winnerMultiple ? (
                          <Text textStyle="detail">{`${formatNumberToLocale(round.winnerMultiple, 0, 2)}x`}</Text>
                        ) : (
                          <EmptyCell />
                        )}
                      </Td>

                      {/* Your entries */}
                      <Td>
                        {connectedUserDepositsValue ? (
                          <>
                            <Amount network={network} amount={formatToSignificant(connectedUserDepositsValue)} />
                            <Text color="text-03" textStyle="detail">
                              {formatNumberToLocale(
                                getBigIntishPercent(round.potValue, connectedUserDepositsValue),
                                0,
                                2
                              )}
                              %
                            </Text>
                          </>
                        ) : (
                          <EmptyCell />
                        )}
                      </Td>

                      {/* Number of players */}
                      <Td color="text-03">
                        <Text textStyle="detail" bold>
                          {round.numberOfParticipants}
                        </Text>
                      </Td>

                      {/* Time */}
                      <Td color="text-03">
                        <Text textStyle="detail" color="text-03">
                          {!!round.cutoffTime ? formatTimestampAsDateString(round.cutoffTime) : t("yolo::Not started")}
                        </Text>
                      </Td>

                      {/* Verify */}
                      <Td textAlign="center" padding="12px 16px !important">
                        {!!round.drawnHash && round.status === "DRAWN" ? (
                          <IconButton
                            onClick={(e) => e.stopPropagation()}
                            bgColor="transparent"
                            color="interactive-03"
                            sx={{
                              _hover: {
                                bgColor: "hover-interactive-02",
                              },
                              _active: {
                                bgColor: "onclick-interactive-02",
                              },
                            }}
                            aria-label={t("yolo::Verify round results")}
                            as={Link}
                            size="sm"
                            href={getExplorerLink(round.drawnHash, "transaction", roundChainId)}
                            isExternal
                          >
                            <LaunchOpenInNewIcon />
                          </IconButton>
                        ) : (
                          <EmptyCell />
                        )}
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
          {isHistoryLoading && (
            <Flex flexDirection="column" alignItems="center" p={8}>
              <SectionLoader />
            </Flex>
          )}
        </TableContainer>
        <TableFooter
          currentPageNumber={page}
          goToPage={setPage}
          labelPrefix="yolo-history"
          totalPagesNumber={history?.count ? Math.ceil(history.count / ITEMS_PER_PAGE) : 1}
        />
      </Box>
    </>
  );
};
