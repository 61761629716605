import { ETH_AVG_BLOCK_TIME_MS } from "@looksrare/config";
import { getPointsMultiplier, getRoundPercentElapsed } from "@looksrare/utils";
import { multiplierThresholds } from "../../config/gems";

interface Args {
  currentSeconds: number;
  roundCutoffTime: number;
  roundDuration: number;
  secondsToNotifyBeforeThreshold?: number;
}

/**
 * Return true if next points multiplier threshold is within secondsToNotifyBeforeThreshold
 */
export const getIsCloseToMultiplierThreshold = ({
  currentSeconds,
  roundCutoffTime,
  roundDuration,
  secondsToNotifyBeforeThreshold = ETH_AVG_BLOCK_TIME_MS / 1000,
}: Args): boolean => {
  const secondsElapsedAsPercent = getRoundPercentElapsed({ currentSeconds, roundCutoffTime, roundDuration });
  const currentMultiplier = getPointsMultiplier(secondsElapsedAsPercent, multiplierThresholds);
  const nextThresholdAsPercent = multiplierThresholds[currentMultiplier];

  const secondsToNextThreshold = Math.round(roundDuration * ((nextThresholdAsPercent - secondsElapsedAsPercent) / 100));
  return secondsToNextThreshold <= secondsToNotifyBeforeThreshold;
};
