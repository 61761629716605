import React from "react";
import { AlertProps, AlertTitle, Button, Flex } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Alert, useToast, ConfirmInWalletModal } from "@looksrare/uikit";
import { useGetFormattedErrorAndTitle, useSubmitTransaction, useAssertNetworkDisclosure } from "@looksrare/utils";
import { useWithdrawDepositsV1 } from "../../utils/contract/v1";
import { UnclaimedRefund, YoloSupportedNetworks } from "../../types";

interface Props extends AlertProps {
  unclaimedRefunds: UnclaimedRefund[];
  roundId: string;
  onSuccess: () => void;
}

export const WithdrawV1Deposits = ({ roundId, unclaimedRefunds, onSuccess, ...props }: Props) => {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useAssertNetworkDisclosure({ network: YoloSupportedNetworks.ethereum });
  const { toast } = useToast();
  const getFormattedErrorAndTitle = useGetFormattedErrorAndTitle();

  const withdrawDeposits = useWithdrawDepositsV1();

  const handler = useSubmitTransaction({
    onSend: async () => {
      onOpen();
      const tx = await withdrawDeposits(
        roundId,
        unclaimedRefunds.map((r) => r.index)
      );
      return tx ?? null;
    },
    onSuccess: () => {
      toast({
        title: t("yolo::Successfully Withdrawn"),
        description: t("yolo::Your assets from the canceled round are now back in your wallet."),
      });
      onSuccess();
      onClose();
    },
    onError: (error: any) => {
      const { title, description } = getFormattedErrorAndTitle(error);
      toast({ title, description, status: "error", dataIdSuffix: "yolo-withdraw" });
    },
  });

  return (
    <>
      <ConfirmInWalletModal
        isOpen={isOpen}
        onClose={onClose}
        isTxConfirmed={handler.isTxConfirmed}
        isTxSending={handler.isTxSending}
        isTxWaiting={handler.isTxWaiting}
        isTxError={handler.isTxError}
      />
      <Alert variant="left-accent" status="warning" centerIcon {...props}>
        <Flex justifyContent="space-between" alignItems="center" width="100%" wrap="wrap">
          <AlertTitle>
            {t("yolo::({{version}}) Round {{round}} Canceled - Withdraw Your Entry Now", {
              round: roundId.toLocaleString(),
              version: "v1",
            })}
          </AlertTitle>
          <Button size="xs" onClick={handler.submitTransaction}>
            {t("yolo::Withdraw")}
          </Button>
        </Flex>
      </Alert>
    </>
  );
};
