import { Address } from "viem";
import { useAccount } from "wagmi";
import NextLink from "next/link";
import { useTranslation } from "next-i18next";
import { Box, Flex, SimpleGrid, useColorModeValue, Divider, ModalBody, useToken } from "@chakra-ui/react";
import { UsernameDisplay, RankOne, HelpIcon, ModalHeaderLegacy, ProfileAvatar } from "@looksrare/uikit";
import {
  formatCompactNumber,
  formatNumberToLocale,
  formatToSignificant,
  isAddressEqual,
  isAuthorized,
  divideWeiByWei,
} from "@looksrare/utils";
import { Text, Button, SectionLoader } from "@looksrare/uikit";
import { Round } from "../../../types";
import { getNetworkFromYoloContractName, useDepositor } from "../../../utils";
import { useYoloConfig } from "../../../config";
import { Amount } from "../../Amount";
import { AssetDepositRows } from "./AssetDepositRows";
import { getRoundPlayedEmoji, getBiggestWinEmoji, getLuckiestWinEmoji, getRoundWonEmoji } from "./emoji";
import { EmojiBox } from "./EmojiBox";

interface Props {
  round: Round;
  userAddress: Address;
  onClose: () => void;
  onGemsHelpClick: () => void;
}

export const PlayerProfileModalBody: React.FC<Props> = ({ round, userAddress, onClose, onGemsHelpClick }) => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const {
    supportsUserProfiles,
    supportNfts,
    supportErc20,
    supportsMetrics,
    points: { enabled: pointsEnabled, label: pointsLabel, Icon: PointsIcon },
    PlayerViewProfileCTA,
  } = useYoloConfig();
  const shouldShowDeposits = supportNfts || supportErc20;

  const isWinner = isAddressEqual(userAddress, round.winner?.address);
  const playerDeposits = round.deposits.filter((deposit) => isAddressEqual(deposit.depositor.address, userAddress));
  const { data: user } = useDepositor(userAddress, getNetworkFromYoloContractName(round.contract));
  const valuePerEntryBi = BigInt(round.valuePerEntry.toString());
  const userNbOfEntries = playerDeposits.reduce((acc, deposit) => acc + BigInt(deposit.numberOfEntries), 0n);
  const userTotalValue = valuePerEntryBi * userNbOfEntries;
  const userShare = divideWeiByWei(userTotalValue, round.potValue) * 100;

  const totalGemsEarned = playerDeposits.reduce<bigint>((acc, deposit) => acc + BigInt(deposit.gemsEarned || 0n), 0n);
  const totalGemsEarnedDisplay = formatCompactNumber(Number(totalGemsEarned));

  const [uiBg] = useToken("colors", ["ui-bg"]);

  const backgroundAvatarOverlay = useColorModeValue(
    `linear-gradient(180deg, rgba(255, 255, 255, 0.75) 0%, ${uiBg} 100%)`,
    `linear-gradient(180deg, rgba(18, 22, 25, 0.85) 0%, ${uiBg} 100%)`
  );

  const isSetupAccountCTAShown = supportsUserProfiles && !isAuthorized(address) && isAddressEqual(address, userAddress);
  const network = getNetworkFromYoloContractName(round.contract);

  const { yoloMetrics } = user || {};
  return (
    <>
      <Box borderTopRadius="dialog">
        <Box pos="absolute" width="100%" height={330} overflow="hidden" borderTopRadius="dialog" zIndex={0}>
          <ProfileAvatar
            address={userAddress}
            src={user?.avatar?.image.src}
            boxSize="100%"
            size={550}
            borderRadius={0}
          />
          <Box pos="absolute" inset={0} bg={backgroundAvatarOverlay} />
        </Box>
        <ModalHeaderLegacy onClose={onClose} pb={0} showCloseButton />

        <ModalBody pos="relative" pt={0}>
          <Flex flexDirection="column" alignItems="center" mb={4}>
            <ProfileAvatar address={userAddress} src={user?.avatar?.image.src} size={64} mb={4} />
            <Flex alignItems="center">
              <UsernameDisplay
                address={userAddress}
                name={user?.name}
                isVerified={user?.isVerified}
                bold
                color={isWinner ? "yellow.200" : undefined}
              />
              {isWinner && <RankOne boxSize={5} ml={1} />}
            </Flex>
          </Flex>

          {(supportsMetrics || supportsUserProfiles) && (
            <Flex justifyContent="space-between" alignItems="center" mb={2}>
              {supportsMetrics && (
                <Text color="text-03" textStyle="detail" bold>
                  {t("yolo::Past Rounds")}
                </Text>
              )}
              {!!PlayerViewProfileCTA && <PlayerViewProfileCTA userAddress={userAddress} />}
            </Flex>
          )}

          {supportsMetrics && (
            <SimpleGrid
              borderRadius="container"
              border="solid 1px"
              borderColor="border-01"
              columns={{ base: 2, sm: 4 }}
            >
              <EmojiBox
                value={yoloMetrics?.totalRoundsPlayed.toString()}
                label="Played"
                emoji={getRoundPlayedEmoji(yoloMetrics?.totalRoundsPlayed)}
                borderRight="solid 1px"
                borderBottom={{ base: "solid 1px", sm: 0 }}
              />
              <EmojiBox
                value={yoloMetrics?.totalRoundsWon.toString()}
                label="Won"
                emoji={getRoundWonEmoji(yoloMetrics?.totalRoundsWon)}
                borderRight="solid 1px"
                borderBottom={{ base: "solid 1px", sm: 0 }}
              />
              <EmojiBox
                value={yoloMetrics?.biggestETHWin ? formatToSignificant(yoloMetrics.biggestETHWin, 2).toString() : "-"}
                label="Biggest Win"
                emoji={getBiggestWinEmoji(yoloMetrics?.biggestETHWin)}
                borderRight="solid 1px"
              />
              <EmojiBox
                value={
                  yoloMetrics?.biggestWinMultiple && yoloMetrics.biggestWinMultiple > 0
                    ? `${formatNumberToLocale(yoloMetrics.biggestWinMultiple, 0, 2)}x`
                    : "-"
                }
                label="Luckiest Win"
                emoji={getLuckiestWinEmoji(yoloMetrics?.biggestWinMultiple)}
              />
            </SimpleGrid>
          )}
        </ModalBody>
      </Box>

      <Divider position="relative" />

      <ModalBody
        bg="ui-bg"
        maxHeight="50vh"
        overflowY="auto"
        zIndex={1}
        pb={isSetupAccountCTAShown ? 104 : 6}
        mb={isSetupAccountCTAShown ? 1 : 0}
        borderBottomRadius={isSetupAccountCTAShown ? 0 : "dialog"}
      >
        <Text color="text-03" textStyle="detail" bold mb={1}>
          {t("yolo::This Round")}
        </Text>
        <SimpleGrid bg="ui-01" borderRadius="container" columns={pointsEnabled ? 3 : 2} gap={4} p={4} mb={6}>
          <Box>
            <Amount
              network={network}
              amount={formatToSignificant(userTotalValue)}
              textProps={{ textStyle: "heading-04" }}
            />
            <Text textStyle="helper" color="text-03">
              {t("yolo::Entries’ Value")}
            </Text>
          </Box>
          <Box>
            <Text textStyle="heading-04" bold>
              {formatNumberToLocale(userShare, 0, 2)}%
            </Text>
            <Text textStyle="helper" color="text-03">
              {t("yolo::Win Chance")}
            </Text>
          </Box>
          {pointsEnabled && (
            <Box>
              <Flex alignItems="center">
                {!!PointsIcon && <PointsIcon boxSize={5} mr={1} />}
                <Text textStyle="heading-04" bold>
                  {totalGemsEarnedDisplay} {!PointsIcon && pointsLabel}
                </Text>
              </Flex>
              <Flex alignItems="center">
                <Text textStyle="helper" color="text-03">
                  {t(`yolo::${pointsLabel}`)}
                </Text>
                <HelpIcon cursor="pointer" color="text-03" boxSize={3} ml={1} onClick={onGemsHelpClick} />
              </Flex>
            </Box>
          )}
        </SimpleGrid>
        {(() => {
          if (!shouldShowDeposits) {
            return null;
          }
          if (playerDeposits) {
            return <AssetDepositRows round={round} playerDeposits={playerDeposits} />;
          } else {
            return <SectionLoader />;
          }
        })()}
      </ModalBody>

      {isSetupAccountCTAShown && (
        <Box bg="ui-02" width="100%" p={8} pt={6} borderBottomRadius="dialog" position="absolute" bottom={0} left={0}>
          <Button as={NextLink} href="/rewards/gems" width="100%">
            {t("yolo::Set Up Profile • Earn Gems")}
          </Button>
        </Box>
      )}
    </>
  );
};
