import React from "react";
import { useDisclosure } from "@chakra-ui/react";
import groupBy from "lodash/groupBy";
import delay from "lodash/delay";
import { useYoloConfig } from "../../config";
import { ContractVersion } from "../../types";
import { useCurrentParticipant, useYoloQueryParams } from "../../utils";
import { WithdrawRolloverModal } from "../modals/v2/WithdrawRollOverModal";
import { WithdrawV2Deposits } from "./WithdrawV2Deposits";

interface DepositsV2CheckProps {
  contractVersion: ContractVersion;
}

export const DepositsV2Check = ({ contractVersion }: DepositsV2CheckProps) => {
  const { network } = useYoloQueryParams();
  const disclosure = useDisclosure();
  const { data: participant, refetch } = useCurrentParticipant(network);
  const {
    contract: { getYoloContractNameFromNetworkAndVersion },
  } = useYoloConfig();
  const contractName = getYoloContractNameFromNetworkAndVersion(network, contractVersion);
  const refunds = participant?.yoloUnclaimedRefunds.filter((refund) => refund.round.contract === contractName);
  const groupedRefunds = groupBy(refunds, "round.onChainId");
  const delayedRefetch = () => delay(refetch, 2_000); // @NOTE let indexer catch up
  const roundsCount = Object.keys(groupedRefunds).length;

  if (!refunds?.length) {
    return null;
  }

  return (
    <>
      <WithdrawV2Deposits onStart={disclosure.onOpen} roundsCount={roundsCount} />
      <WithdrawRolloverModal
        isOpen={disclosure.isOpen}
        onClose={() => {
          delayedRefetch();
          disclosure.onClose();
        }}
      />
    </>
  );
};
