import { useAccount, usePublicClient } from "wagmi";
import { AutoTransactionStepRow, TransactionSetter, useHandleModalStep } from "@looksrare/uikit";
import { DataLayerEventNames, fromDecimals, useCoinPrices, useSendAnalyticsEvent } from "@looksrare/utils";
import { useYoloQueryParams } from "../../../../../utils/chains";
import { ClaimPrizesCalldata } from "../../../../../types";
import { getCurrentRoundId } from "../../../../../utils/api/subgraph";
import { useYoloConfig } from "../../../../../config";

interface Props {
  onComplete: () => void;
  isStepActive: boolean;
  ctaText: string;
  isPayingFeesWithLooks: boolean;
  ethOnlyClaims: ClaimPrizesCalldata;
  hasPlatformFees: boolean;
  rollableOverAmount: bigint;
}

export const RolloverStep = ({
  onComplete,
  isStepActive,
  ctaText,
  ethOnlyClaims,
  isPayingFeesWithLooks,
  hasPlatformFees,
  rollableOverAmount,
}: Props) => {
  const { address } = useAccount();
  const publicClient = usePublicClient();
  const { network } = useYoloQueryParams();
  const sendAnalyticsEvent = useSendAnalyticsEvent();
  const { data: prices } = useCoinPrices();

  const useHandleRollOverTx = (setTransaction: TransactionSetter) => {
    const {
      contract: { useRollOverEthToNextRound, getYoloContractNamesFromNetwork },
    } = useYoloConfig();
    const rollOverEth = useRollOverEthToNextRound();

    return useHandleModalStep({
      onSubmit: async () => {
        if (!publicClient) {
          throw new Error("No public client found");
        }
        const contractNames = getYoloContractNamesFromNetwork(network);
        const startingRound = await getCurrentRoundId(contractNames);
        const hash = await rollOverEth(startingRound, ethOnlyClaims, isPayingFeesWithLooks);
        setTransaction(hash);

        const receipt = await publicClient.waitForTransactionReceipt({ hash });
        if (receipt.status === "success" && hasPlatformFees) {
          const totalPriceEth = fromDecimals(rollableOverAmount);
          const totalPriceUsd = prices?.eth ? parseFloat(totalPriceEth) * prices?.eth.price : 0;
          sendAnalyticsEvent({
            event: DataLayerEventNames.YOLO_DEPOSIT,
            connectedWalletAddress: address,
            totalPriceEth,
            totalPriceUsd: totalPriceUsd.toString(),
          });

          setTransaction(undefined);
          onComplete();
        } else {
          throw new Error(`Rolling over ETH to the next rounds failed. Transaction hash: ${receipt.transactionHash}`);
        }
      },
    });
  };

  return (
    <AutoTransactionStepRow isStepActive={isStepActive} useHandleTransaction={useHandleRollOverTx} ctaText={ctaText} />
  );
};
