import { usePublicClient } from "wagmi";
import { useTranslation } from "next-i18next";
import { AutoTransactionStepRow, TransactionSetter } from "@looksrare/uikit";
import { useHandleModalStep } from "@looksrare/utils";
import { useClaimPrizesV1, useGetClaimPrizesPaymentRequiredV1 } from "../../../../../utils/contract/v1";
import { UnclaimedPrize, ContractVersion } from "../../../../../types";
import { getPendingClaims } from "../../../../../utils/contract/getPendingClaims";

interface Props {
  unclaimedV1Prizes: UnclaimedPrize[];
  isStepActive: boolean;
  onComplete: () => void;
  onSkip?: () => void;
}

export const ClaimV1TransactionStep = ({ unclaimedV1Prizes, isStepActive, onComplete, onSkip }: Props) => {
  const { t } = useTranslation();
  const publicClient = usePublicClient();
  const claimPrizes = useClaimPrizesV1();

  const { claimsByContractVersion, pendingAmount } = getPendingClaims(unclaimedV1Prizes);
  const v1ClaimCallData = Object.values(claimsByContractVersion[ContractVersion.V1]);

  const { data: fee } = useGetClaimPrizesPaymentRequiredV1(v1ClaimCallData, {
    enabled: pendingAmount > 0n,
    refetchOnWindowFocus: false,
  });

  const useHandleTransaction = (setTransaction: TransactionSetter) =>
    useHandleModalStep({
      onSubmit: async () => {
        if (!publicClient) {
          throw new Error("No public client found");
        }
        const hash = await claimPrizes(v1ClaimCallData, fee);
        setTransaction(hash);
        const receipt = await publicClient.waitForTransactionReceipt({ hash });
        if (receipt.status === "success") {
          setTransaction(undefined);
          onComplete();
        } else {
          throw new Error(`Claiming v1 prizes failed. Transaction hash: ${receipt.transactionHash}`);
        }
      },
    });

  return (
    <AutoTransactionStepRow
      isStepActive={isStepActive}
      useHandleTransaction={useHandleTransaction}
      ctaText={t("yolo::Claim V1 Prizes")}
      onSkip={onSkip}
    />
  );
};
