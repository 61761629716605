type Multiplier = number;
type PercentageElapsed = number;

const fractionOfRound = 1 / 12; // 0.083333333333333

export const multiplierThresholds: Record<Multiplier, PercentageElapsed> = {
  5: fractionOfRound * 3 * 100, // 25%
  4.5: fractionOfRound * 4 * 100, // 33.3
  4: fractionOfRound * 5 * 100, // 41.7%
  3.5: fractionOfRound * 6 * 100, // 50%
  3: fractionOfRound * 7 * 100, // 58.3%
  2.5: fractionOfRound * 8 * 100, // 66.7%
  2: fractionOfRound * 9 * 100, // 75%
  1.5: fractionOfRound * 10 * 100, // 83.3%
};
