import { Grid, GridItem, Flex, VStack } from "@chakra-ui/react";
import { Address } from "viem";
import {
  toDecimals,
  multiplyWeiByNumber,
  useGetCurrencyConfig,
  useMultipleTokenEthTwap,
  divideWeiByWei,
  getChainIdFromSupportedNetwork,
  defaultTwapWindow,
} from "@looksrare/utils";
import { useTranslation } from "next-i18next";
import { getUnixTime } from "date-fns";
import { WEI_PER_ETHER } from "@looksrare/config";
import { useAssertNetworkDisclosure } from "@looksrare/utils";
import { Button, ErrorIcon, Text } from "@looksrare/uikit";
import { useYoloConfig } from "../../config";
import { RoundOverview } from "../RoundOverview";
import { Container } from "../Container";
import { Round, RoundStatus } from "../../types";
import { useConnectedUserEntriesValue } from "../../utils/useConnectedUserEntriesValue";
import { MobileStickyBar } from "../MobileStickyBar";
import { PointsMultiplierText } from "../round/points/PointsMultiplierText";
import { getRoundLimits } from "../../utils/getRoundLimits";
import { getNetworkFromYoloContractName } from "../../utils";
import { UserCart } from "./UserCart";
import { useYoloCartStore } from "./assetsState";
import { EnterButton } from "./EnterButton";
import { DesktopView } from "./DesktopView";
import { MobileView } from "./MobileView";
import { GasSelector } from "./GasSelector";

interface Props {
  round: Round;
  userAddress: Address;
  onClose: () => void;
}

export const Enter = ({ round, userAddress, onClose }: Props) => {
  const { t } = useTranslation();
  const network = getNetworkFromYoloContractName(round.contract);

  const {
    referenceToken,
    points: { hideEnterNowMultiplier },
    YoloPointsExplanationModal,
    isWalletBlocked,
  } = useYoloConfig();
  const { collections, tokens, ethAmountWei, ethAmountRoundsCount, reset: resetCart } = useYoloCartStore();

  const tokenAddresses = Object.keys(tokens) as Address[];
  const twapQueries = useMultipleTokenEthTwap(tokenAddresses, defaultTwapWindow, {
    enabled: referenceToken.symbol === "ETH",
  });

  const getCurrencyConfig = useGetCurrencyConfig(getChainIdFromSupportedNetwork(network));
  const {
    isOpen: isPointsModalOpen,
    onOpen: onModalPointsOpen,
    onClose: onModalPointsClose,
  } = useAssertNetworkDisclosure({ network });
  const { maxNumberOfDepositsReached, maxNumberOfParticipantsReached } = getRoundLimits({
    round,
    address: userAddress,
    maxDeposit: referenceToken.maxDeposit,
  });
  const isRoundClosed =
    !!round.cutoffTime && (round.status !== RoundStatus.Open || getUnixTime(new Date()) >= round.cutoffTime);
  const canRoundBeEntered = !(isRoundClosed || maxNumberOfParticipantsReached || maxNumberOfDepositsReached);

  // Current round values are used to update the round overview values
  const currentRoundEthAmountWei = BigInt(ethAmountWei);

  // Overall cart values are used to update the CTAs
  const cartEthAmountWei = BigInt(ethAmountRoundsCount) * BigInt(ethAmountWei);

  const [currentRoundtotalErc20PriceWei, cartErc20PriceWei] = Object.entries(tokens).reduce(
    (accum, [address, token]) => {
      const coinTwap =
        referenceToken.symbol !== "ETH"
          ? WEI_PER_ETHER // @TODO yg-play If it's not ETH, we assume the price is 1
          : (twapQueries?.[tokenAddresses.indexOf(address as Address)]?.data as bigint) || 0n;

      const tokenDecimals = getCurrencyConfig(address as Address)?.decimals;

      // TWAP is in WEI precision, so we need to upscale the token amount to 18 decimals
      const tokenAmountAsWeiPrecision = toDecimals(token.amount.toString(), 18 - tokenDecimals);
      const currentRoundAmountValueWei = multiplyWeiByNumber(
        coinTwap,
        divideWeiByWei(BigInt(tokenAmountAsWeiPrecision), WEI_PER_ETHER)
      );
      const tokenAmountValueWei = multiplyWeiByNumber(
        coinTwap,
        divideWeiByWei(BigInt(tokenAmountAsWeiPrecision * BigInt(token.roundsCount)), WEI_PER_ETHER)
      );

      accum[0] += coinTwap ? currentRoundAmountValueWei : 0n;
      accum[1] += coinTwap ? tokenAmountValueWei : 0n;
      return accum;
    },
    [0n, 0n]
  );

  const totalCollectionPriceWei = Object.values(collections).reduce((accum, collection) => {
    const collectionPrice = !!collection.price ? collection.price.toString() : "0";
    return accum + toDecimals(collectionPrice) * BigInt(collection.nfts.length);
  }, 0n);

  const currentRoundCartValue = currentRoundEthAmountWei + currentRoundtotalErc20PriceWei + totalCollectionPriceWei;
  const userCartValue = cartEthAmountWei + cartErc20PriceWei + totalCollectionPriceWei;

  const newPotValue = BigInt(round.potValue.toString()) + currentRoundCartValue;
  const userEntriesValue = useConnectedUserEntriesValue(round);
  const newUserEntriesValue = currentRoundCartValue + userEntriesValue;

  const totalNfsInAllCollections = Object.values(collections).reduce((accum, collection) => {
    return accum + BigInt(collection.nfts.length);
  }, 0n);
  const totalNftsInTokens = Object.values(tokens).reduce((accum, token) => {
    const amountBi = BigInt(token.amount.toString());
    if (amountBi > 0n) {
      return accum + 1n;
    }
    return accum;
  }, 0n);
  const totalNumOfItems = totalNfsInAllCollections + totalNftsInTokens + (currentRoundEthAmountWei > 0n ? 1n : 0n);

  const onCancel = () => {
    resetCart();
    onClose();
  };

  return (
    <>
      <YoloPointsExplanationModal isOpen={isPointsModalOpen} onClose={onModalPointsClose} network={network} />
      <Grid
        gap={{ sm: 2 }}
        templateColumns={{ base: "1fr", lg: "repeat(4, 1fr)" }}
        templateRows={{ base: "1fr", lg: "repeat(4, 1fr)" }}
        templateAreas={`"main main main stat"
                        "main main main cta"
                        "main main main cta"
                        "main main main cta"`}
        pb={{ base: "128px", lg: "0" }}
      >
        {/* Collections list */}
        <GridItem display="flex" flexDirection="column" as={Container} area="main" overflow="hidden">
          <MobileView
            round={round}
            userAddress={userAddress}
            onClose={onCancel}
            display={{ base: "initial", sm: "none" }}
          />
          <DesktopView
            round={round}
            userAddress={userAddress}
            onClose={onCancel}
            display={{ base: "none", sm: "initial" }}
          />
        </GridItem>

        {/* Round overview */}
        <GridItem as={Container} area="stat" display={{ base: "none", lg: "inherit" }}>
          <RoundOverview
            round={round}
            totalPotValue={newPotValue}
            userEntriesValue={newUserEntriesValue}
            highlight
            p={4}
          />
        </GridItem>

        {/* User assets to be added */}
        <GridItem
          as={Container}
          area="cta"
          display={{ base: "none", lg: "flex" }}
          flexDirection="column"
          bg="ui-01"
          py={4}
        >
          <Flex justifyContent="space-between" alignItems="center" px={4} mb={4}>
            <Text textStyle="heading-04" bold>
              {t("yolo::Selection {{nbOfItems}}", { nbOfItems: totalNumOfItems })}
            </Text>
            <Button variant="outline" colorScheme="gray" size="xs" onClick={resetCart}>
              {t("yolo::Clear")}
            </Button>
          </Flex>
          {network === "ethereum" && <GasSelector px={4} mb={4} />}
          <UserCart />
          <VStack px={4}>
            {(() => {
              if (isWalletBlocked) {
                return (
                  <Button
                    rightIcon={<ErrorIcon boxSize={5} />}
                    variant="outline"
                    colorScheme="red"
                    width="100%"
                    isDisabled
                  >
                    {t("Wallet Blocked")}
                  </Button>
                );
              }

              return (
                <EnterButton
                  cartValue={userCartValue}
                  totalNumOfItems={Number(totalNumOfItems)}
                  onEnter={onClose}
                  round={round}
                  ethAmountRoundsCount={ethAmountRoundsCount}
                />
              );
            })()}
            {canRoundBeEntered && !hideEnterNowMultiplier && (
              <PointsMultiplierText
                cutoffTime={round.cutoffTime}
                roundDuration={round.roundDuration}
                onHelpClick={onModalPointsOpen}
              />
            )}
          </VStack>
        </GridItem>
        <MobileStickyBar
          display={{ base: "block", lg: "none" }}
          round={round}
          userEntriesValue={newUserEntriesValue}
          totalPotValue={newPotValue}
          highlight
          position="fixed"
          bottom={0}
          left={0}
        >
          {(() => {
            if (isWalletBlocked) {
              return (
                <Button
                  rightIcon={<ErrorIcon boxSize={5} />}
                  variant="outline"
                  colorScheme="red"
                  width="100%"
                  isDisabled
                >
                  {t("Wallet Blocked")}
                </Button>
              );
            }

            return (
              <EnterButton
                cartValue={userCartValue}
                totalNumOfItems={Number(totalNumOfItems)}
                onEnter={onClose}
                round={round}
                ethAmountRoundsCount={ethAmountRoundsCount}
              />
            );
          })()}
        </MobileStickyBar>
      </Grid>
    </>
  );
};
