import type { Address } from "viem";
import { isAddressEqual } from "@looksrare/utils";
import { type Round } from "../types";

/**
 * Returns the total amount deposited to a round for a given address
 */
export const getTotalDepositsByAddress = (address: Address, deposits: Round["deposits"]): bigint => {
  return deposits.reduce((accum, deposit) => {
    if (isAddressEqual(address, deposit.depositor.address)) {
      return accum + BigInt(deposit.amount);
    }
    return accum;
  }, 0n);
};
