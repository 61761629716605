import { Badge, Flex, HStack, StackProps, Tab, TabList, Tabs, TabsProps, VStack } from "@chakra-ui/react";
import NextLink from "next/link";
import { chainIconMap, Text, useGameSupportedNetworks } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import upperFirst from "lodash/upperFirst";
import { getChainIdFromSupportedNetwork } from "@looksrare/utils/chain";
import { YoloSupportedNetworks } from "../../types";
import { useYoloConfig } from "../../config";

// @note this order must exactly match the order of networks passed in GameProvider `supportedNetworks`
enum TabIndex {
  ARBITRUM,
  ETHEREUM,
}

interface Props {
  currentNetwork: YoloSupportedNetworks;
  hrefBuilder?: (network: keyof typeof YoloSupportedNetworks) => string; // keyof to make it compatible with SupportedNetworks
}

export const ButtonStackNetworkSelector = ({ currentNetwork, ...props }: Props & StackProps) => {
  const { t } = useTranslation();
  const { basePath } = useYoloConfig();
  const supportedNetworks = useGameSupportedNetworks();
  return (
    <VStack alignItems="stretch" spacing={1} {...props}>
      {supportedNetworks.map((network) => {
        const shouldShowLowFeesBadge = network !== "ethereum";
        const Icon = chainIconMap[getChainIdFromSupportedNetwork(network)];
        return (
          <Flex
            key={network}
            py={3}
            px={2}
            layerStyle="clickable"
            borderRadius="button"
            width="100%"
            justifyContent="flex-start"
            {...(currentNetwork === network ? { backgroundColor: "ui-01" } : {})}
            as={NextLink}
            href={`/${basePath}/${network}`}
          >
            <HStack>
              <Icon boxSize={6} />
              <HStack>
                <Text>{upperFirst(network)}</Text>
                {shouldShowLowFeesBadge && (
                  <Badge bg="support-error-inverse" color="white">
                    {t("yolo::Low Fees!")}
                  </Badge>
                )}
              </HStack>
            </HStack>
          </Flex>
        );
      })}
    </VStack>
  );
};

export const TabNetworkSelector = ({
  currentNetwork,
  hrefBuilder = (network) => `/yolo/${network}`,
  ...props
}: Props & Omit<TabsProps, "children">) => {
  const { t } = useTranslation();
  const supportedNetworks = useGameSupportedNetworks();

  return (
    <Tabs
      position="relative"
      variant="blank"
      defaultIndex={currentNetwork === "arbitrum" ? TabIndex.ARBITRUM : TabIndex.ETHEREUM}
      mb={8}
      borderBottom="1px solid #2A3136"
      {...props}
    >
      <TabList justifyContent="flex-start">
        {supportedNetworks.map((network) => {
          const shouldShowLowFeesBadge = network !== "ethereum";
          const Icon = chainIconMap[getChainIdFromSupportedNetwork(network)];

          return (
            <NextLink href={hrefBuilder(network)} key={network}>
              <Tab>
                <HStack spacing={1}>
                  <Text>{upperFirst(network)}</Text>
                  {shouldShowLowFeesBadge && (
                    <Badge bg="support-error-inverse" color="white">
                      {t("yolo::Low Fees!")}
                    </Badge>
                  )}
                  <Icon boxSize={6} />
                </HStack>
              </Tab>
            </NextLink>
          );
        })}
      </TabList>
    </Tabs>
  );
};
