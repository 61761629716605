import { ModalBody, ModalHeader } from "@chakra-ui/react";
import { BulkTransactionStep, Text } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import { ClaimPrizesCalldata, UnclaimedPrize } from "../../../../../types";
import { BulkLooksApprovalsStep } from "../../shared/LooksApprovals";
import { ClaimV1TransactionStep } from "./ClaimV1TransactionStep";
import { ClaimV2TransactionStep } from "./ClaimV2TransactionStep";

interface Props {
  onComplete: () => void;
  claimPrizesCalldata: ClaimPrizesCalldata;
  unclaimedV1Prizes?: UnclaimedPrize[];
  isPayingFeesWithLooks: boolean;
  feesFromUserWallet: bigint;
}

export const ClaimTransactionView = ({
  onComplete,
  unclaimedV1Prizes,
  isPayingFeesWithLooks,
  claimPrizesCalldata,
  feesFromUserWallet,
}: Props) => {
  const { t } = useTranslation();
  const hasOnlyV2Claims = !unclaimedV1Prizes || unclaimedV1Prizes.length === 0;
  const hasOnlyV1Claims = !claimPrizesCalldata.length;

  const [subStep, setSubStep] = useState<"v1" | "v2">(hasOnlyV2Claims ? "v2" : "v1");
  const [requiresApprovals, setRequiresApprovals] = useState(isPayingFeesWithLooks);

  return (
    <>
      <ModalHeader>
        <Text bold textStyle="heading-04">
          {t("yolo::Claim")}
        </Text>
      </ModalHeader>
      <ModalBody bg="ui-01">
        {(() => {
          if (hasOnlyV2Claims) {
            return !isPayingFeesWithLooks ? (
              <ClaimV2TransactionStep
                isStepActive={subStep === "v2"}
                claimPrizesCalldata={claimPrizesCalldata}
                isPayingFeesWithLooks={isPayingFeesWithLooks}
                onComplete={onComplete}
                feesFromUserWallet={feesFromUserWallet}
                ctaText={t("yolo::Claim Prizes")}
              />
            ) : (
              <>
                <BulkLooksApprovalsStep
                  status={requiresApprovals ? "current" : "past"}
                  amount={feesFromUserWallet}
                  onComplete={() => setRequiresApprovals(false)}
                />
                <BulkTransactionStep
                  title={t("yolo::Claim Prizes")}
                  status={requiresApprovals ? "future" : "current"}
                  collapse={requiresApprovals}
                >
                  <ClaimV2TransactionStep
                    isStepActive={subStep === "v2"}
                    claimPrizesCalldata={claimPrizesCalldata}
                    isPayingFeesWithLooks={isPayingFeesWithLooks}
                    onComplete={onComplete}
                    feesFromUserWallet={feesFromUserWallet}
                    ctaText={t("yolo::Claim Prizes")}
                  />
                </BulkTransactionStep>
              </>
            );
          }
          if (hasOnlyV1Claims && !!unclaimedV1Prizes) {
            return (
              <ClaimV1TransactionStep
                isStepActive={subStep === "v1"}
                unclaimedV1Prizes={unclaimedV1Prizes}
                onComplete={onComplete}
              />
            );
          }
          return (
            // Has both V1 and V2 claims
            <>
              <BulkTransactionStep title={t("yolo::Claim V1 Prizes")} status="current">
                {unclaimedV1Prizes && (
                  <ClaimV1TransactionStep
                    isStepActive={subStep === "v1"}
                    unclaimedV1Prizes={unclaimedV1Prizes}
                    onSkip={() => setSubStep("v2")}
                    onComplete={() => setSubStep("v2")}
                  />
                )}
              </BulkTransactionStep>
              {!isPayingFeesWithLooks ? (
                <ClaimV2TransactionStep
                  isStepActive={subStep === "v2"}
                  claimPrizesCalldata={claimPrizesCalldata}
                  isPayingFeesWithLooks={isPayingFeesWithLooks}
                  onComplete={onComplete}
                  feesFromUserWallet={feesFromUserWallet}
                  ctaText={t("yolo::Claim Prizes")}
                />
              ) : (
                <>
                  <BulkLooksApprovalsStep
                    status={requiresApprovals ? "current" : "past"}
                    amount={feesFromUserWallet}
                    onComplete={() => setRequiresApprovals(false)}
                  />
                  <BulkTransactionStep
                    title={t("yolo::Claim Prizes")}
                    status={requiresApprovals ? "future" : "current"}
                    collapse={requiresApprovals}
                  >
                    <ClaimV2TransactionStep
                      isStepActive={subStep === "v2"}
                      claimPrizesCalldata={claimPrizesCalldata}
                      isPayingFeesWithLooks={isPayingFeesWithLooks}
                      onComplete={onComplete}
                      feesFromUserWallet={feesFromUserWallet}
                      ctaText={t("yolo::Claim Prizes")}
                    />
                  </BulkTransactionStep>
                </>
              )}
            </>
          );
        })()}
      </ModalBody>
    </>
  );
};
