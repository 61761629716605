import React, { useEffect, useState } from "react";
import { Divider } from "@chakra-ui/react";
import { Address } from "viem";
import { useTranslation } from "next-i18next";
import { isAddressEqual } from "@looksrare/utils";
import { BulkTransactionStep, BulkTransactionStepProps, StepStatus, useGameAddresses } from "@looksrare/uikit";
import { useGetApprovals } from "../../../utils/contract/useGetApprovals";
import { useYoloCartStore } from "../../depositAssets/assetsState";
import { ApprovalNft } from "./ApprovalNft";
import { ApprovalErc20 } from "./ApprovalErc20";

interface Props extends Omit<BulkTransactionStepProps, "title"> {
  status: StepStatus;
  isCurrentStep: boolean;
  onComplete: () => void;
}

export const StepApproveAssets = ({ status, isCurrentStep, onComplete, ...props }: Props) => {
  const { t } = useTranslation();
  const { USDT, YOLO_TRANSFER_MANAGER } = useGameAddresses();
  const { collections, tokens } = useYoloCartStore();
  const { data: approvals, isLoading } = useGetApprovals(collections);
  const [current, setCurrent] = useState(0);

  const collectionAddresses = Object.keys(collections) as Address[];
  const erc20Addresses = Object.keys(tokens) as Address[];
  const totalSteps = collectionAddresses.length + erc20Addresses.length;

  useEffect(() => {
    if (isCurrentStep && current === totalSteps) {
      onComplete();
    }
  }, [current, isCurrentStep, onComplete, totalSteps]);

  return (
    <BulkTransactionStep
      status={status}
      title={t("yolo::Approval ({{totalSteps}})", { totalSteps: totalSteps })}
      description={t("yolo::This enables us to transfer the specific assets you've selected.")}
      {...props}
    >
      {erc20Addresses.map((tokenAddress, index) => {
        const token = tokens[tokenAddress];
        return (
          <>
            <ApprovalErc20
              key={tokenAddress}
              tokenAddress={tokenAddress}
              tokenName={token.name}
              amount={token.amount}
              step={index}
              isCurrent={isCurrentStep && index === current}
              next={() => setCurrent((prev) => prev + 1)}
              spenderAddress={YOLO_TRANSFER_MANAGER}
              isUsdt={isAddressEqual(tokenAddress, USDT)}
            />
            {(index < tokenAddress.length - 1 || collectionAddresses.length > 1) && <Divider my={4} />}
          </>
        );
      })}
      {isLoading || !approvals
        ? "loading..."
        : collectionAddresses.map((collectionAddress, index) => {
            const isApproved = approvals[index];
            const collection = collections[collectionAddress];
            return (
              <>
                <ApprovalNft
                  key={collectionAddress}
                  address={collectionAddress}
                  collectionName={collection.name}
                  isAlreadyApproved={isApproved}
                  isCurrent={isCurrentStep && index + erc20Addresses.length === current}
                  next={() => setCurrent((prev) => prev + 1)}
                  step={index + erc20Addresses.length}
                />
                {index < collectionAddresses.length - 1 && <Divider my={4} />}
              </>
            );
          })}
    </BulkTransactionStep>
  );
};
