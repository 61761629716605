import { useEffect, useMemo } from "react";
import { Table, Tr, Td, Tbody, Thead, Th, Flex, Box, Checkbox } from "@chakra-ui/react";
import { Address } from "viem";
import { useAccount } from "wagmi";
import { useTranslation } from "next-i18next";
import { EthHalfIcon, CloudinaryImage } from "@looksrare/uikit";
import {
  ReservoirOracleResponse,
  formatToSignificant,
  toDecimals,
  useOracleFloorData,
  StateSetter,
} from "@looksrare/utils";
import { RarityTag, getHighestAttributeTraitPrice } from "@looksrare/nfts";
import { useIntersectionObserver } from "@looksrare/utils";
import { SectionLoader, Text } from "@looksrare/uikit";
import { TokenType, ContractVersion } from "../../../types";
import { truncateToContractPrecision } from "../../../utils/truncate";
import { PayloadAddNft, PayloadRemoveNft, YoloCartAssets } from "../assetsState";
import { TWAP_WINDOW_BY_VERSION, useYoloConfig } from "../../../config";
import { useInfiniteUserTokens, useTokenAttributes } from "../../../utils";

interface Props {
  collectionAddress: Address;
  selectedNfts: YoloCartAssets["collections"][Address]["nfts"];
  addNFT: StateSetter<PayloadAddNft>;
  removeNFT: StateSetter<PayloadRemoveNft>;
  roundVersion: ContractVersion;
  roundValuePerEntry: bigint;
}

type NFTCard = any; //@TODO migrate nft types

export const NftRow = ({
  collectionAddress,
  addNFT,
  removeNFT,
  nft,
  isChecked,
  oracle,
  roundValuePerEntry,
  ...props
}: Pick<Props, "collectionAddress" | "addNFT" | "removeNFT" | "roundValuePerEntry"> & {
  nft: NFTCard;
  isChecked: boolean;
  oracle: ReservoirOracleResponse | undefined;
}) => {
  const {
    referenceToken: { minDeposit },
  } = useYoloConfig();
  const attributesQuery = useTokenAttributes(nft.collection.address, nft.tokenId);
  const highestTraitPrice = useMemo(() => {
    if (attributesQuery.isLoading) {
      return null;
    }
    if (!!attributesQuery.data) {
      return getHighestAttributeTraitPrice(attributesQuery.data);
    }
    return 0n;
  }, [attributesQuery.data, attributesQuery.isLoading]);

  const onChangeSelection = (checked: boolean, current: NFTCard) => {
    if (oracle) {
      if (checked) {
        addNFT({
          collectionAddress: current.collection.address,
          name: current.collection.name,
          type: TokenType.ERC721,
          price: truncateToContractPrecision(oracle.price),
          message: oracle.message,
          nft: { image: current.image.src, tokenId: current.tokenId },
          minimumEntries: toDecimals(oracle.price.toString()) / roundValuePerEntry,
        });
      } else {
        removeNFT({ collectionAddress, tokenId: current.tokenId });
      }
    }
  };

  return (
    <Tr
      bg={isChecked ? "ui-01" : "inherit"}
      _hover={{ bg: "hover-ui" }}
      transition="background-color 0.2s ease-in-out"
      {...props}
    >
      <Td>
        <Flex width="fit-content" cursor="default" alignItems="center">
          <Checkbox
            mr={4}
            onChange={(e) => onChangeSelection(e.target.checked, nft)}
            isDisabled={!oracle || oracle?.price < minDeposit}
            isChecked={isChecked}
          />

          <Flex alignItems="center">
            <Box width="40px" mr={2} position="relative" borderRadius="button" overflow="hidden">
              <CloudinaryImage
                src={nft.image.src}
                alt={nft.tokenId}
                layout="fill"
                objectFit="contain"
                priority={true}
                sizes="(max-width: 48rem) 95vw, (max-width: 90rem) 60vw, 536px"
                quality="auto:best"
              />
            </Box>
            <Flex flexDirection={{ base: "column", sm: "row" }} alignItems="center">
              <Text color="text-02" textStyle="helper">{`#${nft.tokenId}`}</Text>
              {nft.rarity && !!nft.collection.totalSupply && (
                <RarityTag
                  rarity={nft.rarity}
                  totalSupply={nft.collection.totalSupply}
                  mt={{ base: 1, sm: 0 }}
                  ml={{ sm: 2 }}
                />
              )}
            </Flex>
          </Flex>
        </Flex>
      </Td>
      <Td textAlign="right">{highestTraitPrice ? formatToSignificant(highestTraitPrice, 4) : ""}</Td>
      <Td textAlign="right">
        {oracle && (
          <Flex alignItems="center" justifyContent="flex-end">
            <EthHalfIcon boxSize={4} />
            <Text textStyle="detail" bold>
              {!!oracle.price ? formatToSignificant(toDecimals(oracle.price.toString()), 2) : "-"}
            </Text>
          </Flex>
        )}
      </Td>
    </Tr>
  );
};

export const AddNfts = ({
  roundVersion,
  collectionAddress,
  addNFT,
  removeNFT,
  selectedNfts,
  roundValuePerEntry,
}: Props) => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const {
    referenceToken: { minDeposit },
  } = useYoloConfig();

  const { observerRef, isIntersecting } = useIntersectionObserver("800px");
  const {
    data: nftsRes,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteUserTokens({
    address: address!,
    filter: { collection: collectionAddress },
    sort: "PRICE_DESC" as any,
  });
  const nfts = useMemo(() => nftsRes && nftsRes.pages.flat(), [nftsRes]);
  const { data: oracle } = useOracleFloorData(
    collectionAddress,
    TWAP_WINDOW_BY_VERSION[roundVersion], // These values need to mirror what's done by the contract
    {
      refetchInterval: 10_000,
      gcTime: 10_000,
      staleTime: Infinity, // Data is re-fetched on interval
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  useEffect(() => {
    if (hasNextPage && isIntersecting && !isFetching) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetching, isIntersecting]);

  return (
    <>
      {oracle && oracle?.price < minDeposit && (
        <Text textAlign="center" color="text-error">
          {t("yolo::This collection floor price dropped below {{min}} ETH thus cannot be used for to YOLO", {
            min: minDeposit,
          })}
        </Text>
      )}
      <Table>
        <Thead>
          <Tr>
            <Th>{t("yolo::Token")}</Th>
            <Th textAlign="right">{t("yolo::Top Property")}</Th>
            <Th textAlign="right">{t("yolo::In-game Value")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {nfts?.map((nft) => {
            const isChecked = !!selectedNfts.find((selected) => selected.tokenId === nft.tokenId);
            return (
              <NftRow
                key={nft.id}
                nft={nft}
                isChecked={isChecked}
                addNFT={addNFT}
                removeNFT={removeNFT}
                collectionAddress={collectionAddress}
                oracle={oracle}
                roundValuePerEntry={roundValuePerEntry}
              />
            );
          })}
          <Tr>
            <Td>
              <div ref={observerRef} />
            </Td>
          </Tr>
        </Tbody>
      </Table>
      {isFetching && (
        <Flex flexDirection="column" alignItems="center" p={8}>
          <SectionLoader />
        </Flex>
      )}
    </>
  );
};
