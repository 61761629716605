import React from "react";
import { useTranslation } from "next-i18next";
import { ModalBody } from "@chakra-ui/react";
import { ModalLegacy, ModalLegacyProps } from "@looksrare/uikit";
import { Round } from "../../../types";
import { TransactionView } from "./TransactionView";

interface Props extends Omit<ModalLegacyProps, "children"> {
  round: Round;
  onEnter: () => void;
  cartValue: bigint;
}

export const EnterYoloModal: React.FC<Props> = ({ round, onEnter, cartValue, ...props }) => {
  const { t } = useTranslation();
  return (
    <ModalLegacy title={t("yolo::Confirm Entries")} closeOnOverlayClick={false} {...props}>
      <ModalBody>
        <TransactionView round={round} onEnter={onEnter} cartValue={cartValue} />
      </ModalBody>
    </ModalLegacy>
  );
};
