import { BigIntish } from "@looksrare/utils";
import { useTranslation } from "next-i18next";
import { useMemo } from "react";

export enum RarityLevel {
  COMMON,
  UNCOMMON,
  RARE,
  EPIC,
  LEGENDARY,
  ONE_OF_ONE,
}

type RarityType = "token" | "attribute";

export const useRarityLevel = (
  rankBn?: BigIntish,
  totalSupplyBn?: BigIntish | null,
  // handle token and attribute rarity differently
  rarityType: RarityType = "token"
): RarityLevel => {
  // Convert to string to avoid re-rendering when BigIntish reference changes
  const rankString = rankBn?.toString();
  const totalSupplyString = totalSupplyBn?.toString();

  return useMemo(() => {
    if (!!rankString && !!totalSupplyString) {
      try {
        const rankNumber = parseInt(rankString);
        const supplyNumber = parseInt(totalSupplyString);
        const percent = (rankNumber / supplyNumber) * 100;

        /**
         * @note Currently "One of One" is only assigned to attributes, not tokens.
         * @todo If all attributes become available, or if BE provides a boolean indicating
         * a token is one of one, we can update this logic to handle tokens as well. Current limitation
         * is due to attributes not being fetched as part of the NftCard query.
         */
        if (rankNumber === 1 && rarityType === "attribute") {
          return RarityLevel.ONE_OF_ONE;
        }
        if (percent <= 0.1) {
          return RarityLevel.LEGENDARY;
        }
        if (percent <= 1) {
          return RarityLevel.EPIC;
        }
        if (percent <= 10) {
          return RarityLevel.RARE;
        }
        if (percent <= 50) {
          return RarityLevel.UNCOMMON;
        }
        return RarityLevel.COMMON;
      } catch (error) {
        return RarityLevel.COMMON;
      }
    }
    return RarityLevel.COMMON;
  }, [rankString, rarityType, totalSupplyString]);
};

const defaultColor = "gray";

export const rarityLevelToColor = (rarityLevel: RarityLevel): string => {
  switch (rarityLevel) {
    case RarityLevel.COMMON:
      return "gray";
    case RarityLevel.UNCOMMON:
      return "green";
    case RarityLevel.RARE:
      return "blue";
    case RarityLevel.EPIC:
      return "purple";
    case RarityLevel.LEGENDARY:
      return "orange";
    case RarityLevel.ONE_OF_ONE:
      return "yellow";
    default:
      return defaultColor;
  }
};

export const useRarityText = (rarityLevel: RarityLevel): string => {
  const { t } = useTranslation();
  const rarityText = {
    [RarityLevel.COMMON]: t("Common"),
    [RarityLevel.UNCOMMON]: t("Uncommon"),
    [RarityLevel.RARE]: t("Rare"),
    [RarityLevel.EPIC]: t("Epic"),
    [RarityLevel.LEGENDARY]: t("Legendary"),
    [RarityLevel.ONE_OF_ONE]: t("One of One"),
  }[rarityLevel];
  return rarityText;
};
