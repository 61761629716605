import { AutoTransactionStepRow, TransactionSetter } from "@looksrare/uikit";
import { useHandleModalStep } from "@looksrare/utils";
import { usePublicClient } from "wagmi";
import { useYoloConfig } from "../../../../config";
import { ClaimPrizesCalldata } from "../../../../types";

interface Props {
  onComplete: () => void;
  claimRefundsCalldata: ClaimPrizesCalldata;
  isStepActive: boolean;
  ctaText: string;
}

export const WithdrawAllStep = ({ onComplete, claimRefundsCalldata, isStepActive, ctaText }: Props) => {
  const {
    contract: { useWithdrawDeposits },
  } = useYoloConfig();

  const withdrawDeposits = useWithdrawDeposits();
  const publicClient = usePublicClient();

  const useHandleTransaction = (setTransaction: TransactionSetter) =>
    useHandleModalStep({
      onSubmit: async () => {
        if (!publicClient) {
          throw new Error("No public client found");
        }
        const hash = await withdrawDeposits(claimRefundsCalldata);
        setTransaction(hash);
        const receipt = await publicClient.waitForTransactionReceipt({ hash });
        if (receipt.status === "success") {
          setTransaction(undefined);
          onComplete();
        } else {
          throw new Error(`Withdrawing refunds failed. Transaction hash: ${receipt.transactionHash}`);
        }
      },
    });

  return (
    <AutoTransactionStepRow isStepActive={isStepActive} useHandleTransaction={useHandleTransaction} ctaText={ctaText} />
  );
};
