import { useWidgetBotCrate } from "@looksrare/utils";
import { useWidgetBotOptions } from "@looksrare/uikit";
import { History } from "../components/history";
import { Layout } from "../components/Layout";
import { Round } from "../types";
import { Main } from "../components/round";

/**
 * Round view for a past round
 */
export const HistoryRoundView = ({ round }: { round: Round }) => {
  useWidgetBotCrate(useWidgetBotOptions());

  return (
    <Layout withBackgroundImage>
      <Main round={round} isHistoricRound />
    </Layout>
  );
};

/**
 * List of past rounds
 */
export const HistoryView = () => {
  useWidgetBotCrate(useWidgetBotOptions());

  return (
    <Layout>
      <History />
    </Layout>
  );
};
