import { useState, useEffect } from "react";

export const useCountdownPercentage = (cutoffTime: number, duration: number, callback?: () => void) => {
  const [timerPercentage, setTimerPercentage] = useState(0);
  const startTimestamp = cutoffTime - duration;

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = Date.now() / 1000;
      const percentage = Math.min(Math.max(((currentTime - startTimestamp) / duration) * 100, 0), 100);

      callback && callback();

      if (!duration) {
        clearInterval(intervalId);
      } else {
        setTimerPercentage(percentage);
      }
    }, 1000);

    return () => clearInterval(intervalId);
    // Omit callback from deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration, startTimestamp]);

  return timerPercentage;
};
