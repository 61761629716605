import { Address } from "viem";
import { BigIntish, ReservoirOracleFloorPriceMessage } from "@looksrare/utils";
import { Duration } from "date-fns";

//Copied over from marketplace
enum RarityProvider {
  RARITY_SNIPER = "RARITY_SNIPER",
  LOOKSRARE = "LOOKSRARE",
}

interface RarityData {
  provider: RarityProvider;
  rank: BigIntish;
  url: string;
}

export interface ImageData {
  src: string;
  contentType?: string;
}

// ENUMS
export enum YoloSupportedNetworks {
  ethereum = "ethereum",
  arbitrum = "arbitrum",
  blast = "blast",
}

// Iterations on the contract. Can be deployed on different networks
export enum ContractVersion {
  V1 = "V1",
  V2 = "V2",
  V1_LIMITED = "V1_LIMITED",
}

// Contract names, usually scoped to a network so that BE can quickly find the contract address
export enum YoloContractName {
  YOLO_V1 = "YOLO_V1",
  YOLO_V2 = "YOLO_V2",
  YOLO_V2_ARBITRUM = "YOLO_V2_ARBITRUM",
  YOLO_V2_BLAST = "YOLO_V2_BLAST",
  YOLO_LIMITED_V1_BLAST = "YOLO_LIMITED_V1_BLAST",
}

export enum RoundStatus {
  None = "NONE",
  Open = "OPEN",
  Drawing = "DRAWING",
  Drawn = "DRAWN",
  Cancelled = "CANCELLED",
}

export enum TokenType {
  ETH = "ETH", // TODO-yolo-l2 rename to "NATIVE"
  ERC20 = "ERC20",
  ERC721 = "ERC721",
}

export enum SortRoundBy {
  NEWEST = "NEWEST",
  OLDEST = "OLDEST",
  ROUND_VALUE_ASC = "ROUND_VALUE_ASC",
  ROUND_VALUE_DESC = "ROUND_VALUE_DESC",
  WIN_MULTIPLE_ASC = "WIN_MULTIPLE_ASC",
  WIN_MULTIPLE_DESC = "WIN_MULTIPLE_DESC",
}

// CONTRACT INPUTS

type TokenTypeContract = 0 | 1 | 2;

export const TokenTypeContractId: Record<TokenType, TokenTypeContract> = {
  [TokenType.ETH]: 0,
  [TokenType.ERC20]: 1,
  [TokenType.ERC721]: 2,
};

export interface DepositCalldata {
  tokenType: TokenTypeContract;
  tokenAddress: string;
  tokenIdsOrAmounts: BigIntish[];
  reservoirOracleFloorPrice: ReservoirOracleFloorPriceMessage;
  minimumEntries: bigint; // Slippage protection
}

export interface ClaimPrizesCalldataV1 {
  roundId: BigIntish;
  prizeIndices: BigIntish[];
}

export type ClaimPrizesCalldata = { roundId: bigint; depositIndices: readonly bigint[] }[];

// API

type YoloDepositorMetrics = {
  biggestETHWin: BigIntish;
  biggestWinMultiple: number;
  totalRoundsWon: number;
  totalRoundsPlayed: number;
};

export type Depositor = {
  name?: string;
  address: Address;
  isVerified?: boolean;
  isProfileImageVisible?: boolean | null;
  avatar?: {
    image: ImageData;
  };
};

export type DepositorWithMetrics = Depositor & {
  yoloMetrics: YoloDepositorMetrics;
};

type DepositToken = {
  id: BigIntish;
  index: number;
  type: TokenType;
  currency: Address;
  amount: BigIntish;
  gemsEarned: BigIntish | null;
  numberOfEntries: BigIntish;
  claimed: boolean;
  token: null;
  depositor: Depositor;
};

export type DepositNft = Omit<DepositToken, "token"> & {
  token: {
    tokenId: BigIntish;
    image: ImageData;
    rarity: RarityData[] | [];
    collection: {
      name: string;
      totalSupply: BigIntish | null;
      isVerified?: boolean;
      address: Address;
    };
  };
};

export type Deposit = DepositToken | DepositNft;

export const isDepositNft = (deposit: Deposit): deposit is DepositNft =>
  deposit.type === TokenType.ERC721 && "token" in deposit;

// @note BE requested we remove gemsEarned from the history queries only
export type HistoryDeposit = Omit<Deposit, "gemsEarned">;

type StartedRound = {
  onChainId: number;
  status: RoundStatus;
  cutoffTime: number;
  numberOfParticipants: number;
  maximumNumberOfDeposits: number;
  maximumNumberOfParticipants: number;
  entriesCount: number;
  valuePerEntry: BigIntish;
  protocolFeeBp: BigIntish;
  potValue: BigIntish;
  drawnHash?: string;
  winnerMultiple?: number;
  deposits: Deposit[];
  winner?: Depositor;
  contract: YoloContractName;
  roundDuration: number;
};

type IdleRound = Omit<StartedRound, "cutoffTime" | "status"> & {
  // A round has no cutoffTime until a player enters
  cutoffTime: null;
  status: RoundStatus.Open;
};

export type Round = StartedRound | IdleRound;

export interface HistoryRound extends Omit<StartedRound, "deposits"> {
  deposits: HistoryDeposit[];
}

export interface UnclaimedRefund {
  round: {
    onChainId: Round["onChainId"];
    valuePerEntry: Round["valuePerEntry"];
    contract: Round["contract"];
  };
  amount: Deposit["amount"];
  currency: Deposit["currency"];
  index: Deposit["index"];
  numberOfEntries: Deposit["numberOfEntries"];
}

export interface UnclaimedPrize extends UnclaimedRefund {
  round: UnclaimedRefund["round"] & {
    protocolFeeBp: number;
  };
  token: {
    tokenId: DepositNft["token"]["tokenId"];
    collection: DepositNft["token"]["collection"];
  } | null;
}

export interface Participant {
  yoloUnclaimedPrizes: UnclaimedPrize[];
  yoloUnclaimedRefunds: UnclaimedRefund[];
}

// App

export interface CurrentRoundProps {
  timeBeforeNextRound?: number;
  finishRound?: (callback: () => void) => void;
  duration?: Duration;
  isHistoricRound: boolean;
}

export enum GasMultiplier {
  DEFAULT = 1,
  FAST = 1.8,
}

export type GroupedDepositsByType = Partial<Record<Deposit["type"], Deposit[]>>;
export type GroupedErc20ByAddress = Record<Address, Deposit[]>;
