import { constants } from "ethers";
import { CurrencyConfig, ReservoirOracleFloorPriceMessage } from "@looksrare/utils";
import { Context, FC, useContext } from "react";
import { GameContext, GameProviderContext, Icon, IconProps, RainbowTextProps } from "@looksrare/uikit";
import { BoxProps } from "@chakra-ui/react";
import { GameAddresses } from "@looksrare/config/types";
import { Address } from "viem";
import {
  useClaimPrizes,
  useDeposit,
  useDepositEthIntoMultipleRounds,
  useGetClaimPrizesPaymentRequired,
  useReadOracleSignatureValidityPeriod,
  useRollOverEthToNextRound,
  useWithdrawDeposits,
  getYoloContractNameFromNetworkAndVersion,
  getYoloContractNamesFromNetwork,
} from "../utils";
import { ContractVersion, YoloSupportedNetworks } from "../types";
import { AddErc20 } from "../components/depositAssets/AddPanel/AddErc20";
import { WarningModal } from "../components/modals/WarningModal";
import { PotItemsListComponentProps } from "../components/round/PotItemsList";
import { StepSendToPotsMultipleRound } from "../components/modals/EnterYoloModal/StepSendToPotsMultipleRounds";
import { GemMessage } from "../components/round/points/GemMessage";
import { YoloPointsExplanationModal } from "../components/round/points/YoloPointsExplanationModal";
import { Help } from "../views/HelpView";
import { getUserColor } from "./colors";

export const LOCAL_STORAGE_YOLO_WARNING = "warning_yolo";
export const LOCAL_STORAGE_YOLO_INPUT_ETH = "eth_input_yolo";
export const MAX_YOLO_ROUNDS = 150; // hard cap

export const TWAP_WINDOW_BY_VERSION: Record<ContractVersion, number> = {
  [ContractVersion.V1]: 86400,
  [ContractVersion.V2]: 3600,
  [ContractVersion.V1_LIMITED]: 3600,
} as const;

export const defaultOracleMessage: ReservoirOracleFloorPriceMessage = {
  id: constants.HashZero,
  payload: constants.HashZero,
  timestamp: 0n,
  signature: constants.HashZero,
};

export const VRF_CHAINLINK_URL = "https://docs.chain.link/vrf/v2/introduction";
export const VRF_GELATO_URL = " https://docs.gelato.network/web3-services/vrf/understanding-vrf";

export * from "./colors";
export * from "./gems";

export interface YoloConfig {
  //@TODO make these properties optional
  /** Base path for the game, used to build links. Do *not* add trailing/leading slash in its definition */
  basePath: string;
  /** Used to display cumulative values in CTAs */
  referenceToken: CurrencyConfig & {
    minDeposit: number;
    maxDisplayDecimals: number;
    maxPrecision: number; // Number of decimals that entries must be divisible by
    maxDeposit?: number;
  };
  supportedTokens: (keyof GameAddresses)[];
  /** Hide local mute button and use the one in the nav */
  useGlobalMuteButton: boolean;
  /** Pick a points preset */
  supportsMetrics: boolean;
  getUserColor: typeof getUserColor;
  points: {
    enabled: boolean;
    hideEnterNowMultiplier: boolean;
    hideMultiplierInRoundOverview: boolean;
    label: string;
    Icon: FC<IconProps> | null;
    accentColor: string;
    bgGradient: string;
    /** Bonus points for first entrant */
    bonusPoints: number;
  };
  /** Show/hide nfts related UI */
  supportNfts: boolean;
  /** Show/hide erc20 tokens related UI */
  supportErc20: boolean;

  supportNativeToken: boolean;
  /** Removes animated bg from enter view*/
  removeRoundCtaBg: boolean;
  /** Show/hide links to /accounts */
  supportsUserProfiles: boolean;
  /** Show/hide the logo when the cart is empty */
  hideLogoInCart: boolean;
  /** Whether the UI should try and load /images/yolo/jackpot-background-[dark | light].png bg image */
  hasBackgroundImage: boolean;
  /** Mobile sticky bar props */
  mobileStickyBarProps?: BoxProps;
  /** List of predefined shortcuts for values */
  depositPresets: string[];

  round: {
    ctaArea: {
      props: BoxProps;
    };
  };

  defaultDepositContractName: keyof GameAddresses;
  isRolloverEnabled: boolean;
  contract: {
    /** The contract to use for gameplay */
    version: ContractVersion;
    /** What contract should be picked from game addresses e.g. during approvals */
    identifier: keyof GameAddresses;
    useDeposit: typeof useDeposit;
    useDepositEthIntoMultipleRounds: typeof useDepositEthIntoMultipleRounds;
    useClaimPrizes: typeof useClaimPrizes;
    useWithdrawDeposits: typeof useWithdrawDeposits;
    useGetClaimPrizesPaymentRequired: typeof useGetClaimPrizesPaymentRequired;
    useRollOverEthToNextRound: typeof useRollOverEthToNextRound;
    useReadOracleSignatureValidityPeriod: typeof useReadOracleSignatureValidityPeriod;
    getYoloContractNamesFromNetwork: typeof getYoloContractNamesFromNetwork;
    getYoloContractNameFromNetworkAndVersion: typeof getYoloContractNameFromNetworkAndVersion;
  };
  //TODO move these under 'components'
  AddErc20: typeof AddErc20;
  TransferManagerIcon: typeof Icon;
  WinnerIcon: typeof Icon;
  CenterWinnerAvatar?: FC<{ address: Address; src?: string }>;
  PotItemsList: FC<PotItemsListComponentProps>;
  StepSendToPotsMultipleRound: typeof StepSendToPotsMultipleRound;
  GemMessage: typeof GemMessage;
  YoloPointsExplanationModal: typeof YoloPointsExplanationModal;
  EyeCatchingText: FC<RainbowTextProps>;
  WarningModal: typeof WarningModal;
  PlayerViewProfileCTA: null | FC<{ userAddress: Address }>;
  /* Not completely supported on the FE */
  GameExplanationCard?: FC;
  roulette: {
    CenterActiveMain: FC<{ onChainId: number; totalValue: number; network: YoloSupportedNetworks }>;
  };
  Help: typeof Help;
  selfTimeoutUntil?: string | null;
  isWalletBlocked: boolean;
  isMuted: boolean;
}

export const useYoloConfig = () => {
  const context = useContext(GameProviderContext as Context<GameContext<YoloConfig> | undefined>);
  if (!context?.config) {
    throw new Error("No config found");
  }

  return context.config;
};
