import { Box, Flex, Skeleton } from "@chakra-ui/react";
import { Text } from "@looksrare/uikit";
import { useAnimatedLayerStyle } from "@looksrare/utils";
import { useTranslation } from "next-i18next";
import { divideWeiByNumber, multiplyWeiByNumber } from "@looksrare/utils/bigint";
import { formatToSignificant } from "@looksrare/utils/formatting/formatToSignificant";
import { formatNumberToLocale } from "@looksrare/utils/formatting/formatNumberToLocale";
import { shallow } from "zustand/shallow";
import { YoloSupportedNetworks } from "../types";
import { useCurrentParticipantInfiniteFutureEntries } from "../utils";
import { NetworkNativeTokenIcon } from "./NetworkNativeTokenIcon";
import { useYoloCartStore } from "./depositAssets/assetsState";

interface Props {
  network: YoloSupportedNetworks;
}

export const FutureRoundsDatapoints = ({ network }: Props) => {
  const { ethAmountWei, ethAmountRoundsCount } = useYoloCartStore(
    (state) => ({ ethAmountRoundsCount: state.ethAmountRoundsCount, ethAmountWei: state.ethAmountWei }),
    shallow
  );
  const { t } = useTranslation();

  // Server state
  const { data: response, isLoading } = useCurrentParticipantInfiniteFutureEntries(network);
  const roundsEntered = response?.pages.flat();

  // Client state
  const futureRoundsUserInputCount = ethAmountRoundsCount - 1; // only ETH supports multiple rounds deposit atm

  const futureRoundsCount = Math.max(
    // using Math.max() because entering future rounds can overlap with already entered rounds
    roundsEntered?.[0].totalRoundsCount ?? 0,
    futureRoundsUserInputCount
  );

  const futureRoundsUserInputValueWei = multiplyWeiByNumber(BigInt(ethAmountWei), futureRoundsCount);

  const futureRoundsEntriesValueWei =
    BigInt(roundsEntered?.[0].totalWalletValueWei ?? 0n) + futureRoundsUserInputValueWei;
  const averagePerRound =
    futureRoundsCount === 0 ? 0n : divideWeiByNumber(futureRoundsEntriesValueWei, futureRoundsCount);

  const futureRoundsCountDisplay =
    isLoading || futureRoundsCount === 0
      ? "—"
      : t("{{futureRounds}} Round(s)", { futureRounds: formatNumberToLocale(futureRoundsCount, 0) });

  const futureRoundsEntriesValueDisplay =
    isLoading || futureRoundsEntriesValueWei === 0n
      ? "—"
      : formatToSignificant(futureRoundsEntriesValueWei, 4, { decimals: 18 });

  const averagePerRoundDisplay = isLoading ? "—" : formatToSignificant(averagePerRound);

  const animatedRoundsCountLayerStyle = useAnimatedLayerStyle(futureRoundsCount);
  const animatedTotalEntriesLayerStyle = useAnimatedLayerStyle(futureRoundsEntriesValueWei);

  return (
    <>
      <Box flex={1}>
        <Skeleton isLoaded={!isLoading}>
          <Text
            color={futureRoundsCount > 0 ? "text-01" : "text-03"}
            textStyle={{ base: "heading-05", sm: "heading-04" }}
            bold
            layerStyle={animatedRoundsCountLayerStyle}
            key={futureRoundsCountDisplay}
          >
            {futureRoundsCountDisplay}
          </Text>
        </Skeleton>
        <Text color="text-03" textStyle="helper">
          {t("yolo::Your Future Entries")}
        </Text>
      </Box>
      <Box flex={1}>
        <Flex
          alignItems="center"
          layerStyle={animatedTotalEntriesLayerStyle}
          key={futureRoundsEntriesValueWei.toString()}
        >
          <NetworkNativeTokenIcon network={network} boxSize={5} mr={2} />
          <Skeleton isLoaded={!isLoading} minWidth="60px">
            <Text
              color={futureRoundsEntriesValueWei > 0n ? "text-01" : "text-03"}
              textStyle={{ base: "heading-05", sm: "heading-04" }}
              bold
            >
              {futureRoundsEntriesValueDisplay}
            </Text>
          </Skeleton>
        </Flex>
        <Text color="text-03" textStyle="helper">
          {t("yolo::Total ({{averagePerRound}} Avg)", {
            averagePerRound: averagePerRoundDisplay,
          })}
        </Text>
      </Box>
    </>
  );
};
