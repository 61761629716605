import { useState } from "react";
import { VStack, Flex, Box } from "@chakra-ui/react";
import { navHeightResponsive } from "@looksrare/chakra-theme";
import { getUnixTime } from "date-fns";
import { useTranslation } from "next-i18next";
import { Text, getStepStatus, useGameAddresses } from "@looksrare/uikit";
import { Round, RoundStatus } from "../../../types";
import { useYoloCartStore } from "../../depositAssets/assetsState";
import { YoloRoundCountdown } from "../../YoloRoundCountdown";
import { useYoloConfig } from "../../../config";
import { StepApproveTransferManager } from "./StepApproveTransferManager";
import { StepApproveAssets } from "./StepApproveAssets";
import { StepSendToPots } from "./StepSendToPots";

enum Step {
  APPROVE_TRANSFER_MANAGER,
  APPROVE_ASSETS,
  SEND_TO_POT,
  COMPLETE,
}

interface TransactionViewProps {
  round: Round;
  onEnter: () => void;
  cartValue: bigint;
}

export const TransactionView: React.FC<React.PropsWithChildren<TransactionViewProps>> = ({
  round,
  onEnter,
  cartValue,
}) => {
  const { t } = useTranslation();
  const gameAddresses = useGameAddresses();
  const {
    contract: { identifier },
    TransferManagerIcon,
  } = useYoloConfig();
  const { collections, tokens } = useYoloCartStore();
  const hasEthOnly = Object.keys(collections).length === 0 && Object.keys(tokens).length === 0;
  const [step, setStep] = useState(hasEthOnly ? Step.SEND_TO_POT : Step.APPROVE_TRANSFER_MANAGER);
  const shouldWarnAboutRemainingTime = !!round.cutoffTime && round.cutoffTime - getUnixTime(new Date()) <= 30;

  // this scrollable area maxHeight accounts for the nav height and the
  // height within the sticky padding that is not scrollable
  const innerScrollAreaMaxHeight = {
    base: `calc(100vh - ${navHeightResponsive.base} - 14rem - 1px)`,
    md: `calc(100vh - ${navHeightResponsive.md} - 15rem - 1px)`,
  };

  return (
    <VStack spacing={4} pb={2} width="100%" sx={{ "> *": { width: "100%" } }}>
      <Box bg="ui-bg" borderRadius="container" p={4} mb={2}>
        <Flex justifyContent="space-between" alignItems="center">
          <Text color="text-02" bold>
            Round {round.onChainId}
          </Text>
          <YoloRoundCountdown
            cutoffTimeMs={round.cutoffTime}
            isCancelled={round.status === RoundStatus.Cancelled}
            isFinished={round.status === RoundStatus.Drawn}
          />
        </Flex>
        {shouldWarnAboutRemainingTime && (
          <Text color="text-warning" textStyle="detail" mt={3}>
            {t("yolo::Warning: less than 30 seconds left. Your transaction might not make it in time.")}
          </Text>
        )}
      </Box>
      <VStack alignItems="flex-start" spacing={4} maxHeight={innerScrollAreaMaxHeight} overflowY="auto">
        <StepApproveTransferManager
          status={getStepStatus(Step.APPROVE_TRANSFER_MANAGER, step)}
          collapse={step !== Step.APPROVE_TRANSFER_MANAGER}
          isCurrentStep={step === Step.APPROVE_TRANSFER_MANAGER}
          onComplete={() => setStep(Step.APPROVE_ASSETS)}
          transferMangerAddress={gameAddresses.YOLO_TRANSFER_MANAGER}
          transferManagerIcon={TransferManagerIcon}
          operatorAddress={gameAddresses[identifier]}
          width="100%"
        />
        <StepApproveAssets
          status={getStepStatus(Step.APPROVE_ASSETS, step)}
          collapse={step !== Step.APPROVE_ASSETS}
          isCurrentStep={step === Step.APPROVE_ASSETS}
          onComplete={() => setStep(Step.SEND_TO_POT)}
          width="100%"
        />
        <StepSendToPots
          status={getStepStatus(Step.SEND_TO_POT, step)}
          collapse={step !== Step.SEND_TO_POT}
          onComplete={onEnter}
          width="100%"
          round={round}
          cartValue={cartValue}
        />
      </VStack>
    </VStack>
  );
};
