import { Modal, ModalCloseButton, ModalProps, useGameAddresses } from "@looksrare/uikit";
import { useState } from "react";
import { fromDecimals, toDecimals } from "@looksrare/utils";
import delay from "lodash/delay";
import { useCurrentParticipant, useYoloQueryParams } from "../../../../utils";
import { getPendingClaims } from "../../../../utils/contract/getPendingClaims";
import { truncateToContractPrecision } from "../../../../utils/truncate";
import { ModalAction } from "../../types";
import { RollOverTransactionView } from "../shared/RollOverTransactionView";
import { useYoloConfig } from "../../../../config";
import { SuccessView } from "./SuccessView";
import { ActionSelectionView } from "./ActionSelectionView";
import { evaluateUnclaimedRefunds } from "./utils";
import { WithdrawAllTransactionView } from "./WithdrawAllTransactionView";

enum Views {
  ACTION_SELECTION = "actionSelection",
  TRANSACTIONS = "transactions",
  WITHDRAW_SUCCESS = "withdrawSuccess",
}

type WithdrawRolloverModalBody = Pick<ModalProps, "onClose">;

/**
 * Handles V2 (only) withdrawals and roll-overs
 */
export const WithdrawRolloverModalBody = ({ onClose }: WithdrawRolloverModalBody) => {
  const addresses = useGameAddresses();
  const { network } = useYoloQueryParams();
  const {
    contract: { version, getYoloContractNameFromNetworkAndVersion },
    isRolloverEnabled,
    referenceToken: { minDeposit, decimals, maxDisplayDecimals },
  } = useYoloConfig();

  const contractName = getYoloContractNameFromNetworkAndVersion(network, version);
  // The claim can be triggered by any v2 round but a user can claim all his v2 refunds at once
  const { data: participant, refetch } = useCurrentParticipant(network);
  const v2WithdrawalsAvailable = participant?.yoloUnclaimedRefunds.filter(
    ({ round }) => round.contract === contractName
  );

  const {
    unclaimedEth = 0n,
    unclaimedEthRefunds,
    unclaimedValue: unclaimedV2Value,
  } = evaluateUnclaimedRefunds(v2WithdrawalsAvailable || [], addresses.ETH);

  const { claimsByContractVersion } = getPendingClaims(v2WithdrawalsAvailable);
  const { claimsByContractVersion: ethOnlyClaimsByContractVersion } = getPendingClaims(unclaimedEthRefunds);

  const rollableOverAmount = (() => {
    const unclaimedEthFloat = parseFloat(fromDecimals(unclaimedEth));
    const truncatedRollableAmount = truncateToContractPrecision(unclaimedEthFloat, maxDisplayDecimals);
    return toDecimals(truncatedRollableAmount.toString());
  })();

  const canRollover = isRolloverEnabled && rollableOverAmount > toDecimals(minDeposit.toString(), decimals);

  const [view, setView] = useState<Views>(canRollover ? Views.ACTION_SELECTION : Views.TRANSACTIONS);
  const [selectedAction, setSelectedAction] = useState<ModalAction>(ModalAction.WithdrawAll);

  const refunds = Object.values(claimsByContractVersion[version]);
  const ethOnlyRefunds = Object.values(ethOnlyClaimsByContractVersion[version]);
  const roundsToWithdraw = Object.keys(claimsByContractVersion[version]).length;
  const hasOtherEntriesToWithdraw = ethOnlyRefunds.length !== roundsToWithdraw;

  const onWithdrawOtherEntries = async () => {
    await refetch(); // Wait for this to update the unclaimed prizes
    // Once a user has rolled over his rollable-entries, he might want to withdraw the rest
    setSelectedAction(ModalAction.WithdrawAll);
    setView(Views.TRANSACTIONS);
  };

  return (
    <>
      <ModalCloseButton alignSelf="flex-end" position="absolute" top={4} right={4} onClick={onClose} />
      {view === Views.ACTION_SELECTION && (
        <ActionSelectionView
          selectedAction={selectedAction}
          setSelectedAction={setSelectedAction}
          onComplete={() => setView(Views.TRANSACTIONS)}
          rollOverAmount={rollableOverAmount}
          roundsToWithdraw={roundsToWithdraw}
          claimableAmount={unclaimedV2Value}
          network={network}
          hasFees={false}
        />
      )}
      {view === Views.TRANSACTIONS &&
        (selectedAction === ModalAction.WithdrawAll ? (
          <WithdrawAllTransactionView
            claimRefundsCalldata={refunds}
            onComplete={() => setView(Views.WITHDRAW_SUCCESS)}
          />
        ) : (
          <RollOverTransactionView
            feesFromUserWallet={0n}
            isPayingFeesWithLooks={false}
            ethOnlyClaims={ethOnlyRefunds}
            onComplete={() => setView(Views.WITHDRAW_SUCCESS)}
            hasPlatformFees={false}
            rollableOverAmount={rollableOverAmount}
          />
        ))}
      {view === Views.WITHDRAW_SUCCESS && (
        <SuccessView
          rolledOverAmount={selectedAction === ModalAction.RollOver ? rollableOverAmount : 0n}
          onClose={() => {
            onClose();
            delay(refetch, 3000); // Let indexer catch up
          }}
          onWithdrawOtherEntries={hasOtherEntriesToWithdraw ? onWithdrawOtherEntries : undefined}
        />
      )}
    </>
  );
};

type Props = Omit<ModalProps, "children">;
export const WithdrawRolloverModal = ({ onClose, isOpen, ...props }: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      modalContentProps={{ overflow: "hidden" }}
      closeOnOverlayClick={false}
      {...props}
    >
      {isOpen && <WithdrawRolloverModalBody onClose={onClose} />}
    </Modal>
  );
};
