import React from "react";
import { Button } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { useAssertNetworkDisclosure } from "@looksrare/utils";
import { WinnerModal } from "../modals/v2/WinnerModal";
import { useYoloQueryParams } from "../../utils/chains";

export const ClaimWinningsButton = () => {
  const { network } = useYoloQueryParams();
  const { t } = useTranslation();
  const disclosure = useAssertNetworkDisclosure({ network });

  return (
    <>
      <WinnerModal isOpen={disclosure.isOpen} onClose={disclosure.onClose} />
      <Button width="100%" onClick={disclosure.onOpen} mb={3}>
        {t("yolo::Claim Winnings")}
      </Button>
    </>
  );
};
