import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { persist, createJSONStorage } from "zustand/middleware";
import { getStorageKey } from "@looksrare/utils";
import { GasMultiplier } from "../types";

interface YoloState {
  highlightedUser?: string;
  setHighlightedUser: (user?: string) => void;

  isSoundOn: boolean;
  toggleSound: () => void;

  gasMultiplier: GasMultiplier;
  setGasMultiplier: (gasMultiplier: GasMultiplier) => void;
}

export const useYoloStore = create(
  persist(
    immer<YoloState>((set) => ({
      highlightedUser: undefined,
      setHighlightedUser: (user) =>
        set((state) => {
          state.highlightedUser = user;
        }),
      isSoundOn: false,
      toggleSound: () => {
        set((state) => {
          state.isSoundOn = !state.isSoundOn;
        });
      },
      gasMultiplier: GasMultiplier.DEFAULT,
      setGasMultiplier: (gasMultiplier: GasMultiplier) => {
        set((state) => {
          state.gasMultiplier = gasMultiplier;
        });
      },
    })),
    {
      name: getStorageKey("yolo-state"),
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
