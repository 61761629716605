import React, { useMemo } from "react";
import { useTranslation } from "next-i18next";
import { Flex, FlexProps } from "@chakra-ui/react";
import { formatGwei } from "viem";
import { GasIcon, GasFilledIcon, HelpIcon, SniperIcon } from "@looksrare/uikit";
import { Button, ButtonToggle, TooltipText, Popover } from "@looksrare/uikit";
import { GasMultiplier } from "../../types";
import { useYoloStore } from "../../utils/yoloStore";
import { useGetGasPrice } from "../../utils/api/realtime";

export const GasSelector = (props: FlexProps) => {
  const { t } = useTranslation();
  const { gasMultiplier, setGasMultiplier } = useYoloStore();
  const gasWei = useGetGasPrice();

  const multiplier = useMemo(
    () => [
      { label: t("yolo::Default"), value: GasMultiplier.DEFAULT, ActiveIcon: GasFilledIcon, InactiveIcon: GasIcon },
      { label: t("yolo::Fast"), value: GasMultiplier.FAST, ActiveIcon: SniperIcon, InactiveIcon: SniperIcon },
    ],
    [t]
  );

  return (
    <Flex alignItems="center" {...props}>
      <ButtonToggle size="sm" flexGrow={1}>
        {multiplier.map(({ label, value, ActiveIcon, InactiveIcon }) => {
          const isActive = gasMultiplier === value;
          const multiplierGasValueWei = gasWei ? BigInt(Math.round(gasWei * value)) : null;
          const formattedGasWei = multiplierGasValueWei ? formatGwei(multiplierGasValueWei).replace(/\..*/g, "") : null;
          return (
            <Button
              key={value}
              variant={isActive ? "solid" : "outline"}
              onClick={() => setGasMultiplier(value)}
              color={isActive ? "link-01" : "link-02"}
              size="xs"
              flexGrow={1}
            >
              {isActive ? <ActiveIcon mr={1} /> : <InactiveIcon mr={1} />}
              {label}
              {formattedGasWei && ` (${formattedGasWei})`}
            </Button>
          );
        })}
      </ButtonToggle>
      {gasWei && (
        <Popover
          label={
            <TooltipText textStyle="detail">
              {t(
                "yolo::Current network fee. Enter rounds quicker with Fast mode: it increases your default gas by 50%."
              )}
            </TooltipText>
          }
        >
          <Flex height={4} ml={2}>
            <HelpIcon boxSize={4} color="text-03" />
          </Flex>
        </Popover>
      )}
    </Flex>
  );
};
