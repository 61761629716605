import React, { useMemo, useState } from "react";
import dynamic from "next/dynamic";
import NextLink from "next/link";
import groupBy from "lodash/groupBy";
import { Address } from "viem";
import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  IconButton,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useAccount } from "wagmi";
import { getUnixTime } from "date-fns";
import { useTranslation } from "next-i18next";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  ErrorIcon,
  HelpFilledIcon,
  JumpToEndIcon,
  LazyLoad,
  RecentlyViewedIcon,
  SelfExclusionModal,
  Text,
  useGameSupportedNetworks,
  VolumeMuteFilledIcon,
  VolumeUpFilledIcon,
} from "@looksrare/uikit";
import { useAssertNetworkDisclosure } from "@looksrare/utils";
import { useYoloConfig } from "../../config";
import { Container } from "../Container";
import { RoundOverview } from "../RoundOverview";
import { ContractVersion, CurrentRoundProps, Round, RoundStatus } from "../../types";
import { useRoundStartSound } from "../../utils/sounds";
import { useConnectedUserEntriesValue } from "../../utils/useConnectedUserEntriesValue";
import { getRoundLimits } from "../../utils/getRoundLimits";
import { MobileStickyBar } from "../MobileStickyBar";
import {
  useCurrentParticipant,
  getNetworkFromYoloContractName,
  getYoloContractVersionFromName,
  useCurrentParticipantInfiniteFutureEntries,
} from "../../utils";
import { WinnerModal } from "../modals/v2/WinnerModal";
import { WithdrawRolloverModal } from "../modals/v2/WithdrawRollOverModal";
import { useGetGasPrice, useYoloGasThresholdWei } from "../../utils/api/realtime";
import { FutureRoundsDatapoints } from "../FutureRoundsDatapoints";
import { PlayerTile, PlayerTileSkeleton } from "./PlayerTile";
import { ViewEntriesButton } from "./ViewEntriesButton";
import { Roulette } from "./Roulette";
import { useUserSorting } from "./useUserSorting";
import { ClaimWinningsButton } from "./ClaimWinningsButton";
import { Hitman } from "./Hitman";
import { PageViews } from "./PageViews";
import { PointsMultiplierText } from "./points/PointsMultiplierText";
import { RoundPaused } from "./RoundPaused";
import { ButtonStackNetworkSelector, TabNetworkSelector } from "./NetworkSelector";

const EnterButton = dynamic(() => import("./EnterButton"), {
  ssr: false,
});

interface Props extends CurrentRoundProps {
  round: Round;
  refetch?: () => void;
  onEnter?: () => void;
}

export const Main = ({ round, onEnter, refetch, finishRound, timeBeforeNextRound, isHistoricRound }: Props) => {
  const { t } = useTranslation();
  const canChooseNetwork = useGameSupportedNetworks().length > 1;
  const { address } = useAccount();
  const {
    supportNfts,
    supportErc20,
    PotItemsList,
    basePath,
    round: { ctaArea },
    points,
    removeRoundCtaBg,
    useGlobalMuteButton,
    GemMessage,
    YoloPointsExplanationModal,
    getUserColor,
    GameExplanationCard,
    referenceToken,
    selfTimeoutUntil,
    isWalletBlocked,
  } = useYoloConfig();

  const shouldShowPotItems = !!PotItemsList || supportNfts || supportErc20;
  const hasGameExplanationCard = !!GameExplanationCard;

  const network = getNetworkFromYoloContractName(round.contract);
  const { data: participant } = useCurrentParticipant(network);
  const { isOpen: isPointsOpen, onOpen, onClose } = useDisclosure();
  const winnerModalDisclosure = useAssertNetworkDisclosure({ network });
  const withdrawRollOverDisclosure = useAssertNetworkDisclosure({ network });

  const [isWinnerHighlighted, setIsWinnerHighlighted] = useState(!finishRound);
  const depositsPerAddress = useMemo(() => groupBy(round.deposits, "depositor.address"), [round.deposits]);
  const connectedUserEntriesValue = useConnectedUserEntriesValue(round);
  const yoloGasThresholdWei = useYoloGasThresholdWei();
  const gasWei = useGetGasPrice();

  const { cannotEnter, maxNumberOfDepositsReached, maxNumberOfParticipantsReached } = getRoundLimits({
    round,
    address,
    maxDeposit: referenceToken.maxDeposit,
  });

  const isRoundClosed =
    !!round.cutoffTime && (round.status !== RoundStatus.Open || getUnixTime(new Date()) >= round.cutoffTime);

  const canRoundBeEntered = !(isRoundClosed || maxNumberOfParticipantsReached || maxNumberOfDepositsReached);
  const isRoundRunning = round.status === RoundStatus.Drawing || round.status === RoundStatus.Open;

  const isContractAssumedPaused = !!gasWei && gasWei > yoloGasThresholdWei;

  const { isMuted, toggleMute } = useRoundStartSound(round);
  const sortUsers = useUserSorting(round, depositsPerAddress, isWinnerHighlighted);
  const sortedPlayersAddresses = useMemo(
    () => Object.keys(depositsPerAddress).sort(sortUsers),
    [sortUsers, depositsPerAddress]
  ) as Address[];

  const enterBackground = useColorModeValue(
    "linear-gradient(0deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.30) 100%), linear-gradient(0deg, #0CE466 0%, #0CE466 100%), linear-gradient(0deg, #FFF 0%, #FFF 100%), url(/images/yolo/jackpot-enter-background.gif), lightgray 50% / cover no-repeat;",
    "linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), linear-gradient(0deg, #0CE466 0%, #0CE466 100%), url(/images/yolo/jackpot-enter-background.gif), lightgray 50% / cover no-repeat;"
  );
  const enterBackgroundBlendMode = useColorModeValue("normal, color, difference, normal", "normal, multiply, normal");
  const designatedUserColors: Record<string, string> = useMemo(
    () =>
      Object.keys(depositsPerAddress).reduce(
        (designations, userAddress, i) => ({ ...designations, [userAddress]: getUserColor(i) }),
        {}
      ),
    [depositsPerAddress, getUserColor]
  );

  const unclaimedPrizesOnCurrentNetwork = useMemo(
    () =>
      participant?.yoloUnclaimedPrizes.filter(
        (prize) => getNetworkFromYoloContractName(prize.round.contract) === network
      ),
    [participant?.yoloUnclaimedPrizes, network]
  );

  const buildArrowButtonHref = (target: "next" | "previous") => {
    const targetRoundId = target === "next" ? round.onChainId + 1 : round.onChainId - 1;
    const contractVersion = getYoloContractVersionFromName(round.contract);

    switch (contractVersion) {
      case ContractVersion.V1:
        return `/${basePath}/${network}/${contractVersion.toLowerCase()}/${targetRoundId}`;
      case ContractVersion.V1_LIMITED:
      case ContractVersion.V2:
      default:
        return `/${basePath}/${network}/${targetRoundId}`;
    }
  };

  const { data: response } = useCurrentParticipantInfiniteFutureEntries(network);
  const hasEnteredFutureRounds = !!response?.pages?.[0]?.totalRoundsCount && response.pages[0].totalRoundsCount > 0;

  const gridLastRowXl = (() => {
    if (hasGameExplanationCard) {
      return shouldShowPotItems
        ? '"pot gameTypeCard gameTypeCard cta"'
        : '"gameTypeCard gameTypeCard gameTypeCard cta"';
    }
    return shouldShowPotItems ? '"players pot pot cta"' : '"player main main cta"';
  })();

  return (
    <>
      <YoloPointsExplanationModal isOpen={isPointsOpen} onClose={onClose} network={network} />
      {canChooseNetwork && <TabNetworkSelector currentNetwork={network} display={{ base: "block", xl: "none" }} />}
      <Grid
        gap={2}
        flex={1}
        templateColumns={{ base: "100%", lg: "60% 40%", xl: "288px 1fr 1fr 360px" }}
        templateAreas={{
          // no networks until xl: before then, they are tabs outside grid
          // no future rounds starting from lg: they are rendered in RoundOverview
          base: `
            ${hasGameExplanationCard ? '"gameTypeCard"' : ""}  
            ${hasEnteredFutureRounds ? '"future"' : ""}
            "main"
            "players"
            "stat"
            ${shouldShowPotItems ? '"pot"' : ""}
            "cta"`,
          lg: `"main stat" 
             "main cta"
             "players cta"
             "players cta"
             "pot pot"
             ${hasGameExplanationCard ? '"gameTypeCard gameTypeCard"' : ""}`,
          xl: `"${canChooseNetwork ? "networks" : "players"} main main stat"
          "players main main cta"
          "players main main cta"
          ${gridLastRowXl}`,
        }}
        pb={{ base: "208px", lg: "0" }} // Compensate mobile fixed footer
      >
        {canChooseNetwork && (
          <GridItem as={Container} area="networks" px={2} py={4} display={{ base: "none", xl: "grid" }}>
            <Box>
              <Text mx={2} color="text-03">
                {t("yolo::Game Network")}
              </Text>
              <ButtonStackNetworkSelector currentNetwork={network} />
            </Box>
          </GridItem>
        )}
        {hasEnteredFutureRounds && (
          <GridItem display={{ base: "block", lg: "none" }} area="future" as={Container} p={4}>
            <VStack spacing={3} alignItems="stretch">
              <Text bold>{t("yolo::Your Future Entries")}</Text>
              <HStack px={4}>
                <FutureRoundsDatapoints network={network} />
              </HStack>
            </VStack>
          </GridItem>
        )}
        <GridItem as={Container} display="flex" flexDirection="column" area="players">
          <Box flexGrow={1} h="100%">
            <Flex justifyContent="space-between" alignItems="center" p={4}>
              <Text color="text-02" bold>
                {t("yolo::{{count}} Players", { count: round.numberOfParticipants })}
              </Text>
              {!isHistoricRound && <PageViews />}
            </Flex>
            <Flex
              flexDirection="column"
              maxHeight={canChooseNetwork ? "60dvh" : "80dvh"}
              minHeight={{ base: "auto", lg: "calc(min(100vw, 580px) + 150px)" }} // Matching roulette
              overflowY="auto"
              overflowX="hidden" //@TODO debug me
              px={2}
              pb={1}
            >
              {!!onEnter && !cannotEnter && sortedPlayersAddresses.length === 0 && <GemMessage onEnter={onEnter} />}
              {sortedPlayersAddresses.map((userAddress) => (
                <LazyLoad
                  key={userAddress}
                  placeholder={<PlayerTileSkeleton />}
                  options={{ triggerOnce: false }}
                  w="100%"
                >
                  <PlayerTile
                    key={userAddress}
                    userAddress={userAddress}
                    round={round}
                    rightColor={designatedUserColors[userAddress]}
                    isWinnerHighlighted={isWinnerHighlighted}
                  />
                </LazyLoad>
              ))}
            </Flex>
          </Box>
        </GridItem>
        <GridItem as={Container} area="main" overflow="hidden" display="flex" minHeight="min(100vw, 580px)">
          <Flex flexDirection="column" justifyContent="flex-start" p={4} flex="1">
            <Flex justifyContent="space-between" alignItems="center" mb={8}>
              <Text color="text-02" bold>
                {!isRoundRunning ? t("yolo::Round #{{round}}", { round: round.onChainId }) : t("yolo::Current Round")}
              </Text>
              <Box>
                <Button
                  as={NextLink}
                  href={`/${basePath}/${network}/history`}
                  variant="outline"
                  colorScheme="secondary"
                  size="xs"
                  mr={{ base: 1, sm: 2 }}
                >
                  <RecentlyViewedIcon />
                  <Box display={{ base: "none", sm: "inline" }} ml={2}>
                    {t("yolo::History")}
                  </Box>
                </Button>
                <IconButton
                  as={NextLink}
                  href={buildArrowButtonHref("previous")}
                  aria-label="Previous round"
                  variant="outline"
                  colorScheme="secondary"
                  size="xs"
                  isDisabled={round.onChainId === 1}
                  pointerEvents={round.onChainId === 1 ? "none" : "auto"}
                  mr={{ base: 1, sm: 2 }}
                >
                  <ArrowLeftIcon />
                </IconButton>
                <IconButton
                  as={NextLink}
                  href={buildArrowButtonHref("next")}
                  aria-label="Next round"
                  variant="outline"
                  colorScheme="secondary"
                  size="xs"
                  isDisabled={isRoundRunning}
                  pointerEvents={isRoundRunning ? "none" : "auto"}
                  mr={{ base: 1, sm: 2 }}
                >
                  <ArrowRightIcon />
                </IconButton>
                <IconButton
                  as={NextLink}
                  href={`/${basePath}/${network}`}
                  aria-label="Current round"
                  variant="outline"
                  colorScheme="secondary"
                  size="xs"
                  isDisabled={isRoundRunning}
                  pointerEvents={isRoundRunning ? "none" : "auto"}
                  mr={{ base: 1, sm: 2 }}
                >
                  <JumpToEndIcon />
                </IconButton>
                {!useGlobalMuteButton && (
                  <Button
                    display="inline-block"
                    onClick={toggleMute}
                    variant={isMuted ? "outline" : "solid"}
                    colorScheme="secondary"
                    color={isMuted ? "red.400" : "link-01"}
                    size="xs"
                    alignSelf="flex-end"
                    padding={0}
                  >
                    {isMuted ? <VolumeMuteFilledIcon /> : <VolumeUpFilledIcon />}
                  </Button>
                )}
              </Box>
            </Flex>
            <Center flex={1}>
              <Box
                position="relative"
                width="100%"
                minWidth={{ base: "290px", xs: "320px", sm: "380px" }}
                minHeight={{ base: "290px", xs: "320px", sm: "380px" }}
                maxWidth="55dvh"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Roulette
                  // Small trick to avoid layout shift when the round is paused/unpause
                  visibility={isContractAssumedPaused ? "hidden" : "visible"}
                  round={round}
                  depositsPerAddress={depositsPerAddress}
                  userColors={designatedUserColors}
                  finishRound={finishRound}
                  timeBeforeNextRound={timeBeforeNextRound}
                  setIsWinnerHighlighted={setIsWinnerHighlighted}
                  isHistoricRound={isHistoricRound}
                  startClaim={winnerModalDisclosure.onOpen}
                  startWithdraw={withdrawRollOverDisclosure.onOpen}
                />
                {isContractAssumedPaused && (
                  <Flex alignItems="center" position="absolute" top={0} left={0} width="100%" height="100%" p={4}>
                    <RoundPaused gasWei={gasWei ?? 0} />
                  </Flex>
                )}
              </Box>
            </Center>
          </Flex>
          <Hitman onReceive={refetch} mt={6} enable={!isRoundClosed && !isHistoricRound} />
        </GridItem>
        <GridItem as={Container} area="stat" display={{ base: "none", lg: "inherit" }}>
          <RoundOverview
            round={round}
            userEntriesValue={connectedUserEntriesValue}
            totalPotValue={BigInt(round.potValue)}
            p={4}
          />
        </GridItem>
        {/* Set min-height for empty UI */}
        {shouldShowPotItems && (
          <GridItem as={Container} area="pot" minHeight="196px">
            <PotItemsList round={round} userColors={designatedUserColors} />
          </GridItem>
        )}
        {hasGameExplanationCard && (
          <GridItem area="gameTypeCard">
            <GameExplanationCard />
          </GridItem>
        )}
        <GridItem
          position="relative"
          as={Container}
          area="cta"
          display={{ base: "none", lg: "flex" }}
          flexDirection="column"
          justifyContent="center"
          p={4}
          overflow="hidden"
          {...(canRoundBeEntered && !isWalletBlocked ? ctaArea.props : {})}
        >
          {!removeRoundCtaBg && canRoundBeEntered && (
            <Box
              background={enterBackground}
              pos="absolute"
              inset={0}
              bgPos="center"
              bgSize="cover"
              bgBlendMode={enterBackgroundBlendMode}
            />
          )}
          {unclaimedPrizesOnCurrentNetwork && unclaimedPrizesOnCurrentNetwork.length > 0 && <ClaimWinningsButton />}
          {(() => {
            if (isWalletBlocked) {
              return (
                <Button rightIcon={<ErrorIcon boxSize={5} />} variant="outline" colorScheme="red" isDisabled>
                  {t("Wallet Blocked")}
                </Button>
              );
            }

            // onEnter is only passed for the current round
            if (onEnter) {
              return <EnterButton round={round} onEnter={onEnter} />;
            }

            if (isHistoricRound) {
              return (
                <Button as={NextLink} href={`/${basePath}/${network}`} variant="outline">
                  {t("yolo::Back to Current Round")}
                  <JumpToEndIcon ml={1} />
                </Button>
              );
            }

            return (
              <Button width="100%" isDisabled color="text-inverse" bgColor="ui-inverse">
                {t("yolo::Round Closed")}
              </Button>
            );
          })()}
          <ViewEntriesButton round={round} />
          {canRoundBeEntered && !points.hideEnterNowMultiplier && (
            <Box zIndex={1} mt={2}>
              <PointsMultiplierText
                cutoffTime={round.cutoffTime}
                roundDuration={round.roundDuration}
                onHelpClick={onOpen}
              />
            </Box>
          )}
        </GridItem>
        <Button
          as={NextLink}
          href={`/${basePath}/help`}
          aria-label="More info"
          gridArea="cta"
          position={{ base: "relative", lg: "fixed" }}
          bottom={{ base: 0, lg: "24" }}
          right={{ base: 0, lg: "7" }}
          variant="solid"
          color="ui-01-inverse"
          borderRadius="circular"
          bgColor="interactive-02"
          size="sm"
          padding={{ base: 4, lg: 0 }}
        >
          <HelpFilledIcon boxSize={5} mr={{ base: 2, lg: 0 }} />
          <Box display={{ base: "inline", lg: "none" }}>Help</Box>
        </Button>
        <MobileStickyBar
          display={{ base: "block", lg: "none" }}
          round={round}
          userEntriesValue={connectedUserEntriesValue}
          totalPotValue={BigInt(round.potValue)}
          position="fixed"
          bottom={0}
          left={0}
        >
          {(() => {
            if (isWalletBlocked) {
              return (
                <Button
                  rightIcon={<ErrorIcon boxSize={5} />}
                  variant="outline"
                  colorScheme="red"
                  width="100%"
                  isDisabled
                >
                  {t("Wallet Blocked")}
                </Button>
              );
            }

            // onEnter is only passed for the current round
            if (onEnter) {
              return <EnterButton round={round} onEnter={onEnter} />;
            }

            if (isHistoricRound) {
              return (
                <Button as={NextLink} href={`/${basePath}/${network}`} variant="outline" width="100%">
                  {t("yolo::Back to Current Round")}
                  <JumpToEndIcon ml={1} />
                </Button>
              );
            }

            return (
              <Button width="100%" isDisabled color="text-inverse" bgColor="ui-inverse">
                {t("yolo::Round Closed")}
              </Button>
            );
          })()}
        </MobileStickyBar>
      </Grid>
      <WinnerModal
        justWonRound={isHistoricRound || !round.winner ? undefined : round}
        isOpen={winnerModalDisclosure.isOpen}
        onClose={winnerModalDisclosure.onClose}
      />
      <WithdrawRolloverModal isOpen={withdrawRollOverDisclosure.isOpen} onClose={withdrawRollOverDisclosure.onClose} />

      {!!selfTimeoutUntil && (
        <SelfExclusionModal endDate={selfTimeoutUntil}>
          {unclaimedPrizesOnCurrentNetwork && unclaimedPrizesOnCurrentNetwork.length > 0 && <ClaimWinningsButton />}
        </SelfExclusionModal>
      )}
    </>
  );
};
