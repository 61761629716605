import { graphql as baseGraphql } from "@looksrare/utils";
import { logDataDogError } from "@looksrare/utils";

const shouldLogVerboseError = process.env.NEXT_PUBLIC_VERBOSE_ERROR_LOGGING === "1";

/**
 * General wrapper around requests to the api & subgraph to centralize error handling
 *
 * @param query GraphQL query
 * @param params GraphQL params (via the gql function)
 * @param requestHeaders HeadersInit
 */
export const graphql = async <TReturn = any>(
  query: string,
  params?: Record<string, any>,
  requestHeaders?: HeadersInit
) => {
  return baseGraphql<TReturn>({
    query,
    params,
    requestHeaders,
    shouldLogVerboseError,
    onError: (data) => {
      logDataDogError("GraphQL", data);
    },
  });
};
