import { gql } from "graphql-request";

export const roundFragment = gql`
  fragment RoundFragment on YoloRound {
    onChainId
    status
    cutoffTime
    maximumNumberOfDeposits
    maximumNumberOfParticipants
    numberOfParticipants
    entriesCount
    valuePerEntry
    protocolFeeBp
    potValue
    drawnHash
    winnerMultiple
    contract
    roundDuration
  }
`;

export const depositFragment = gql`
  fragment DepositFragment on YoloDeposit {
    id
    index
    type
    currency
    amount
    numberOfEntries
    gemsEarned
    claimed
  }
`;

export const tokenDepositFragment = gql`
  fragment TokenDepositFragment on YoloDeposit {
    token {
      tokenId
      image {
        src
        contentType
      }
      rarity {
        rank
        provider
        url
      }
      collection {
        name
        totalSupply
        isVerified
        address
      }
    }
  }
`;

export const yoloUserFragment = gql`
  fragment YoloUserFragment on User {
    name
    address
    isVerified
    isProfileImageVisible
    avatar {
      image {
        src
        contentType
      }
    }
  }
`;

export const userMetricsFragment = gql`
  fragment UserMetricsFragment on YoloDepositorMetrics {
    biggestETHWin
    biggestWinMultiple
    totalRoundsWon
    totalRoundsPlayed
  }
`;

export const historyDepositFragment = gql`
  fragment HistoryDepositFragment on YoloDeposit {
    id
    index
    type
    currency
    amount
    numberOfEntries
    claimed
  }
`;
