import { Button, DownloadIcon, Text } from "@looksrare/uikit";
import { ModalBody, ModalFooter, VStack, chakra } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { ModalAction } from "../../types";
import { ActionSelectors } from "../shared/ActionSelectors";
import { YoloSupportedNetworks } from "../../../../types";

interface Props {
  selectedAction: ModalAction;
  setSelectedAction: (modalAction: ModalAction) => void;
  onComplete: () => void;
  rollOverAmount: bigint;
  roundsToWithdraw: number;
  claimableAmount: bigint;
  network: YoloSupportedNetworks;
  hasFees: boolean;
}

export const ActionSelectionView = ({
  selectedAction,
  setSelectedAction,
  onComplete,
  rollOverAmount,
  roundsToWithdraw,
  claimableAmount,
  network,
  hasFees,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <ModalBody bg="ui-bg">
        <VStack spacing={4} pt={6}>
          <DownloadIcon boxSize={16} color="text-03" />
          <Text bold textStyle="heading-03">
            {t("yolo::Roll Over / Withdraw")}
          </Text>
          <Text textAlign="center">
            {t(
              "yolo::You can roll over all your ETH entries from canceled rounds into the current round, or you can claim your winnings."
            )}
            {roundsToWithdraw > 1 && (
              <>
                <br />
                <br />
                <chakra.span color="text-03">
                  {t("yolo::Rounds to Withdraw: {{count}}", { count: roundsToWithdraw })}
                </chakra.span>
              </>
            )}
          </Text>
        </VStack>
      </ModalBody>
      <ModalBody>
        <ActionSelectors
          selectedAction={selectedAction}
          setSelectedAction={setSelectedAction}
          rollableOverAmount={rollOverAmount}
          claimableAmount={claimableAmount}
          network={network}
          hasFees={hasFees}
        />
      </ModalBody>
      <ModalFooter>
        <Button w="100%" onClick={onComplete}>
          {t(selectedAction === ModalAction.WithdrawAll ? "yolo::Withdraw Entries" : "yolo::Roll Over")}
        </Button>
      </ModalFooter>
    </>
  );
};
