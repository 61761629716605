import { Address } from "viem";
import { Box, Flex, HStack } from "@chakra-ui/react";
import { useAccount } from "wagmi";
import { useTranslation } from "next-i18next";
import { Button, CheckmarkFilledIcon, UsernameDisplay, CloudinaryImage, ProfileAvatar } from "@looksrare/uikit";
import { formatNumberToLocale, formatToSignificant, isAddressEqual } from "@looksrare/utils";
import { Text } from "@looksrare/uikit";
import { Round, ContractVersion } from "../../../types";
import { getNetworkFromYoloContractName, getYoloContractVersionFromName } from "../../../utils/chains";
import { NetworkNativeTokenIcon } from "../../NetworkNativeTokenIcon";
import { useYoloConfig } from "../../../config";

const userNameProps = {
  bg: "linear-gradient(180deg, #F8CC32 0%, #F8CC32 50%, #FF8A14 100%)",
  bgClip: "text",
  textStyle: { base: "heading-05", xs: "heading-03" },
};

interface Props {
  round: Round;
  timeLeft?: number;
  onClickUser: (address: Address) => void;
  claimed: boolean;
  onClaimRolloverClick: () => void;
}

export const CenterWinner = ({ round, timeLeft, onClickUser, claimed, onClaimRolloverClick }: Props) => {
  const { isRolloverEnabled, CenterWinnerAvatar } = useYoloConfig();
  const { t } = useTranslation();
  const winnerAddress = round.winner?.address as Address;
  const { address } = useAccount();
  const userIsWinner = !!address && isAddressEqual(address, winnerAddress);
  const contractVersion = getYoloContractVersionFromName(round.contract);
  const network = getNetworkFromYoloContractName(round.contract);

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      transition="all 0.2s ease-in-out"
      {...(userIsWinner
        ? {}
        : {
            _hover: { opacity: "50%" },
            _active: { opacity: "70%" },
            cursor: "pointer",
            onClick: () => onClickUser(winnerAddress),
          })}
      aria-label="See winner details"
    >
      {!!CenterWinnerAvatar ? (
        <CenterWinnerAvatar address={winnerAddress} src={round.winner?.avatar?.image.src} />
      ) : (
        <Box position="relative" height="96px">
          <CloudinaryImage src="/images/yolo/winner.svg" alt="Winner" width={128} height={96} priority />
          <ProfileAvatar
            address={winnerAddress}
            src={round.winner?.avatar?.image.src}
            size={64}
            position="absolute"
            top={0}
            left={0}
            transform="translate(50%, 38%)"
          />
        </Box>
      )}

      <Text {...userNameProps} bold>
        {userIsWinner ? (
          t("yolo::You Won!")
        ) : (
          <UsernameDisplay
            address={winnerAddress}
            name={round.winner?.name}
            isVerified={round.winner?.isVerified}
            verifiedIconProps={{ boxSize: 3 }}
            textProps={userNameProps}
          />
        )}
      </Text>
      <Box>
        <Flex alignItems="center">
          <NetworkNativeTokenIcon network={network} width={{ base: 2, xs: 3 }} />
          <Text textStyle={{ base: "helper", xs: "body" }} bold ml={2}>
            {formatToSignificant(round.potValue, 4)}
          </Text>
          <Text color="text-02" textStyle="heading-05" mx={2}>
            •
          </Text>
          <Text textStyle={{ base: "helper", xs: "body" }} color="text-02" bold>
            {t("yolo::{{multiple}}x WIN", {
              multiple: round.winnerMultiple ? formatNumberToLocale(round.winnerMultiple, 0, 2) : "",
            })}
          </Text>
        </Flex>
      </Box>
      {!!timeLeft && (
        <Text textStyle="detail" color="text-02">
          {t("yolo::Next Round: {{timeLeft}}s", { timeLeft: timeLeft })}
        </Text>
      )}
      {userIsWinner && (
        <>
          {claimed ? (
            <Button isDisabled size="sm" mt={2}>
              <HStack>
                <Text>{t("yolo::Claimed")}</Text>
                <CheckmarkFilledIcon />
              </HStack>
            </Button>
          ) : (
            <Button size="sm" mt={2} onClick={onClaimRolloverClick}>
              {contractVersion === ContractVersion.V1 || !isRolloverEnabled
                ? t("yolo::Claim")
                : t("yolo::Claim / Roll Over")}
            </Button>
          )}
        </>
      )}
    </Flex>
  );
};
