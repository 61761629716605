import { useAccount } from "wagmi";
import {
  useTokenBalance,
  fromDecimals,
  useTokenEthTwap,
  multiplyWeiByNumber,
  toDecimals,
  useEthBalance,
  CurrencyConfig,
} from "@looksrare/utils";
import { useTranslation } from "next-i18next";
import { Address } from "viem";
import { YoloSupportedNetworks } from "../../types";
import { useYoloConfig } from "../../config";

interface EthInputValidationArgs {
  input: string;
  network: YoloSupportedNetworks;
}

export const useEthInputValidation = ({ input, network }: EthInputValidationArgs) => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const { data: weiBalance } = useEthBalance(address!);
  const {
    referenceToken: { minDeposit, maxPrecision },
  } = useYoloConfig();

  const ethBalance = fromDecimals(weiBalance || 0);
  const ethDecimals = input.split(".")[1]?.length || 0;

  const isEmptyInput = input === "";
  const isBelowMinimum = parseFloat(input) < minDeposit;
  const isMultipleOfEntryValue = ethDecimals <= maxPrecision;
  const gasBuffer = network === "ethereum" ? 0.01 : 0.001;
  const isBalanceSufficient = parseFloat(input) <= parseFloat(ethBalance) - gasBuffer;

  const isValidInput = !isEmptyInput && !isBelowMinimum && isMultipleOfEntryValue && isBalanceSufficient;

  const warning = (() => {
    if (isEmptyInput) {
      return "";
    }
    if (!isBalanceSufficient) {
      return t("yolo::Insufficient balance");
    }
    if (isBelowMinimum) {
      return t("yolo::Minimum amount is {{amount}} ETH", { amount: minDeposit });
    }
    if (!isMultipleOfEntryValue) {
      return t("yolo::Must be a multiple of {{amount}} ETH", { amount: minDeposit });
    }
    return "";
  })();

  return { isValidInput, warning };
};

interface Erc20InputValidationArgs {
  input: string;
  tokenAddress: Address;
  currencyConfig: CurrencyConfig;
}

export const useErc20InputValidation = ({ input, tokenAddress, currencyConfig }: Erc20InputValidationArgs) => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const {
    referenceToken: { minDeposit },
  } = useYoloConfig();

  const { data: tokenBalanceWei } = useTokenBalance({
    contractAddress: tokenAddress,
    address: address!,
    queryOptions: { enabled: !!address },
  });
  const { data: coinTwap } = useTokenEthTwap(tokenAddress);

  const tokenBalance = fromDecimals(tokenBalanceWei || 0, { decimals: currencyConfig.decimals });
  const tokenBalanceFloat = parseFloat(tokenBalance);

  const inputFloat = parseFloat(input);

  const inputValueWei = multiplyWeiByNumber(coinTwap || 0n, inputFloat || 0);

  const isEmptyInput = input === "";
  const isBelowMinimum = inputValueWei < toDecimals(minDeposit.toString());
  const isBalanceSufficient = inputFloat <= tokenBalanceFloat;

  const isValidInput = !isEmptyInput && !isBelowMinimum && isBalanceSufficient;

  const warning = (() => {
    if (isEmptyInput) {
      return "";
    }
    if (!isBalanceSufficient) {
      return t("yolo::Insufficient balance");
    }
    if (isBelowMinimum) {
      return t("yolo::Minimum value is {{amount}} ETH", { amount: minDeposit });
    }
    return "";
  })();

  return { isValidInput, warning };
};
