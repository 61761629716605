import { useCallback } from "react";
import { useAccount } from "wagmi";
import { isAddressEqual } from "@looksrare/utils";
import { Deposit, Round } from "../../types";

export const useUserSorting = (round: Round, usersDeposits: Record<string, Deposit[]>, isWinnerHighlighted = false) => {
  const { address: currentAddress } = useAccount();

  const sortUsers = useCallback(
    (a: string, b: string) => {
      if (isWinnerHighlighted) {
        if (isAddressEqual(a, round.winner?.address)) {
          return -1;
        }

        if (isAddressEqual(b, round.winner?.address)) {
          return 1;
        }
      }

      if (isAddressEqual(a, currentAddress)) {
        return -1;
      }

      if (isAddressEqual(b, currentAddress)) {
        return 1;
      }

      const depositsA = usersDeposits[a];
      const depositsB = usersDeposits[b];
      const depositsAValue = depositsA.reduce((total, deposit) => total + BigInt(deposit.numberOfEntries), 0n);
      const depositsBValue = depositsB.reduce((total, deposit) => total + BigInt(deposit.numberOfEntries), 0n);

      return depositsBValue > depositsAValue ? 1 : -1;
    },
    [currentAddress, isWinnerHighlighted, round.winner, usersDeposits]
  );

  return sortUsers;
};
