import { VStack } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "next-i18next";
import { useAddressesByNetwork } from "@looksrare/utils";
import { BulkTransactionStep, StepStatus, ApproveTransferManagerStep, ApproveErc20SpenderStep } from "@looksrare/uikit";

enum Steps {
  APPROVE_TRANSFER_MANAGER = "APPROVE_TRANSFER_MANAGER",
  APPROVE_LOOKS = "APPROVE_LOOKS",
}

interface Props {
  status: StepStatus;
  amount: bigint;
  onComplete: () => void;
}

/**
 * Renders a BulkTransactionSteps that manages all the approvals for spending user's LOOKS
 */
export const BulkLooksApprovalsStep = ({ status, amount, onComplete }: Props) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState<Steps>(Steps.APPROVE_TRANSFER_MANAGER);
  const { YOLO_V2, LOOKS, TRANSFER_MANAGER } = useAddressesByNetwork();

  return (
    <BulkTransactionStep title={t("yolo::Approvals")} status={status} collapse={status !== "current"}>
      <VStack alignItems="stretch" px={6} py={4} borderRadius="container" bg="ui-bg" spacing={4}>
        <ApproveTransferManagerStep
          isStepActive={currentStep === Steps.APPROVE_TRANSFER_MANAGER}
          ctaText={t("yolo::Approve Transfer Manager")}
          operator={YOLO_V2}
          transferManager={TRANSFER_MANAGER}
          onComplete={() => setCurrentStep(Steps.APPROVE_LOOKS)}
        />
        <ApproveErc20SpenderStep
          isStepActive={currentStep === Steps.APPROVE_LOOKS}
          ctaText={t("yolo::Approve LOOKS")}
          currency={LOOKS}
          amount={amount}
          spender={TRANSFER_MANAGER}
          onComplete={onComplete}
        />
      </VStack>
    </BulkTransactionStep>
  );
};
