import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { Duration } from "date-fns";

interface CurrentRoundState {
  duration?: Duration;
  timeBeforeNextRound: number; // seconds
}

const initialState: CurrentRoundState = {
  duration: undefined,
  timeBeforeNextRound: 0,
};

interface CurrentRoundStore extends CurrentRoundState {
  reset: () => void;
  setDuration: (duration?: Duration) => void;
  setTimeBeforeNextRound: (timeBeforeNextRound: number) => void;
}

export const useCurrentRoundStore = create(
  immer<CurrentRoundStore>((set) => ({
    ...initialState,
    reset: () => set(() => ({ ...initialState })),
    setDuration: (duration?: Duration) => set((state) => ({ ...state, duration })),
    setTimeBeforeNextRound: (timeBeforeNextRound: number) => set((state) => ({ ...state, timeBeforeNextRound })),
  }))
);
