import { useMemo } from "react";
import { useColorMode } from "@chakra-ui/react";
import { Duration } from "date-fns";
import { palette } from "@looksrare/chakra-theme";

export const useTimerColor = (timerPercentage: number, duration?: Duration) => {
  const { colorMode } = useColorMode();

  return useMemo(() => {
    if (duration && duration.minutes !== undefined && duration.seconds !== undefined && timerPercentage < 100) {
      if (duration.minutes === 0) {
        if (duration.seconds < 30) {
          return palette.red[400];
        }

        return palette.yellow[200];
      }
    }

    if (colorMode === "dark") {
      return palette.white[900];
    } else {
      return palette.black[900];
    }
  }, [duration, colorMode, timerPercentage]);
};
