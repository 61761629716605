import { useState } from "react";
import { BoxProps } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { BulkTransactionStep, StepStatus } from "@looksrare/uikit";
import { useYoloCartStore } from "../../depositAssets/assetsState";
import { Round } from "../../../types";
import { useYoloConfig } from "../../../config";
import { getNetworkFromYoloContractName } from "../../../utils";
import { StepSendToPotsSingleRound } from "./StepSendToPotsSingleRound";

enum Substeps {
  SEND_ETH_MULTIPLE_ROUNDS_TO_POT,
  SEND_ASSETS_TO_POT,
}

interface Props extends BoxProps {
  status: StepStatus;
  collapse?: boolean;
  onComplete: () => void;
  round: Round;
  cartValue: bigint;
}

export const StepSendToPots = ({ status, collapse, onComplete, round, cartValue, ...props }: Props) => {
  const { t } = useTranslation();
  const { StepSendToPotsMultipleRound, referenceToken } = useYoloConfig();
  const { collections, tokens, ethAmountWei, ethAmountRoundsCount } = useYoloCartStore();
  const isPayingMultipleRounds = ethAmountRoundsCount > 1;

  const isSendingOnlyReferenceToken = (() => {
    switch (referenceToken.symbol) {
      case "ETH":
        return Object.keys(collections).length === 0 && Object.keys(tokens).length === 0;
      default:
        return false;
    }
  })();

  const hasSomethingToSendToSingleRound = !isSendingOnlyReferenceToken || !isPayingMultipleRounds;

  const [currentSubStep, setCurrentSubStep] = useState<Substeps>(
    isPayingMultipleRounds ? Substeps.SEND_ETH_MULTIPLE_ROUNDS_TO_POT : Substeps.SEND_ASSETS_TO_POT
  );

  return (
    <BulkTransactionStep status={status} collapse={collapse} title={t("yolo::Send to Prize Pool")} {...props}>
      {isPayingMultipleRounds && (
        <StepSendToPotsMultipleRound
          startingRound={BigInt(round.onChainId)}
          isStepActive={currentSubStep === Substeps.SEND_ETH_MULTIPLE_ROUNDS_TO_POT}
          onComplete={() =>
            !hasSomethingToSendToSingleRound ? onComplete() : setCurrentSubStep(Substeps.SEND_ASSETS_TO_POT)
          }
          assets={{ collections, tokens, ethAmountWei, ethAmountRoundsCount }}
          cartValue={cartValue}
          network={getNetworkFromYoloContractName(round.contract)}
        />
      )}
      {hasSomethingToSendToSingleRound && (
        <StepSendToPotsSingleRound
          isStepActive={currentSubStep === Substeps.SEND_ASSETS_TO_POT}
          onComplete={onComplete}
          assets={
            isPayingMultipleRounds
              ? { collections, tokens, ethAmountRoundsCount, ethAmountWei: 0n } // Eth has already been sent to the pot in this case
              : { collections, tokens, ethAmountWei, ethAmountRoundsCount }
          }
          round={round}
          cartValue={cartValue}
        />
      )}
    </BulkTransactionStep>
  );
};
