import { AlertProps, AlertTitle, Button, Flex } from "@chakra-ui/react";
import { Alert } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import React from "react";
import { useYoloConfig } from "../../config";

interface Props extends AlertProps {
  onStart: () => void;
  roundsCount: number;
}
export const WithdrawV2Deposits = ({ onStart, roundsCount, ...props }: Props) => {
  const { isRolloverEnabled } = useYoloConfig();
  const { t } = useTranslation();
  return (
    <Alert variant="left-accent" status="warning" centerIcon {...props}>
      <Flex justifyContent="space-between" alignItems="center" width="100%" wrap="wrap">
        <AlertTitle>
          {t("yolo::{{count}}x Canceled Round(s) - Withdraw Entries Now", { count: roundsCount })}
        </AlertTitle>
        <Button size="xs" onClick={onStart}>
          {t(isRolloverEnabled ? "yolo::Roll Over / Withdraw" : "yolo::Withdraw")}
        </Button>
      </Flex>
    </Alert>
  );
};
