import { useQuery } from "@tanstack/react-query";
import { Address } from "viem";
import { useAccount, usePublicClient } from "wagmi";
import { RQueryOptions } from "@looksrare/utils";
import { ERC721Abi } from "@looksrare/config";
import { useGameAddresses } from "@looksrare/uikit/components/GameProvider";
import { YoloCartAssets as Assets } from "../../components/depositAssets/assetsState";

/**
 * Fetch approval of transfer manager for a list of collections
 */
export const useGetApprovals = (collections: Assets["collections"], options?: RQueryOptions<boolean[]>) => {
  const publicClient = usePublicClient();
  const { address } = useAccount();
  const collectionAddresses = Object.keys(collections);
  const gameAddresses = useGameAddresses();

  return useQuery({
    queryKey: ["yolo-tx-approvals", address, ...collectionAddresses],
    queryFn: async () => {
      if (!address) {
        throw new Error("No address found");
      }
      if (!publicClient) {
        throw new Error("No public client found");
      }
      const approvals = await Promise.all(
        collectionAddresses.map((collectionAddress) => {
          return publicClient.readContract({
            address: collectionAddress as Address,
            abi: ERC721Abi,
            functionName: "isApprovedForAll",
            args: [address, gameAddresses.YOLO_TRANSFER_MANAGER],
          });
        })
      );

      return approvals;
    },
    ...options,
  });
};
