import React from "react";
import { Flex, FlexProps } from "@chakra-ui/react";
import { Text, ClockIcon } from "@looksrare/uikit";
import { fromDecimals } from "@looksrare/utils/formatting/fromDecimals";

import { useYoloGasThresholdWei } from "../../utils/api/realtime";

interface Props extends FlexProps {
  gasWei: number;
}

const gweiDecimals = 9;

export const RoundPaused = ({ gasWei, ...props }: Props) => {
  const yoloGasThresholdWei = useYoloGasThresholdWei();
  return (
    <Flex alignItems="center" flexDirection="column" textAlign="center" {...props}>
      <ClockIcon color="text-03" boxSize={16} mb={4} />
      <Text textStyle="heading-03" bold mb={2}>
        Round Paused
      </Text>
      <Text color="text-02">
        YOLO’s taking a short break due to high gas fees on the blockchain. You’ll be able to play again once default
        fees are back below {fromDecimals(yoloGasThresholdWei, { decimals: gweiDecimals, significantDigits: 0 })} GWEI.
        (Current: {fromDecimals(gasWei, { decimals: gweiDecimals, significantDigits: 0 })} GWEI)
      </Text>
    </Flex>
  );
};
