import { Address } from "viem";
import { Maker, MerkleTree, Referrer } from "@looksrare/sdk-v2";
import { type BigIntish } from "@looksrare/utils";
import { gql } from "graphql-request";
import {
  User,
  MarketplaceEvent,
  Collection,
  CollectionBase,
  CollectionOwner,
  CollectionFilterItem,
  ExtendedOwner,
  BaseOwner,
  OrderStatus,
  CollectionStats,
  MarketPlaceContext,
  NFT,
  OpenSeaOrderRecipient,
  OpenSeaOrderType,
  SeaportOrderRecipient,
} from "../types";

export const attributeFragment = gql`
  fragment AttributeFragment on Attribute {
    id
    traitType
    value
    displayType
    count
    floorOrder {
      price
    }
  }
`;

export const attributeWithLastOrder = gql`
  fragment AttributeWithLastOrder on Attribute {
    ...AttributeFragment
    lastOrder {
      price
      currency
      sale {
        createdAt
      }
    }
  }
  ${attributeFragment}
`;

export const collectionFloorFragment = gql`
  fragment CollectionFloorFragment on FloorData {
    floorPrice
    floorPriceGlobal
    floorPriceOS
    floorPriceBlur
    floorPriceX2Y2
    floorPriceLooksRare
    floorChange24h
    floorChange7d
    floorChange30d
  }
`;

export const collectionSalesFragment = gql`
  fragment CollectionSalesFragment on SalesData {
    count24h
    count7d
    count1m
    countAll
    change24h
    change7d
    change1m
  }
`;

export const collectionHistoricalFloorFragment = gql`
  fragment CollectionHistoricalFloorFragment on HistoricalFloor {
    floor24hAgo
    floor30dAgo
    floor7dAgo
  }
`;

export type ExtendedOwnerFragment = ExtendedOwner;

export const extendedOwnerFragment = gql`
  fragment ExtendedOwnerFragment on User {
    address
    name
    avatar {
      image {
        src
        contentType
      }
    }
  }
`;

export type BaseOwnerFragment = BaseOwner;

export const baseOwnerFragment = gql`
  fragment BaseOwnerFragment on User {
    address
    name
  }
`;

export type UserFragment = User; // Currently the two are equal, this may change

export const userFragment = gql`
  fragment UserFragment on User {
    address
    name
    biography
    email
    isEmailVerified
    countCollections
    websiteLink
    instagramLink
    twitterUsername
    isVerified
    raffleCheck
    avatar {
      id
      tokenId
      name
      description
      image {
        src
        contentType
      }
      collection {
        address
      }
    }
  }
`;

export type CollectionOwnerFragment = CollectionOwner;

export const collectionOwnerFragment = gql`
  fragment CollectionOwnerFragment on User {
    address
    name
    isVerified
    avatar {
      id
      tokenId
      image {
        src
        contentType
      }
    }
  }
`;

export type CollectionFilterItemFragment = CollectionFilterItem;

export const collectionFilterItemFragment = gql`
  fragment CollectionFilterItemFragment on Collection {
    name
    address
    totalSupply
    owned
    isVerified
    isHidden
    volume {
      volume24h
    }
    logo {
      src
      contentType
    }
    floor {
      ...CollectionFloorFragment
    }
  }
  ${collectionFloorFragment}
`;

export type CollectionBaseFragment = CollectionBase;

export const collectionBaseFragment = gql`
  fragment CollectionBaseFragment on Collection {
    name
    address
    type
    logo {
      src
      contentType
    }
    banner {
      src
      contentType
    }
    isVerified
    isExplicit
    countOwners
    countWatchlists
    totalSupply
    floor {
      ...CollectionFloorFragment
    }
    historicalFloor {
      ...CollectionHistoricalFloorFragment
    }
    volume {
      volume24h
      change24h
      volume7d
      change7d
      volume1m
      change1m
      volumeAll
    }
    sales {
      ...CollectionSalesFragment
    }
    listingCount {
      listingCountGlobal
    }
    isInMainWatchlist
  }
  ${collectionFloorFragment}
  ${collectionHistoricalFloorFragment}
  ${collectionSalesFragment}
`;

export type CollectionFragment = Collection;

export const collectionCollaboratorsFragment = gql`
  fragment CollectionCollaboratorsFragment on Collection {
    collaborators(filter: { isActive: true }) {
      collaborator
    }
  }
`;

/**
 * Pseudo-static data that doesn't change often
 */
export const collectionPageStaticFragment = gql`
  fragment CollectionPageStaticFragment on Collection {
    name
    address
    type
    description
    logo {
      src
      contentType
    }
    banner {
      src
      contentType
    }
    maxRarity
    totalSupply
    isVerified
    isExplicit
    websiteLink
    facebookLink
    twitterUsername
    telegramLink
    instagramLink
    mediumLink
    discordLink
    owner {
      ...CollectionOwnerFragment
    }
    feeSetter {
      ...CollectionOwnerFragment
    }
  }
  ${collectionOwnerFragment}
`;
export const collectionFragment = gql`
  fragment CollectionFragment on Collection {
    ...CollectionPageStaticFragment
    countOwners
    floor {
      ...CollectionFloorFragment
    }
    volume {
      volume24h
      change24h
      volume7d
      change7d
      volumeAll
    }
  }
  ${collectionPageStaticFragment}
  ${collectionFloorFragment}
`;

export type CollectionStatsFragment = CollectionStats;

export const collectionStatsFragment = gql`
  fragment CollectionStatsFragment on Collection {
    totalSupply
    countOwners
    countWatchlists
    floor {
      ...CollectionFloorFragment
    }
    historicalFloor {
      ...CollectionHistoricalFloorFragment
    }
    volume {
      volume24h
      change24h
      volume7d
      change7d
      volume1m
      change1m
      volumeAll
      volume7d
      change7d
      volume1m
      change1m
    }
    sales {
      ...CollectionSalesFragment
    }
    listingCount {
      listingCountGlobal
    }
  }
  ${collectionFloorFragment}
  ${collectionHistoricalFloorFragment}
  ${collectionSalesFragment}
`;

/**
 * Values shared across LooksRareV2OrderFragment, LooksRareV1OrderFragment & OpenSeaOrderFragment
 */
export interface BaseOrderFragment {
  context: MarketPlaceContext;
  signer: string;
  collection: {
    address: Address;
  };
  price: BigIntish;
  currency: Address;
  startTime: BigIntish;
  hash: string;
  id: string;
}

export interface LooksRareV2OrderFragment extends BaseOrderFragment {
  quoteType: Maker["quoteType"];
  amounts: Maker["amounts"];
  orderNonce: Maker["orderNonce"];
  globalNonce: Maker["globalNonce"];
  subsetNonce: Maker["subsetNonce"];
  strategyId: Maker["strategyId"];
  itemIds: Maker["itemIds"];
  additionalParameters: Maker["additionalParameters"];
  collectionType: Maker["collectionType"];
  referrer?: Referrer;
  // Shared with LooksRareV1OrderFragment
  endTime: BigIntish;
  signature: string;
  merkleTree: MerkleTree | null; // merkleTree only returned for merkleTree orders
}

export interface LooksRareV1OrderFragment extends BaseOrderFragment {
  minPercentageToAsk: BigIntish;
  strategy: string;
  params: any[];
  // Shared with OpenSeaOrderFragment
  endTime: BigIntish;
  isOrderAsk: boolean;
  amount: BigIntish;
  nonce: BigIntish;
  token?: {
    tokenId: string;
  };
  // Shared with LooksRareV2OrderFragment
  signature: string;
}

export interface OpenSeaOrderFragment extends BaseOrderFragment {
  conduitKey: string;
  orderType: OpenSeaOrderType;
  protocolAddress: string;
  recipients: OpenSeaOrderRecipient[];
  salt: string;
  zone: string;
  zoneHash: string;
  // Shared with LooksRareV1OrderFragment
  endTime: BigIntish;
  isOrderAsk: boolean;
  amount: BigIntish;
  nonce: BigIntish;
  token?: {
    tokenId: string;
  };
}

export interface BlurOrderFragment extends BaseOrderFragment {
  isOrderAsk: boolean;
  token?: {
    tokenId: string;
  };
}

export interface LooksRareSeaportFragment extends BaseOrderFragment {
  id: string;
  nonce: BigIntish;
  amount: BigIntish;
  endTime: BigIntish;
  conduitKey: string;
  recipients: SeaportOrderRecipient[];
  salt: string;
  zone: string;
  zoneHash: string;
  signature: Address;
  isAsk: boolean;
  strategyId: number;
  collection: {
    address: Address;
    type: string;
  };
  token?: {
    tokenId: string;
  } | null;
}

export type OrderFragment =
  | LooksRareV2OrderFragment
  | OpenSeaOrderFragment
  | BlurOrderFragment
  | LooksRareSeaportFragment;
export type OrderFragmentWithoutTokenId = Omit<OrderFragment, "token">;

export const baseOrderFragment = gql`
  fragment BaseOrderFragment on Order {
    context
    signer
    collection {
      address
    }
    price
    currency
    startTime
    hash
    id
  }
`;

export const looksRareV1OrderFragment = gql`
  fragment LooksRareV1OrderFragment on LooksRareOrder {
    amount
    isOrderAsk
    minPercentageToAsk
    params
    strategy
    signature
    nonce
    endTime
    token {
      tokenId
    }
  }
`;

// @note - Fetch `rate` from the referrer as it is needed for sdk-aggregator
export const looksRareV2OrderFragment = gql`
  fragment LooksRareV2OrderFragment on LooksRareV2Order {
    quoteType
    amounts
    orderNonce
    globalNonce
    subsetNonce
    itemIds
    strategyId
    additionalParameters
    signature
    collectionType
    endTime
    merkleTree {
      proof {
        value
        position
      }
      root
    }
    referrer {
      address
      rate
    }
  }
`;

export const openSeaOrderFragment = gql`
  fragment OpenSeaOrderFragment on OpenSeaOrder {
    nonce
    amount
    isOrderAsk
    conduitKey
    orderType
    protocolAddress
    recipients {
      amount
      recipient
      token
    }
    salt
    zone
    zoneHash
    endTime
    collection {
      address
      type
    }
    token {
      tokenId
    }
  }
`;

export const blurOrderFragment = gql`
  fragment BlurOrderFragment on BlurOrder {
    isOrderAsk
    token {
      tokenId
    }
  }
`;

export const looksrareSeaportOrderFragment = gql`
  fragment LooksRareSeaportOrderFragment on LooksRareSeaportOrder {
    id
    amount
    nonce
    conduitKey
    salt
    zone
    zoneHash
    signature
    endTime
    isAsk
    strategyId
    token {
      tokenId
    }
    recipients {
      endAmount
      identifierOrCriteria
      itemType
      recipient
      startAmount
      token
    }
  }
`;

export const orderFragment = gql`
  fragment OrderFragment on Order {
    ...BaseOrderFragment
    ... on LooksRareV2Order {
      ...LooksRareV2OrderFragment
    }
    ... on OpenSeaOrder {
      ...OpenSeaOrderFragment
    }
    ... on BlurOrder {
      ...BlurOrderFragment
    }
    ... on LooksRareSeaportOrder {
      ...LooksRareSeaportOrderFragment
    }
  }
  ${baseOrderFragment}
  ${looksRareV2OrderFragment}
  ${looksrareSeaportOrderFragment}
  ${openSeaOrderFragment}
  ${blurOrderFragment}
`;

// For BE performace: Exactly the same as "orderFragment" minus the token resolver.
// The token id is populated from elsewhere.
export const orderFragmentForTokensFetch = gql`
  fragment OrderFragmentForTokensFetch on Order {
    ...BaseOrderFragment
    ... on LooksRareV2Order {
      ...LooksRareV2OrderFragment
    }
    # OpenSeaOrderFragment without the token resolver
    ... on OpenSeaOrder {
      nonce
      amount
      isOrderAsk
      conduitKey
      orderType
      protocolAddress
      recipients {
        amount
        recipient
        token
      }
      salt
      zone
      zoneHash
    }
    # LooksRareSeaportOrderFragment without the token resolver
    ... on LooksRareSeaportOrder {
      id
      amount
      nonce
      conduitKey
      salt
      zone
      zoneHash
      signature
      endTime
      recipients {
        endAmount
        identifierOrCriteria
        itemType
        recipient
        startAmount
        token
      }
    }
  }
  ${baseOrderFragment}
  ${looksRareV2OrderFragment}
`;

// @note - Fetch `rate` from the referrer as it is needed for sdk-aggregator
export const looksrareishBaseOrderFragment = gql`
  fragment LooksRarishBaseOrderFragment on Order {
    context
    signer
    collection {
      address
    }
    price
    currency
    startTime
    endTime
    hash
    id
    ... on LooksRareV2Order {
      quoteType
      amounts
      orderNonce
      globalNonce
      subsetNonce
      itemIds
      strategyId
      additionalParameters
      signature
      collectionType
      merkleTree {
        proof {
          value
          position
        }
        root
      }
      referrer {
        address
        rate
      }
    }
    ... on LooksRareSeaportOrder {
      ...LooksRareSeaportOrderFragment
    }
  }
  ${looksrareSeaportOrderFragment}
`;

export type ListingsFeedTokenMetadataFragment = {
  rarity: NFT["rarity"];
  tokenId: NFT["tokenId"];
  image: NFT["image"];
  name: NFT["name"];
};

type OsListingsFeedTokenMetadataFragment = {
  context: MarketPlaceContext.OPENSEA;
  token: ListingsFeedTokenMetadataFragment;
};

type BlurListingsFeedTokenMetadataFragment = {
  context: MarketPlaceContext.BLUR;
  token: ListingsFeedTokenMetadataFragment;
};

type LooksRareSeaportListingsFeedTokenMetadataFragment = {
  context: MarketPlaceContext.LOOKSRARE_SEAPORT;
  token: ListingsFeedTokenMetadataFragment;
};

export type LrV2ListingsFeedTokenMetadataFragment = {
  context: MarketPlaceContext.LOOKSRARE_V2;
  tokens: ListingsFeedTokenMetadataFragment[];
};

export type AggregatedListingsFeedTokenMetadataFragment =
  | LrV2ListingsFeedTokenMetadataFragment
  | OsListingsFeedTokenMetadataFragment
  | BlurListingsFeedTokenMetadataFragment
  | LooksRareSeaportListingsFeedTokenMetadataFragment;

export const listingsFeedTokenMetadataFragment = gql`
  fragment ListingsFeedTokenMetadataFragment on Order {
    ... on OpenSeaOrder {
      context
      token {
        tokenId
        image {
          src
          contentType
        }
        name
        rarity {
          provider
          rank
          url
        }
      }
    }
    ... on BlurOrder {
      context
      token {
        tokenId
        image {
          src
          contentType
        }
        name
        rarity {
          provider
          rank
          url
        }
      }
    }
    ... on LooksRareV2Order {
      context
      tokens {
        tokenId
        image {
          src
          contentType
        }
        name
        rarity {
          provider
          rank
          url
        }
      }
    }
    ... on LooksRareSeaportOrder {
      context
      token {
        tokenId
        image {
          src
          contentType
        }
        name
        rarity {
          provider
          rank
          url
        }
      }
    }
  }
`;

export type EventOrderFragment = { status: OrderStatus } & (
  | BlurOrderFragment
  | OpenSeaOrderFragment
  | LooksRareV2OrderFragment
  | LooksRareV1OrderFragment
  | LooksRareSeaportFragment
);

export interface EventFragment extends Omit<MarketplaceEvent, "order"> {
  order: EventOrderFragment | null;
}

const userEventFragment = gql`
  fragment UserEventFragment on User {
    address
    name
    isVerified
    avatar {
      image {
        src
        contentType
      }
    }
  }
`;

const eventCollectionFragment = gql`
  fragment EventCollectionFragment on Collection {
    address
    name
    description
    totalSupply
    type
    isVerified
    logo {
      src
      contentType
    }
    floor {
      ...CollectionFloorFragment
    }
    historicalFloor {
      ...CollectionHistoricalFloorFragment
    }
  }
  ${collectionFloorFragment}
  ${collectionHistoricalFloorFragment}
`;

const eventTokenFragment = gql`
  fragment EventTokenFragment on Token {
    tokenId
    image {
      src
      contentType
    }
    name
    rarity {
      provider
      rank
      url
    }
  }
`;

const baseEventFragment = gql`
  fragment BaseEventFragment on Event {
    id
    type
    hash
    context
    createdAt
    to {
      ...UserEventFragment
    }
    from {
      ...UserEventFragment
    }
    token {
      ...EventTokenFragment
    }
    collection {
      ...EventCollectionFragment
    }
    order {
      status
      ...OrderFragment
      ... on LooksRareOrder {
        ...LooksRareV1OrderFragment
      }
    }
  }
  ${userEventFragment}
  ${eventTokenFragment}
  ${eventCollectionFragment}
  ${looksRareV1OrderFragment}
  ${orderFragment}
`;

export const eventFragment = gql`
  fragment EventFragment on Event {
    ...BaseEventFragment
    ... on OpenSeaEvent {
      recipient {
        address
      }
    }
  }
  ${baseEventFragment}
`;

export const tokensFragment = gql`
  fragment TokensFragment on Token {
    id
    tokenId
    isRefreshed
    isHidden
    image {
      src
      contentType
    }
    name
    lastOrder {
      price
      currency
    }
    rarity {
      provider
      rank
      url
    }
    collection {
      name
      address
      type
      isVerified
      isHidden
      totalSupply
      maxRarity
      logo {
        src
        contentType
      }
      volume {
        volume24h
      }
      floor {
        ...CollectionFloorFragment
      }
      osSlug
    }
  }
  ${collectionFloorFragment}
`;

/**
 * Note BE requires a "$watcher" arg to fetch `isWatching`, so we attach `isWatching` conditionally in each request
 */
export const watchlistFragment = gql`
  fragment WatchlistFragment on Watchlist {
    collections {
      ...CollectionBaseFragment
    }
    id
    isMain
    name
    owner {
      address
      name
    }
    watchersCount
    collectionCount
    isWatching
  }
  ${collectionBaseFragment}
`;

export const watchlistBaseFragment = gql`
  fragment WatchlistBaseFragment on Watchlist {
    id
    isMain
    name
    owner {
      address
      name
    }
    watchersCount
    collectionCount
    isWatching
  }
`;

export const userListingPointsFragment = gql`
  fragment UserListingPointsFragment on User {
    listingPoints {
      dailyRank
      points24h
      seasonPoints
      seasonRank
      multiplier
    }
  }
`;

export const listingSeasonFragment = gql`
  fragment CurrentListingSeasonFragment on ListingPointSeason {
    season
    startTime
    endTime
    looksRewardPool
  }
`;

export const gameStatsFragment = gql`
  fragment GameStatsFragment on GameStats {
    totalPlayers
    totalGamesPlayed
    volumePlayedWei
    volumeWonWei
  }
`;

export const rewardStatsFragment = gql`
  fragment RewardStatsFragment on RewardStats {
    totalEarners
    totalRewardsEarnedUsd
  }
`;
