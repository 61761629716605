import { useState } from "react";
import { useBoolean } from "@chakra-ui/react";
import { TFunction, useTranslation } from "next-i18next";
import { RoundStatus, SortRoundBy } from "../../types";

export interface RoundStatusFilters {
  label: string;
  value: RoundStatus | undefined;
}

export enum StatusFilter {
  ALL = 0,
  COMPLETED = 1,
  CANCELED = 2,
}

export const roundStatusFilters = (t: TFunction): RoundStatusFilters[] => [
  { label: t("yolo::All"), value: undefined },
  { label: t("yolo::Completed"), value: RoundStatus.Drawn },
  { label: t("yolo::Canceled"), value: RoundStatus.Cancelled },
];

export const useFilters = () => {
  const [statusFilter, setStatusFilter] = useState(StatusFilter.COMPLETED);
  const [sortBy, setSortBy] = useState<SortRoundBy>(SortRoundBy.NEWEST);
  const userFilter = useBoolean(false);

  const { t } = useTranslation();
  const filters = roundStatusFilters(t);

  return {
    statusFilter: filters[statusFilter],
    setStatusFilter,
    userFilter,
    sortBy,
    setSortBy,
  };
};
