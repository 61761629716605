import { useState } from "react";
import { Address } from "viem";
import { Grid, GridItem, Box, BoxProps } from "@chakra-ui/react";
import { useGameAddresses } from "@looksrare/uikit";
import { Round } from "../../types";
import { getNetworkFromYoloContractName, getYoloContractVersionFromName } from "../../utils";
import { useYoloConfig } from "../../config";
import { UserAssetList } from "./UserAssetList";
import { AddPanel } from "./AddPanel";
import { Header } from "./Header";

interface Props extends BoxProps {
  round: Round;
  userAddress: Address;
  onClose: () => void;
}

export const MobileView = ({ round, userAddress, onClose, ...props }: Props) => {
  const { defaultDepositContractName, supportNativeToken } = useYoloConfig();
  const defaultDepositAddress = useGameAddresses()[defaultDepositContractName];
  const [assetAddress, setAssetAddress] = useState<Address | undefined>(
    supportNativeToken ? undefined : defaultDepositAddress
  );

  return (
    <Box {...props}>
      <Header
        onClose={onClose}
        onBack={
          !!assetAddress ? () => setAssetAddress(supportNativeToken ? undefined : defaultDepositAddress!) : undefined
        }
      />
      <Grid templateColumns="1fr" flexGrow={1}>
        {assetAddress === undefined ? (
          <GridItem borderRight="solid 1px" borderColor="border-01" p={4}>
            <UserAssetList
              round={round}
              userAddress={userAddress}
              assetAddress={assetAddress}
              setAssetAddress={setAssetAddress}
            />
          </GridItem>
        ) : (
          <GridItem maxHeight="710px" overflowY="auto" p={4}>
            <AddPanel
              assetAddress={assetAddress}
              network={getNetworkFromYoloContractName(round.contract)}
              roundVersion={getYoloContractVersionFromName(round.contract)}
              roundValuePerEntry={BigInt(round.valuePerEntry)}
            />
          </GridItem>
        )}
      </Grid>
    </Box>
  );
};
