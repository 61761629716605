import { useState } from "react";
import { Address, zeroAddress } from "viem";
import { Grid, GridItem, Box, BoxProps, VStack } from "@chakra-ui/react";
import { useGameAddresses } from "@looksrare/uikit";
import { Round } from "../../types";
import { getNetworkFromYoloContractName, getYoloContractVersionFromName } from "../../utils";
import { useYoloConfig } from "../../config";
import { UserAssetList } from "./UserAssetList";
import { AddPanel } from "./AddPanel";
import { Header } from "./Header";

interface Props extends BoxProps {
  round: Round;
  userAddress: Address;
  onClose: () => void;
}

export const DesktopView = ({ round, userAddress, onClose, ...props }: Props) => {
  const { defaultDepositContractName } = useYoloConfig();
  const defaultDepositAddress = useGameAddresses()[defaultDepositContractName];
  const [assetAddress, setAssetAddress] = useState<Address>(defaultDepositAddress || zeroAddress);

  return (
    <Box {...props}>
      <Header onClose={onClose} />
      <Grid
        templateColumns="repeat(3, 1fr)"
        templateAreas={`"left right right"
                        "left right right"
                        "left right right"
                        "left right right"`}
        height="100%"
      >
        <GridItem area="left" borderRight="solid 1px" borderColor="border-01" maxHeight="944px" overflowY="auto" p={4}>
          <UserAssetList
            round={round}
            userAddress={userAddress}
            assetAddress={assetAddress}
            setAssetAddress={setAssetAddress}
          />
        </GridItem>

        <GridItem as={VStack} area="right" maxHeight="710px" overflowY="auto" p={8} spacing={8} alignItems="stretch">
          <AddPanel
            assetAddress={assetAddress}
            network={getNetworkFromYoloContractName(round.contract)}
            roundVersion={getYoloContractVersionFromName(round.contract)}
            roundValuePerEntry={BigInt(round.valuePerEntry)}
          />
        </GridItem>
      </Grid>
    </Box>
  );
};
