import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { usePublicClient, useWalletClient } from "wagmi";
import { BigIntish, RQueryOptions, useAddressesByNetwork } from "@looksrare/utils";
import { YoloAbi } from "@looksrare/config/abi/Yolo";
import { ClaimPrizesCalldataV1 } from "../../types";

const useYoloV1ContractInfo = () => {
  const addressesByNetwork = useAddressesByNetwork();

  return {
    address: addressesByNetwork.YOLO_V1,
    abi: YoloAbi,
  } as const;
};

/**
 * Claim prizes for the connected user
 */
export const useClaimPrizesV1 = () => {
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const yoloContractInfo = useYoloV1ContractInfo();

  return useCallback(
    async (claimPrizesCalldata: ClaimPrizesCalldataV1[], value?: BigIntish) => {
      if (!walletClient) {
        throw Error("No wallet client found");
      }
      if (!publicClient) {
        throw new Error("No public client found");
      }
      const claimPrizesCalldataFormatted = claimPrizesCalldata.map((claimPrizeCalldata) => {
        return {
          roundId: BigInt(claimPrizeCalldata.roundId.toString()),
          prizeIndices: claimPrizeCalldata.prizeIndices.map((prizeIndice) => BigInt(prizeIndice.toString())),
        };
      });

      const valueBi = value ? BigInt(value.toString()) : undefined;
      const [account] = await walletClient.getAddresses();
      const { request } = await publicClient.simulateContract({
        ...yoloContractInfo,
        functionName: "claimPrizes",
        args: [claimPrizesCalldataFormatted],
        value: valueBi,
        account,
      });
      return walletClient.writeContract(request);
    },
    [publicClient, walletClient, yoloContractInfo]
  );
};

/**
 * Withdraw deposits from cancelled rounds
 */
export const useWithdrawDepositsV1 = () => {
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const yoloContractInfo = useYoloV1ContractInfo();

  return useCallback(
    async (roundId: BigIntish, depositIndices: BigIntish[]) => {
      if (!walletClient) {
        throw Error("No wallet client found");
      }
      if (!publicClient) {
        throw new Error("No public client found");
      }
      const roundIdBi = BigInt(roundId.toString());
      const depositIndicesBi = depositIndices.map((depositIndice) => BigInt(depositIndice.toString()));

      const [account] = await walletClient.getAddresses();
      const estimatedGas = await publicClient.estimateContractGas({
        ...yoloContractInfo,
        functionName: "withdrawDeposits",
        args: [roundIdBi, depositIndicesBi],
        account,
      });
      return walletClient?.writeContract({
        ...yoloContractInfo,
        functionName: "withdrawDeposits",
        args: [roundIdBi, depositIndicesBi],
        account,
        gas: estimatedGas,
      });
    },
    [publicClient, walletClient, yoloContractInfo]
  );
};

/**
 * Pull amount to be paid for a user during the claiming process.
 * If all prizes are nft, the winner needs to pay the protocol fee while he claims.
 */
export const useGetClaimPrizesPaymentRequiredV1 = (
  claimPrizesCalldata: ClaimPrizesCalldataV1[],
  options?: RQueryOptions<bigint>
) => {
  const publicClient = usePublicClient();
  const yoloContractInfo = useYoloV1ContractInfo();

  return useQuery({
    queryKey: ["yolo-protocol-fee-owed"],
    queryFn: async () => {
      if (!publicClient) {
        throw new Error("No public client found");
      }
      const claimPrizesCalldataFormatted = claimPrizesCalldata.map((claimPrizeCalldata) => {
        return {
          roundId: BigInt(claimPrizeCalldata.roundId.toString()),
          prizeIndices: claimPrizeCalldata.prizeIndices.map((prizeIndice) => BigInt(prizeIndice.toString())),
        };
      });
      const fee = await publicClient.readContract({
        ...yoloContractInfo,
        functionName: "getClaimPrizesPaymentRequired",
        args: [claimPrizesCalldataFormatted],
      });
      return fee;
    },
    ...options,
  });
};
