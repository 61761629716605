import { Button, NextLink } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { formatToSignificant } from "@looksrare/utils/formatting/formatToSignificant";
import { useYoloQueryParams } from "../../../../utils";
import { YoloSuccessView } from "../shared/YoloSuccessView";
import { useYoloConfig } from "../../../../config";

interface Props {
  rolledOverAmount: bigint;
  onClose: () => void;
  onWithdrawOtherEntries?: () => void;
}

export const SuccessView = ({ rolledOverAmount, onClose, onWithdrawOtherEntries }: Props) => {
  const { network } = useYoloQueryParams();
  const { basePath, referenceToken } = useYoloConfig();
  const hasRolledOverEth = rolledOverAmount > 0n;
  const { t } = useTranslation();

  const title = hasRolledOverEth ? t("yolo::Roll Over Complete!") : t("yolo::Withdraw Complete!");
  const description = hasRolledOverEth
    ? t("yolo::You've rolled over {{amount}} {{token}} into the current round.", {
        amount: formatToSignificant(rolledOverAmount),
        token: referenceToken.symbol,
      })
    : t("yolo::Withdraw Complete!");

  return (
    <YoloSuccessView onClose={onClose} title={title} description={description}>
      {hasRolledOverEth ? (
        <>
          {!!onWithdrawOtherEntries && (
            <Button flex="1" onClick={onWithdrawOtherEntries}>
              {t("yolo::Withdraw Other Entries")}
            </Button>
          )}
          <Button as={NextLink} href={`/${basePath}/${network}`} flex="1" colorScheme="gray" onClick={onClose}>
            {t("yolo::View Round")}
          </Button>
        </>
      ) : (
        <Button flex="1" colorScheme="gray" onClick={onClose}>
          {t("yolo::Got it")}
        </Button>
      )}
    </YoloSuccessView>
  );
};
