import { getChainIdFromSupportedNetwork } from "@looksrare/utils";
import { chainTokenIconMap } from "@looksrare/uikit/constants";
import { IconProps } from "@looksrare/uikit";
import { YoloSupportedNetworks } from "../types";
import { useYoloConfig } from "../config";

export const NetworkNativeTokenIcon = ({ network, ...props }: { network: YoloSupportedNetworks } & IconProps) => {
  const { referenceToken } = useYoloConfig();
  const Icon =
    referenceToken.symbol === "ETH" ? chainTokenIconMap[getChainIdFromSupportedNetwork(network)] : referenceToken.icon;
  return <Icon {...props} />;
};
