import { Th, TableColumnHeaderProps, Flex, FlexProps } from "@chakra-ui/react";
import { Chevron } from "@looksrare/uikit";
import { Text } from "@looksrare/uikit";

export const IconWrapper = (props: FlexProps) => (
  <Flex
    justifyContent="center"
    alignItems="center"
    borderRadius="circular"
    width="40px"
    height="40px"
    mr={4}
    {...props}
  />
);

export const EmptyCell = () => (
  <Text color="text-disabled" textStyle="detail" bold>
    —
  </Text>
);

export const ThSortable: React.FC<TableColumnHeaderProps & { isUp: boolean }> = ({ isUp, children, ...props }) => (
  <Th layerStyle="clickable" {...props}>
    {children}
    <Chevron boxSize={4} direction={isUp ? "up" : "down"} ml={1} />
  </Th>
);
