import React from "react";
import groupBy from "lodash/groupBy";
import delay from "lodash/delay";
import { useYoloConfig } from "../../config";
import { ContractVersion } from "../../types";
import { useCurrentParticipant, useYoloQueryParams } from "../../utils";
import { WithdrawV1Deposits } from "./WithdrawV1Deposits";

export const DepositsV1Check = () => {
  const { network } = useYoloQueryParams();
  const { data: participant, refetch } = useCurrentParticipant(network);
  const {
    contract: { getYoloContractNameFromNetworkAndVersion },
  } = useYoloConfig();
  const contractName = getYoloContractNameFromNetworkAndVersion(network, ContractVersion.V1);
  const yoloRefunds = participant?.yoloUnclaimedRefunds.filter((refund) => refund.round.contract === contractName);
  const groupedRefunds = groupBy(yoloRefunds, "round.onChainId");
  const delayedRefetch = () => delay(refetch, 2_000); // @NOTE let indexer catch up

  if (!yoloRefunds) {
    return null;
  }

  return (
    <>
      {Object.entries(groupedRefunds).map(([roundId, refunds]) => (
        <WithdrawV1Deposits key={roundId} roundId={roundId} unclaimedRefunds={refunds} onSuccess={delayedRefetch} />
      ))}
    </>
  );
};
