import { Address } from "viem";
import { BigIntish, ReservoirOracleResponse, StateSetter } from "@looksrare/utils";
import { omit } from "lodash";
import { create } from "zustand";
import { TokenType } from "../../types";

/**
 * Maintain the list of assets added to the user cart during the deposit process
 */

// Reducer types

export interface YoloCartAssets {
  ethAmountWei: BigIntish;
  ethAmountRoundsCount: number;
  tokens: {
    [address: Address]: {
      name: string;
      type: TokenType;
      amount: BigIntish;
      minimumEntries: bigint;
      roundsCount: number;
    };
  };
  collections: {
    [address: Address]: {
      name: string;
      type: TokenType;
      price: ReservoirOracleResponse["price"];
      message: ReservoirOracleResponse["message"];
      minimumEntries: bigint;
      nfts: {
        tokenId: string;
        image: string;
      }[];
    };
  };
}

interface Actions {
  addEth: StateSetter<PayloadAddEth>;
  removeEth: () => void;
  addErc20: StateSetter<PayloadAddToken>;
  removeErc20: StateSetter<PayloadRemoveErc20>;
  addNFT: StateSetter<PayloadAddNft>;
  removeNFT: StateSetter<PayloadRemoveNft>;
  reset: () => void;
}

const initialState: YoloCartAssets = {
  ethAmountWei: 0,
  ethAmountRoundsCount: 1,
  tokens: {},
  collections: {},
};

export const useYoloCartStore = create<YoloCartAssets & Actions>()((set) => ({
  ...initialState,
  addEth: (payload: PayloadAddEth) =>
    set({
      ethAmountWei: payload.amount,
      ethAmountRoundsCount: payload.roundsCount,
    }),
  removeEth: () => set({ ethAmountWei: 0, ethAmountRoundsCount: 1 }),
  addErc20: (payload: PayloadAddToken) =>
    set((state) => ({ tokens: { ...state.tokens, [payload.address]: payload.token } })),
  removeErc20: (payload: PayloadRemoveErc20) => set((state) => ({ tokens: omit(state.tokens, payload.address) })),
  addNFT: (payload: PayloadAddNft) =>
    set((state) => ({
      collections: {
        ...state.collections,
        [payload.collectionAddress]: {
          ...payload,
          nfts: [...(state.collections[payload.collectionAddress]?.nfts ?? []), payload.nft],
        },
      },
    })),
  removeNFT: (payload: PayloadRemoveNft) =>
    set((state) => {
      const { collectionAddress, tokenId } = payload;
      const filteredNfts = state.collections[collectionAddress].nfts.filter((n) => n.tokenId !== tokenId);

      return {
        ...state,
        collections:
          filteredNfts.length === 0
            ? omit(state.collections, collectionAddress)
            : {
                ...state.collections,
                [collectionAddress]: {
                  ...state.collections[collectionAddress],
                  nfts: filteredNfts,
                },
              },
      };
    }),
  reset: () =>
    set(() => ({
      ...initialState,
    })),
}));

export interface PayloadAddNft extends Omit<YoloCartAssets["collections"][Address], "nfts"> {
  collectionAddress: Address;
  nft: YoloCartAssets["collections"][Address]["nfts"][number];
}

export interface PayloadRemoveNft {
  collectionAddress: Address;
  tokenId: string;
}

export interface PayloadAddToken {
  address: Address;
  token: YoloCartAssets["tokens"][Address];
}

export interface PayloadRemoveErc20 {
  address: Address;
}

export interface PayloadAddEth {
  amount: BigIntish;
  roundsCount: number;
}
