import { Box, chakra, Flex } from "@chakra-ui/react";
import {
  formatNumberToLocale,
  formatUsd,
  fromDecimals,
  getChainIdFromSupportedNetwork,
  useCoinPrices,
  useGetCurrencyConfig,
} from "@looksrare/utils";
import { CloudinaryImage, Text, TextProps, VerifiedIcon } from "@looksrare/uikit";
import {
  Deposit,
  DepositNft,
  GroupedDepositsByType,
  GroupedErc20ByAddress,
  isDepositNft,
  Round,
  TokenType,
  YoloSupportedNetworks,
} from "../../../types";
import { getNetworkFromYoloContractName } from "../../../utils";
import { NetworkNativeTokenIcon } from "../../NetworkNativeTokenIcon";
import { Amount } from "../../Amount";

const textProps: TextProps = {
  textStyle: "detail",
  bold: true,
};

const ValueBox = ({ valueWei, network }: { valueWei: bigint; network: YoloSupportedNetworks }) => {
  const { data: prices } = useCoinPrices();
  const ethPrice = prices?.eth.price || 0;
  const valueEth = parseFloat(fromDecimals(valueWei));
  return (
    <Box>
      <Amount network={network} amount={formatNumberToLocale(valueEth, 0, 2)} />
      <Text textStyle="helper" color="text-03" textAlign="right">
        {formatUsd(valueEth * ethPrice, 2, 2)}
      </Text>
    </Box>
  );
};

const EthRow: React.FC<{ round: Round; deposits: Deposit[] }> = ({ deposits, round, ...props }) => {
  const depositValueWei = deposits.reduce(
    (acc, deposit) => acc + BigInt(round.valuePerEntry) * BigInt(deposit.numberOfEntries),
    0n
  );
  const depositValueEth = fromDecimals(depositValueWei);
  const network = getNetworkFromYoloContractName(round.contract);

  return (
    <Flex justifyContent="space-between" alignItems="center" p={2} {...props}>
      <Amount
        gap={3}
        icon={() => <NetworkNativeTokenIcon network={network} boxSize={8} />}
        network={network}
        textProps={textProps}
        amount={`${formatNumberToLocale(parseFloat(depositValueEth), 0, 2)} ETH`}
      />
      <ValueBox valueWei={depositValueWei} network={network} />
    </Flex>
  );
};

const ERC20Row: React.FC<{ round: Round; deposits: Deposit[] }> = ({ deposits, round, ...props }) => {
  const network = getNetworkFromYoloContractName(round.contract);
  const getCurrencyConfig = useGetCurrencyConfig(getChainIdFromSupportedNetwork(network));
  const { icon: Icon, symbol, decimals } = getCurrencyConfig(deposits[0].currency);
  const depositAmountRaw = deposits.reduce((acc, deposit) => acc + BigInt(deposit.amount.toString()), 0n);
  const depositAmount = fromDecimals(depositAmountRaw, { decimals });

  const depositsValueWei = deposits.reduce((acc, deposit) => {
    const depositValueWei = BigInt(round.valuePerEntry) * BigInt(deposit.numberOfEntries);
    return acc + depositValueWei;
  }, 0n);

  return (
    <Flex justifyContent="space-between" alignItems="center" p={2} {...props}>
      <Flex alignItems="center" flexGrow={1}>
        {!!Icon && <Icon boxSize={8} />}
        <Text {...textProps}>{`${formatNumberToLocale(parseFloat(depositAmount), 0, 2)} ${symbol}`}</Text>
      </Flex>
      <ValueBox valueWei={depositsValueWei} network={network} />
    </Flex>
  );
};

const ERC721Row: React.FC<{ round: Round; deposit: DepositNft }> = ({ deposit, round, ...props }) => {
  const depositValueWei = BigInt(round.valuePerEntry.toString()) * BigInt(deposit.numberOfEntries);
  const network = getNetworkFromYoloContractName(round.contract);
  return (
    <Flex justifyContent="space-between" alignItems="center" p={2} {...props}>
      <Flex alignItems="center" flexGrow={1}>
        <Box>
          <Box position="relative" width="32px" height="32px" sx={{ img: { color: "transparent" } }}>
            <CloudinaryImage
              src={deposit.token.image.src}
              contentType={deposit.token.image.contentType}
              alt={deposit.token.collection.address + deposit.token.tokenId}
              layout="fill"
              objectFit="contain"
              sizes="32px"
            />
          </Box>
        </Box>
        <Box ml={3}>
          <Text {...textProps} m={0}>
            #{deposit.token.tokenId.toString()}
          </Text>
          <chakra.span w="100%" display="inline-flex" alignItems="center">
            <Box overflow="hidden">
              <Text whiteSpace="normal" noOfLines={1} textStyle="helper" bold color="text-01" {...textProps}>
                {deposit.token.collection.name}
              </Text>
            </Box>
            {deposit.token.collection.isVerified && <VerifiedIcon boxSize={4} ml={1} />}
          </chakra.span>
        </Box>
      </Flex>
      <ValueBox valueWei={depositValueWei} network={network} />
    </Flex>
  );
};

export const AssetDepositRows: React.FC<{ round: Round; playerDeposits: Deposit[] }> = ({ playerDeposits, round }) => {
  const groupedDepositsByType = playerDeposits.reduce<GroupedDepositsByType>((accum, deposit) => {
    accum[deposit.type] = [...(accum[deposit.type] || []), deposit];
    return accum;
  }, {});

  const groupedErc20ByAddress = groupedDepositsByType[TokenType.ERC20]?.reduce<GroupedErc20ByAddress>(
    (accum, deposit) => {
      accum[deposit.currency] = [...(accum[deposit.currency] || []), deposit];
      return accum;
    },
    {}
  );

  return (
    <>
      {groupedDepositsByType[TokenType.ETH] && <EthRow round={round} deposits={groupedDepositsByType[TokenType.ETH]} />}
      {groupedErc20ByAddress &&
        Object.entries(groupedErc20ByAddress).map(([tokenAddress, deposits]) => (
          <ERC20Row key={tokenAddress} round={round} deposits={deposits} />
        ))}
      {groupedDepositsByType[TokenType.ERC721]?.map((deposit) =>
        !isDepositNft(deposit) ? null : <ERC721Row key={deposit.id.toString()} round={round} deposit={deposit} />
      )}
    </>
  );
};
