import { useAnimatedLayerStyle } from "@looksrare/utils";
import { IconProps } from "@looksrare/uikit/components/Icons";
import { Flex, FlexProps } from "@chakra-ui/react";
import { TextProps, Text } from "@looksrare/uikit";
import { YoloSupportedNetworks } from "../types";
import { NetworkNativeTokenIcon } from "./NetworkNativeTokenIcon";

export interface AmountProps extends FlexProps {
  icon?: React.FC<{ network: YoloSupportedNetworks } & IconProps>;
  network: YoloSupportedNetworks;
  amount: string;
  textProps?: TextProps;
}

export const Amount = ({ icon: Icon = NetworkNativeTokenIcon, network, amount, textProps, ...props }: AmountProps) => (
  <Flex alignItems="center" gap={1} {...props}>
    <Icon network={network} boxSize={4} />
    <Text textStyle="detail" bold {...textProps}>
      {amount}
    </Text>
  </Flex>
);

export const AnimatedAmount = ({
  icon: Icon = NetworkNativeTokenIcon,
  network,
  amount,
  textProps,
  ...props
}: AmountProps) => {
  const amountAsNumber = parseFloat(amount);
  const animatedLayerStyle = useAnimatedLayerStyle(amountAsNumber);

  return (
    <Amount
      amount={amount}
      network={network}
      key={amount}
      icon={Icon}
      textProps={{
        layerStyle: animatedLayerStyle,
        ...textProps,
      }}
      {...props}
    />
  );
};
