import { Flex, FlexProps, ColorProps, useDisclosure, Box, VStack } from "@chakra-ui/react";
import { Address } from "viem";
import { useAccount } from "wagmi";
import { UsernameDisplay, RankOneGradient, Text, ProfileAvatar } from "@looksrare/uikit";
import { formatCompactNumber, formatToSignificant, isAddressEqual, divideWeiByWei } from "@looksrare/utils";
import { useMemo } from "react";
import noop from "lodash/noop";
import { formatPercentage } from "@looksrare/utils/formatting/formatPercentage";
import { useYoloStore } from "../../utils/yoloStore";
import { Depositor, Round, RoundStatus } from "../../types";
import { PlayerProfileModal } from "../modals/PlayerProfileModal";
import { getNetworkFromYoloContractName } from "../../utils";
import { NetworkNativeTokenIcon } from "../NetworkNativeTokenIcon";
import { useYoloConfig } from "../../config";

// @NOTE: Do NOT use an animated skeleton here, thousands of these could be rendered
export const PlayerTileSkeleton = () => <Box mb={1} width="100%" height="68px" bg="ui-01" borderRadius="container" />;

interface Props {
  round: Round;
  userAddress: Address;
  rightColor?: ColorProps["color"];
  userValue?: bigint;
  hidePercentage?: boolean;
  shouldOpenEntries?: boolean;
  isWinnerHighlighted?: boolean;
}

export const PlayerTile = ({
  round,
  userAddress,
  rightColor,
  userValue,
  hidePercentage,
  shouldOpenEntries = true,
  isWinnerHighlighted = false,
}: Props) => {
  const playerProfileDisclosure = useDisclosure();
  const yoloPointsExplanationDisclosure = useDisclosure();
  const { address } = useAccount();
  const { setHighlightedUser } = useYoloStore();
  const {
    YoloPointsExplanationModal,
    WinnerIcon,
    points: { enabled: supportsPoints, Icon: PointsIcon, label: pointsLabel },
  } = useYoloConfig();
  const isRoundCompleted = round.status === RoundStatus.Drawing || round.status === RoundStatus.Drawn;
  const network = getNetworkFromYoloContractName(round.contract);

  const isCurrentUser = !!address && isAddressEqual(address, userAddress);
  const [user, totalGemsEarned, userNbOfEntries] = useMemo(
    () =>
      round.deposits.reduce<[Depositor | undefined, bigint, bigint]>(
        (acc, deposit) => {
          if (isAddressEqual(deposit.depositor.address, userAddress)) {
            const [, gems, entries] = acc;
            return [
              deposit.depositor,
              gems + BigInt(deposit.gemsEarned || 0n),
              entries + BigInt(deposit.numberOfEntries),
            ];
          }
          return acc;
        },
        [undefined, 0n, 0n]
      ),
    [round, userAddress]
  );

  const totalGemsEarnedDisplay = formatCompactNumber(Number(totalGemsEarned));

  const valuePerEntryBi = BigInt(round.valuePerEntry.toString());
  const userTotalValue = userValue ?? valuePerEntryBi * userNbOfEntries;
  const userShare = divideWeiByWei(userTotalValue, round.potValue) * 100;

  const openEntriesProps: Partial<FlexProps> = shouldOpenEntries
    ? {
        onClick: playerProfileDisclosure.onOpen,
        cursor: "pointer",
        "aria-label": `See player ${user?.name || userAddress} entries`,
        transition: "background-color 0.2s",
        _active: { bg: "onclick-ui" },
        _hover: { bg: "hover-ui" },
      }
    : {};

  const isWinner = isWinnerHighlighted && isAddressEqual(userAddress, round.winner?.address);

  const getOpacity = () => {
    const highlightedUser = useYoloStore.getState().highlightedUser;
    const isHighlighted = isAddressEqual(userAddress, highlightedUser);

    if (isHighlighted) {
      return 1;
    }

    if (highlightedUser !== undefined && !isHighlighted) {
      return 0.5;
    }

    if (isRoundCompleted && !isWinner) {
      return 0.5;
    }

    return 1;
  };

  return (
    <>
      {supportsPoints && (
        <YoloPointsExplanationModal
          isOpen={yoloPointsExplanationDisclosure.isOpen}
          onClose={yoloPointsExplanationDisclosure.onClose}
          network={network}
        />
      )}
      <PlayerProfileModal
        isOpen={playerProfileDisclosure.isOpen}
        onClose={playerProfileDisclosure.onClose}
        onGemsHelpClick={supportsPoints ? yoloPointsExplanationDisclosure.onOpen : noop}
        userAddress={userAddress}
        round={round}
      />

      <Box
        pb={1} // @NOTE: This is to create a larger hover trigger, as unnecessarily toggling hover causes jarring UI updates
        onMouseOver={() => setHighlightedUser(userAddress)}
        onMouseLeave={() => setHighlightedUser(undefined)}
      >
        <Flex
          position="relative"
          bg="ui-bg"
          borderRadius="button"
          borderRight="solid 12px"
          borderColor={rightColor}
          alignItems="center"
          p={3}
          opacity={getOpacity()}
          width="100%"
          {...openEntriesProps}
        >
          {isWinner && (
            <Box position="absolute" height="100%" overflow="hidden" borderRadius="button" top={0} left={0}>
              <RankOneGradient />
            </Box>
          )}
          <ProfileAvatar
            address={userAddress}
            src={user?.avatar?.image.src}
            size={32}
            isPrivate={user?.isProfileImageVisible === false}
          />
          <Flex justifyContent="space-between" alignItems="center" flexGrow={1} ml={3}>
            <VStack spacing={1} direction="column" alignItems="start">
              <Flex alignItems="center" fontWeight={isWinner ? "bold" : "normal"}>
                {isCurrentUser ? (
                  <Text color={isWinner ? "yellow.200" : "link-01"} textStyle="detail">
                    You
                  </Text>
                ) : (
                  <UsernameDisplay
                    address={userAddress}
                    name={user?.name}
                    isVerified={user?.isVerified}
                    color={isWinner ? "yellow.200" : "text-03"}
                    textStyle="detail"
                    verifiedIconProps={{ boxSize: 3 }}
                  />
                )}
                {isWinner && <WinnerIcon boxSize={5} ml={1} />}
              </Flex>
              {supportsPoints && totalGemsEarned > 0 && (
                <Flex alignItems="center">
                  {!!PointsIcon && <PointsIcon boxSize={3} />}
                  <Text color="text-02" textStyle="detail" ml={1}>
                    {totalGemsEarnedDisplay} {!PointsIcon && pointsLabel}
                  </Text>
                </Flex>
              )}
            </VStack>
            <VStack spacing={1} direction="column" alignItems="end">
              {!hidePercentage && (
                <Flex justifyContent="space-between">
                  <Text textStyle="detail" bold ml={2}>
                    {formatPercentage(userShare)}
                  </Text>
                </Flex>
              )}
              <Flex alignItems="center">
                <Text color="text-02" textStyle="detail">
                  {formatToSignificant(userTotalValue)}
                </Text>
                <NetworkNativeTokenIcon network={network} boxSize={3} ml={1} />
              </Flex>
            </VStack>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};
