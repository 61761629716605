import React from "react";
import { Box, BoxProps, useColorModeValue } from "@chakra-ui/react";
import { Container, CloudinaryImage } from "@looksrare/uikit";
import { useYoloConfig } from "../config";
import { YoloChartStyles } from "./YoloChartStyles";

const YoloContainer = (props: BoxProps) => (
  <Container p={4} maxWidth={{ base: "1440px", "3xl": "90vw" }} minH="90dvh" flexDirection="column" {...props} />
);

const Background = ({ children, ...props }: BoxProps) => {
  const background = useColorModeValue("jackpot-background-light", "jackpot-background-dark");

  return (
    <Box {...props}>
      <Box pos="fixed" inset="0" zIndex={0}>
        <CloudinaryImage
          placeholderSrc=""
          src={`/images/yolo/${background}.png`}
          alt=""
          layout="fill"
          objectFit="cover"
        />
      </Box>
      {children}
    </Box>
  );
};

interface Props extends BoxProps {
  withBackgroundImage?: boolean;
}
export const Layout: React.FC<Props> = ({ withBackgroundImage = false, ...props }) => {
  const { hasBackgroundImage } = useYoloConfig();
  if (hasBackgroundImage && withBackgroundImage) {
    return (
      <Background>
        <YoloChartStyles />
        <YoloContainer {...props} />
      </Background>
    );
  }
  return <YoloContainer {...props} />;
};
