import React from "react";
import { Address } from "viem";
import { ModalLegacy, ModalLegacyProps } from "@looksrare/uikit";
import { Round } from "../../../types";
import { PlayerProfileModalBody } from "./PlayerProfileModalBody";

interface Props extends Omit<ModalLegacyProps, "children"> {
  round: Round;
  userAddress: Address;
  onGemsHelpClick: () => void;
}

export const PlayerProfileModal: React.FC<Props> = ({ round, userAddress, onClose, onGemsHelpClick, ...props }) => {
  return (
    <ModalLegacy title="Entries" hideHeader onClose={onClose} {...props}>
      {props.isOpen && (
        <PlayerProfileModalBody
          round={round}
          userAddress={userAddress}
          onClose={onClose}
          onGemsHelpClick={onGemsHelpClick}
        />
      )}
    </ModalLegacy>
  );
};
