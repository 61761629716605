import { useEffect } from "react";
import { Address, zeroAddress } from "viem";
import { Box, chakra, Flex, FlexProps } from "@chakra-ui/react";
import {
  getChainIdFromSupportedNetwork,
  isAddressEqual,
  useGetCurrencyConfig,
  useIntersectionObserver,
} from "@looksrare/utils";
import { ProfileAvatar, useGameAddresses, VerifiedIcon } from "@looksrare/uikit";
import { chainTokenIconMap } from "@looksrare/uikit/constants";
import { Text } from "@looksrare/uikit";
import { Round, YoloSupportedNetworks } from "../../types";
import { getNetworkFromYoloContractName } from "../../utils/chains";
import { CollectionFilterItem, useUserWhitelistedCollections } from "../../utils/api/useUserWhitelistedCollections";
import { useYoloConfig } from "../../config";

/**
 * List user NFTs and ERC20 tokens available for deposit
 */

interface RowProps extends FlexProps {
  isSelected?: boolean;
  onSelect: () => void;
}

const Wrapper = (props: FlexProps) => (
  <Flex
    alignItems="center"
    cursor="pointer"
    sx={{ _hover: { bg: "ui-01" } }}
    transition="background-color 0.3s ease"
    borderRadius="mini"
    p={2}
    {...props}
  />
);

const CollectionRow = ({
  collection,
  isSelected = false,
  onSelect,
  ...props
}: RowProps & { collection: CollectionFilterItem }) => {
  const { name, address, isVerified, logo } = collection;

  return (
    <Wrapper bg={isSelected ? "ui-01" : "transparent"} onClick={onSelect} aria-label={`Select ${name}`} {...props}>
      <ProfileAvatar borderRadius="button" size={32} address={address} src={logo?.src} flexShrink={0} mr={3} />
      <chakra.span w="100%" display="inline-flex" alignItems="center">
        <Box overflow="hidden">
          <Text
            whiteSpace="normal"
            noOfLines={1}
            textStyle="detail"
            color={isSelected ? "link-01" : "text-01"}
            bold={isSelected}
          >
            {name}
          </Text>
        </Box>
        {isVerified && <VerifiedIcon boxSize={4} ml={1} />}
      </chakra.span>
    </Wrapper>
  );
};

const TokenRow = ({
  tokenAddress,
  isSelected = false,
  onSelect,
  network,
  ...props
}: RowProps & { network: YoloSupportedNetworks; tokenAddress: Address }) => {
  const currentChainId = getChainIdFromSupportedNetwork(network);
  const getCurrencyConfig = useGetCurrencyConfig(currentChainId);
  const {
    icon: Icon,
    symbol,
  } = // @TODO patch for native tokens
    tokenAddress === zeroAddress
      ? { icon: chainTokenIconMap[currentChainId], symbol: "ETH" }
      : getCurrencyConfig(tokenAddress);

  return (
    <Wrapper bg={isSelected ? "ui-01" : "transparent"} onClick={onSelect} {...props}>
      {!!Icon && ( // @TODO-yg this should always be defined, but isn't. Seems to be a circular dependency issue
        <Icon color="text-03" boxSize={6} mr={3} />
      )}
      <Text color={isSelected ? "link-01" : "text-01"} bold={isSelected} textStyle="detail">
        {symbol}
      </Text>
    </Wrapper>
  );
};

interface Props {
  round: Round;
  userAddress: Address;
  assetAddress?: string;
  setAssetAddress: any;
}

export const UserAssetList = ({ assetAddress, setAssetAddress, userAddress, round }: Props) => {
  const network = getNetworkFromYoloContractName(round.contract);
  const { supportedTokens } = useYoloConfig();
  const addressesByNetwork = useGameAddresses();

  const { observerRef, isIntersecting } = useIntersectionObserver("200px");
  const supportsNFTs = network === "ethereum";
  const { collections, fetchNextCollections, hasMoreCollections, isFetching } = useUserWhitelistedCollections(
    userAddress,
    round,
    supportsNFTs
  );

  const shouldFetchNextPage = supportsNFTs && hasMoreCollections && isIntersecting && !isFetching;

  useEffect(() => {
    if (shouldFetchNextPage) {
      fetchNextCollections();
    }
  }, [fetchNextCollections, shouldFetchNextPage]);

  return (
    <>
      <Text color="text-03" textStyle="helper" bold mb={2}>
        Tokens
      </Text>
      {supportedTokens.map((tokenName) => {
        const tokenAddress = addressesByNetwork[tokenName] || "0x";
        return tokenAddress === "0x" ? null : (
          <TokenRow
            key={tokenAddress}
            tokenAddress={tokenAddress}
            isSelected={isAddressEqual(assetAddress, tokenAddress)}
            onSelect={() => setAssetAddress(tokenAddress)}
            network={network}
          />
        );
      })}

      {supportsNFTs && (
        <>
          <Text color="text-03" textStyle="helper" bold mb={2} mt={6}>
            NFTs
          </Text>
          {collections &&
            collections.map((collection) => (
              <CollectionRow
                key={collection.address}
                collection={collection}
                isSelected={isAddressEqual(assetAddress, collection.address)}
                onSelect={() => setAssetAddress(collection.address)}
              />
            ))}
        </>
      )}
      <div ref={observerRef} />
    </>
  );
};
