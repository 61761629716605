import React, { useState, useEffect } from "react";
import { Address } from "viem";
import { usePublicClient, useWalletClient } from "wagmi";
import { useTranslation } from "next-i18next";
import { VStack } from "@chakra-ui/react";
import { type BigIntish, useHandleModalStep, useWalletClientIsReady } from "@looksrare/utils";
import { DEFAULT_ERC20_APPROVAL_AMOUNT, Erc20Abi, USDTAbi } from "@looksrare/config";
import { BulkTransactionStepStatus, StepErrorCta, Text, TransactionStepRow } from "@looksrare/uikit";

export const ApprovalErc20 = ({
  tokenAddress,
  amount,
  tokenName,
  isCurrent,
  next,
  step,
  spenderAddress,
  isUsdt,
}: {
  tokenAddress: Address;
  amount: BigIntish;
  tokenName: string;
  isCurrent: boolean;
  next: () => void;
  step: number;
  spenderAddress: Address;
  isUsdt: boolean;
}) => {
  const { t } = useTranslation();
  const { data: walletClient } = useWalletClient();
  const { isSignerReady } = useWalletClientIsReady();
  const publicClient = usePublicClient();
  const [transaction, setTransaction] = useState<string>();

  const { handleSubmit, isRejected, isIdle, isAccepted } = useHandleModalStep({
    onSubmit: async () => {
      if (walletClient && publicClient) {
        const [account] = await walletClient.getAddresses();

        /* USDT is non standard ERC20, so we need to use a different ABI. The code is duplicated instead of conditionally
         * assigning the ABI to help TS infer correctly
         */
        if (isUsdt) {
          const usdtContractInfo = {
            address: tokenAddress,
            abi: USDTAbi,
          };
          const allowanceAmount = await publicClient.readContract({
            ...usdtContractInfo,
            functionName: "allowance",
            args: [account, spenderAddress],
          });

          if (allowanceAmount < BigInt(amount.toString())) {
            const { request } = await publicClient.simulateContract({
              ...usdtContractInfo,
              functionName: "approve",
              args: [spenderAddress, DEFAULT_ERC20_APPROVAL_AMOUNT],
              account,
            });
            const hash = await walletClient.writeContract(request);
            setTransaction(hash);
            const receipt = await publicClient.waitForTransactionReceipt({ hash });

            if (receipt.status === "success") {
              setTransaction(undefined);
            } else {
              throw new Error(`${receipt.transactionHash} failed`);
            }
          }
          next();
        } else {
          const erc20ContractInfo = {
            address: tokenAddress,
            abi: Erc20Abi,
          } as const;

          const allowanceAmount = await publicClient.readContract({
            ...erc20ContractInfo,
            functionName: "allowance",
            args: [account, spenderAddress],
          });

          if (allowanceAmount < BigInt(amount.toString())) {
            const { request } = await publicClient.simulateContract({
              ...erc20ContractInfo,
              functionName: "approve",
              args: [spenderAddress, DEFAULT_ERC20_APPROVAL_AMOUNT],
              account,
            });
            const hash = await walletClient.writeContract(request);
            setTransaction(hash);
            const receipt = await publicClient.waitForTransactionReceipt({ hash });

            if (receipt.status === "success") {
              setTransaction(undefined);
            } else {
              throw new Error(`${receipt.transactionHash} failed`);
            }
          }
          next();
        }
      }
    },
  });

  useEffect(() => {
    if (isCurrent && isSignerReady) {
      handleSubmit({ callOnlyOnce: true });
    }
  }, [handleSubmit, isCurrent, isSignerReady]);

  const rowStatus = ((): BulkTransactionStepStatus => {
    if (isAccepted) {
      return "done";
    }
    if (isRejected) {
      return "error";
    }
    if (isIdle) {
      return "wait";
    }
    return "pending";
  })();

  return (
    <VStack alignItems="flex-start" width="100%">
      <Text textStyle="helper" bold color={isRejected ? "text-error" : "text-01"}>
        {isRejected
          ? t("yolo::You declined the transaction {{counterDisplay}}", { counterDisplay: step + 1 })
          : t("yolo::Confirm transaction in wallet {{counterDisplay}}", { counterDisplay: step + 1 })}
      </Text>
      <TransactionStepRow transactionHash={transaction} status={rowStatus} text={tokenName} />
      {isRejected && <StepErrorCta onRetry={() => handleSubmit()} />}
    </VStack>
  );
};
