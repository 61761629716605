import { Box, ModalBody, ModalHeader, VStack } from "@chakra-ui/react";
import { BulkTransactionStep, Text } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import { ClaimPrizesCalldata } from "../../../../../types";
import { BulkLooksApprovalsStep } from "../LooksApprovals";
import { RolloverStep } from "./RolloverStep";

enum Steps {
  APPROVE_TRANSFER_MANAGER = "APPROVE_TRANSFER_MANAGER",
  APPROVE_LOOKS = "APPROVE_LOOKS",
  ROLL_OVER = "ROLL_OVER",
}

interface Props {
  onComplete: () => void;
  isPayingFeesWithLooks: boolean;
  ethOnlyClaims: ClaimPrizesCalldata;
  feesFromUserWallet: bigint;
  /* We do not apply fees to rollovers of refunds*/
  hasPlatformFees: boolean;
  rollableOverAmount: bigint;
}

export const RollOverTransactionView = ({
  onComplete,
  isPayingFeesWithLooks,
  ethOnlyClaims,
  feesFromUserWallet,
  hasPlatformFees,
  rollableOverAmount,
}: Props) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState<Steps>(
    isPayingFeesWithLooks ? Steps.APPROVE_TRANSFER_MANAGER : Steps.ROLL_OVER
  );

  const isApprovalStep = [Steps.APPROVE_TRANSFER_MANAGER, Steps.APPROVE_LOOKS].includes(currentStep);

  return (
    <>
      <ModalHeader>
        <Text bold textStyle="heading-04">
          {t("yolo::Roll Over")}
        </Text>
      </ModalHeader>
      <ModalBody>
        <VStack spacing={3} alignItems="stretch">
          {!isPayingFeesWithLooks ? (
            <Box px={6} py={4} borderRadius="container" bg="ui-bg">
              <RolloverStep
                isStepActive={currentStep === Steps.ROLL_OVER}
                ctaText={t("yolo::Roll Over")}
                onComplete={onComplete}
                ethOnlyClaims={ethOnlyClaims}
                isPayingFeesWithLooks={isPayingFeesWithLooks}
                hasPlatformFees={hasPlatformFees}
                rollableOverAmount={rollableOverAmount}
              />
            </Box>
          ) : (
            <>
              <BulkLooksApprovalsStep
                status={isApprovalStep ? "current" : "past"}
                amount={feesFromUserWallet}
                onComplete={() => setCurrentStep(Steps.ROLL_OVER)}
              />
              <BulkTransactionStep
                title={t("yolo::Roll Over")}
                status={isApprovalStep ? "future" : "current"}
                collapse={currentStep !== Steps.ROLL_OVER}
              >
                <RolloverStep
                  isStepActive={currentStep === Steps.ROLL_OVER}
                  ctaText={t("yolo::Roll Over ETH")}
                  onComplete={onComplete}
                  ethOnlyClaims={ethOnlyClaims}
                  hasPlatformFees={hasPlatformFees}
                  rollableOverAmount={rollableOverAmount}
                  isPayingFeesWithLooks={isPayingFeesWithLooks}
                />
              </BulkTransactionStep>
            </>
          )}
        </VStack>
      </ModalBody>
    </>
  );
};
