import { Address } from "viem";
import { BigIntish, type Pagination as IPagination, TokenStandard } from "../../utils";
import { AggregatedAsk, ExecutableOrder, LooksRareIshOrder, OrderStrategyType, AggregatedOrderWithV1 } from "./orders";

export type AttributeDisplayType = "date" | "number" | "string";

export interface Attribute {
  id: string;
  traitType: string;
  value: string;
  count: BigIntish;
  displayType?: AttributeDisplayType;
  floorOrder?: {
    price: BigIntish;
  };
  lastOrder?: {
    price: BigIntish;
    currency: Address;
    sale: {
      createdAt: string;
    };
  };
}

export type Pagination = IPagination; // @TODO migration alias
export interface CollectionOwner {
  address: Address;
  isVerified?: boolean;
  name?: string;
  avatar?: Omit<UserAvatar, "collection" | "name" | "description">;
}

export interface CollectionVolume {
  volumeAll: BigIntish | null;
  volume24h: BigIntish | null;
  change24h: number | null;
  volume7d: BigIntish | null;
  change7d: number | null;
  volume1m: BigIntish | null;
  change1m: number | null;
}

export interface CollectionFloor {
  floorPriceGlobal: BigIntish | null;
  floorPriceOS: BigIntish | null;
  floorPriceBlur: BigIntish | null;
  floorPriceX2Y2: BigIntish | null;
  // note floorPriceLooksRare is currently the same as floorPrice
  floorPriceLooksRare: BigIntish | null;
  floorPrice: BigIntish | null;
  floorChange24h: number | null;
  floorChange7d: number | null;
  floorChange30d: number | null;
}

export interface HistoricalFloor {
  floor24hAgo: BigIntish | null;
  floor30dAgo: BigIntish | null;
  floor7dAgo: BigIntish | null;
}

export interface CollectionSales {
  count24h: BigIntish | null;
  count7d: BigIntish | null;
  count1m: BigIntish | null;
  countAll: BigIntish | null;
  change24h: number | null;
  change7d: number | null;
  change1m: number | null;
}

export interface CollectionListings {
  listingCountGlobal: BigIntish | null;
  // A currently unused breakdown by marketplace is also available
}

// Used for most minimal Collection displays i.e. within collection tables
export interface CollectionBase {
  name: string;
  address: Address;
  type: TokenStandard;
  totalSupply: BigIntish | null;
  logo?: ImageData;
  banner?: ImageData;
  isVerified?: boolean;
  isExplicit?: boolean;
  volume: CollectionVolume;
  countOwners?: BigIntish;
  countWatchlists: number;
  floor: CollectionFloor;
  sales: CollectionSales;
  listingCount: CollectionListings;
  historicalFloor: HistoricalFloor;
  maxRarity: BigIntish | null;
  watchlists?: BigIntish;
  isInMainWatchlist: boolean;
}

/**
 * Used for populating filters by collection
 */
export interface CollectionFilterItem {
  name: string;
  address: Address;
  totalSupply: CollectionBase["totalSupply"];
  volume: Pick<CollectionVolume, "volume24h">;
  owned: BigIntish;
  logo?: ImageData;
  isVerified: boolean;
  isHidden: boolean | null;
  floor: CollectionFloor;
}

export interface Collection extends CollectionBase {
  owner: CollectionOwner;
  feeSetter: CollectionOwner | null;
  description?: string;
  websiteLink?: string;
  facebookLink?: string;
  twitterUsername?: string;
  telegramLink?: string;
  instagramLink?: string;
  mediumLink?: string;
  discordLink?: string;
  collaborators: { collaborator: string }[];
}

export interface CollectionRecommendation {
  name: CollectionBase["name"];
  address: CollectionBase["address"];
  logo?: CollectionBase["logo"];
  banner?: Collection["banner"];
  isVerified: CollectionBase["isVerified"];
  floor: CollectionFloor;
  historicalFloor: HistoricalFloor;
  volume: {
    volume7d: CollectionVolume["volume7d"];
    change7d: CollectionVolume["change7d"];
  };
}

export type CollectionStaticData = Pick<
  Collection,
  | "name"
  | "address"
  | "type"
  | "description"
  | "logo"
  | "banner"
  | "isVerified"
  | "isExplicit"
  | "maxRarity"
  | "owner"
  | "feeSetter"
  | "collaborators"
  | "websiteLink"
  | "facebookLink"
  | "twitterUsername"
  | "telegramLink"
  | "instagramLink"
  | "mediumLink"
  | "discordLink"
>;

export interface WatchlistCollectionSearchResult {
  name: CollectionBase["name"];
  address: CollectionBase["address"];
  logo?: CollectionBase["logo"];
  isVerified: CollectionBase["isVerified"];
}

export interface CollectionStats {
  totalSupply: CollectionBase["totalSupply"];
  floor: CollectionFloor;
  historicalFloor: HistoricalFloor;
  volume: CollectionVolume;
  countOwners: CollectionBase["countOwners"];
  countWatchlists: CollectionBase["countWatchlists"];
}

export type SentimentAggregate = "DAILY" | "HOURLY";

export interface CollectionSentimentData {
  date: number;
  negative: number;
  positive: number;
  neutral: number;
  sentiment: number;
  volume: number;
}

// @TODO this needs to be renamed to be more clear as it is not a user object but an NFT
export interface UserAvatar {
  id: string;
  tokenId: string;
  name: string;
  description: string;
  image: ImageData;
  collection: {
    address: Address;
  };
}

// This type is used in conjunction with displaying a user link
export interface UserProfileDisplay {
  address: Address;
  isVerified: boolean;
  image?: ImageData;
  name: string | null;
  ensDomain?: string | null;
}

export interface User {
  address: Address;
  isVerified?: boolean;
  name: string | null;
  avatar?: UserAvatar; // Optional to allow compatibility with UserProfileDisplay
  biography?: string;
  email?: string | null;
  isEmailVerified?: boolean | null;
  websiteLink?: string;
  instagramLink?: string;
  twitterUsername?: string;
  countCollections?: BigIntish;
  raffleCheck?: boolean;
}

export interface BaseOwner {
  address: Address;
  name?: string;
}

export interface ExtendedOwner extends BaseOwner {
  avatar?: { image: ImageData };
}

export interface ExtendedTokenOwner {
  owner: ExtendedOwner;
  balance: BigIntish;
}

export interface BaseTokenOwner {
  owner: BaseOwner;
  balance: BigIntish;
}

// Simplified NFT for displaying/selecting an nft
export interface NFTAvatar {
  id: NFT["id"];
  tokenId: NFT["tokenId"];
  collection: NFT["collection"]["address"];
  image: NFT["image"];
  name: NFT["name"];
}

// Collection props requested by NFT entity
export interface NFTCollection {
  address: Address;
  name: string;
  type: TokenStandard;
  totalSupply: CollectionBase["totalSupply"];
  countOwners: CollectionBase["countOwners"];
  logo?: ImageData;
  isVerified?: boolean;
  owner?: CollectionOwner;
  description?: string;
  websiteLink?: string;
  twitterUsername?: string;
  instagramLink?: string;
  discordLink?: string;
  osSlug: string | null;
}

export interface NFT {
  id: string;
  tokenId: string;
  image: ImageData;
  name: string;
  animation?: AnimationData;
  description?: string;
  attributes?: Attribute[];
  isRefreshed: boolean;
  countOwners: BigIntish;
  totalSupply: BigIntish;
  collection: NFTCollection;
  rarity: RarityData[] | [];
}

export interface NftAsksBidsLastOrder {
  ask?: ExecutableOrder; // There is an ask only if the NFT is listed for direct sale
  asks?: AggregatedAsk[]; // All asks for this token - may include non-executable orders
  bids: LooksRareIshOrder[]; // The bid array will be empty of there is no offer
  lastOrder?: { price: BigIntish; currency: Address };
}

// Collection props requested by NFTCard entity
export type NFTCardCollection = {
  address: Address;
  name: string;
  type: TokenStandard;
  isVerified?: boolean;
  logo?: ImageData;
  isHidden: boolean | null;
  floor: CollectionFloor;
  totalSupply: CollectionBase["totalSupply"];
  volume: CollectionVolume;
  maxRarity: BigIntish | null;
  osSlug: string | null;
};

// Used for NFT Grid Card components
export interface NFTCard extends Omit<NFT, "attributes" | "collection" | "animation" | "countOwners" | "totalSupply"> {
  owners: BaseTokenOwner[];
  collection: NFTCardCollection;
  isHidden: boolean | null;
  ask?: NftAsksBidsLastOrder["ask"];
  asks?: NftAsksBidsLastOrder["asks"];
  bids: NftAsksBidsLastOrder["bids"];
  lastOrder?: NftAsksBidsLastOrder["lastOrder"];
}

/**
 * CollectionTokenTransaction
 */

export enum EventType {
  MINT = "MINT",
  TRANSFER = "TRANSFER",
  LIST = "LIST",
  SALE = "SALE",
  OFFER = "OFFER",
  CANCEL_LIST = "CANCEL_LIST",
  CANCEL_OFFER = "CANCEL_OFFER",
}

export enum MarketPlaceContext {
  LOOKSRARE = "LOOKSRARE",
  OPENSEA = "OPENSEA",
  LOOKSRARE_V2 = "LOOKSRARE_V2",
  BLUR = "BLUR",
  LOOKSRARE_SEAPORT = "LOOKSRARE_SEAPORT",
}

export interface OpenSeaEventRecipient {
  address: Address;
}

export type EventOrder = { status: OrderStatus } & AggregatedOrderWithV1;

interface Event {
  id: string;
  from: UserProfileDisplay;
  to: UserProfileDisplay | null;
  type: EventType;
  context: MarketPlaceContext;
  createdAt: string;
  hash: string | null;
  order: EventOrder | null;
  token?: {
    tokenId: NFT["tokenId"];
    image: ImageData;
    name: string;
    rarity: RarityData[];
  };
  collection: {
    address: Address;
    name: string;
    description: string;
    totalSupply: CollectionBase["totalSupply"];
    type: TokenStandard;
    isVerified: boolean;
    logo?: ImageData;
    floor: CollectionFloor;
    historicalFloor: HistoricalFloor;
  };
}

export type LooksRareEvent = Event;
export type LooksRareSeaportEvent = Event;
export type BlurEvent = Event;

export interface OpenSeaEvent extends Event {
  recipient: OpenSeaEventRecipient;
}

export type MarketplaceEvent = BlurEvent | LooksRareEvent | LooksRareSeaportEvent | OpenSeaEvent;

export interface Trait {
  traitType: string;
  values: Attribute[];
}

export interface OffsetPagination {
  offset: number;
  first: number;
}

export interface RarityFilter {
  min?: string | null;
  max?: string | null;
}

export interface TokenFilter {
  collection?: Address;
  owner?: Address;
  order?: OrderFilter;
  withAskOnly?: boolean;
  withoutAskOnly?: boolean;
  withStandardBidOnly?: boolean;
  withCollectionBidOnly?: boolean;
  isHidden?: boolean;
  attributes?: AttributeFilter[];
  rarity?: RarityFilter;
  /**
   * Marketplaces passed to the `context` argument will have their asks reflected in token sorting and order filtering
   */
  context?: MarketPlaceContext[];
  /**
   * Limited to 30. Docs from GQL Schema:
   * Ids Field is uniquely designed for batching unique lookups.
   * No ordering is guaranteed and it all other filters will be ignored.
   * All invalid 'collection-tokenId' lookups will be ignored.
   */
  ids?: { collection: Address; tokenId: BigIntish }[];
}

export interface TokenOwnerFilter {
  addresses?: Address[];
}

export interface PriceFilter {
  min?: string | null;
  max?: string | null;
}

export interface AttributeFilter {
  traitType: string;
  values: string[];
}

export interface OrderFilter {
  context?: MarketPlaceContext[];
  isOrderAsk?: boolean;
  collection?: Address;
  tokenId?: string;
  signer?: Address;
  strategyType?: OrderStrategyType[];
  price?: PriceFilter;
  startTime?: string | number;
  endTime?: string | number;
  status?: OrderStatus[];
  nonce?: BigIntish;
  ids?: string[];
}

export enum OrderStatus {
  CANCELLED = "CANCELLED",
  EXECUTED = "EXECUTED",
  EXPIRED = "EXPIRED",
  VALID = "VALID",
  INVALID_OWNER = "INVALID_OWNER", // stale ask
  ERC_APPROVAL = "ERC_APPROVAL", // stale ask
  TM_APPROVAL = "TM_APPROVAL", // stale v2 ask - transfer manager approval
  ERC20_APPROVAL = "ERC20_APPROVAL", // stale bid
  ERC20_BALANCE = "ERC20_BALANCE", // stale bid
  UNEXECUTABLE = "UNEXECUTABLE", // BE order simulation determines this order cannot be executed, specific reason unknown
}

export interface EventFilter {
  collection?: Address;
  tokenId?: string;
  from?: Address;
  type?: EventType[];
  watchlists?: number[];
  context?: MarketPlaceContext[];
}

export enum OrderSort {
  EXPIRING_SOON = "EXPIRING_SOON",
  NEWEST = "NEWEST",
  PRICE_ASC = "PRICE_ASC",
  PRICE_DESC = "PRICE_DESC",
}

export enum CollectionsSort {
  // VOL
  VOL_24H_DESC = "VOL_24H_DESC",
  VOL_7D_DESC = "VOL_7D_DESC",
  VOL_1M_DESC = "VOL_1M_DESC",
  VOL_ALL_DESC = "VOL_ALL_DESC",
  VOL_24H_ASC = "VOL_24H_ASC",
  VOL_7D_ASC = "VOL_7D_ASC",
  VOL_1M_ASC = "VOL_1M_ASC",
  VOL_ALL_ASC = "VOL_ALL_ASC",
  //VOL CHANGE
  VOL_CHANGE_24H_DESC = "VOL_CHANGE_24H_DESC",
  VOL_CHANGE_7D_DESC = "VOL_CHANGE_7D_DESC",
  VOL_CHANGE_1M_DESC = "VOL_CHANGE_1M_DESC",
  VOL_CHANGE_24H_ASC = "VOL_CHANGE_24H_ASC",
  VOL_CHANGE_7D_ASC = "VOL_CHANGE_7D_ASC",
  VOL_CHANGE_1M_ASC = "VOL_CHANGE_1M_ASC",
  // FLOOR
  FLOOR_PRICE_DESC = "FLOOR_PRICE_DESC",
  FLOOR_PRICE_ASC = "FLOOR_PRICE_ASC",
  // FLOOR CHANGE
  FLOOR_PRICE_CHANGE_24H_DESC = "FLOOR_PRICE_CHANGE_24H_DESC",
  FLOOR_PRICE_CHANGE_7D_DESC = "FLOOR_PRICE_CHANGE_7D_DESC",
  FLOOR_PRICE_CHANGE_1M_DESC = "FLOOR_PRICE_CHANGE_1M_DESC",
  FLOOR_PRICE_CHANGE_24H_ASC = "FLOOR_PRICE_CHANGE_24H_ASC",
  FLOOR_PRICE_CHANGE_7D_ASC = "FLOOR_PRICE_CHANGE_7D_ASC",
  FLOOR_PRICE_CHANGE_1M_ASC = "FLOOR_PRICE_CHANGE_1M_ASC",
  // LISTED
  LISTING_COUNT_ASC = "LISTING_COUNT_ASC",
  LISTING_COUNT_DESC = "LISTING_COUNT_DESC",
}

/**
 * @TODO Check to see if we can combine this with CollectionSort
 * @TODO Update values once BE is ready
 */
/* eslint-disable */
export enum CollectionRankingsSort {
  VOLUME_24H_DESC = "HIGHEST_24H",
  VOLUME_24H_ASC = "HIGHEST_24H",
  VOLUME_7D_DESC = "HIGHEST_7D",
  VOLUME_7D_ASC = "HIGHEST_7D",
  VOLUME_1M_DESC = "HIGHEST_1M",
  VOLUME_1M_ASC = "HIGHEST_1M",
  VOLUME_DESC = "HIGHEST_TOTAL",
  VOLUME_ASC = "HIGHEST_TOTAL",
}
/* eslint-enable */

export enum CollectionTimeframe {
  DAY = "24h",
  WEEK = "7d",
  MONTH = "30d",
  MAX = "max",
}

// Note - this is not all possible relative collection sorts, just those currently used by the app
export enum RelativeCollectionsSort {
  ALPHABETICAL_ASC = "ALPHABETICAL_ASC",
  VOL_24H_DESC = "VOL_24H_DESC",
  OWNED_ASC = "OWNED_ASC",
  OWNED_DESC = "OWNED_DESC",
  HOLDING_VALUE_DESC = "HOLDING_VALUE_DESC",
}

export enum TokensSort {
  LAST_RECEIVED = "LAST_RECEIVED",
  PRICE_ASC = "PRICE_ASC",
  PRICE_DESC = "PRICE_DESC",
  RARITY_ASC = "RARITY_ASC",
  RARITY_DESC = "RARITY_DESC",
  OWNER_LAST_RECEIVED = "OWNER_LAST_RECEIVED",
  BID_DESC = "BID_DESC",
}

export interface Royalty {
  id: string;
  currency: Address;
  amount: BigIntish;
  to: string;
  hash: string;
  createdAt: string;
  token: {
    id: string;
    tokenId: string;
    image: ImageData;
    name: string;
  };
}

export interface ImageData {
  src: string;
  contentType?: string;
}

export interface AnimationData {
  src: string;
  contentType?: string;
  original?: string;
}

export interface SearchFilterInput {
  term: string;
}

/** Show/Hide Token */
export enum ManageUserAction {
  HIDE = "HIDE",
  UNHIDE = "UNHIDE",
}

export type SuccessPayload = {
  success: boolean;
};

export interface ValidationPayload extends SuccessPayload {
  invalidated: BigIntish;
  revalidated: BigIntish;
}

export enum RarityProvider {
  RARITY_SNIPER = "RARITY_SNIPER",
  LOOKSRARE = "LOOKSRARE",
}

export interface RarityData {
  provider: RarityProvider;
  rank: BigIntish;
  url: string;
}

export interface PreviousTradingReward {
  volume: BigIntish;
  start: string;
  end: string;
}

export enum NotificationType {
  SALE = "SALE",
  OFFER_ACCEPTED = "OFFER_ACCEPTED",
  OFFER = "OFFER",
  COLLECTION_OFFER = "COLLECTION_OFFER",
}

export interface Notification {
  id: string | null;
  type: NotificationType;
  /**
   * DateTime string i.e. "2023-01-26T09:46:58.052Z"
   */
  seenAt: string | null;
  createdAt: string;
  token?: {
    // token is undefined for collection offer notifications
    tokenId: string;
    image: ImageData;
    name: string;
  };
  collection: {
    address: Address;
    name: string;
    type: TokenStandard;
    isVerified: boolean;
    logo?: ImageData;
  };
  order: EventOrder;
}

export interface NotificationSettings {
  isEnabled: boolean | null;
  isPushEnabled: boolean;
  minOfferPercentageToNotify: number;
  allowedListingSold: boolean;
  allowedOfferAccepted: boolean;
  allowedOfferReceived: boolean;
  allowedCollectionOfferReceived: boolean;
  allowedMinOfferPercentageToNotify: boolean;
}

export type ValidNotificationSetting = keyof NotificationSettings;

export interface Watchlist {
  id: number;
  isMain: boolean;
  owner: {
    address: User["address"];
    name?: User["name"];
  };
  name?: string;
  watchersCount?: number;
  isWatching?: boolean;
  collectionCount: number;
}

export interface CurrentListingSeason {
  season: number;
  startTime: string;
  endTime: string;
  looksRewardPool: BigIntish;
}

export interface ListingSeason extends CurrentListingSeason {
  looksRewardClaimed: BigIntish;
}

export interface UserSeasonRewards {
  address: Address;
  claimedAt: Date | null;
  looks: BigIntish; // LOOKS claimable for this season - only use in UI
  looksCumulative: BigIntish; // LOOKS claimable accross all seasons - use in all claiming contract functions
  points: string; // Stringified decimal number
  proof: Address[] | null;
  rank: number;
}

export interface UserListingPoints {
  dailyRank: string;
  multiplier: number;
  points24h: string;
  seasonPoints: string;
  seasonRank: string;
}

export interface ListingPointsWithUser extends UserListingPoints {
  user: {
    address: Address;
    name?: string;
    isVerified?: boolean;
    avatar: {
      image: ImageData;
    } | null;
  };
}

export interface ListingPointsCurrentSeasonLeaderboard {
  total: BigIntish;
  points: ListingPointsWithUser[];
}

export type HistoricListingSeasonLeaderboard = SeasonAllocation[];

export interface SeasonAllocation {
  user: {
    address: Address;
    name?: string;
    isVerified?: boolean;
    avatar: {
      image: ImageData;
    } | null;
  };
  rank: number;
  looks: BigIntish;
  points: string; // stringified number
}

export interface Commission {
  amount: BigIntish;
  currency: Address;
  // DateTime string i.e. "2023-01-26T09:46:58.052Z"
  createdAt: string;
  eventLogIndex: number;
  id: number;
  transactionHash: string;
}

export interface CommissionStats {
  commission1m: BigIntish | null;
  commission24h: BigIntish | null;
  commission7d: BigIntish | null;
  commissionAll: BigIntish | null;
}

export interface ImportedOrderNote {
  context: MarketPlaceContext.BLUR | MarketPlaceContext.OPENSEA;
  priceBelowBp: number;
}

/**
 * @note COMING_SOON is only used in our UI, not from BE
 */
export type MilestoneCode =
  | "YOLO_ROUNDS"
  | "YOLO_FIRST"
  | "YOLO_WINNER"
  | "YOLO_LOOKS"
  | "YOLO_STREAK"
  | "YOLO_NFT"
  | "RAFFLE_ENTER"
  | "RAFFLE_ETH"
  | "PROFILE"
  | "GEM"
  | "GM_STREAK"
  | "PTB_WINNER"
  | "PTB_WIN_STREAK"
  | "PTB_FUTURE_ROUNDS_ENTERED"
  | "PTB_ROUNDS_LOOKS"
  | "PTB_ROUNDS_ETH"
  | "MKTP_BUY_SELL"
  | "MKTP_STREAK"
  | "COMING_SOON";
type ProfileLevelCode = "AVATAR" | "BIO" | "USERNAME" | "TWITTER" | "PROFILE_SETUP";
type GemLevelCode =
  | "GEM_1000"
  | "GEM_10000"
  | "GEM_50000"
  | "GEM_100000"
  | "GEM_250000"
  | "GEM_500000"
  | "GEM_1000000"
  | "GEM_2000000"
  | "GEM_5000000"
  | "GEM_10000000"
  | "GEM_20000000"
  | "GEM_35000000"
  | "GEM_60000000";
type YoloRoundsLevelCode =
  | "YOLO_ROUNDS_1"
  | "YOLO_ROUNDS_2"
  | "YOLO_ROUNDS_3"
  | "YOLO_ROUNDS_4"
  | "YOLO_ROUNDS_5"
  | "YOLO_ROUNDS_6"
  | "YOLO_ROUNDS_7"
  | "YOLO_ROUNDS_8"
  | "YOLO_ROUNDS_9"
  | "YOLO_ROUNDS_10";
type YoloFirstLevelCode =
  | "YOLO_FIRST_1"
  | "YOLO_FIRST_2"
  | "YOLO_FIRST_3"
  | "YOLO_FIRST_4"
  | "YOLO_FIRST_5"
  | "YOLO_FIRST_6"
  | "YOLO_FIRST_7"
  | "YOLO_FIRST_8"
  | "YOLO_FIRST_9"
  | "YOLO_FIRST_10";
type YoloWinnerLevelCode =
  | "YOLO_WINNER_1"
  | "YOLO_WINNER_2"
  | "YOLO_WINNER_3"
  | "YOLO_WINNER_4"
  | "YOLO_WINNER_5"
  | "YOLO_WINNER_6"
  | "YOLO_WINNER_7"
  | "YOLO_WINNER_8"
  | "YOLO_WINNER_9"
  | "YOLO_WINNER_10";
type YoloLooksLevelCode =
  | "YOLO_LOOKS_1"
  | "YOLO_LOOKS_2"
  | "YOLO_LOOKS_3"
  | "YOLO_LOOKS_4"
  | "YOLO_LOOKS_5"
  | "YOLO_LOOKS_6"
  | "YOLO_LOOKS_7"
  | "YOLO_LOOKS_8"
  | "YOLO_LOOKS_9"
  | "YOLO_LOOKS_10";
type YoloNftLevelCode =
  | "YOLO_NFT_1"
  | "YOLO_NFT_2"
  | "YOLO_NFT_3"
  | "YOLO_NFT_4"
  | "YOLO_NFT_5"
  | "YOLO_NFT_6"
  | "YOLO_NFT_7"
  | "YOLO_NFT_8"
  | "YOLO_NFT_9"
  | "YOLO_NFT_10";
type YoloStreakLevelCode =
  | "YOLO_STREAK_1"
  | "YOLO_STREAK_2"
  | "YOLO_STREAK_3"
  | "YOLO_STREAK_4"
  | "YOLO_STREAK_5"
  | "YOLO_STREAK_6"
  | "YOLO_STREAK_7"
  | "YOLO_STREAK_8"
  | "YOLO_STREAK_9"
  | "YOLO_STREAK_10";
type RaffleEnterLevelCode =
  | "RAFFLE_ENTER_1"
  | "RAFFLE_ENTER_2"
  | "RAFFLE_ENTER_3"
  | "RAFFLE_ENTER_4"
  | "RAFFLE_ENTER_5"
  | "RAFFLE_ENTER_6"
  | "RAFFLE_ENTER_7"
  | "RAFFLE_ENTER_8"
  | "RAFFLE_ENTER_9"
  | "RAFFLE_ENTER_10";
type RaffleTicketsLevelCode =
  | "RAFFLE_ETH_1"
  | "RAFFLE_ETH_2"
  | "RAFFLE_ETH_3"
  | "RAFFLE_ETH_4"
  | "RAFFLE_ETH_5"
  | "RAFFLE_ETH_6"
  | "RAFFLE_ETH_7"
  | "RAFFLE_ETH_8"
  | "RAFFLE_ETH_9"
  | "RAFFLE_ETH_10";
type PTBWinnerLevelCode =
  | "PTB_WINNER_1"
  | "PTB_WINNER_2"
  | "PTB_WINNER_3"
  | "PTB_WINNER_4"
  | "PTB_WINNER_5"
  | "PTB_WINNER_6"
  | "PTB_WINNER_7"
  | "PTB_WINNER_8"
  | "PTB_WINNER_9"
  | "PTB_WINNER_10"
  | "PTB_WINNER_11"
  | "PTB_WINNER_12"
  | "PTB_WINNER_13";
type PTBWinStreakCode =
  | "PTB_WIN_STREAK_1"
  | "PTB_WIN_STREAK_2"
  | "PTB_WIN_STREAK_3"
  | "PTB_WIN_STREAK_4"
  | "PTB_WIN_STREAK_5"
  | "PTB_WIN_STREAK_6"
  | "PTB_WIN_STREAK_7"
  | "PTB_WIN_STREAK_8";
type PtbFutureRoundsEnteredCode =
  | "PTB_FUTURE_ROUNDS_ENTERED_1"
  | "PTB_FUTURE_ROUNDS_ENTERED_2"
  | "PTB_FUTURE_ROUNDS_ENTERED_3"
  | "PTB_FUTURE_ROUNDS_ENTERED_4"
  | "PTB_FUTURE_ROUNDS_ENTERED_5"
  | "PTB_FUTURE_ROUNDS_ENTERED_6"
  | "PTB_FUTURE_ROUNDS_ENTERED_7"
  | "PTB_FUTURE_ROUNDS_ENTERED_8"
  | "PTB_FUTURE_ROUNDS_ENTERED_9"
  | "PTB_FUTURE_ROUNDS_ENTERED_10";
type PTBRoundsLooksLevelCode =
  | "PTB_ROUNDS_LOOKS_1"
  | "PTB_ROUNDS_LOOKS_2"
  | "PTB_ROUNDS_LOOKS_3"
  | "PTB_ROUNDS_LOOKS_4"
  | "PTB_ROUNDS_LOOKS_5"
  | "PTB_ROUNDS_LOOKS_6"
  | "PTB_ROUNDS_LOOKS_7"
  | "PTB_ROUNDS_LOOKS_8"
  | "PTB_ROUNDS_LOOKS_9"
  | "PTB_ROUNDS_LOOKS_10";
type PTBRoundsEthLevelCode =
  | "PTB_ROUNDS_ETH_1"
  | "PTB_ROUNDS_ETH_2"
  | "PTB_ROUNDS_ETH_3"
  | "PTB_ROUNDS_ETH_4"
  | "PTB_ROUNDS_ETH_5"
  | "PTB_ROUNDS_ETH_6"
  | "PTB_ROUNDS_ETH_7"
  | "PTB_ROUNDS_ETH_8"
  | "PTB_ROUNDS_ETH_9"
  | "PTB_ROUNDS_ETH_10";
type MktpBuySellLevelCode =
  | "MKTP_BUY_SELL_1"
  | "MKTP_BUY_SELL_2"
  | "MKTP_BUY_SELL_3"
  | "MKTP_BUY_SELL_4"
  | "MKTP_BUY_SELL_5"
  | "MKTP_BUY_SELL_6"
  | "MKTP_BUY_SELL_7"
  | "MKTP_BUY_SELL_8"
  | "MKTP_BUY_SELL_9"
  | "MKTP_BUY_SELL_10";
type MktpStreakLevelCode =
  | "MKTP_STREAK_1"
  | "MKTP_STREAK_2"
  | "MKTP_STREAK_3"
  | "MKTP_STREAK_4"
  | "MKTP_STREAK_5"
  | "MKTP_STREAK_6"
  | "MKTP_STREAK_7"
  | "MKTP_STREAK_8"
  | "MKTP_STREAK_9"
  | "MKTP_STREAK_10";
// @note HIDDEN_REWARD is only used in our UI, not from BE
export type MilestoneLevelCode =
  | ProfileLevelCode
  | GemLevelCode
  | YoloRoundsLevelCode
  | YoloFirstLevelCode
  | PTBWinStreakCode
  | PtbFutureRoundsEnteredCode
  | YoloWinnerLevelCode
  | YoloLooksLevelCode
  | YoloNftLevelCode
  | YoloStreakLevelCode
  | RaffleEnterLevelCode
  | RaffleTicketsLevelCode
  | PTBWinnerLevelCode
  | PTBRoundsLooksLevelCode
  | PTBRoundsEthLevelCode
  | MktpBuySellLevelCode
  | MktpStreakLevelCode
  | "HIDDEN_REWARD";

export interface UserMilestoneLevel {
  code: MilestoneLevelCode;
  milestone: MilestoneCode;
  points: number;
  claimedAt: string | null; // DateTime string i.e. "2023-07-05T14:00:00.000Z"
  createdAt: string | null; // DateTime string i.e. "2023-07-05T14:00:00.000Z"
  progress: BigIntish;
  goal: BigIntish;
}

type GmState = "IN_PROGRESS" | "MISSED" | "CLAIMED";

export interface GmDataPoint {
  endTime: string; // DateTime string i.e. "2023-07-05T14:00:00.000Z"
  startTime: string; // DateTime string i.e. "2023-07-05T14:00:00.000Z"
  state: GmState;
  streak: number;
  points: number;
}

export interface UserGmDetails {
  currentStreak: number;
  highestStreak: number;
  hasClaimedCurrent: boolean;
  history: GmDataPoint[];
  nextClaim: string; // DateTime string i.e. "2023-07-05T14:00:00.000Z"
  nextRewardAmount: number;
}

export interface UserMilestones {
  yoloRounds: UserMilestoneLevel[];
  yoloFirst: UserMilestoneLevel[];
  yoloWinner: UserMilestoneLevel[];
  yoloStreak: UserMilestoneLevel[];
  raffleEth: UserMilestoneLevel[];
  ptbWinStreak: UserMilestoneLevel[];
  ptbFutureRoundsEntered: UserMilestoneLevel[];
  profile: UserMilestoneLevel[];
  gem: UserMilestoneLevel[];
  ptbWinner: UserMilestoneLevel[];
  ptbEth: UserMilestoneLevel[];
  mktpBuySell: UserMilestoneLevel[];
  mktpStreak: UserMilestoneLevel[];
  gmStreak: {
    details: UserGmDetails;
  };
}

export type DailyQuestCode =
  | "YOLO_FIRST"
  | "YOLO_ROUNDS"
  | "YOLO_WINNER"
  | "RAFFLE_CREATE"
  | "RAFFLE_TICKETS"
  | "GM"
  | "COMPLETION_BONUS"
  | "PTB_WINS"
  | "PTB_WINS_1"
  | "PTB_ENTER_CONSECUTIVE"
  | "MKTP_BUY_SELL" // @TODO new-quests remove after migration
  | "MKTP_BUY"
  | "MKTP_SELL";

export interface UserDailyQuest {
  code: DailyQuestCode;
  points: number;
  progress: number;
  goal: number;
  claimedAt: string | null; // DateTime string i.e. "2023-07-05T14:00:00.000Z"
  createdAt: string | null; // DateTime string i.e. "2023-07-05T14:00:00.000Z"
}

export interface UserDailyQuests {
  quests: UserDailyQuest[];
  nextDay: string; // DateTime string i.e. "2023-07-05T14:00:00.000Z"
}

export interface GameStats {
  totalPlayers: BigIntish;
  totalGamesPlayed: BigIntish;
  volumePlayedWei: BigIntish;
  volumeWonWei: BigIntish;
}

export interface RewardStats {
  totalEarners: BigIntish;
  totalRewardsEarnedUsd: BigIntish;
}
