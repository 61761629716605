import { Address, zeroAddress } from "viem";
import random from "lodash/random";
import { useTranslation } from "next-i18next";
import { Flex, ButtonProps, FlexProps } from "@chakra-ui/react";
import { Button, RainbowBorderButton } from "@looksrare/uikit";
import { isAddressEqual, twapToUsdPrice, useCoinPrices, useTokenEthTwap } from "@looksrare/utils";
import { TWAP_WINDOW_BY_VERSION, useYoloConfig } from "../../../config";
import { ContractVersion, YoloSupportedNetworks } from "../../../types";
import { NetworkNativeTokenIcon } from "../../NetworkNativeTokenIcon";

// @todo-yolo https://linear.app/looksrare/issue/LR-3664/changeimprove-latest-input-icon
const IS_LATEST_INPUT_SUPPORTED = false;

const pressetButtonProps: ButtonProps = {
  size: "xs",
  minWidth: { base: "40px", md: "60px" },
  mr: { base: 1, md: 2 },
};

const getMaxDegenDeposit = (tokenBalanceFloat = 0, maxDeposit = 0) => {
  const tokenThreshold = Math.max(tokenBalanceFloat * 0.8);

  // Max deposit set and wallet has available funds
  if (maxDeposit > 0 && tokenThreshold >= maxDeposit) {
    return maxDeposit;
  }

  return tokenThreshold;
};

interface DegenSelectBarProps extends FlexProps {
  setInput: (value: string) => void;
  tokenBalance: string;
  latestInput: string | null;
  tokenAddress: Address;
  roundVersion: ContractVersion;
  network: YoloSupportedNetworks;
}

export const DegenSelectBar = ({
  setInput,
  latestInput,
  tokenBalance,
  tokenAddress,
  roundVersion,
  network,
  ...props
}: DegenSelectBarProps) => {
  const { t } = useTranslation();
  const {
    depositPresets,
    referenceToken: { minDeposit, maxPrecision, maxDeposit = 0 },
  } = useYoloConfig();
  const { data: prices } = useCoinPrices();
  const isEth = isAddressEqual(tokenAddress, zeroAddress);
  const { data: coinTwap = isEth ? BigInt(1e18) : undefined } = useTokenEthTwap(
    tokenAddress,
    TWAP_WINDOW_BY_VERSION[roundVersion],
    {
      enabled: !isEth,
    }
  );

  const tokenBalanceFloat = parseFloat(tokenBalance);
  const ethPriceUsd = prices?.eth.price || 0;
  const minValueUsd = minDeposit * ethPriceUsd;

  const tokenPriceUsd = (() => {
    if (isEth) {
      return ethPriceUsd;
    }

    if (ethPriceUsd && coinTwap && tokenAddress) {
      return twapToUsdPrice(BigInt(coinTwap), ethPriceUsd);
    }
    return 0;
  })();
  const minValueCurrency = minValueUsd / tokenPriceUsd;

  const handleSetRandomly = () => {
    if (!ethPriceUsd) {
      return;
    }
    const min = Math.max(tokenBalanceFloat * 0.05, isEth ? minDeposit : minValueCurrency);
    const max = getMaxDegenDeposit(tokenBalanceFloat, maxDeposit);
    const value = random(min, max, true);

    setInput(value.toFixed(maxPrecision).toString());
  };

  const handleSetPreset = (preset: string) => {
    setInput(preset);
  };

  return (
    <Flex alignItems="center" flexWrap="wrap" {...props}>
      {depositPresets.map((preset, index) => {
        const presetValueUsd = (ethPriceUsd || 0) * Number(preset);

        const tokenBalanceUsd = tokenBalanceFloat * tokenPriceUsd;
        const priceFluctuationCompensation =
          index === 0
            ? 1.05 // 5% extra to avoid slipping below the contract minimum
            : 1; // Not needed in the other cases

        const presetValueInCurrency = isEth ? preset : (presetValueUsd * priceFluctuationCompensation) / tokenPriceUsd;

        return (
          <Button
            key={preset}
            colorScheme="secondary"
            variant="outline"
            onClick={() => handleSetPreset(presetValueInCurrency.toString())}
            isDisabled={!isEth && (!prices || presetValueUsd >= tokenBalanceUsd)}
            {...pressetButtonProps}
          >
            <NetworkNativeTokenIcon network={network} mr={1} />
            {preset}
          </Button>
        );
      })}

      {IS_LATEST_INPUT_SUPPORTED && (
        <Button
          colorScheme="secondary"
          isDisabled={!latestInput || parseFloat(latestInput) >= tokenBalanceFloat}
          onClick={() => setInput(latestInput ?? "0")}
          title={t("yolo::Your latest input")}
          mr={2}
          {...pressetButtonProps}
        >
          <NetworkNativeTokenIcon network={network} />
          {latestInput ?? "-"}
        </Button>
      )}

      <RainbowBorderButton
        onClick={handleSetRandomly}
        isDisabled={!tokenPriceUsd || tokenBalanceFloat < minDeposit}
        boxProps={{ width: "100%", height: "30px" }}
        {...pressetButtonProps}
      >
        🎲
      </RainbowBorderButton>
    </Flex>
  );
};
