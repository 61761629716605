import { AddFundsButton, Button, CheckboxCheckedFilledIcon, CheckboxOutlineIcon, Text } from "@looksrare/uikit";
import { HStack, ModalBody, ModalFooter, VStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { fromDecimals } from "@looksrare/utils/formatting/fromDecimals";
import { formatNumberToLocale } from "@looksrare/utils/formatting/formatNumberToLocale";
import { formatToSignificant, useEthBalance, useLooksBalance } from "@looksrare/utils";
import { useAccount } from "wagmi";
import { ModalAction } from "../../types";
import { ActionSelectors } from "../shared/ActionSelectors";
import { YoloSupportedNetworks } from "../../../../types";
import { EyeCatchingText } from "../../../gameCustomisations/EyeCatchingText";
import { WinningsBreakdown } from "./WinningsBreakdown";

interface Props {
  selectedAction: ModalAction;
  setSelectedAction: (modalAction: ModalAction) => void;
  onComplete: () => void;
  rollableOverAmount: bigint;
  claimableAmount: bigint;
  roundsToCollectCount: number;
  isPayingFeesWithLooks: boolean;
  setIsPayingFeesWithLooks: (isPayingFeesWithLooks: boolean) => void;
  savingsWithLooksPercent?: number;
  feesFromUserWallet: bigint;
  /** The fees with no discount and ignoring whether they're taken from the pot or the user wallet */
  fullFeesValue: bigint;
  /** The total value of the claims, before fees */
  fullValue: bigint;
  network: YoloSupportedNetworks;
  isLoading: boolean;
}

export const ActionSelectionView = ({
  selectedAction,
  setSelectedAction,
  onComplete,
  rollableOverAmount,
  claimableAmount,
  roundsToCollectCount,
  isPayingFeesWithLooks,
  setIsPayingFeesWithLooks,
  savingsWithLooksPercent,
  feesFromUserWallet,
  fullFeesValue,
  fullValue,
  network,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const { address } = useAccount();

  const { data: looksBalance = 0n, refetch: refetchLooksBalance } = useLooksBalance(address!, { enabled: !!address });
  const { data: ethBalanceWei = 0n, refetch: refetchEthBalance } = useEthBalance(address!, { enabled: !!address });

  const refetchBalances = () => {
    refetchLooksBalance();
    refetchEthBalance();
  };

  const selectedCurrencyBalance = isPayingFeesWithLooks ? looksBalance : ethBalanceWei;
  const hasSufficientFunds = selectedCurrencyBalance >= feesFromUserWallet;

  return (
    <>
      <ModalBody bg="ui-bg">
        <VStack spacing={4} pt={6} alignItems="flex-start">
          <Text bold>{t("yolo::Rounds to Withdraw: {{count}}", { count: roundsToCollectCount })}</Text>
          <WinningsBreakdown
            width="100%"
            fullValue={fullValue}
            isPayingFeesWithLooks={isPayingFeesWithLooks}
            feesFromUserWallet={feesFromUserWallet}
            fullFeesValue={fullFeesValue}
            savingsWithLooksPercent={savingsWithLooksPercent || 0}
            hasSufficientFunds={hasSufficientFunds}
            network={network}
          />
          {!!savingsWithLooksPercent && fullFeesValue > 0n && (
            <HStack px={4} onClick={() => setIsPayingFeesWithLooks(!isPayingFeesWithLooks)} cursor="pointer">
              {isPayingFeesWithLooks ? (
                <CheckboxCheckedFilledIcon color="text-01" />
              ) : (
                <CheckboxOutlineIcon color="text-03" />
              )}
              <Text textStyle="detail" bold>
                {t("yolo::Pay Fee with LOOKS")}
              </Text>
              <EyeCatchingText>
                {savingsWithLooksPercent
                  ? t(`yolo::save ${formatNumberToLocale(savingsWithLooksPercent * 100, 0)}%`)
                  : "-"}
              </EyeCatchingText>
            </HStack>
          )}
        </VStack>
      </ModalBody>
      <ModalBody>
        <ActionSelectors
          selectedAction={selectedAction}
          setSelectedAction={setSelectedAction}
          rollableOverAmount={rollableOverAmount}
          claimableAmount={claimableAmount}
          network={network}
          hasFees={fullFeesValue > 0n}
        />
      </ModalBody>
      <ModalFooter>
        {hasSufficientFunds ? (
          <Button w="100%" onClick={onComplete} isLoading={isLoading}>
            {t(selectedAction === ModalAction.WithdrawAll ? "yolo::Withdraw Entries" : "yolo::Roll Over")}
          </Button>
        ) : (
          <AddFundsButton
            onClose={refetchBalances}
            w="100%"
            defaultBuyCurrency={isPayingFeesWithLooks ? "LOOKS" : "ETH"}
            defaultBuyAmount={-1 * parseFloat(fromDecimals(feesFromUserWallet - selectedCurrencyBalance))}
          >
            {t("yolo::Add {{amount}} {{currency}}", {
              amount: formatToSignificant(feesFromUserWallet - selectedCurrencyBalance),
              currency: isPayingFeesWithLooks ? "LOOKS" : "ETH",
            })}
          </AddFundsButton>
        )}
      </ModalFooter>
    </>
  );
};
