import { useTranslation } from "next-i18next";
import { Flex } from "@chakra-ui/react";
import { useAccount } from "wagmi";
import { fromDecimals } from "@looksrare/utils";
import { useAssertNetworkDisclosure } from "@looksrare/utils";
import { Text, Button, ConnectWalletButton } from "@looksrare/uikit";
import { useYoloQueryParams } from "../../utils/chains";
import { getPendingClaims } from "../../utils/contract/getPendingClaims";
import { WinnerModal } from "../modals/v2/WinnerModal";
import { useCurrentParticipant } from "../../utils/api/hooks";
import { NetworkNativeTokenIcon } from "../NetworkNativeTokenIcon";

export const ClaimWinnings = () => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const { network } = useYoloQueryParams();
  const disclosure = useAssertNetworkDisclosure({ network });
  const { data: participant, isLoading } = useCurrentParticipant(network);
  const { pendingAmount } = getPendingClaims(participant?.yoloUnclaimedPrizes);
  return (
    <>
      <WinnerModal isOpen={disclosure.isOpen} onClose={disclosure.onClose} />
      <Flex
        p={4}
        border="solid 1px"
        borderColor="interactive-01"
        borderRadius="dialog"
        justifyContent="center"
        alignItems="center"
        flexDirection={{ base: "column", md: "row" }}
        width={{ base: "100%", md: "fit-content" }}
      >
        <Flex
          flexDirection={{ base: "row", md: "column" }}
          justifyContent="space-between"
          alignItems={{ base: "center", md: "initial" }}
          width={{ base: "100%", md: "fit-content" }}
          mr={{ md: 16 }}
          mb={{ base: 4, md: 0 }}
        >
          <Text color="text-03" textStyle="detail" bold mb={{ md: 2 }} mr={{ base: 4, md: 0 }}>
            {t("yolo::Your Unclaimed Winnings")}
          </Text>
          <Flex alignItems="center">
            <NetworkNativeTokenIcon network={network} />
            <Text ml={2} bold>
              {address ? fromDecimals(pendingAmount) : "—"}
            </Text>
          </Flex>
        </Flex>

        {!!address ? (
          <Button
            width={{ base: "100%", md: "fit-content" }}
            isDisabled={pendingAmount === 0n || isLoading}
            onClick={disclosure.onOpen}
            size={{ base: "xs", md: "md" }}
          >
            {t("yolo::Claim Now")}
          </Button>
        ) : (
          <ConnectWalletButton width={{ base: "100%", md: "fit-content" }} />
        )}
      </Flex>
    </>
  );
};
