import { useTranslation } from "next-i18next";
import { Box, Flex, HStack } from "@chakra-ui/react";
import { formatToSignificant, fromDecimals, toDecimals } from "@looksrare/utils";
import {
  Text,
  Popover,
  HelpIcon,
  TooltipText,
  ButtonProps,
  Button,
  CheckmarkFilledIcon,
  CheckmarkUnfilledIcon,
} from "@looksrare/uikit";
import { YoloSupportedNetworks } from "../../../../types";
import { useYoloConfig } from "../../../../config";
import { truncateToContractPrecision } from "../../../../utils/truncate";
import { ModalAction } from "../../types";
import { NetworkNativeTokenIcon } from "../../../NetworkNativeTokenIcon";

interface SelectorProps extends ButtonProps {
  isSelected?: boolean;
}

const Selector = ({ isSelected, isDisabled, children, ...props }: SelectorProps) => {
  return (
    <Box
      as={Button} // using button directly does not allow overriding bg
      bg={isSelected ? "ui-01" : undefined}
      variant="ghost"
      colorScheme="secondary"
      px={4}
      py={2}
      w="100%"
      h="unset"
      fontWeight="unset"
      isDisabled={isDisabled}
      {...props}
    >
      <HStack spacing={4} w="100%">
        {isSelected ? (
          <CheckmarkFilledIcon boxSize={5} />
        ) : (
          <CheckmarkUnfilledIcon color={isDisabled ? "text-disabled" : "text-03"} boxSize={5} />
        )}
        {children}
      </HStack>
    </Box>
  );
};

export interface Props {
  selectedAction: ModalAction;
  setSelectedAction: (modalAction: ModalAction) => void;
  rollableOverAmount: bigint;
  claimableAmount: bigint;
  network: YoloSupportedNetworks;
  hasFees: boolean;
}

export const ActionSelectors = ({
  selectedAction,
  setSelectedAction,
  rollableOverAmount,
  claimableAmount,
  network,
  hasFees,
}: Props) => {
  const { referenceToken } = useYoloConfig();
  const { t } = useTranslation();

  const rolloverDisabledPopoverText = !!referenceToken.maxDeposit
    ? t(
        "yolo::ETH prizes can only be rolled over to the current round if the total ETH after fees is between {{minDeposit}} and {{maxDeposit}}.",
        { minDeposit: referenceToken.minDeposit, maxDeposit: referenceToken.maxDeposit }
      )
    : t(
        "yolo::ETH prizes can only be rolled over to the current round if the total ETH after fees is {{amount}} or above.",
        { amount: referenceToken.minDeposit }
      );

  const rolloverAmountNumber = parseFloat(fromDecimals(rollableOverAmount));
  const canRollOver = (() => {
    if (!!referenceToken.maxDeposit && rolloverAmountNumber > referenceToken.maxDeposit) {
      return false;
    }
    return rollableOverAmount >= toDecimals(referenceToken.minDeposit.toString());
  })();

  return (
    <>
      <HStack spacing={2} mb={4}>
        <Text bold>{t("yolo::Next Steps")}</Text>
        <Popover
          renderInPortal
          label={
            <>
              <TooltipText>
                {t(
                  "yolo::You can claim all prizes now, or you can roll over any {{token}} prizes to the current round to save on gas fees.",
                  { token: referenceToken.symbol }
                )}
              </TooltipText>
              <br />
              <TooltipText>
                {t("yolo::You can still claim any other prize after you roll over {{token}}.", {
                  token: referenceToken.symbol,
                })}
              </TooltipText>
              {hasFees && (
                <>
                  <br />
                  <TooltipText>
                    {t(
                      "yolo::{{token}} prizes can only be rolled over to the current round if the total ETH after fees is {{amount}} or above.",
                      { token: referenceToken.symbol, amount: referenceToken.minDeposit }
                    )}
                  </TooltipText>
                </>
              )}
            </>
          }
        >
          <span>
            <HelpIcon boxSize="16px" color="text-03" />
          </span>
        </Popover>
      </HStack>
      <Popover label={<TooltipText>{rolloverDisabledPopoverText}</TooltipText>} disabled={canRollOver}>
        <span>
          <Selector
            isSelected={selectedAction === ModalAction.RollOver}
            isDisabled={!canRollOver}
            onClick={() => setSelectedAction(ModalAction.RollOver)}
          >
            <Flex justifyContent="space-between" w="100%">
              <Text>{t("yolo::Roll Over {{token}} to Current Round", { token: referenceToken.symbol })}</Text>
              <HStack spacing={1}>
                <Text textStyle="detail" bold>
                  {rolloverAmountNumber > 0
                    ? truncateToContractPrecision(rolloverAmountNumber, referenceToken.maxPrecision)
                    : "0"}
                </Text>
                <NetworkNativeTokenIcon network={network} boxSize={5} />
              </HStack>
            </Flex>
          </Selector>
        </span>
      </Popover>
      <Selector
        isSelected={selectedAction === ModalAction.WithdrawAll}
        onClick={() => setSelectedAction(ModalAction.WithdrawAll)}
      >
        <Flex justifyContent="space-between" w="100%">
          <Text>{t("yolo::Withdraw All Entries")}</Text>
          <HStack spacing={1}>
            <Text textStyle="detail" bold>
              {formatToSignificant(claimableAmount, referenceToken.maxDisplayDecimals)}
            </Text>
            <NetworkNativeTokenIcon network={network} boxSize={5} />
          </HStack>
        </Flex>
      </Selector>
    </>
  );
};
