import { useEffect, useCallback } from "react";
import { Round, RoundStatus } from "../types";
import { useYoloStore } from "./yoloStore";

export const useRoundStartSound = (round: Round) => {
  const { isSoundOn, toggleSound } = useYoloStore();

  useEffect(() => {
    if (round.status === RoundStatus.Open && isSoundOn) {
      const audio = new Audio("/sounds/dingding.mp3");
      audio.play();
    }
  }, [isSoundOn, round.status]);

  return { isMuted: !isSoundOn, toggleMute: toggleSound };
};

export const useReloadSound = () => {
  const { isSoundOn } = useYoloStore();

  return useCallback(() => {
    if (isSoundOn) {
      const audio = new Audio("/sounds/hitman.mp3");
      audio.play();
    }
  }, [isSoundOn]);
};
