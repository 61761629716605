import { Address, isAddressEqual } from "viem";
import { UnclaimedRefund } from "../../../../types";

interface RefundsEvaluation {
  /** Total value in WEI of all prizes */
  unclaimedValue: bigint;
  /** Total value in WEI of all ETH prizes */
  unclaimedEth: bigint;
  /** Refunds that contain ETH */
  unclaimedEthRefunds: UnclaimedRefund[];
}

export const evaluateUnclaimedRefunds = (
  unclaimedRefunds: UnclaimedRefund[],
  rollableTokenAddress: Address
): RefundsEvaluation => {
  const initialValue: RefundsEvaluation = {
    unclaimedValue: 0n,
    unclaimedEth: 0n,
    unclaimedEthRefunds: [],
  };
  if (!unclaimedRefunds) {
    return initialValue;
  }

  return unclaimedRefunds.reduce<typeof initialValue>((acc, unclaimedRefund) => {
    const isRollableTokenAddress =
      !!unclaimedRefund.currency && isAddressEqual(unclaimedRefund.currency, rollableTokenAddress);
    const prizeValueEth = BigInt(unclaimedRefund.numberOfEntries) * BigInt(unclaimedRefund.round.valuePerEntry);

    if (isRollableTokenAddress) {
      acc.unclaimedEth += prizeValueEth;
      acc.unclaimedEthRefunds.push(unclaimedRefund);
    }
    acc.unclaimedValue += prizeValueEth;
    return acc;
  }, initialValue);
};
