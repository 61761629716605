import React from "react";
import { useTranslation } from "next-i18next";
import { Flex, IconButton } from "@chakra-ui/react";
import { CloseIcon, ArrowLeftIcon } from "@looksrare/uikit";
import { Text } from "@looksrare/uikit";

export const Header = ({ onBack, onClose }: { onBack?: () => void; onClose: () => void }) => {
  const { t } = useTranslation();
  return (
    <Flex justifyContent="space-between" alignItems="center" bg="ui-01" p={4}>
      <Flex alignItems="center">
        {onBack && (
          <IconButton variant="ghost" colorScheme="secondary" size="sm" aria-label="back" onClick={onBack} mr={4}>
            <ArrowLeftIcon />
          </IconButton>
        )}
        <Text textStyle="heading-04" bold>
          {t("yolo::Select Entries")}
        </Text>
      </Flex>
      <IconButton size="xs" variant="ghost" colorScheme="secondary" onClick={onClose} aria-label="Close">
        <CloseIcon />
      </IconButton>
    </Flex>
  );
};
