import { MarketPlaceContext } from "../types";
import { AggregatedAsk, ExecutableOrder, LooksRareV1Order } from "../types/orders";
import { isBlurOrderExecutionEnabled } from "./helpers";

/**
 * Check if the input order is an OS, Blur or V2 order - an 'ExecutableOrder'
 * @param order AggregatedAsk | LooksRareV1Order
 * @returns boolean
 */
export const isExecutableOrder = (
  order: AggregatedAsk | LooksRareV1Order | null | undefined
): order is ExecutableOrder => {
  if (!order) {
    return false;
  }
  return (
    order.context === MarketPlaceContext.OPENSEA ||
    order.context === MarketPlaceContext.LOOKSRARE_V2 ||
    order.context === MarketPlaceContext.LOOKSRARE_SEAPORT ||
    (isBlurOrderExecutionEnabled && order.context === MarketPlaceContext.BLUR)
  );
};
