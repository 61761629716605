import { useEffect, useState } from "react";
import { Hash, getAddress, Address } from "viem";
import { BoxProps, VStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { usePublicClient, useWalletClient } from "wagmi";
import { TransferManagerAbi } from "@looksrare/config";
import {
  Text,
  BulkTransactionStep,
  BulkTransactionStepStatus,
  StepStatus,
  TransactionStepRow,
  StepErrorCta,
  Icon,
} from "@looksrare/uikit";
import { useHandleModalStep, useWalletClientIsReady } from "@looksrare/utils";

interface Props extends BoxProps {
  status: StepStatus;
  collapse?: boolean;
  isCurrentStep: boolean;
  onComplete: () => void;
  transferMangerAddress: Address;
  transferManagerIcon: typeof Icon;
  operatorAddress: Address;
}

export const StepApproveTransferManager = ({
  status,
  collapse,
  isCurrentStep,
  onComplete,
  transferMangerAddress,
  transferManagerIcon: TransferManagerIcon,
  operatorAddress,
  ...props
}: Props) => {
  const { isSignerReady } = useWalletClientIsReady();
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  const { t } = useTranslation();
  const [transaction, setTransaction] = useState<Hash>();

  const { handleSubmit, isRejected, isIdle, isAccepted } = useHandleModalStep({
    onSubmit: async () => {
      if (walletClient && publicClient) {
        const contractInfo = {
          address: getAddress(transferMangerAddress),
          abi: TransferManagerAbi,
        } as const;

        const [account] = await walletClient.getAddresses();
        const hasApproved = await publicClient.readContract({
          ...contractInfo,
          functionName: "hasUserApprovedOperator",
          args: [account, operatorAddress],
        });
        if (!hasApproved) {
          const { request } = await publicClient.simulateContract({
            ...contractInfo,
            functionName: "grantApprovals",
            args: [[operatorAddress]],
            account,
          });
          const hash = await walletClient.writeContract(request);
          setTransaction(hash);
          const receipt = await publicClient.waitForTransactionReceipt({ hash });

          if (receipt.status === "success") {
            setTransaction(undefined);
          } else {
            throw new Error(`${receipt.transactionHash} failed`);
          }
        }
        onComplete();
      }
    },
  });

  const rowStatus = ((): BulkTransactionStepStatus => {
    if (isRejected) {
      return "error";
    }
    if (isIdle) {
      return "wait";
    }
    if (isAccepted) {
      return "done";
    }
    return "pending";
  })();

  useEffect(() => {
    if (isCurrentStep && isSignerReady) {
      handleSubmit({ callOnlyOnce: true });
    }
  }, [handleSubmit, isCurrentStep, isSignerReady]);

  const counterDisplay = "(1/1)";

  return (
    <BulkTransactionStep
      status={status}
      collapse={collapse}
      title={t("yolo::Transfer Manager Approval")}
      description={t("yolo::This enables us to transfer your assets. You only need to do this once.")}
      {...props}
    >
      <VStack spacing={4} alignItems="flex-start" width="100%">
        <Text textStyle="helper" bold color={isRejected ? "text-error" : "text-01"}>
          {isRejected
            ? t("yolo::You declined the transaction {{counterDisplay}}", { counterDisplay })
            : t("yolo::Confirm transaction in wallet {{counterDisplay}}", { counterDisplay })}
        </Text>
        <TransactionStepRow
          transactionHash={transaction}
          icon={TransferManagerIcon}
          status={rowStatus}
          text={t("yolo::Transfer Manager")}
        />
        {isRejected && <StepErrorCta onRetry={() => handleSubmit()} />}
      </VStack>
    </BulkTransactionStep>
  );
};
