import { gql } from "graphql-request";
import { Address } from "viem";
import {
  Attribute,
  attributeWithLastOrder,
  baseOwnerFragment,
  Collection,
  CollectionFloor,
  CollectionVolume,
  formatAggregatedAsk,
  formatV2BidsInline,
  getCheapestExecutableAsk,
  getGuardedMarketPlaceContext,
  ImageData,
  looksrareishBaseOrderFragment,
  LooksRareV2OrderFragment,
  MarketPlaceContext,
  NFTCard,
  orderFragmentForTokensFetch,
  OrderFragmentWithoutTokenId,
  OrderStatus,
  Pagination,
  SearchFilterInput,
  TokenFilter,
  TokenOwnerFilter,
  tokensFragment,
  TokensSort,
} from "@looksrare/nfts";
import { BigIntish, TokenStandard } from "@looksrare/utils";
import {
  YoloContractName,
  HistoryRound,
  Participant,
  Round,
  RoundStatus,
  SortRoundBy,
  YoloSupportedNetworks,
  DepositorWithMetrics,
} from "../../types";
import { getNetworkFromYoloContractName } from "../chains";
import { graphql } from "./graphql";
import {
  depositFragment,
  historyDepositFragment,
  roundFragment,
  tokenDepositFragment,
  yoloUserFragment,
  userMetricsFragment,
} from "./fragments";

export const getWhitelistedCurrencies = async (contractName: YoloContractName): Promise<Address[]> => {
  const query = gql`
    query GetWhitelistedCurrencies($contract: YoloContract!) {
      yoloCurrencies(contract: $contract)
    }
  `;
  const res = await graphql(query, { contract: contractName });
  return res.yoloCurrencies;
};

export const getCurrentRoundId = async (
  contracts: YoloContractName[],
  requestHeaders?: HeadersInit
): Promise<bigint> => {
  const query = gql`
    query YoloRounds($sort: YoloRoundSort, $filter: YoloRoundFilterInput, $pagination: OffsetPaginationInput) {
      yoloRounds(sort: $sort, filter: $filter, pagination: $pagination) {
        rounds {
          onChainId
        }
      }
    }
  `;
  const params = {
    sort: SortRoundBy.NEWEST,
    pagination: {
      first: 1,
    },
    filter: {
      contracts,
    },
  };
  const res = await graphql(query, params, requestHeaders);
  return res.yoloRounds.rounds[0].onChainId;
};

/**
 * @todo
 * This function needs to be refactored for networks that support multiple contracts e.g. Yolo Limited on Blast
 * Currently it returns the first round data in the array but this could lead to a mismatch.
 * i.e. You request [YOLO_LIMITED_V1_BLAST, YOLO_V2_BLAST] and it will always return YOLO_LIMITED_V1_BLAST
 */
export const getCurrentRound = async (contracts: YoloContractName[], requestHeaders?: HeadersInit): Promise<Round> => {
  const shouldQueryTokens = contracts.some(
    (contract) => getNetworkFromYoloContractName(contract) === YoloSupportedNetworks.ethereum
  );

  const query = gql`
    query YoloRounds(
      $sort: YoloRoundSort
      $filter: YoloRoundFilterInput
      $pagination: OffsetPaginationInput
     ) {
      yoloRounds(sort: $sort, filter: $filter, pagination: $pagination) {
        rounds {
          ...RoundFragment
          deposits {
            ...DepositFragment
            ${shouldQueryTokens ? `...TokenDepositFragment` : ""}
            depositor {
              ...YoloUserFragment
            }
          }
          winner {
            ...YoloUserFragment
          }
        }
      }
    }
    ${roundFragment}
    ${depositFragment}
    ${yoloUserFragment}
    ${shouldQueryTokens ? tokenDepositFragment : ""}
  `;

  const params = {
    sort: SortRoundBy.NEWEST,
    pagination: {
      first: 1,
    },
    filter: {
      contracts,
    },
  };
  const res = await graphql(query, params, requestHeaders);
  return res.yoloRounds.rounds[0];
};

export const getRound = async (
  onChainId: number,
  contractName: YoloContractName,
  requestHeaders?: HeadersInit
): Promise<Round> => {
  const supportsNfts = getNetworkFromYoloContractName(contractName) === YoloSupportedNetworks.ethereum;

  const query = gql`
    query YoloRound($onChainId: Int!, $contract: YoloContract!) {
      yoloRound(onChainId: $onChainId, contract: $contract) {
        ...RoundFragment
        deposits {
          ...DepositFragment
          ${supportsNfts ? `...TokenDepositFragment` : ""}
          depositor {
            ...YoloUserFragment
            yoloMetrics {
              ...UserMetricsFragment
            }
          }
        }
        winner {
          ...YoloUserFragment
        }
      }
    }
    ${roundFragment}
    ${depositFragment}
    ${yoloUserFragment}
    ${userMetricsFragment}
    ${supportsNfts ? tokenDepositFragment : ""}
  `;

  const res = await graphql(query, {
    onChainId,
    contract: contractName,
    requestHeaders,
  });
  return res.yoloRound;
};

export const ITEMS_PER_PAGE = 12;
export const getHistoryRounds = async (
  contracts: YoloContractName[],
  page: number,
  orderBy: SortRoundBy,
  status?: RoundStatus,
  player?: string
): Promise<{ count: number; rounds: HistoryRound[] }> => {
  const query = gql`
    query GetHistoryRounds($filter: YoloRoundFilterInput, $sort: YoloRoundSort, $pagination: OffsetPaginationInput) {
      yoloRounds(filter: $filter, sort: $sort, pagination: $pagination) {
        rounds {
          ...RoundFragment
          winner {
            ...YoloUserFragment
          }
          deposits {
            ...HistoryDepositFragment
            depositor {
              address
            }
          }
        }
        count
      }
    }
    ${roundFragment}
    ${historyDepositFragment}
    ${yoloUserFragment}
  `;

  const res = await graphql(query, {
    filter: {
      statuses: status,
      depositor: player,
      contracts,
    },
    sort: orderBy,
    pagination: {
      first: ITEMS_PER_PAGE,
      offset: page * ITEMS_PER_PAGE,
    },
  });

  return res.yoloRounds;
};

export const getDepositor = async (address: Address, contracts: YoloContractName[]): Promise<DepositorWithMetrics> => {
  const query = gql`
    query YoloParticipantMetrics($address: Address!) {
      user(address: $address) {
        name
        address
        isVerified
        avatar {
          image {
            src
          }
        }
        yoloMetrics {
          ...UserMetricsFragment
        }
      }
    }
    ${userMetricsFragment}
  `;
  const res = await graphql(query, {
    address,
    contracts,
  });
  return res.user;
};

export const getCurrentParticipant = async (
  userAddress: string,
  contracts: YoloContractName[]
): Promise<Participant> => {
  const supportsNfts = contracts.some(
    (contract) => getNetworkFromYoloContractName(contract) !== YoloSupportedNetworks.blast
  );

  const query = gql`
    query YoloCurrentParticipant($address: Address!, $contracts: [YoloContract!]!) {
      user(address: $address) {
        yoloUnclaimedPrizes(filter: { contracts: $contracts }) {
          amount
          currency
          round {
            contract
            onChainId
            valuePerEntry
            protocolFeeBp
          }
          ${
            supportsNfts
              ? `token {
            collection {
              address
            }
            tokenId
          }`
              : ""
          }
          index
          numberOfEntries
        }
        yoloUnclaimedRefunds(filter: { contracts: $contracts }) {
          amount
          currency
          round {
            contract
            onChainId
            valuePerEntry
          }
          index
          numberOfEntries
        }
      }
    }
  `;
  const res = await graphql(query, {
    address: userAddress,
    contracts,
  });
  return res.user as Participant;
};

export interface GetParticipantFutureEntriesResponse {
  totalRoundsCount: number;
  totalWalletValueWei: BigIntish;
  rounds: {
    onChainId: number;
    deposits: {
      numberOfEntries: number;
    }[];
  }[];
}

export const getParticipantFutureEntries = async (
  userAddress: string,
  contracts: YoloContractName[],
  pagination: Pagination,
  requestHeaders?: HeadersInit
): Promise<GetParticipantFutureEntriesResponse> => {
  const query = gql`
    query YoloParticipantFutureEntries(
      $address: Address!
      $contracts: [YoloContract!]!
      $pagination: OffsetPaginationInput
    ) {
      user(address: $address) {
        yoloFutureRounds(contracts: $contracts, pagination: $pagination) {
          totalRoundsCount
          totalWalletValueWei
          rounds {
            onChainId
            deposits {
              numberOfEntries
            }
          }
        }
      }
    }
  `;
  const res = await graphql(
    query,
    {
      address: userAddress,
      contracts,
      pagination,
    },
    requestHeaders
  );
  return res.user.yoloFutureRounds;
};

export const getTokenAttributes = async (
  { collection, tokenId }: { collection: Address; tokenId: string },
  requestHeaders?: HeadersInit
): Promise<Attribute[]> => {
  const query = gql`
    query GetTokenAttributes($collection: Address!, $tokenId: BigNumber!) {
      token(collection: $collection, tokenId: $tokenId) {
        attributes {
          ...AttributeWithLastOrder
        }
      }
    }
    ${attributeWithLastOrder}
  `;

  const res = await graphql(query, { collection, tokenId }, requestHeaders);
  if (!res.token) {
    return [];
  }
  return res.token.attributes;
};

interface UserTokensQueryArgs {
  filter?: TokenFilter;
  pagination?: Pagination;
  sort?: TokensSort;
  ownerFilter?: TokenOwnerFilter;
  search?: SearchFilterInput;
  requestHeaders?: HeadersInit;
  address: Address;
}

type GetTokensResponse = {
  tokens?: {
    id: string;
    tokenId: string;
    image: ImageData;
    name: string;
    lastOrder?: {
      price: BigIntish;
      currency: Address;
    };
    owners: NFTCard["owners"];
    isRefreshed: NFTCard["isRefreshed"];
    isHidden: NFTCard["isHidden"];
    collection: {
      name: string;
      address: Address;
      type: TokenStandard;
      isVerified: boolean;
      totalSupply: BigIntish;
      isHidden: boolean | null;
      floor: CollectionFloor;
      logo?: ImageData;
      maxRarity: Collection["maxRarity"];
      volume: CollectionVolume;
      osSlug: string | null;
    };
    asks: OrderFragmentWithoutTokenId[];
    bids: LooksRareV2OrderFragment[];
    rarity: NFTCard["rarity"];
  }[];
};

export const getUserTokens = async ({
  address,
  filter,
  pagination,
  sort,
  ownerFilter,
  requestHeaders,
  search,
}: UserTokensQueryArgs): Promise<NFTCard[]> => {
  const query = gql`
    query GetUserTokens(
      $address: Address!
      $filter: TokenFilterInput
      $pagination: PaginationInput
      $sort: TokenSortInput
      $ownerFilter: TokenOwnerInput
      $bidsFilter: OrderFilterInput
      $asksContext: [MarketplaceContext!]
      $search: SearchFilterInput
    ) {
      user(address: $address) {
        tokens(filter: $filter, pagination: $pagination, sort: $sort, search: $search) {
          ...TokensFragment
          owners(filter: $ownerFilter) {
            owner {
              ...BaseOwnerFragment
            }
            balance
          }
          asks(signer: $address, context: $asksContext) {
            ...OrderFragmentForTokensFetch
          }
          bids(filter: $bidsFilter, sort: PRICE_DESC, pagination: { first: 1 }) {
            ...LooksRarishBaseOrderFragment
          }
        }
      }
    }
    ${baseOwnerFragment}
    ${tokensFragment}
    ${orderFragmentForTokensFetch}
    ${looksrareishBaseOrderFragment}
  `;

  const tokenFilter: TokenFilter = {
    ...filter,
    context: getGuardedMarketPlaceContext([
      MarketPlaceContext.LOOKSRARE_V2,
      MarketPlaceContext.OPENSEA,
      MarketPlaceContext.BLUR,
      MarketPlaceContext.LOOKSRARE_SEAPORT,
    ]),
  };

  const res: { user?: GetTokensResponse } = await graphql(
    query,
    {
      address,
      filter: tokenFilter,
      pagination,
      sort,
      ownerFilter,
      bidsFilter: {
        context: [MarketPlaceContext.LOOKSRARE_V2, MarketPlaceContext.LOOKSRARE_SEAPORT],
        status: OrderStatus.VALID,
      },
      asksContext: getGuardedMarketPlaceContext([
        MarketPlaceContext.LOOKSRARE_V2,
        MarketPlaceContext.OPENSEA,
        MarketPlaceContext.BLUR,
        MarketPlaceContext.LOOKSRARE_SEAPORT,
      ]),
      search,
    },
    requestHeaders
  );
  if (!res?.user?.tokens) {
    return [];
  }

  return res.user.tokens.map((token) => {
    // `asks` returns asks across all marketplaces - `ask` only returns the cheapest executable order
    const asks = !!token.asks?.length ? token.asks.map((ask) => formatAggregatedAsk(ask, token.tokenId)) : undefined;
    const ask = asks ? getCheapestExecutableAsk(asks) : undefined;
    return {
      ...token,
      asks,
      ask,
      bids: token.bids.map(formatV2BidsInline),
    };
  });
};
