import { useTranslation } from "next-i18next";
import { Box, Divider, Flex, Stack, StackProps } from "@chakra-ui/react";
import { EthHalfIcon, Text } from "@looksrare/uikit";
import { RarityLevel, rarityLevelToColor, useRarityText } from "../../utils";

const ColorRow = ({ rarityLevel }: { rarityLevel: RarityLevel }) => {
  const { t } = useTranslation();
  const rarityText = useRarityText(rarityLevel);
  const bgColor = `${rarityLevelToColor(rarityLevel)}.300`;

  const labelText = {
    [RarityLevel.COMMON]: t("Bottom {{percent}}%", { percent: 50 }),
    [RarityLevel.UNCOMMON]: t("Top {{percent}}%", { percent: 50 }),
    [RarityLevel.RARE]: t("Top {{percent}}%", { percent: 10 }),
    [RarityLevel.EPIC]: t("Top {{percent}}%", { percent: 1 }),
    [RarityLevel.LEGENDARY]: t("Top {{percent}}%", { percent: 0.1 }),
    [RarityLevel.ONE_OF_ONE]: t("Unique (1 of 1)"),
  }[rarityLevel];

  return (
    <Flex key={rarityLevel} alignItems="center" gap={2}>
      <Box borderRadius="50%" w={5} h={5} bgColor={bgColor} />
      <Text textStyle="detail" bold color="text-01" flexGrow={1}>
        {rarityText}
      </Text>
      <Text textStyle="detail" color="text-03">
        {labelText}
      </Text>
    </Flex>
  );
};
type RarityLevelGuideProps = StackProps;
export const RarityLevelGuide = (props: RarityLevelGuideProps) => {
  const { t } = useTranslation();
  return (
    <Stack spacing={6} {...props}>
      <Stack spacing={1}>
        <Text textStyle="detail" bold color="text-03">
          {t("Colors")}
        </Text>
        <Stack spacing={1} divider={<Divider />} pt={1}>
          {[...Array(6)].map((_, index) => {
            const rarityLevel = (5 - index) as RarityLevel;
            return <ColorRow key={rarityLevel} rarityLevel={rarityLevel} />;
          })}
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <Text textStyle="detail" bold color="text-03">
          {t("Numbers")}
        </Text>
        <Stack spacing={1} divider={<Divider />} pt={1}>
          <Flex alignItems="center" justifyContent="space-between">
            <Flex alignItems="center">
              <Text textStyle="detail" bold color="text-01" flexGrow={1} as="span" mr={1}>
                110
              </Text>
              <Text textStyle="detail" color="text-01" flexGrow={1} as="span">
                (11%)
              </Text>
            </Flex>
            <Text textStyle="detail" color="text-03">
              {t("Quantity with this attribute")}
            </Text>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between">
            <Flex alignItems="center" gap={1}>
              <EthHalfIcon boxSize={4} />
              <Text textStyle="detail" bold color="text-01" flexGrow={1} as="span">
                0.1
              </Text>
            </Flex>
            <Text textStyle="detail" color="text-03">
              {t("Floor: Lowest priced token with this attribute")}
            </Text>
          </Flex>
        </Stack>
      </Stack>
    </Stack>
  );
};
