import { Address, isAddressEqual } from "viem";
import { multiplyWeiByNumber } from "@looksrare/utils";
import { UnclaimedPrize } from "../../../../types";

interface PrizesEvaluation {
  /** Total value in WEI of all prizes */
  unclaimedValue: bigint;
  /** Total value in WEI of all reference token prizes (only ETH atm)*/
  unclaimedReferenceToken: bigint;
  /** Prizes that contain reference token */
  unclaimedReferencePrizes: UnclaimedPrize[];
  feesValue: bigint;
  nftsInPrizes: { collection: Address; tokenId: bigint }[];
}

/**
 * A util to go from UnclaimedPrize to aggregated values needed to estimate fees and build UI
 */
export const evaluateUnclaimedPrizes = (
  unclaimedPrizes: UnclaimedPrize[],
  referenceTokenAddress: Address
): PrizesEvaluation => {
  const initialValue: PrizesEvaluation = {
    unclaimedValue: 0n,
    unclaimedReferenceToken: 0n,
    unclaimedReferencePrizes: [],
    nftsInPrizes: [],
    feesValue: 0n,
  };
  if (!unclaimedPrizes) {
    return initialValue;
  }

  return unclaimedPrizes.reduce<typeof initialValue>((acc, unclaimedPrize) => {
    const isReferenceTokenPrize =
      !!unclaimedPrize.currency && isAddressEqual(unclaimedPrize.currency, referenceTokenAddress);
    const prizeValueEth = BigInt(unclaimedPrize.numberOfEntries) * BigInt(unclaimedPrize.round.valuePerEntry);

    if (isReferenceTokenPrize) {
      acc.unclaimedReferenceToken += prizeValueEth;
      acc.unclaimedReferencePrizes.push(unclaimedPrize);
    } else if (!!unclaimedPrize.token) {
      acc.nftsInPrizes.push({
        collection: unclaimedPrize.token.collection.address,
        tokenId: BigInt(unclaimedPrize.token.tokenId),
      });
    }
    acc.feesValue += multiplyWeiByNumber(prizeValueEth, unclaimedPrize.round.protocolFeeBp / 10_000);
    acc.unclaimedValue += prizeValueEth;
    return acc;
  }, initialValue);
};
