import { Flex } from "@chakra-ui/react";
import { Button, ErrorIcon, Text } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { RoundStatus } from "../../../types";
import { useYoloConfig } from "../../../config";
import { pulse } from "./style";

interface Props {
  roundStatus: RoundStatus;
  timeBeforeNextRound?: number;
  onWithdrawRolloverClick?: () => void;
}

export const CenterFailed = ({ roundStatus, timeBeforeNextRound, onWithdrawRolloverClick }: Props) => {
  const { t } = useTranslation();
  const { isRolloverEnabled } = useYoloConfig();

  return (
    <Flex flexDirection="column" alignItems="center">
      <ErrorIcon boxSize={{ base: 12, sm: 16 }} mb={4} />
      <Text textStyle={{ base: "heading-05", sm: "heading-04" }} bold>
        {t("yolo::Not Enough Players")}
      </Text>
      <Text
        animation={`${pulse} 2s infinite`}
        textAlign="center"
        color="link-01"
        bold
        textStyle={{ base: "caption", sm: "body" }}
      >
        {roundStatus === RoundStatus.Cancelled
          ? timeBeforeNextRound
            ? t("yolo::Next round: {{timeBeforeNextRound}}s", { timeBeforeNextRound })
            : ""
          : t("yolo::Next round will start soon")}
      </Text>
      {!!onWithdrawRolloverClick && (
        <Button mt={2} onClick={onWithdrawRolloverClick}>
          {t(isRolloverEnabled ? "yolo::Roll Over / Withdraw" : "yolo::Withdraw")}
        </Button>
      )}
    </Flex>
  );
};
