import { useState } from "react";
import { Box, Flex, Stack } from "@chakra-ui/react";
import { useAccount } from "wagmi";
import { useTranslation } from "next-i18next";
import {
  AddFundsButton,
  HelpIcon,
  IncrementButton,
  Button,
  Text,
  Popover,
  NumberInput,
  TooltipText,
} from "@looksrare/uikit";
import {
  toDecimals,
  formatNumberToLocale,
  fromDecimals,
  useCoinPrices,
  formatUsd,
  multiplyWeiByNumber,
  useAddressesByNetwork,
  useEthBalance,
  useLocalStorage,
  BigIntish,
  StateSetter,
} from "@looksrare/utils";
import { ContractVersion, YoloSupportedNetworks } from "../../../types";
import { useYoloQueryParams } from "../../../utils";
import { PayloadAddEth } from "../assetsState";
import { useEthInputValidation } from "../inputValidation";
import { NetworkNativeTokenIcon } from "../../NetworkNativeTokenIcon";
import { useGetGasPrice } from "../../../utils/api/realtime";
import { LOCAL_STORAGE_YOLO_INPUT_ETH, MAX_YOLO_ROUNDS } from "../../../config";
import { DegenSelectBar } from "./DegenSelectBar";

const AmountWithUsdPreview = ({
  usdAmount = 0,
  ethAmount,
  network,
}: {
  usdAmount: number;
  ethAmount: string;
  network: YoloSupportedNetworks;
}) => {
  return (
    <Flex gap={2} alignItems="center">
      <Text textStyle="caption" color="text-03">
        ({formatUsd(usdAmount)})
      </Text>

      <Text textStyle="detail" bold>
        {formatNumberToLocale(parseFloat(ethAmount), 0, 4)} ETH
      </Text>
      <NetworkNativeTokenIcon network={network} boxSize={5} />
    </Flex>
  );
};

interface Props {
  onAddEth: StateSetter<PayloadAddEth>;
  ethAmountWei: BigIntish;
  ethAmountRoundsCount: number;
  roundVersion: ContractVersion;
}

export const AddEth = ({ onAddEth, ethAmountWei, ethAmountRoundsCount, roundVersion }: Props) => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const { data: prices } = useCoinPrices();
  const addressesByNetwork = useAddressesByNetwork();
  const { network } = useYoloQueryParams();

  const ethPriceUsd = prices?.eth.price;
  const { data: weiBalance = 0n } = useEthBalance(address!);
  const ethBalance = fromDecimals(weiBalance);

  const { value: latestInput, setValue: setLatestInput } = useLocalStorage<string>(LOCAL_STORAGE_YOLO_INPUT_ETH, true);
  const [ethInput, setEthInput] = useState("");
  const [roundsCountInput, setRoundsCountInput] = useState<"" | number>(1);
  const { isValidInput, warning } = useEthInputValidation({ input: ethInput, network });

  const requiredBalance = multiplyWeiByNumber(toDecimals(ethInput) || 0, roundsCountInput || 0);
  const requiredBalanceEth = fromDecimals(requiredBalance);

  const cartHasEth = BigInt(ethAmountWei.toString()) > 0n;

  // Estimate gas savings if entering multiple rounds at once
  const gasWei = useGetGasPrice() || 0;

  // Found by simulating. First entry requires ~40k more gas units, but savings only apply to subsequent entries
  const gasUnitsRequiredToEnterYoloV1 = 96333;

  const gasSavings = multiplyWeiByNumber(gasUnitsRequiredToEnterYoloV1 || 0, ((roundsCountInput || 0) - 1) * gasWei);
  const gasSavingsEth = fromDecimals(gasSavings);
  const gasSavingsUsd = parseFloat(gasSavingsEth) * (ethPriceUsd || 0);

  const isInputDifferentFromState =
    isValidInput && (toDecimals(ethInput) !== ethAmountWei || Number(roundsCountInput) !== ethAmountRoundsCount);

  const handleAddEth = () => {
    onAddEth({ amount: toDecimals(ethInput), roundsCount: Number(roundsCountInput) });
    setLatestInput(ethInput);
  };

  const handleMaxRounds = () => {
    const maxRounds = Math.floor((Number(ethBalance) - gasWei) / Number(ethInput));
    setRoundsCountInput(Math.min(MAX_YOLO_ROUNDS, maxRounds));
  };

  return (
    <Stack spacing={6}>
      <Stack spacing={4}>
        <Text bold>{t("yolo::ETH entry per round")}</Text>
        <Stack spacing={2}>
          <NumberInput size="md" value={ethInput} onTextChange={(value) => setEthInput(value)} />

          {!!warning && (
            <Text textStyle="helper" color="text-error">
              {warning}
            </Text>
          )}
          <DegenSelectBar
            setInput={setEthInput}
            latestInput={latestInput}
            tokenBalance={ethBalance}
            tokenAddress={addressesByNetwork.ETH}
            roundVersion={roundVersion}
            network={network}
          />
        </Stack>
        <Flex justifyContent="space-between" alignItems="center">
          <Text color="text-03" textStyle="detail">
            {t("yolo::ETH in wallet:")}
          </Text>

          <AmountWithUsdPreview
            usdAmount={ethPriceUsd ? Number(ethBalance) * ethPriceUsd : 0}
            ethAmount={ethBalance}
            network={network}
          />
        </Flex>
      </Stack>
      <Stack spacing={4}>
        <Flex gap={2} alignItems="center">
          <Text bold>{t("yolo::Number of Rounds")}</Text>
          <Popover
            variant="tooltip"
            label={
              <TooltipText textStyle="detail">
                {t(
                  "yolo::You can enter ETH into multiple consecutive rounds in a single transaction, to save on gas fees."
                )}
              </TooltipText>
            }
          >
            <Flex>
              <HelpIcon boxSize={4} color="text-03" />
            </Flex>
          </Popover>
          {network === "ethereum" && (
            <Text
              textStyle="helper"
              color="white"
              borderRadius="circular"
              bgColor="support-error-inverse"
              py="1px"
              px="4px"
              bold
            >
              {t("yolo::Save gas!")}
            </Text>
          )}
        </Flex>

        <Flex gap={2}>
          <IncrementButton
            height="48px"
            inputProps={{ height: "46px" }}
            buttonProps={{
              height: "44px",
            }}
            value={roundsCountInput}
            onChange={(asString, asNumber) => setRoundsCountInput(Number.isNaN(asNumber) ? "" : asNumber)}
            min={1}
          />
          <Button colorScheme="gray" variant="outline" onClick={handleMaxRounds} isDisabled={!isValidInput}>
            {t("yolo::Max")}
          </Button>
        </Flex>

        <Box>
          <Flex justifyContent="space-between" alignItems="center">
            <Text color="text-03" textStyle="detail">
              {t("yolo::Total Entry")}
            </Text>

            <AmountWithUsdPreview
              usdAmount={ethPriceUsd && requiredBalanceEth ? Number(requiredBalanceEth) * ethPriceUsd : 0}
              ethAmount={requiredBalanceEth || "0"}
              network={network}
            />
          </Flex>
          {requiredBalance > weiBalance && (
            <Text color="text-error" textStyle="detail" textAlign="right">
              {t("yolo::Insufficient balance")}
            </Text>
          )}
        </Box>
        <Flex justifyContent="space-between" alignItems="center">
          <Text color="text-03" textStyle="detail">
            {t("yolo::Est. gas savings:")}
          </Text>

          <AmountWithUsdPreview usdAmount={gasSavingsUsd} ethAmount={gasSavingsEth} network={network} />
        </Flex>
      </Stack>
      {requiredBalance > weiBalance ? (
        <AddFundsButton
          defaultBuyCurrency="ETH"
          defaultBuyAmount={parseFloat(fromDecimals(requiredBalance - weiBalance))}
        >
          {t("yolo::Add Funds")}
        </AddFundsButton>
      ) : (
        <Button onClick={handleAddEth} isDisabled={!isValidInput || !isInputDifferentFromState}>
          {cartHasEth ? t("yolo::Update Selection") : t("yolo::Add Selection")}
        </Button>
      )}
    </Stack>
  );
};
