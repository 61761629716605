import { useTranslation } from "next-i18next";
import { chakra, Divider, Flex, HStack, StackProps, VStack } from "@chakra-ui/react";
import { LogoRoundIcon, Text } from "@looksrare/uikit";
import { divideWeiByWei, formatNumberToLocale, formatToSignificant, multiplyWeiByNumber } from "@looksrare/utils";
import { YoloSupportedNetworks } from "../../../../types";
import { NetworkNativeTokenIcon } from "../../../NetworkNativeTokenIcon";
import { useYoloConfig } from "../../../../config";

interface WinningsBreakdownProps extends StackProps {
  savingsWithLooksPercent: number;
  isPayingFeesWithLooks: boolean;
  feesFromUserWallet: bigint;
  fullFeesValue: bigint;
  fullValue: bigint;
  hasSufficientFunds: boolean;
  network: YoloSupportedNetworks;
}

export const WinningsBreakdown = ({
  isPayingFeesWithLooks,
  feesFromUserWallet,
  fullFeesValue,
  fullValue,
  savingsWithLooksPercent,
  hasSufficientFunds,
  network,
  ...props
}: WinningsBreakdownProps) => {
  const { t } = useTranslation();
  const { referenceToken } = useYoloConfig();
  const feesAsEth = isPayingFeesWithLooks ? multiplyWeiByNumber(fullFeesValue, savingsWithLooksPercent) : fullFeesValue;
  const fullFeesAsPercentage = divideWeiByWei(fullFeesValue, fullValue);
  const discountedFeesAsRatio = divideWeiByWei(feesAsEth, fullValue);

  return (
    <VStack alignItems="stretch" divider={<Divider />} {...props} px={4}>
      <Flex justifyContent="space-between" flex={1}>
        <Text>{t("yolo::Total Prize Value")}</Text>
        <HStack>
          <Text textStyle="detail" bold>
            {formatToSignificant(fullValue)}
          </Text>
          <NetworkNativeTokenIcon network={network} boxSize={5} />
        </HStack>
      </Flex>
      {fullFeesValue > 0n && (
        <>
          <Flex justifyContent="space-between" flex={1}>
            <Text>
              <chakra.span textDecoration={isPayingFeesWithLooks ? "line-through" : undefined}>
                {formatNumberToLocale(fullFeesAsPercentage * 100, 0, 2)}%{" "}
              </chakra.span>

              {isPayingFeesWithLooks && (
                <chakra.span color="interactive-03">{` -${formatNumberToLocale(
                  discountedFeesAsRatio * 100,
                  0,
                  2
                )}% `}</chakra.span>
              )}
              <chakra.span color={isPayingFeesWithLooks ? "interactive-03" : "text-01"}>{t("yolo::Fees")}</chakra.span>
            </Text>
            <HStack>
              <Text textStyle="detail" bold>
                -{formatToSignificant(feesAsEth, referenceToken.maxDisplayDecimals)}
              </Text>
              <NetworkNativeTokenIcon network={network} boxSize={5} />
            </HStack>
          </Flex>
          <Flex justifyContent="space-between" flex={1}>
            <Text>{t("yolo::You Pay")}</Text>
            <VStack alignItems="flex-end">
              <HStack>
                <Text textStyle="detail" bold>
                  {formatToSignificant(feesFromUserWallet, isPayingFeesWithLooks ? 1 : 4)}
                </Text>
                {isPayingFeesWithLooks ? (
                  <LogoRoundIcon boxSize={5} />
                ) : (
                  <NetworkNativeTokenIcon network={network} boxSize={5} />
                )}
              </HStack>
              {!hasSufficientFunds && (
                <Text textStyle="detail" color="support-error">
                  {t("yolo::Insufficient Funds")}
                </Text>
              )}
            </VStack>
          </Flex>
        </>
      )}
    </VStack>
  );
};
