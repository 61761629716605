import type { Address } from "viem";
import { getUnixTime } from "date-fns";
import { useTranslation } from "next-i18next";
import { fromDecimals } from "@looksrare/utils";
import { Round, RoundStatus } from "../types";
import { getTotalDepositsByAddress } from "./getTotalDepositsByAddress";

interface RoundLimitArgs {
  round: Round;
  maxDeposit?: number;
  address?: Address;
}

interface RoundInternalStatus {
  cannotEnter: boolean;
  maxNumberOfParticipantsReached: boolean;
  maxNumberOfDepositsReached: boolean;
  hasReachedMaxDeposit: boolean;
  message?: string;
}

export const getRoundLimits = ({ round, maxDeposit, address }: RoundLimitArgs): RoundInternalStatus => {
  const isTooLate = !!round.cutoffTime && getUnixTime(new Date()) >= round.cutoffTime;
  const cannotEnter = round.status !== RoundStatus.Open || isTooLate;
  const maxNumberOfParticipantsReached = round.numberOfParticipants === round.maximumNumberOfParticipants;
  const maxNumberOfDepositsReached = round.deposits.length === round.maximumNumberOfDeposits;

  const hasReachedMaxDeposit = (() => {
    if (!address || typeof maxDeposit === "undefined" || maxDeposit === 0) {
      return false;
    }
    const totalDepositFloat = Number(fromDecimals(getTotalDepositsByAddress(address, round.deposits)));
    return totalDepositFloat >= maxDeposit;
  })();

  const message = (() => {
    const { t } = useTranslation();
    if (cannotEnter) {
      return t("yolo::Round Closed");
    }

    if (maxNumberOfParticipantsReached) {
      return t("yolo::Max Number Of Users Reached");
    }

    if (maxNumberOfDepositsReached) {
      return t("yolo::Max Number Of Deposits Reached");
    }

    if (hasReachedMaxDeposit) {
      return t("yolo::Max Deposit of {{maxDeposit}} ETH Reached", { maxDeposit });
    }

    return undefined;
  })();

  return {
    cannotEnter,
    maxNumberOfParticipantsReached,
    maxNumberOfDepositsReached,
    hasReachedMaxDeposit,
    message,
  };
};
