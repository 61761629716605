import { Button, NextLink } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { formatToSignificant } from "@looksrare/utils/formatting/formatToSignificant";
import { Address } from "viem";
import { BigIntish } from "@looksrare/utils/types/bigint";
import { useRouter } from "next/router";
import { useState } from "react";
// import { ListableNFT, useBulkListingStore } from "views/account/components/BulkListing/bulkListingStore"; //@TODO game provider
// import { getTokens } from "utils/graphql";
import { useYoloQueryParams } from "../../../../utils";
import { YoloSuccessView } from "../shared/YoloSuccessView";
import { useYoloConfig } from "../../../../config";

interface Props {
  rolledOverAmount: bigint;
  onClose: () => void;
  onWithdrawOtherEntries?: () => void;
  nftsInPot: { collection: Address; tokenId: BigIntish }[];
}

export const SuccessView = ({ rolledOverAmount, onClose, onWithdrawOtherEntries, nftsInPot }: Props) => {
  const hasRolledOverEth = rolledOverAmount > 0n;
  const { t } = useTranslation();
  const router = useRouter();
  const { network } = useYoloQueryParams();
  const { basePath, referenceToken } = useYoloConfig();
  const [isLoadingBeforeRedirecting] = useState(false);

  const onSellPrizes = async () => {
    /* @TODO-yolo-yg game provider should set this callback
    setIsLoadingBeforeRedirecting(true);
    const nfts: ListableNFT[] = await getTokens({
      filter: {
        ids: nftsInPot, // @NOTE limited to 30 on both BE and bulk listing flow
      },
    });
    useBulkListingStore.getState().init(nfts);
    */
    router.push("/accounts/me#sell");
  };

  const title = hasRolledOverEth ? t("yolo::Roll Over Complete!") : t("yolo::Claimed!");
  const description = hasRolledOverEth
    ? t("yolo::You've rolled over {{amount}} {{token}} into the current round.", {
        amount: formatToSignificant(rolledOverAmount),
        token: referenceToken.symbol,
      })
    : t("yolo::Your winnings are now in your wallet");

  return (
    <YoloSuccessView onClose={onClose} title={title} description={description}>
      {hasRolledOverEth ? (
        <>
          {!!onWithdrawOtherEntries && (
            <Button colorScheme="gray" flex="1" onClick={onWithdrawOtherEntries}>
              {t("yolo::Claim Other Prizes")}
            </Button>
          )}
          <Button as={NextLink} href={`/${basePath}/${network}`} flex="1" onClick={onClose}>
            {t("yolo::View Round")}
          </Button>
        </>
      ) : (
        <>
          {nftsInPot.length > 0 && (
            <Button flex="1" onClick={onSellPrizes} isLoading={isLoadingBeforeRedirecting}>
              {t("yolo::Sell Prizes")}
            </Button>
          )}
          <Button flex="1" onClick={onClose}>
            {t("yolo::Got it")}
          </Button>
        </>
      )}
    </YoloSuccessView>
  );
};
