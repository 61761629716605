import React from "react";
import { useDisclosure, Button } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useAccount } from "wagmi";
import { Round } from "../../types";
import { PlayerProfileModal } from "../modals/PlayerProfileModal";
import { getNetworkFromYoloContractName } from "../../utils";
import { useYoloConfig } from "../../config";

interface Props {
  round: Round;
}

export const ViewEntriesButton = ({ round }: Props) => {
  const { t } = useTranslation();
  const { YoloPointsExplanationModal } = useYoloConfig();
  const playerProfileDisclosure = useDisclosure();
  const yoloPointsExplanationDisclosure = useDisclosure();
  const { address } = useAccount();
  const hasDeposited = round.deposits.some((deposit) => deposit.depositor.address === address);
  const network = getNetworkFromYoloContractName(round.contract);

  if (!address || !hasDeposited) {
    return null;
  }

  return (
    <>
      <YoloPointsExplanationModal
        isOpen={yoloPointsExplanationDisclosure.isOpen}
        onClose={yoloPointsExplanationDisclosure.onClose}
        network={network}
      />
      <PlayerProfileModal
        isOpen={playerProfileDisclosure.isOpen}
        onClose={playerProfileDisclosure.onClose}
        onGemsHelpClick={yoloPointsExplanationDisclosure.onOpen}
        userAddress={address!}
        round={round}
      />
      <Button width="100%" colorScheme="secondary" onClick={playerProfileDisclosure.onOpen} mt={3}>
        {t("yolo::View Entries")}
      </Button>
    </>
  );
};
