import { getAddress } from "viem";
import { SEAPORT_15 } from "@looksrare/config";
import { TokenStandard } from "@looksrare/utils";
import { Attribute, MarketPlaceContext } from "../types";
import {
  AggregatedAsk,
  BlurOrder,
  ExecutableOrder,
  LooksRareSeaportOrder,
  LooksRareV2Order,
  OpenSeaOrder,
} from "../types/orders";
import {
  LooksRareSeaportFragment,
  LooksRareV2OrderFragment,
  OrderFragment,
  OrderFragmentWithoutTokenId,
} from "./fragments";
import { isExecutableOrder } from "./guards";

export const getHighestAttributeTraitPrice = (attributes: Attribute[]) => {
  const highestPrice = attributes.reduce((bestSoFar, current) => {
    const currentPrice = current.floorOrder?.price ? BigInt(current.floorOrder.price.toString()) : 0n;
    return currentPrice > bestSoFar ? currentPrice : bestSoFar;
  }, 0n);

  return highestPrice;
};

export const isBlurOrderExecutionEnabled = process.env.BLUR_ORDER_EXECUTION_ENABLED === "1";

/**
 * Filter MarketPlaceContext array to include or exclude 'BLUR' depending on env var BLUR_ORDER_EXECUTION_ENABLED
 */
export const getGuardedMarketPlaceContext = (
  marketPlaceContextArray: MarketPlaceContext[]
): MarketPlaceContext[] | undefined => {
  // No filtering if Blur order execution is enabled
  if (isBlurOrderExecutionEnabled) {
    return marketPlaceContextArray;
  }

  const filteredContext = marketPlaceContextArray.filter(
    (marketplaceContext) => marketplaceContext !== MarketPlaceContext.BLUR
  );

  return filteredContext.length > 0 ? filteredContext : undefined;
};

/**
 * Return cheapest `ExecutableOrder` from an array of `AggregatedAsk`
 */
export const getCheapestExecutableAsk = (asks: AggregatedAsk[]): ExecutableOrder | undefined => {
  const executableAsks = asks.filter((ask) => {
    if (isExecutableOrder(ask)) {
      return true;
    }
  });

  if (executableAsks.length > 0) {
    return executableAsks[0] as ExecutableOrder; // BE sorts by price already and favours LR ask if if the price is equal
  }
};

export const formatV2BidsInline = (v2OrderFragment: LooksRareV2OrderFragment): LooksRareV2Order => {
  return {
    ...v2OrderFragment,
    collection: v2OrderFragment.collection.address,
  } as LooksRareV2Order;
};

/**
 * Constructs a LooksRare order from a fragment
 */
export const formatLrSeaportFragmentToOrder = (fragment: LooksRareSeaportFragment): LooksRareSeaportOrder => ({
  id: fragment.id,
  collection: fragment.collection.address,
  collectionType: fragment.collection.type as TokenStandard,
  amount: fragment.amount,
  strategyId: fragment.strategyId,
  context: MarketPlaceContext.LOOKSRARE_SEAPORT,
  currency: fragment.currency,
  endTime: fragment.endTime,
  startTime: fragment.startTime,
  hash: fragment.hash,
  signature: fragment.signature,
  nonce: fragment.nonce,
  price: fragment.price,
  signer: getAddress(fragment.signer),
  conduitKey: fragment.conduitKey,
  recipients: fragment.recipients,
  salt: fragment.salt,
  zone: fragment.zone,
  zoneHash: fragment.zoneHash,
  isAsk: fragment.isAsk,
  // This is added so we can exectute LooksRareSeaport orders through the aggregator
  protocolAddress: SEAPORT_15,
  tokenId: fragment.token?.tokenId || null,
});

/**
 * Construct `AggregatedAsk` from either OrderFragment or OrderFragmentWithoutTokenId
 */
export const formatAggregatedAsk = (
  gqlAsk: OrderFragmentWithoutTokenId | OrderFragment,
  tokenId: string | null
): AggregatedAsk => {
  const collection = gqlAsk.collection.address;

  if (gqlAsk.context === MarketPlaceContext.LOOKSRARE_V2) {
    return { ...gqlAsk, collection } as LooksRareV2Order;
  }

  if (gqlAsk.context === MarketPlaceContext.LOOKSRARE_SEAPORT) {
    return formatLrSeaportFragmentToOrder({
      ...gqlAsk,
      token: {
        tokenId,
      },
    } as LooksRareSeaportFragment);
  }

  return { ...gqlAsk, tokenId, collection } as OpenSeaOrder | BlurOrder;
};
