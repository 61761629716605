import { Flex, Box, BoxProps, HStack, useDisclosure, Divider, SimpleGrid, VStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { type BigIntish, formatNumberToLocale, fromDecimals, divideWeiByWei } from "@looksrare/utils";
import { Text } from "@looksrare/uikit";
import { Round, RoundStatus } from "../types";
import { truncateToContractPrecision } from "../utils/truncate";
import { getNetworkFromYoloContractName } from "../utils";
import { useYoloConfig } from "../config";
import { PointsMultiplierCounter } from "./round/points/PointsMultiplierCounter";
import { YoloRoundCountdown } from "./YoloRoundCountdown";
import { NetworkNativeTokenIcon } from "./NetworkNativeTokenIcon";
import { AnimatedAmount } from "./Amount";
import { FutureRoundsDatapoints } from "./FutureRoundsDatapoints";

interface Props extends BoxProps {
  round: Round;
  userEntriesValue: bigint;
  totalPotValue: BigIntish;
  highlight?: boolean;
}

export const RoundOverview = ({ round, userEntriesValue, totalPotValue, highlight = false, ...props }: Props) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    points: { hideMultiplierInRoundOverview },
    YoloPointsExplanationModal,
    referenceToken,
  } = useYoloConfig();
  const { onChainId, numberOfParticipants, cutoffTime } = round;
  const totalPointValueBi = BigInt(totalPotValue.toString());
  const isRoundRunning = !!cutoffTime && cutoffTime * 1000 > Date.now();

  const userEntriesValueDisplay = truncateToContractPrecision(
    parseFloat(fromDecimals(userEntriesValue)),
    referenceToken.maxPrecision
  );
  const potValueDisplay = truncateToContractPrecision(
    parseFloat(fromDecimals(totalPotValue)),
    referenceToken.maxPrecision
  );

  const winChance =
    totalPointValueBi > 0n && userEntriesValue > 0n ? divideWeiByWei(userEntriesValue, totalPointValueBi) * 100 : 0;
  const network = getNetworkFromYoloContractName(round.contract);

  return (
    <>
      <YoloPointsExplanationModal isOpen={isOpen} onClose={onClose} network={network} />
      <Box {...props}>
        <Flex justifyContent="space-between">
          <Text alignSelf="center" color="text-02" bold mr={3}>
            {`Round ${onChainId}`}
          </Text>
          <HStack>
            {!hideMultiplierInRoundOverview && isRoundRunning && (
              <PointsMultiplierCounter cutoffTime={cutoffTime} roundDuration={round.roundDuration} onClick={onOpen} />
            )}
            <YoloRoundCountdown
              cutoffTimeMs={round.cutoffTime}
              isCancelled={round.status === RoundStatus.Cancelled}
              isFinished={round.status === RoundStatus.Drawn}
            />
          </HStack>
        </Flex>
        <VStack alignItems="stretch" spacing={3}>
          <SimpleGrid gap={3} columns={{ base: 3, lg: 2 }}>
            <Box>
              <AnimatedAmount
                icon={() => <NetworkNativeTokenIcon network={network} boxSize={5} />}
                network={network}
                amount={formatNumberToLocale(potValueDisplay, 0, referenceToken.maxDisplayDecimals)}
                textProps={{ textStyle: { base: "heading-05", sm: "heading-04" }, ml: 1 }}
              />
              <Text color="text-03" textStyle="helper">
                {t("yolo::Prize Pool")}
              </Text>
            </Box>

            <Box display={{ base: "none", lg: "inherit" }}>
              <Flex alignItems="baseline">
                <Text textStyle={{ base: "heading-05", sm: "heading-04" }} bold>
                  {numberOfParticipants}
                </Text>
                <Text color="text-disabled" bold>
                  /{round.maximumNumberOfParticipants}
                </Text>
              </Flex>
              <Text color="text-03" textStyle="helper">
                {t("yolo::Players")}
              </Text>
            </Box>

            <Flex direction="column">
              <Flex alignItems="center">
                <NetworkNativeTokenIcon network={network} boxSize={5} />
                <Text
                  color={highlight ? "link-01" : "text-disabled"}
                  textStyle={{ base: "heading-05", sm: "heading-04" }}
                  ml={2}
                  bold
                >
                  {userEntriesValueDisplay}
                </Text>
              </Flex>
              <Text color="text-03" textStyle="helper">
                {t("yolo::Your Entries")}
              </Text>
            </Flex>

            <Box>
              <Text
                color={highlight ? "link-01" : "text-disabled"}
                textStyle={{ base: "heading-05", sm: "heading-04" }}
                bold
              >
                {formatNumberToLocale(winChance, 0, 2)}%
              </Text>
              <Text color="text-03" textStyle="helper">
                {t("yolo::Your Win Chance")}
              </Text>
            </Box>
          </SimpleGrid>
          <Divider display={{ base: "none", lg: "inherit" }} />
          <SimpleGrid gap={3} columns={2} display={{ base: "none", lg: "grid" }}>
            <FutureRoundsDatapoints network={network} />
          </SimpleGrid>
        </VStack>
      </Box>
    </>
  );
};
