import React from "react";
import { Flex } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { ViewFilledIcon } from "@looksrare/uikit";
import { Text } from "@looksrare/uikit";
import { useYoloPageViews } from "../../utils/api/realtime";

export const PageViews = () => {
  const viewers = useYoloPageViews();
  const { t } = useTranslation();

  if (!viewers || viewers.count === undefined) {
    return null;
  }

  return (
    <Flex alignItems="center">
      <Text color="link-01" mr={2}>
        {t("yolo::{{count}} Watching", { count: viewers.count })}
      </Text>
      <ViewFilledIcon boxSize={5} color="link-01" />
    </Flex>
  );
};
