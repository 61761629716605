import { useState, useEffect } from "react";
import { Address, getAddress } from "viem";
import { ERC721Abi } from "@looksrare/config";
import { VStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useHandleModalStep, useWalletClientIsReady, useAddressesByNetwork } from "@looksrare/utils";
import { usePublicClient, useWalletClient } from "wagmi";
import { BulkTransactionStepStatus, StepErrorCta, Text, TransactionStepRow } from "@looksrare/uikit";

export const ApprovalNft = ({
  address,
  collectionName,
  isCurrent,
  isAlreadyApproved,
  next,
  step,
}: {
  address: Address;
  collectionName: string;
  isCurrent: boolean;
  isAlreadyApproved: boolean;
  next: () => void;
  step: number;
}) => {
  const { t } = useTranslation();
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const { isSignerReady } = useWalletClientIsReady();
  const [transaction, setTransaction] = useState<string>();
  const addressesByNetwork = useAddressesByNetwork();

  const { handleSubmit, isRejected, isIdle, isAccepted } = useHandleModalStep({
    onSubmit: async () => {
      if (!publicClient) {
        throw new Error("No public client found");
      }
      if (isAlreadyApproved) {
        return next();
      }
      if (walletClient) {
        const [account] = await walletClient.getAddresses();
        const { request } = await publicClient.simulateContract({
          address,
          abi: ERC721Abi,
          functionName: "setApprovalForAll",
          args: [getAddress(addressesByNetwork.TRANSFER_MANAGER), true],
          account,
        });
        const hash = await walletClient.writeContract(request);
        setTransaction(hash);
        const receipt = await publicClient.waitForTransactionReceipt({ hash });

        if (receipt.status === "success") {
          setTransaction(undefined);
          next();
        } else {
          throw new Error(`${receipt.transactionHash} failed`);
        }
      }
    },
  });

  useEffect(() => {
    if (isCurrent && isSignerReady) {
      handleSubmit({ callOnlyOnce: true });
    }
  }, [handleSubmit, isCurrent, isSignerReady]);

  const rowStatus = ((): BulkTransactionStepStatus => {
    if (isAlreadyApproved || isAccepted) {
      return "done";
    }
    if (isRejected) {
      return "error";
    }
    if (isIdle) {
      return "wait";
    }
    return "pending";
  })();

  return (
    <VStack alignItems="flex-start" width="100%">
      <Text textStyle="helper" bold color={isRejected ? "text-error" : "text-01"}>
        {isRejected
          ? t("yolo::You declined the transaction {{counterDisplay}}", { counterDisplay: step })
          : t("yolo::Confirm transaction in wallet {{counterDisplay}}", { counterDisplay: step })}
      </Text>
      <TransactionStepRow transactionHash={transaction} status={rowStatus} text={collectionName} />
      {isRejected && <StepErrorCta onRetry={() => handleSubmit()} />}
    </VStack>
  );
};
