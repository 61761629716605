import { palette } from "@looksrare/chakra-theme";

const userColors = [
  palette.green[300],
  palette.purple[400],
  palette.red[300],
  palette.blue[400],
  palette.yellow[200],
  palette.magenta[400],
  palette.orange[300],
  palette.green[400],
  palette.purple[300],
  palette.red[400],
  palette.blue[300],
  palette.yellow[400],
  palette.magenta[300],
  palette.orange[400],
  palette.green[200],
  palette.purple[500],
  palette.red[200],
  palette.blue[500],
  palette.yellow[300],
  palette.magenta[500],
  palette.orange[200],
  palette.green[500],
  palette.purple[200],
  palette.red[500],
  palette.blue[200],
  palette.yellow[500],
  palette.magenta[200],
  palette.orange[500],
];

export const getUserColor = (index: number) => userColors[index % userColors.length];
