import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";

export const Container = (props: BoxProps) => {
  return (
    <Box
      border="solid 1px"
      borderColor="border-01"
      bg="ui-glass"
      backdropFilter="blur(10px)"
      borderRadius="container"
      {...props}
    />
  );
};
