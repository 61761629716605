import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { useBoxShadowPreset } from "@looksrare/uikit";
import { useYoloConfig } from "../../../config";

export const GemBackground = (props: BoxProps) => {
  const {
    points: { bgGradient },
  } = useYoloConfig();

  const boxShadow = useBoxShadowPreset("card");
  return (
    <Box
      bgGradient={bgGradient}
      borderRadius="button"
      p="px"
      overflow="hidden"
      boxShadow={boxShadow}
      position="relative"
      {...props}
    />
  );
};
