import { useRouter } from "next/router";
import { YoloContractName, ContractVersion, YoloSupportedNetworks } from "../types";

/**
 * Note: do not export the maps, rely on functions so it's easier to handle any edge cases
 * @TODO-multichain a safer approach will be generating all maps from a networkVersions one, and let each project provide that.
 */

const networkVersionsMap: Record<YoloSupportedNetworks, Partial<Record<ContractVersion, YoloContractName>>> = {
  ethereum: {
    V1: YoloContractName.YOLO_V1,
    V2: YoloContractName.YOLO_V2,
  },
  arbitrum: {
    V2: YoloContractName.YOLO_V2_ARBITRUM,
  },
  blast: {
    V1_LIMITED: YoloContractName.YOLO_LIMITED_V1_BLAST,
    V2: YoloContractName.YOLO_V2_BLAST,
  },
};

const versionsNetworkMap: Record<ContractVersion, Partial<Record<YoloSupportedNetworks, YoloContractName>>> = {
  V1: {
    ethereum: YoloContractName.YOLO_V1,
  },
  V1_LIMITED: {
    blast: YoloContractName.YOLO_LIMITED_V1_BLAST,
  },
  V2: {
    ethereum: YoloContractName.YOLO_V2,
    arbitrum: YoloContractName.YOLO_V2_ARBITRUM,
    blast: YoloContractName.YOLO_V2_BLAST,
  },
};

const contractNameVersionMap: Record<YoloContractName, ContractVersion> = {
  [YoloContractName.YOLO_V1]: ContractVersion.V1,
  [YoloContractName.YOLO_V2]: ContractVersion.V2,
  [YoloContractName.YOLO_V2_ARBITRUM]: ContractVersion.V2,
  [YoloContractName.YOLO_V2_BLAST]: ContractVersion.V2,
  [YoloContractName.YOLO_LIMITED_V1_BLAST]: ContractVersion.V1_LIMITED,
};

const contractNameNetworkMap: Record<YoloContractName, YoloSupportedNetworks> = {
  [YoloContractName.YOLO_V1]: YoloSupportedNetworks.ethereum,
  [YoloContractName.YOLO_V2]: YoloSupportedNetworks.ethereum,
  [YoloContractName.YOLO_V2_ARBITRUM]: YoloSupportedNetworks.arbitrum,
  [YoloContractName.YOLO_V2_BLAST]: YoloSupportedNetworks.blast,
  [YoloContractName.YOLO_LIMITED_V1_BLAST]: YoloSupportedNetworks.blast,
};

export const getYoloContractNameFromNetworkAndVersion = (
  network: YoloSupportedNetworks,
  version: ContractVersion
): YoloContractName | undefined => {
  //@NOTE yolo-play has a custom version of this
  return versionsNetworkMap[version]?.[network];
};

export const getYoloContractVersionFromName = (contractName: YoloContractName): ContractVersion => {
  return contractNameVersionMap[contractName];
};

export const getYoloContractNamesFromNetwork = (network = YoloSupportedNetworks.ethereum): YoloContractName[] => {
  return Object.values(networkVersionsMap[network]);
};

export const getNetworkFromYoloContractName = (contractName: YoloContractName): YoloSupportedNetworks => {
  return contractNameNetworkMap[contractName];
};

type YoloQuery = {
  network: YoloSupportedNetworks;
};

export const useYoloQueryParams = () => {
  const router = useRouter();
  const { network } = router.query as YoloQuery;
  return { network };
};
