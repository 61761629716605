import { GemIcon, Text } from "@looksrare/uikit";
import { getUnixTime } from "date-fns";
import { Flex, Skeleton } from "@chakra-ui/react";
import { Reflection } from "@looksrare/uikit";
import { getPointsMultiplier, getRoundPercentElapsed } from "@looksrare/utils";
import { getIsCloseToMultiplierThreshold } from "../../../utils/gems/getIsCloseToMultiplierThreshold";
import { multiplierThresholds } from "../../../config";
import { GemBackground } from "./GemContainer";

interface Props {
  cutoffTime: number;
  onClick: () => void;
  roundDuration?: number;
}

export const PointsMultiplierCounter = ({ cutoffTime, onClick, roundDuration }: Props) => {
  if (!roundDuration) {
    return <Skeleton borderRadius="button" width="60px" height="30px" />;
  }

  const currentSeconds = getUnixTime(new Date());
  const secondsElapsedAsDecimal = getRoundPercentElapsed({
    currentSeconds,
    roundDuration,
    roundCutoffTime: cutoffTime,
  });
  const multiplier = getPointsMultiplier(secondsElapsedAsDecimal, multiplierThresholds);
  const isCloseToMultiplierThreshold = getIsCloseToMultiplierThreshold({
    currentSeconds,
    roundDuration,
    roundCutoffTime: cutoffTime,
  });

  return (
    <GemBackground
      onClick={onClick}
      role="button"
      aria-label="Click to see your points multiplier"
      sx={{
        ...(isCloseToMultiplierThreshold
          ? {
              animation: "pulse 1.5s ease-in-out infinite",
              "@keyframes pulse": {
                "0%": {
                  opacity: 1,
                },
                "50%": {
                  opacity: 0.5,
                },
                "100%": {
                  opacity: 1,
                },
              },
            }
          : undefined),
      }}
    >
      <Flex
        gap={2}
        // borderRadius one px smaller than container to work with parent borderRadius
        borderRadius="7px"
        bg="ui-01"
        py={1}
        pr={1.5}
        pl={2}
        height="100%"
        alignItems="center"
        layerStyle="clickable"
        position="relative"
        overflow="hidden"
        sx={{
          _hover: {
            ".reflection": {
              transform: "translateX(150%)",
              transition: "transform 0.5s ease-in-out 1.5s",
            },
          },
        }}
      >
        <Reflection
          className="reflection"
          position="absolute"
          height="65px"
          width="92px"
          top="-50%"
          left="0"
          transform="translateX(-100%)"
          pointerEvents="none"
        />
        <Text color="link-02" textStyle="detail" bold>
          {multiplier}x
        </Text>
        <GemIcon boxSize={5} />
      </Flex>
    </GemBackground>
  );
};
