import { keyframes } from "@chakra-ui/react";

export const pulse = keyframes({
  "0%": {
    color: "link-01",
  },
  "50%": {
    opacity: 0.5,
  },
  "100%": {
    color: "link-01",
  },
});
