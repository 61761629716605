import React from "react";
import { Box, BoxProps, Flex } from "@chakra-ui/react";
import { Text } from "@looksrare/uikit";

export const EmojiBox = ({
  value,
  label,
  emoji,
  ...props
}: { value?: string; label: string; emoji: string } & BoxProps) => {
  return (
    // Border color is ignored if set before the border attribute, so it needs to be after the props attribute
    <Flex flexDirection="column" justifyContent="space-between" p={3} {...props} borderColor="border-01">
      <Text bold>{value}</Text>
      <Flex justifyContent="space-between" alignItems="center">
        <Text color="text-03" textStyle="helper">
          {label}
        </Text>
        <Box>{emoji}</Box>
      </Flex>
    </Flex>
  );
};
