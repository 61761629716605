import { CheckmarkFilledIcon, Text, useThrowConfettiFromCenter, useToast } from "@looksrare/uikit";
import { Flex, ModalBody, ModalFooter, VStack } from "@chakra-ui/react";
import { PropsWithChildren, useCallback, useEffect } from "react";

interface Props extends PropsWithChildren {
  onClose: () => void;
  title: string;
  description: string;
}

/**
 * Renders a standard success view with a checkmark icon, title, and description.
 * Also tosses confetti and shows a toast.
 */
export const YoloSuccessView = ({ title, description, children }: Props) => {
  const { toast } = useToast();

  const throwConfetti = useThrowConfettiFromCenter();
  const toastSuccess = useCallback(() => {
    toast({
      title,
      description,
      status: "success",
    });
  }, [description, title, toast]);

  useEffect(() => {
    toastSuccess();
    throwConfetti();
  }, [toastSuccess, throwConfetti]);

  return (
    <>
      <ModalBody>
        <VStack>
          <CheckmarkFilledIcon color="interactive-03" boxSize={16} />
          <Text bold textStyle="heading-04">
            {title}
          </Text>
          <Text color="text-02">{description}</Text>
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Flex gap={2} flex="1" flexWrap="wrap">
          {children}
        </Flex>
      </ModalFooter>
    </>
  );
};
