import { RoundCountdown, RoundCountdownProps } from "@looksrare/uikit";
import { useCurrentRoundStore } from "../currentRoundStore";

type YoloRoundCountdownProps = Omit<RoundCountdownProps, "duration">;

export const YoloRoundCountdown = ({ cutoffTimeMs, isCancelled, isFinished, ...props }: YoloRoundCountdownProps) => {
  const duration = useCurrentRoundStore((state) => state.duration);

  return (
    <RoundCountdown
      duration={duration}
      cutoffTimeMs={cutoffTimeMs}
      isCancelled={isCancelled}
      isFinished={isFinished}
      {...props}
    />
  );
};
