import { Modal, ModalCloseButton, ModalProps } from "@looksrare/uikit";
import { useCallback, useState } from "react";
import { fromDecimals } from "@looksrare/utils/formatting/fromDecimals";
import { toDecimals } from "@looksrare/utils/formatting/toDecimals";
import { divideWeiByWei } from "@looksrare/utils/bigint";
import { useLooksTwap } from "@looksrare/utils/hooks/useLooksTwap";
import { WEI_PER_ETHER } from "@looksrare/config/constants";
import { useAddressesByNetwork } from "@looksrare/utils/hooks/useAddressesByNetwork";
import delay from "lodash/delay";
import { useCurrentParticipant, useInvalidateRoundQueries, useYoloQueryParams } from "../../../../utils";
import { Round, ContractVersion } from "../../../../types";
import { ModalAction } from "../../types";
import { truncateToContractPrecision } from "../../../../utils/truncate";
import { getPendingClaims } from "../../../../utils/contract/getPendingClaims";
import { AnimationView } from "../../WinnerModal/AnimationView";
import { RollOverTransactionView } from "../shared/RollOverTransactionView";
import { useYoloConfig } from "../../../../config";
import { WinSummaryView } from "./WinSummaryView";
import { ActionSelectionView } from "./ActionSelectionView";
import { ClaimTransactionView } from "./TransactionsViews/ClaimTransactionView";
import { evaluateUnclaimedPrizes } from "./utils";
import { SuccessView } from "./SuccessView";

enum Views {
  WIN_ANIMATION = "winAnimation",
  WIN_SUMMARY = "winSummary",
  ACTION_SELECTION = "actionSelection",
  TRANSACTIONS = "transactions",
  SUCCESS = "success",
}

interface WinnerModalBodyProps extends Pick<ModalProps, "onClose"> {
  justWonRound?: Round;
}

export const WinnerModalBody = ({ justWonRound, onClose }: WinnerModalBodyProps) => {
  const addressesByNetwork = useAddressesByNetwork();
  const { network } = useYoloQueryParams();
  const {
    referenceToken,
    contract: { version, getYoloContractNameFromNetworkAndVersion, useGetClaimPrizesPaymentRequired },
    isRolloverEnabled,
    supportedTokens,
  } = useYoloConfig();
  // The claim can be triggered by a specific round but a user can claim all his v2 winnings at once
  const [isPayingFeesWithLooks, setIsPayingFeesWithLooks] = useState(false);
  const canPayFeesWithLooks = supportedTokens.includes("LOOKS");
  const { data: looksTwap } = useLooksTwap({ enabled: canPayFeesWithLooks });
  const { data: participant, isLoading, refetch } = useCurrentParticipant(network);
  const invalidateRoundQueries = useInvalidateRoundQueries();

  const onSuccess = () => {
    invalidateRoundQueries();
    refetch();
  };

  const v1contractName = getYoloContractNameFromNetworkAndVersion(network, ContractVersion.V1);
  const v2contractName = getYoloContractNameFromNetworkAndVersion(network, version);

  const v1PrizesToClaim = participant?.yoloUnclaimedPrizes.filter(({ round }) => round.contract === v1contractName);
  const v2PrizesToClaim = participant?.yoloUnclaimedPrizes.filter(({ round }) => round.contract === v2contractName);

  const {
    unclaimedValue: unclaimedV2Value,
    unclaimedReferenceToken: unclaimedV2ReferenceToken = 0n,
    unclaimedReferencePrizes: unclaimedV2ReferencePrizes,
    feesValue: v2feesValue,
    nftsInPrizes,
  } = evaluateUnclaimedPrizes(v2PrizesToClaim || [], addressesByNetwork[referenceToken.symbol]);

  const { unclaimedValue: unclaimedV1Value, feesValue: v1FeesValue } = evaluateUnclaimedPrizes(
    v1PrizesToClaim || [],
    addressesByNetwork.ETH
  );

  const {
    claimsByContractVersion: { [version]: v2Claims },
  } = getPendingClaims(participant?.yoloUnclaimedPrizes);

  const claimV2PrizesCallData = Object.values(v2Claims);
  const shouldFetchV2Fees = !!claimV2PrizesCallData.length;

  const {
    data: fees,

    isLoading: isV2FeesLoading,
  } = useGetClaimPrizesPaymentRequired(claimV2PrizesCallData, {
    enabled: shouldFetchV2Fees,
  });

  const [
    /** Fees are primarily taken directly from the reference currency in the pot if possible. If not, user has to pay them */
    feesFromUserWallet,
    savingsWithLooksPercent,
    /** Fees could have been paid while rolling over */
    wereFeesAlreadyPaid,
  ] = (() => {
    if (!fees) {
      return [0n];
    }
    if (!canPayFeesWithLooks) {
      return [fees.feesPayingEth];
    } else {
      if (!looksTwap) {
        return [0n];
      }
      const { feesPayingLooks, feesPayingEth } = fees;
      const feeFromUserWallet = isPayingFeesWithLooks ? feesPayingLooks : feesPayingEth;
      const looksFeesEthPrice = (BigInt(looksTwap) * feesPayingLooks) / WEI_PER_ETHER;
      const feesRatio = divideWeiByWei(looksFeesEthPrice, v2feesValue);
      const savingsPercent = 1 - feesRatio;

      const alreadyPaid = feesPayingLooks === 0n;
      return [feeFromUserWallet, savingsPercent, alreadyPaid];
    }
  })();

  /**
   * The amount of available ETH in the prizes _after_ fees determines the available actions and views
   */
  const rollableOverAmount = (() => {
    const feesToSubtractFromEthPot = isPayingFeesWithLooks ? 0n : v2feesValue;
    const rawRollableAmount = unclaimedV2ReferenceToken - feesToSubtractFromEthPot;
    const truncatedRollableAmount = truncateToContractPrecision(
      parseFloat(fromDecimals(rawRollableAmount)),
      referenceToken.maxPrecision
    );
    return toDecimals(truncatedRollableAmount.toString());
  })();

  const [view, setView] = useState<Views>(() => {
    if (!!justWonRound) {
      return Views.WIN_ANIMATION;
    }
    if (isRolloverEnabled) {
      return Views.ACTION_SELECTION;
    }
    return Views.TRANSACTIONS;
  });
  const [selectedAction, setSelectedAction] = useState<ModalAction>(ModalAction.WithdrawAll);

  const stableGoToWinSummary = useCallback(() => setView(Views.WIN_SUMMARY), []);

  const onWithdrawOtherEntries = async () => {
    await refetch(); // Wait for this to update the unclaimed prizes
    setSelectedAction(ModalAction.WithdrawAll);
    setView(Views.TRANSACTIONS);
  };

  const referenceTokenOnlyPrizes = (() => {
    // Currently only the reference token can be rolled over
    const {
      claimsByContractVersion: { [version]: v2EthOnlyClaims },
    } = getPendingClaims(unclaimedV2ReferencePrizes);
    return Object.values(v2EthOnlyClaims);
  })();

  const roundsToCollectCount = Object.keys(v2Claims).length;
  const hasOtherEntriesToWithdraw = unclaimedV1Value + unclaimedV2Value !== unclaimedV2ReferenceToken;
  const allUnclaimedValueMinusFees =
    unclaimedV1Value + unclaimedV2Value - v1FeesValue - (isPayingFeesWithLooks ? 0n : v2feesValue);
  const fullValue = unclaimedV1Value + unclaimedV2Value;

  return (
    <>
      <ModalCloseButton alignSelf="flex-end" position="absolute" top={4} right={4} onClick={onClose} />
      {(() => {
        switch (view) {
          case Views.WIN_ANIMATION:
            return <AnimationView onComplete={stableGoToWinSummary} onClose={onClose} />;
          case Views.WIN_SUMMARY:
            return !justWonRound ? null : (
              <WinSummaryView
                justWonRound={justWonRound!}
                onComplete={() => {
                  isRolloverEnabled ? setView(Views.ACTION_SELECTION) : setView(Views.TRANSACTIONS);
                }}
              />
            );
          case Views.ACTION_SELECTION:
            return (
              <ActionSelectionView
                selectedAction={selectedAction}
                setSelectedAction={setSelectedAction}
                onComplete={() => setView(Views.TRANSACTIONS)}
                isPayingFeesWithLooks={isPayingFeesWithLooks}
                setIsPayingFeesWithLooks={setIsPayingFeesWithLooks}
                rollableOverAmount={rollableOverAmount}
                roundsToCollectCount={roundsToCollectCount}
                savingsWithLooksPercent={savingsWithLooksPercent}
                feesFromUserWallet={feesFromUserWallet}
                fullFeesValue={wereFeesAlreadyPaid ? 0n : v2feesValue + v1FeesValue}
                claimableAmount={wereFeesAlreadyPaid ? fullValue : allUnclaimedValueMinusFees}
                fullValue={fullValue}
                network={network}
                isLoading={isV2FeesLoading}
              />
            );
          case Views.TRANSACTIONS:
            if (isLoading) {
              return null;
            }
            return !isRolloverEnabled || selectedAction === ModalAction.WithdrawAll ? (
              <ClaimTransactionView
                onComplete={() => setView(Views.SUCCESS)}
                isPayingFeesWithLooks={isPayingFeesWithLooks}
                claimPrizesCalldata={claimV2PrizesCallData}
                unclaimedV1Prizes={v1PrizesToClaim} // NOTE: Pass down raw v1 prizes to reuse existing logic. To be deprecated
                feesFromUserWallet={feesFromUserWallet}
              />
            ) : (
              <RollOverTransactionView
                onComplete={() => setView(Views.SUCCESS)}
                isPayingFeesWithLooks={isPayingFeesWithLooks}
                ethOnlyClaims={referenceTokenOnlyPrizes}
                feesFromUserWallet={feesFromUserWallet}
                rollableOverAmount={rollableOverAmount}
                hasPlatformFees
              />
            );
          case Views.SUCCESS:
            return (
              <SuccessView
                nftsInPot={nftsInPrizes}
                rolledOverAmount={selectedAction === ModalAction.WithdrawAll ? 0n : rollableOverAmount}
                onClose={() => {
                  onClose();
                  delay(onSuccess, 3000); // Let indexer catch up
                }}
                onWithdrawOtherEntries={hasOtherEntriesToWithdraw ? onWithdrawOtherEntries : undefined}
              />
            );
        }
      })()}
    </>
  );
};

interface Props extends Omit<ModalProps, "children"> {
  justWonRound?: Round;
}
export const WinnerModal = ({ justWonRound, onClose, ...props }: Props) => {
  return (
    <Modal onClose={onClose} closeOnOverlayClick={false} modalContentProps={{ overflow: "hidden" }} {...props}>
      {props.isOpen && <WinnerModalBody justWonRound={justWonRound} onClose={onClose} />}
    </Modal>
  );
};
