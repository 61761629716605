import {
  UseQueryOptions,
  useQuery,
  useInfiniteQuery,
  useQueryClient,
  keepPreviousData,
  UseQueryResult,
  InfiniteData,
} from "@tanstack/react-query";
import { useAccount } from "wagmi";
import { Address } from "viem";
import { Attribute, NFTCard, SearchFilterInput, TokenFilter, TokenOwnerFilter, TokensSort } from "@looksrare/nfts";
import last from "lodash/last";
import { Pagination, RQueryOptions } from "@looksrare/utils";
import { useCallback } from "react";
import {
  Round,
  RoundStatus,
  SortRoundBy,
  Participant,
  YoloSupportedNetworks,
  YoloContractName,
  DepositorWithMetrics,
} from "../../types";
import { useYoloConfig } from "../../config";
import {
  getRound,
  getHistoryRounds,
  getWhitelistedCurrencies,
  getCurrentParticipant,
  getTokenAttributes,
  getUserTokens,
  getDepositor,
  getParticipantFutureEntries,
  GetParticipantFutureEntriesResponse,
} from "./subgraph";

export const useRound = (id: number, contractName: YoloContractName, options?: RQueryOptions<Round>) => {
  const {
    contract: { version },
  } = useYoloConfig();
  return useQuery({
    queryKey: ["yolo-round", version, contractName, id.toString()],
    queryFn: () => getRound(id, contractName),
    refetchInterval: 5_000,
    gcTime: 1_000,
    refetchOnWindowFocus: false,
    ...options,
  });
};

export const useInvalidateRoundQueries = (contractName?: YoloContractName, roundId?: number) => {
  const queryClient = useQueryClient();
  const {
    contract: { version },
  } = useYoloConfig();
  return useCallback(() => {
    return queryClient.invalidateQueries({
      queryKey: ["yolo-round", version, contractName, roundId?.toString()],
    });
  }, [contractName, roundId, queryClient, version]);
};

export const usePastRounds = (
  network: YoloSupportedNetworks,
  page: number,
  sortBy: SortRoundBy,
  status?: RoundStatus,
  player?: string
) => {
  const {
    contract: { version, getYoloContractNamesFromNetwork },
  } = useYoloConfig();
  const contracts = getYoloContractNamesFromNetwork(network);

  return useQuery(
    {
      queryKey: ["yolo-rounds-history", version, network, page, sortBy, status, player],
      queryFn: () => getHistoryRounds(contracts, page, sortBy, status, player),
      gcTime: 60_000,
      // @todo-rq5
      placeholderData: keepPreviousData,
    } // 1min
  );
};

export const useWhitelistedCurrencies = (contractName: YoloContractName): UseQueryResult<Address[]> => {
  const {
    contract: { version },
  } = useYoloConfig();
  return useQuery({
    queryKey: ["yolo-whitelisted-currencies", version, contractName],
    queryFn: () => getWhitelistedCurrencies(contractName),
    gcTime: 600_000, // 10min
  });
};

export const useDepositor = (
  address: Address,
  network: YoloSupportedNetworks,
  options?: UseQueryOptions<DepositorWithMetrics | null>
) => {
  const {
    contract: { version, getYoloContractNamesFromNetwork },
  } = useYoloConfig();
  const contractNames = getYoloContractNamesFromNetwork(network);
  return useQuery({
    queryKey: ["yolo-participant-metrics", version, network, address],
    queryFn: () => getDepositor(address, contractNames),
    refetchInterval: 600_000,
    gcTime: 10_000,
    refetchOnWindowFocus: false,
    ...options,
  });
};

export const useCurrentParticipant = (network: YoloSupportedNetworks, options?: RQueryOptions<Participant | null>) => {
  const { address } = useAccount();
  const {
    contract: { version, getYoloContractNamesFromNetwork },
  } = useYoloConfig();
  const contractNames = getYoloContractNamesFromNetwork(network);
  return useQuery({
    queryKey: ["yolo-participant", version, network, address],
    queryFn: () => (address ? getCurrentParticipant(address, contractNames) : Promise.resolve(null)),
    refetchInterval: 600_000,
    gcTime: 10_000,
    refetchOnWindowFocus: false,
    ...options,
  });
};

const FUTURE_ROUNDS_PER_PAGE = 1; // currently just need one to get the totals returned alongside the pages

const getNextParticipantFutureEntries = (
  rounds: Pick<GetParticipantFutureEntriesResponse, "rounds">
): Pagination | undefined => {
  if (rounds?.rounds.length < FUTURE_ROUNDS_PER_PAGE) {
    // No more data to fetch
    return undefined;
  }
  const lastRound = last(rounds.rounds);
  return { first: FUTURE_ROUNDS_PER_PAGE, cursor: lastRound?.onChainId.toString() };
};

export const useCurrentParticipantInfiniteFutureEntries = (network: YoloSupportedNetworks) => {
  const { address } = useAccount();
  const {
    contract: { version, getYoloContractNamesFromNetwork },
  } = useYoloConfig();
  const contractNames = getYoloContractNamesFromNetwork(network);
  return useInfiniteQuery<GetParticipantFutureEntriesResponse>({
    queryKey: ["yolo-participant-future-entries", version, network, address],
    queryFn: ({ pageParam }) =>
      getParticipantFutureEntries(address! as Address, contractNames, pageParam as Pagination),

    getNextPageParam: getNextParticipantFutureEntries,
    initialPageParam: { first: FUTURE_ROUNDS_PER_PAGE },
    staleTime: 10_000,
    refetchOnWindowFocus: false,
    enabled: !!address,
  });
};

export const useInvalidateCurrentParticipantFutureEntries = () => {
  const queryClient = useQueryClient();
  const {
    contract: { version },
  } = useYoloConfig();
  return useCallback(() => {
    return queryClient.invalidateQueries({ queryKey: ["yolo-participant-future-entries", version] });
  }, [queryClient, version]);
};

export const useTokenAttributes = (collection: Address, tokenId: string, queryOptions?: RQueryOptions<Attribute[]>) => {
  const {
    contract: { version },
  } = useYoloConfig();
  return useQuery({
    queryKey: ["token-attributes", version, collection, tokenId],
    queryFn: () => getTokenAttributes({ collection, tokenId }),
    staleTime: Infinity,
    gcTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    ...queryOptions,
  });
};

const TOKENS_PER_PAGE = 20;

interface UseInfiniteUserTokensArgs {
  filter?: TokenFilter;
  sort?: TokensSort;
  ownerFilter?: TokenOwnerFilter;
  search?: SearchFilterInput;
  address: Address;
}

const getNextTokensPageParam = (lastPage: NFTCard[]): Pagination | undefined => {
  if (lastPage.length < TOKENS_PER_PAGE) {
    // No more data to fetch
    return undefined;
  }
  const lastNft = last(lastPage);
  return { first: TOKENS_PER_PAGE, cursor: lastNft?.id };
};

export const useInfiniteUserTokens = ({
  address,
  filter,
  sort = TokensSort.LAST_RECEIVED,
  ownerFilter,
  search,
}: UseInfiniteUserTokensArgs) => {
  const {
    contract: { version },
  } = useYoloConfig();
  return useInfiniteQuery<NFTCard[], Error, InfiniteData<NFTCard[], Pagination>, unknown[], Pagination>({
    queryKey: ["user-tokens", version, address, filter, sort, ownerFilter, search],
    queryFn: ({ pageParam }) =>
      getUserTokens({ address, filter, pagination: pageParam as Pagination, sort, ownerFilter, search }),
    getNextPageParam: (lastPage) => getNextTokensPageParam(lastPage),
    initialPageParam: { first: TOKENS_PER_PAGE },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};
