import { type BigIntish, getTopicName, TopicName, useRealtimeSubscription } from "@looksrare/utils";
import { create } from "zustand";
import { IS_TESTNET } from "@looksrare/config";
import { useYoloQueryParams } from "../chains";
import { YoloContractName, YoloSupportedNetworks } from "../../types";

export const useGasPrice = () => {
  const topicName = getTopicName({ name: TopicName.gasPrice });
  return useRealtimeSubscription<number>(topicName);
};

export const useYoloPageViews = () => {
  const topicName = getTopicName({ name: TopicName.yoloActiveViews });
  return useRealtimeSubscription<{ count: number }>(topicName);
};

export const useYoloContractViews = (contract: YoloContractName) => {
  const topicName = getTopicName({ name: TopicName.yoloContractActiveViews, contract });
  return useRealtimeSubscription<{ count: number }>(topicName);
};

export const useYoloActivePlayers = () => {
  const topicName = getTopicName({ name: TopicName.yoloActivePlayers });
  return useRealtimeSubscription<number>(topicName);
};

export type PendingTxPayload = {
  count: number;
  value: BigIntish;
};

export const usePendingTxsSub = (callback?: () => void, enabled?: boolean) => {
  const topicName = getTopicName({ name: TopicName.yoloPendingTx });
  return useRealtimeSubscription<PendingTxPayload>(topicName, {
    onNewData: callback,
    enabled,
  });
};

interface State {
  gasPriceWei: number | null;
  update: (by: number | null) => void;
}

const useGasPriceStore = create<State>((set) => ({
  gasPriceWei: 0,
  update: (price) => set({ gasPriceWei: price }),
}));

export const useYoloGasThresholdWei = () => {
  const { network } = useYoloQueryParams();
  const isEthereumMainnet = network === YoloSupportedNetworks.ethereum && !IS_TESTNET;
  return isEthereumMainnet ? 80_000_000_000 : Infinity;
};

export const useInitiateGasPriceStore = () => {
  const update = useGasPriceStore((state) => state.update);
  const gasWei = useGasPrice();
  update(gasWei);
};
export const useGetGasPrice = () => useGasPriceStore((state) => state.gasPriceWei);
