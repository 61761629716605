import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { Address } from "viem";
import { usePublicClient, useWalletClient } from "wagmi";
import { BigIntish, RQueryOptions, multiplyWeiByNumber } from "@looksrare/utils";
import { YoloLimitedAbi } from "@looksrare/config";
import { useGameAddresses } from "@looksrare/uikit";
import { ClaimPrizesCalldata, DepositCalldata } from "../../types";

const useYoloContractInfo = () => {
  const addresses = useGameAddresses();
  return {
    address: addresses.YOLO_V2,
    abi: YoloLimitedAbi,
  } as const;
};

const YOLO_GAS_BUFFER = 1.1; // Compensate for out of gas errors by adding 10% buffer

/**
 * Deposit assets into a round
 */
export const useDeposit = () => {
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const yoloContractInfo = useYoloContractInfo();

  return useCallback(
    async (roundId: BigIntish, deposits: DepositCalldata[], value: BigIntish, gasPrice?: bigint) => {
      if (!walletClient) {
        throw Error("No wallet client found");
      }
      if (!publicClient) {
        throw new Error("No public client found");
      }
      // Cast to expected contract types
      const roundIdBi = BigInt(roundId.toString());
      const valueBi = BigInt(value.toString());
      const depositsFormatted = deposits.map(
        ({ tokenAddress, tokenIdsOrAmounts, reservoirOracleFloorPrice, tokenType, minimumEntries }) => ({
          tokenType,
          tokenAddress: tokenAddress as Address,
          tokenIdsOrAmounts: tokenIdsOrAmounts.map((idOrAmount) => BigInt(idOrAmount.toString())),
          reservoirOracleFloorPrice: {
            ...reservoirOracleFloorPrice,
            id: reservoirOracleFloorPrice.id,
            timestamp: BigInt(reservoirOracleFloorPrice.timestamp),
          },
          minimumEntries,
        })
      );

      const [account] = await walletClient.getAddresses();
      const depositParams = {
        ...yoloContractInfo,
        functionName: "deposit",
        args: [roundIdBi, depositsFormatted],
        value: valueBi,
        gasPrice,
        account,
      } as const;

      const estimatedGas = await publicClient.estimateContractGas(depositParams);
      const estimatedGasWithBuffer = multiplyWeiByNumber(estimatedGas, YOLO_GAS_BUFFER);

      const { request } = await publicClient.simulateContract({
        ...yoloContractInfo,
        ...depositParams,
        gas: estimatedGasWithBuffer,
      });
      return walletClient.writeContract(request);
    },
    [publicClient, walletClient, yoloContractInfo]
  );
};

export const useDepositEthIntoMultipleRounds = () => {
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const yoloContractInfo = useYoloContractInfo();

  return useCallback(
    async (startingRound: bigint, weiPerRound: bigint, roundsCount: number, gasPrice?: bigint) => {
      if (!walletClient) {
        throw Error("No wallet client found");
      }
      if (!publicClient) {
        throw new Error("No public client found");
      }
      const value = weiPerRound * BigInt(roundsCount);
      const amounts: bigint[] = Array(roundsCount).fill(weiPerRound);

      const valueBi = BigInt(value.toString());
      const [account] = await walletClient.getAddresses();
      const depositParams = {
        ...yoloContractInfo,
        functionName: "depositETHIntoMultipleRounds",
        args: [startingRound, amounts],
        value: valueBi,
        gasPrice,
        account,
      } as const;

      const estimatedGas = await publicClient.estimateContractGas(depositParams);
      const estimatedGasWithBuffer = multiplyWeiByNumber(estimatedGas, YOLO_GAS_BUFFER);

      const { request } = await publicClient.simulateContract({
        ...yoloContractInfo,
        ...depositParams,
        gas: estimatedGasWithBuffer,
      });
      return walletClient.writeContract(request);
    },
    [publicClient, walletClient, yoloContractInfo]
  );
};

/**
 * Claim prizes for the connected user
 */
export const useClaimPrizes = () => {
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const yoloContractInfo = useYoloContractInfo();

  return useCallback(
    async (claimPrizesCalldata: ClaimPrizesCalldata, isPayingFeesWithLooks: boolean, feesAmount?: bigint) => {
      if (!walletClient) {
        throw Error("No wallet client found");
      }
      if (!publicClient) {
        throw new Error("No public client found");
      }
      const [account] = await walletClient.getAddresses();
      const { request } = await publicClient.simulateContract({
        ...yoloContractInfo,
        functionName: "claimPrizes",
        args: [claimPrizesCalldata, isPayingFeesWithLooks],
        value: isPayingFeesWithLooks ? 0n : feesAmount,
        account,
      });
      return walletClient.writeContract(request);
    },
    [publicClient, walletClient, yoloContractInfo]
  );
};

/**
 * Withdraw deposits from cancelled rounds
 */
export const useWithdrawDeposits = () => {
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const yoloContractInfo = useYoloContractInfo();

  return useCallback(
    async (withdrawalsConfig: ClaimPrizesCalldata) => {
      if (!walletClient) {
        throw Error("No wallet client found");
      }
      if (!publicClient) {
        throw new Error("No public client found");
      }
      const [account] = await walletClient.getAddresses();

      const x = {
        ...yoloContractInfo,
        functionName: "withdrawDeposits",
        args: [withdrawalsConfig],
        account,
      } as const;

      const estimatedGas = await publicClient.estimateContractGas(x);
      return walletClient?.writeContract({
        ...x,
        gas: estimatedGas,
      });
    },
    [publicClient, walletClient, yoloContractInfo]
  );
};

interface Fees {
  feesPayingEth: bigint;
  feesPayingLooks: bigint;
}

/**
 * Pull amount to be paid for a user during the claiming process.
 * If all prizes are nft, the winner needs to pay the protocol fee while he claims.
 */
export const useGetClaimPrizesPaymentRequired = (
  claimPrizesCalldata: ClaimPrizesCalldata,
  options?: RQueryOptions<Fees>
) => {
  const publicClient = usePublicClient();
  const yoloContractInfo = useYoloContractInfo();

  return useQuery({
    queryKey: ["yolo-protocol-fee-owed", claimPrizesCalldata],
    queryFn: async () => {
      if (!publicClient) {
        throw new Error("No public client found");
      }
      const [feesPayingEth, feesPayingLooks] = await Promise.all([
        publicClient.readContract({
          ...yoloContractInfo,
          functionName: "getClaimPrizesPaymentRequired",
          args: [claimPrizesCalldata, false],
        }),
        publicClient.readContract({
          ...yoloContractInfo,
          functionName: "getClaimPrizesPaymentRequired",
          args: [claimPrizesCalldata, true],
        }),
      ]);

      return {
        feesPayingEth,
        feesPayingLooks,
      };
    },
    ...options,
  });
};

export const useRollOverEthToNextRound = () => {
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const yoloContractInfo = useYoloContractInfo();

  return useCallback(
    async (startingRound: bigint, claimCalldata: ClaimPrizesCalldata, isPayingFeesWithLooks: boolean) => {
      if (!walletClient) {
        throw Error("No wallet client found");
      }
      if (!publicClient) {
        throw new Error("No public client found");
      }
      const [account] = await walletClient.getAddresses();
      const depositParams = {
        ...yoloContractInfo,
        functionName: "rolloverETH",
        args: [startingRound, claimCalldata, isPayingFeesWithLooks],
        account,
      } as const;

      const estimatedGas = await publicClient.estimateContractGas(depositParams);
      const estimatedGasWithBuffer = multiplyWeiByNumber(estimatedGas, YOLO_GAS_BUFFER);

      const { request } = await publicClient.simulateContract({
        ...yoloContractInfo,
        ...depositParams,
        gas: estimatedGasWithBuffer,
      });
      return walletClient.writeContract(request);
    },
    [publicClient, walletClient, yoloContractInfo]
  );
};

export const useReadOracleSignatureValidityPeriod = () => {
  const publicClient = usePublicClient();
  const yoloContractInfo = useYoloContractInfo();

  return useCallback(async () => {
    if (!publicClient) {
      throw new Error("No public client found");
    }
    return publicClient.readContract({
      ...yoloContractInfo,
      functionName: "signatureValidityPeriod",
    });
  }, [publicClient, yoloContractInfo]);
};
