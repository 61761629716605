// LooksRareV2Order matches the order type returned by API queries.
import { Address } from "viem";
import {
  Maker as V2Maker,
  MakerOrder_V1,
  MakerOrderWithSignature_V1,
  MerkleTree,
  Referrer,
  StrategyType,
} from "@looksrare/sdk-v2";
import { ConsiderationItem } from "@opensea/seaport-js/lib/types";
import { BigIntish, TokenStandard } from "../../utils";
import { CollectionBase, MarketPlaceContext, OrderStatus, ImageData } from "./graphql";

export interface LooksRareV2Order extends V2Maker {
  context: MarketPlaceContext.LOOKSRARE_V2;
  id: string; // Used for paginating order requests
  hash: string;
  signature: string;
  merkleTree: MerkleTree | null;
  referrer?: Referrer;
  // Overwriting V2Maker type
  collection: Address;
  currency: Address;
  signer: Address;
}

// LooksRareV1Order matches the order type returned by API queries.
export interface LooksRareV1Order extends Omit<MakerOrderWithSignature_V1, "params"> {
  context: MarketPlaceContext.LOOKSRARE;
  id: string; // Used for paginating order requests
  hash: string;
  params: MakerOrderWithSignature_V1["params"] | null; // Can be null on BE
  // Overwriting MakerOrderWithSignature_V1 type
  currency: Address;
}

export enum OpenSeaOrderType {
  FULL_OPEN,
  FULL_RESTRICTED,
  PARTIAL_OPEN,
  PARTIAL_RESTRICTED,
}

export interface OpenSeaOrderRecipient {
  amount: bigint;
  recipient: string;
  token: string;
}

export interface OpenSeaOrder extends Omit<MakerOrder_V1, "params" | "minPercentageToAsk" | "strategy"> {
  context: MarketPlaceContext.OPENSEA;
  id: string; // Used for paginating order requests
  hash: string;
  conduitKey: string;
  orderType: OpenSeaOrderType;
  protocolAddress: Address;
  recipients: OpenSeaOrderRecipient[];
  salt: string;
  zone: string;
  zoneHash: string;
  // Overwriting MakerOrder type
  currency: Address;
  signer: Address;
  collection: Address;
}

export interface BlurOrder {
  context: MarketPlaceContext.BLUR;
  isOrderAsk: boolean;
  id: string; // Used for paginating order requests
  hash: string;
  signer: Address;
  collection: Address;
  tokenId: string;
  price: BigIntish;
  currency: Address;
  nonce: BigIntish;
  startTime: BigIntish;
}

export type LooksRareV2OrderWithStatus = LooksRareV2Order & { status: OrderStatus };
export type LooksRareSeaportOrderWithStatus = LooksRareSeaportOrder & { status: OrderStatus };
export type LooksRareIshOrderWithStatus = LooksRareV2OrderWithStatus | LooksRareSeaportOrderWithStatus;
/**
 * Marketplaces with supported, executable orders
 */
export type ExecutableOrder = LooksRareV2Order | OpenSeaOrder | BlurOrder | LooksRareSeaportOrder;
/**
 * Marketplaces which can appear in events, but are not executable
 */
export type AggregatedOrderWithV1 = LooksRareV1Order | ExecutableOrder;
/**
 * Marketplaces which behave like a LooksRare order
 */
export type LooksRareIshOrder = LooksRareV2Order | LooksRareSeaportOrder;

export interface LooksRareIshOwnerOrders {
  v2OwnerOrders: LooksRareV2Order[];
  lrSeaportOwnerOrders: LooksRareSeaportOrder[];
}

/**
 * Order types returned by the `asks` resolver
 * Includes non-executable orders (@note - currently all requested order types are supported)
 */
export type AggregatedAsk = ExecutableOrder;

export enum OrderStrategyType {
  COLLECTION = "COLLECTION",
  PRIVATE = "PRIVATE",
  STANDARD = "STANDARD",
}

export interface ImportableOrder {
  tokenId: BigIntish;
  endTime?: BigIntish; // Blur orders can have undefined endTime
  price: BigIntish;
  context: MarketPlaceContext.OPENSEA | MarketPlaceContext.BLUR;
}

export type CollectionWithImportableOrders = {
  logo?: {
    src: ImageData["src"];
  };
  isVerified: CollectionBase["isVerified"];
  name: CollectionBase["name"];
  address: CollectionBase["address"];
  orders: ImportableOrder[];
};
/**
 * LooksRare Seaport order
 * Note: Matches output of Seaport JS 1:1
 */
export type SeaportOrderRecipient = ConsiderationItem;

export interface LooksRareSeaportOrder {
  id: string;
  amount: BigIntish;
  collection: Address;
  collectionType: TokenStandard;
  strategyId: StrategyType;
  context: MarketPlaceContext.LOOKSRARE_SEAPORT;
  currency: Address;
  endTime: BigIntish;
  hash: string;
  signature: string;
  nonce: BigIntish;
  price: BigIntish;
  signer: Address;
  startTime: BigIntish;
  conduitKey: string;
  recipients: SeaportOrderRecipient[];
  salt: string;
  zone: string;
  zoneHash: string;
  tokenId?: string | null;
  protocolAddress: Address;
  isAsk: boolean;
}
