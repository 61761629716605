import { Box, Flex, HStack } from "@chakra-ui/react";
import { Trans, useTranslation } from "next-i18next";
import {
  ArrowLeftIcon,
  CloudinaryImage,
  LaunchOpenInNewIcon,
  Link,
  NextLink,
  useGameSupportedNetworks,
  useWidgetBotOptions,
} from "@looksrare/uikit";
import { DOC_URL } from "@looksrare/config";
import { Text } from "@looksrare/uikit";
import { Accordion, AccordionLabel, IconLinkButton } from "@looksrare/uikit";
import { useWidgetBotCrate } from "@looksrare/utils";
import { Layout } from "../components/Layout";
import { useYoloConfig } from "../config";

const faqSharedTextProps = {
  textStyle: "detail",
  color: "text-03",
};

export const HelpView = () => {
  const { Help } = useYoloConfig();
  return <Help />;
};

/**
 * Help / FAQ view
 */
export const Help = () => {
  useWidgetBotCrate(useWidgetBotOptions());
  const { t } = useTranslation();
  const {
    basePath,
    referenceToken: { minDeposit },
  } = useYoloConfig();
  const supportedNetworks = useGameSupportedNetworks();
  const howDoesItWorkTexts = [
    {
      question: t("yolo::How do I participate?"),
      answer: (
        <Trans i18nKey="yolo::transHowDoIParticipateAnswer">
          <Text {...faqSharedTextProps}>
            Head over to <Link href={`/${basePath}/${supportedNetworks[0]}`}>LooksRare YOLO</Link>, armed with MetaMask
            or another Web3 wallet. Of course, you’ll also need LOOKS, ETH and/or eligible NFTs to add to the round.
          </Text>
          <Text {...faqSharedTextProps}>
            From there, hit Enter Now and you&apos;ll see the LOOKS, ETH, and eligible NFT collections in your wallet.
          </Text>
          <Text {...faqSharedTextProps}>
            Choose the ones you want to add to the round and hit &quot;Confirm Entry&quot;, then complete the steps in
            your wallet app as prompted.
          </Text>
          <Text {...faqSharedTextProps}>
            Make sure your transactions are fully completed on-chain before the round timer counts down to zero; any
            transactions completed after time&apos;s up will fail.
          </Text>
        </Trans>
      ),
    },
    {
      question: t("yolo::What are my chances of winning?"),
      answer: (
        <Trans i18nKey="yolo::transWhatAreMyChancesOfWinningAnswer">
          <Text {...faqSharedTextProps}>
            Your chance of winning is based on the total value you contributed to the prize pool when the round closes
            vs the total value of the prize pool.
          </Text>
          <Text {...faqSharedTextProps}>For example:</Text>
          <Text {...faqSharedTextProps}>You contributed 0.1 ETH;</Text>
          <Text {...faqSharedTextProps}>The total prize pool is 1 ETH;</Text>
          <Text {...faqSharedTextProps}>Your chance of winning is 10%.</Text>
        </Trans>
      ),
    },
    {
      question: t("yolo::Is there a minimum entry amount?"),
      answer: (
        <Trans i18nKey="yolo::transIsThereAMinimumEntryAmountAnswer_1">
          <Text {...faqSharedTextProps}>
            Yes. Each contribution must be worth at least{" "}
            <Text display="inline" textStyle="detail" color="text-03" bold>
              {
                {
                  amount: minDeposit,
                } as any /* Necessary but trips TS. SEE https://github.com/i18next/react-i18next/issues/1483#issuecomment-1268455602 */
              }{" "}
              ETH
            </Text>{" "}
            equivalent.
          </Text>
        </Trans>
      ),
    },
    {
      question: t("yolo::Are YOLO rounds provably fair?"),
      answer: (
        <Trans i18nKey="yolo::transAreYoloRoundsProvablyFairAnswer">
          <Text {...faqSharedTextProps}>
            You know it. Just like LooksRare Raffles,{" "}
            <Link href="https://docs.chain.link/vrf/v2/introduction" isExternal>
              YOLO gets its randomness from Chainlink&apos;s oracles
            </Link>{" "}
            to ensure the winner is picked fairly, and in the most transparent manner possible.
          </Text>
        </Trans>
      ),
    },
    {
      question: t("yolo::What NFTs are eligible?"),
      answer: (
        <Trans i18nKey="yolo::transWhatNftsAreEligibleAnswer">
          <Text {...faqSharedTextProps}>
            Right now, there&apos;s no definitive list of eligible collections, but you can simply hit &quot;Enter
            Now&quot; on the YOLO game page to see all the eligible NFTs in your wallet.
          </Text>
          <Text {...faqSharedTextProps}>
            A lot of collections are already eligible, and more will be added over time.
          </Text>
          <Text {...faqSharedTextProps}>
            Note: collections can become temporarily ineligible if their floor price drops below the minimum entry
            amount described above.
          </Text>
        </Trans>
      ),
    },
    {
      question: t("yolo::How is NFT value determined?"),
      answer: (
        <Trans i18nKey="yolo::transHowIsNftValueDeterminedAnswer">
          <Text {...faqSharedTextProps}>
            Currently, the in-game value for all NFTs is set to{" "}
            <Text display="inline" textStyle="detail" color="text-03" bold>
              the respective collection&apos;s floor price
            </Text>{" "}
            — regardless of whether they&apos;re ultra-rare or boring AF.
          </Text>
          <Text {...faqSharedTextProps}>
            The floor price used for this in-game value is snapshotted for a collection at the instant the first NFT
            from that collection is entered into a round, and any other NFTs from that collection will have the same
            in-game value until the end of the round.
          </Text>
        </Trans>
      ),
    },
    {
      question: t("yolo::What does the “You Pay” mean when I'm claiming my winnings?"),
      answer: (
        <Trans i18nKey="yolo::transWhatDoesTheYouPayMeanWhenImClaimingAnswer">
          <Text {...faqSharedTextProps}>
            If there isn&apos;t enough ETH in a round to cover the fees (i.e., if only NFTs/LOOKS were contributed to
            the round), you&apos;ll pay the fee in ETH while withdrawing.
          </Text>
          <Text {...faqSharedTextProps}>
            If there&apos;s enough ETH in a round to cover the fees, great! The fee will be subtracted directly from
            your winning stash at the end of the round.
          </Text>
        </Trans>
      ),
    },
    {
      question: t("yolo::What is LooksRare's fee?"),
      answer: (
        <Text {...faqSharedTextProps}>{t("yolo::A 5% fee from each round goes to the LooksRare treasury.")}</Text>
      ),
    },
  ];

  return (
    <Layout pos="relative" withBackgroundImage>
      <Flex direction={{ base: "column", md: "row" }}>
        <Box flex={3}>
          <Text
            as={NextLink}
            href={`/${basePath}/${supportedNetworks[0]}`}
            display="flex"
            alignItems="center"
            color="link-01"
            textStyle="detail"
            bold
            flex="1"
            mb={{ base: 2, md: 4 }}
          >
            <ArrowLeftIcon boxSize={5} mr={4} />
            {t("yolo::Current Round")}
          </Text>

          <Text textStyle="display-01" mt={4} bold>
            {t("yolo::YOLO")}
          </Text>

          <Text textStyle="heading-03" mt={16} bold>
            {t("yolo::The Winner Takes It All")}
          </Text>
          <Text textStyle="body" color="text-02" mt={4}>
            {t(
              "yolo::YOLO is a nail-biting game that invites players to deposit ETH, LOOKS and NFTs from eligible collections into a shared prize pool."
            )}
          </Text>
          <Text textStyle="body" color="text-02">
            {t(
              "yolo::The winner walks away with the entirety of the prize pool — everything that has been contributed, minus LooksRare's fee."
            )}
          </Text>

          <Text textStyle="heading-03" mt={16} bold>
            {t("yolo::How Does It Work?")}
          </Text>

          <Box mt={4} borderRadius="container" overflow="hidden">
            {howDoesItWorkTexts.map(({ question, answer }) => {
              return (
                <Accordion
                  key={question + answer}
                  buttonProps={{
                    borderRadius: "none",
                    borderBottomLeftRadius: "none",
                    borderBottomRightRadius: "none",
                    _hover: {
                      borderRadius: "none",
                      borderBottomLeftRadius: "none",
                      borderBottomRightRadius: "none",
                      bg: "hover-interactive-02",
                    },
                    _active: {
                      bg: "onclick-interactive-02",
                    },
                  }}
                  panelProps={{
                    borderRadius: "none",
                  }}
                  leftButtonElement={<AccordionLabel color="text-01">{question}</AccordionLabel>}
                >
                  {answer}
                </Accordion>
              );
            })}
          </Box>

          <IconLinkButton
            href={`${DOC_URL}/developers/yolo/yolo-overview`}
            aria-label="learn more"
            variant="solid"
            color="link-02"
            bgColor="interactive-02"
            isExternal
            my={16}
          >
            <HStack paddingX="4">
              <Text textStyle="detail">{t("yolo::Learn More")}</Text>
              <LaunchOpenInNewIcon boxSize={5} ml={1} />
            </HStack>
          </IconLinkButton>
        </Box>
        <Box flex={2}>
          <Box display={{ base: "none", md: "block" }} position="relative" minHeight="80vh">
            <CloudinaryImage src="/images/yolo/yolo-rotated.svg" alt="" layout="fill" />
          </Box>

          <Box display={{ base: "block", md: "none" }} position="relative" minHeight="115px" mt={16}>
            <CloudinaryImage src="/images/yolo/yolo.svg" alt="Yolo" layout="fill" />
          </Box>
        </Box>
      </Flex>
    </Layout>
  );
};
