import { ModalBody, ModalHeader } from "@chakra-ui/react";
import { Text } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { ClaimPrizesCalldata } from "../../../../types";
import { WithdrawAllStep } from "./WithdrawAllStep";

interface Props {
  onComplete: () => void;
  claimRefundsCalldata: ClaimPrizesCalldata;
}

export const WithdrawAllTransactionView = ({ onComplete, claimRefundsCalldata }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <ModalHeader>
        <Text bold textStyle="heading-04">
          {t("yolo::Withdraw Entries")}
        </Text>
      </ModalHeader>
      <ModalBody bg="ui-01">
        <WithdrawAllStep
          isStepActive
          onComplete={onComplete}
          claimRefundsCalldata={claimRefundsCalldata}
          ctaText={t("yolo::Transaction")}
        />
      </ModalBody>
    </>
  );
};
