import { Address, zeroAddress } from "viem";
import { useGameAddresses } from "@looksrare/uikit";
import { ContractVersion, YoloSupportedNetworks } from "../../../types";
import { useYoloConfig } from "../../../config";
import { useYoloCartStore } from "../assetsState";
import { AddEth } from "./AddEth";
import { AddNfts } from "./AddNfts";

interface Props {
  network: YoloSupportedNetworks;
  assetAddress: Address;
  roundVersion: ContractVersion;
  roundValuePerEntry: bigint;
}

export const AddPanel = ({ network, roundVersion, assetAddress, roundValuePerEntry }: Props) => {
  const { supportedTokens, AddErc20 } = useYoloConfig();
  const addressesByNetwork = useGameAddresses();

  const { ethAmountWei, ethAmountRoundsCount, addEth, tokens, addErc20, collections, addNFT, removeNFT } =
    useYoloCartStore();
  const tokenAddress = (() => {
    const tokenName = supportedTokens.find(
      (supportedTokenAddress) => addressesByNetwork[supportedTokenAddress] === assetAddress
    );
    return tokenName ? addressesByNetwork[tokenName] : null;
  })();
  if (!!tokenAddress) {
    if (assetAddress === zeroAddress) {
      return (
        <AddEth
          ethAmountWei={ethAmountWei}
          ethAmountRoundsCount={ethAmountRoundsCount}
          onAddEth={addEth}
          roundVersion={roundVersion}
        />
      );
    }
    return (
      <AddErc20
        key={tokenAddress} // Reset state on token change
        token={tokenAddress}
        tokens={tokens}
        onAddErc20={addErc20}
        roundValuePerEntry={roundValuePerEntry}
        roundVersion={roundVersion}
        network={network}
      />
    );
  }
  return (
    <AddNfts
      collectionAddress={assetAddress}
      selectedNfts={collections[assetAddress]?.nfts || []}
      addNFT={addNFT}
      removeNFT={removeNFT}
      roundValuePerEntry={roundValuePerEntry}
      roundVersion={roundVersion}
    />
  );
};
