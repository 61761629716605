import { usePublicClient } from "wagmi";
import { AutoTransactionStepRow, TransactionSetter } from "@looksrare/uikit";
import { useHandleModalStep } from "@looksrare/utils";
import { ClaimPrizesCalldata } from "../../../../../types";
import { useYoloConfig } from "../../../../../config";

interface Props {
  claimPrizesCalldata: ClaimPrizesCalldata;
  isStepActive: boolean;
  isPayingFeesWithLooks: boolean;
  feesFromUserWallet?: bigint;
  onComplete: () => void;
  ctaText: string;
}

export const ClaimV2TransactionStep = ({
  claimPrizesCalldata,
  isStepActive,
  isPayingFeesWithLooks,
  onComplete,
  feesFromUserWallet,
  ctaText,
}: Props) => {
  const {
    contract: { useClaimPrizes },
  } = useYoloConfig();
  const claimPrizes = useClaimPrizes();
  const publicClient = usePublicClient();

  const useHandleTransaction = (setTransaction: TransactionSetter) =>
    useHandleModalStep({
      onSubmit: async () => {
        if (!publicClient) {
          throw new Error("No public client found");
        }
        const hash = await claimPrizes(claimPrizesCalldata, isPayingFeesWithLooks, feesFromUserWallet);
        setTransaction(hash);
        const receipt = await publicClient.waitForTransactionReceipt({ hash });
        if (receipt.status === "success") {
          setTransaction(undefined);
          onComplete();
        } else {
          throw new Error(`Claiming prizes failed. Transaction hash: ${receipt.transactionHash}`);
        }
      },
    });

  return (
    <AutoTransactionStepRow isStepActive={isStepActive} useHandleTransaction={useHandleTransaction} ctaText={ctaText} />
  );
};
