import { Round, UnclaimedRefund, ContractVersion } from "../../types";

import { getYoloContractVersionFromName } from "../chains";

type ClaimParamsV1 = Record<
  Round["onChainId"],
  {
    roundId: number;
    prizeIndices: number[];
  }
>;

type ClaimParamsV2 = Record<
  Round["onChainId"],
  {
    roundId: bigint;
    depositIndices: bigint[];
  }
>;

type ClaimParamsV1Limited = ClaimParamsV2;

type ClaimParamsByContractVersion = {
  [ContractVersion.V1]: ClaimParamsV1;
  [ContractVersion.V2]: ClaimParamsV2;
  [ContractVersion.V1_LIMITED]: ClaimParamsV1Limited;
};

/**
 * Compute the pending amount to be claimed for the user, and the claim function params, by contract version
 * @returns pendingAmount: total amount to be claimed
 * @returns claimParams: list of params to be used in the claim function
 */
export const getPendingClaims = (
  unclaimedPrizes?: UnclaimedRefund[]
): {
  pendingAmount: bigint;
  claimsByContractVersion: ClaimParamsByContractVersion;
} => {
  const claimsByContractVersion: ClaimParamsByContractVersion = {
    [ContractVersion.V1]: {},
    [ContractVersion.V2]: {},
    [ContractVersion.V1_LIMITED]: {},
  };

  if (!unclaimedPrizes) {
    return {
      pendingAmount: 0n,
      claimsByContractVersion,
    };
  }

  let pendingAmount = 0n;

  unclaimedPrizes.forEach((unclaimedPrize) => {
    const { round } = unclaimedPrize;
    const version = getYoloContractVersionFromName(round.contract);
    const roundId = round.onChainId;
    const isRoundInMapping = !!claimsByContractVersion[version][roundId];

    switch (version) {
      case ContractVersion.V1:
        if (!isRoundInMapping) {
          claimsByContractVersion[version][roundId] = { roundId, prizeIndices: [] };
        }
        claimsByContractVersion[version][roundId].prizeIndices.push(unclaimedPrize.index);
        break;
      case ContractVersion.V2:
      case ContractVersion.V1_LIMITED:
        if (!isRoundInMapping) {
          claimsByContractVersion[version][roundId] = { roundId: BigInt(roundId), depositIndices: [] };
        }
        claimsByContractVersion[version][roundId].depositIndices.push(BigInt(unclaimedPrize.index));
    }

    pendingAmount += BigInt(unclaimedPrize.numberOfEntries) * BigInt(round.valuePerEntry.toString());
  });

  return { pendingAmount, claimsByContractVersion };
};
