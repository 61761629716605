import React, { useEffect } from "react";
import { Box, Flex, HStack, ModalBody, SimpleGrid } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { ExternalLink, HelpIcon, CloudinaryImage, Popover, Text, useThrowConfettiFromCenter } from "@looksrare/uikit";
import {
  formatNumberToLocale,
  formatToSignificant,
  fromDecimals,
  multiplyWeiByNumber,
  divideWeiByWei,
  useSoundEffect,
} from "@looksrare/utils";
import { Button } from "@looksrare/uikit";
import { Amount } from "../../../Amount";
import { truncateToContractPrecision } from "../../../../utils/truncate";
import { Container } from "../../../Container";
import { VRF_CHAINLINK_URL, VRF_GELATO_URL, useYoloConfig } from "../../../../config";
import { useConnectedUserEntriesValue } from "../../../../utils/useConnectedUserEntriesValue";
import { Round } from "../../../../types";
import { getNetworkFromYoloContractName } from "../../../../utils";
import { NetworkNativeTokenIcon } from "../../../NetworkNativeTokenIcon";
import { EyeCatchingText } from "../../../gameCustomisations/EyeCatchingText";

interface Props {
  justWonRound: Round;
  onComplete: () => void;
}

export const WinSummaryView = ({ justWonRound, onComplete }: Props) => {
  const { t } = useTranslation();
  const roundPotValue = BigInt(justWonRound.potValue);
  const currentRoundFeesPercentage = Number(justWonRound.protocolFeeBp) / 10_000;
  const { referenceToken, isMuted } = useYoloConfig();
  const userEntriesValue = useConnectedUserEntriesValue(justWonRound);

  const winChance = divideWeiByWei(userEntriesValue, roundPotValue) * 100;
  const currentRoundFeesAmount = multiplyWeiByNumber(BigInt(roundPotValue), currentRoundFeesPercentage);
  const roundNetWinnings = roundPotValue - userEntriesValue - currentRoundFeesAmount;

  const throwConfetti = useThrowConfettiFromCenter();
  const { play: playWinSound } = useSoundEffect({ path: "/sounds/yolo/winner-summary.mp3", volume: 1, isMuted });

  useEffect(() => {
    throwConfetti();
    playWinSound({ restartIfAlreadyPlaying: false });
  }, [playWinSound, throwConfetti]);

  const totalPotValueDisplay = formatNumberToLocale(
    truncateToContractPrecision(parseFloat(fromDecimals(roundPotValue)), referenceToken.maxPrecision)
  );
  const userEntriesValueDisplay = formatNumberToLocale(
    truncateToContractPrecision(parseFloat(fromDecimals(userEntriesValue)), referenceToken.maxPrecision)
  );

  const feesAmountDisplay = fromDecimals(currentRoundFeesAmount);
  const netWinningsDisplay = formatToSignificant(roundNetWinnings);
  const network = getNetworkFromYoloContractName(justWonRound.contract);

  return (
    <>
      <Box width="100%" height="208px" position="relative">
        <CloudinaryImage
          alt="Pepe the frog"
          src="/images/yolo/pepe-the-frog-dance-poster.png"
          layout="fill"
          objectFit="cover"
        />
      </Box>
      <ModalBody position="relative">
        <Flex flexDirection="column" alignItems="center" mb={6}>
          <EyeCatchingText textStyle="display-03" bold>
            {t("yolo::You Won!")}
          </EyeCatchingText>
        </Flex>

        <Container p={4} mb={6} border="none" bgColor="ui-bg">
          <Flex justifyContent="center" alignItems="center" gap={1}>
            <NetworkNativeTokenIcon network={network} boxSize={6} />
            <EyeCatchingText textStyle="display-03" bold>
              {netWinningsDisplay}
            </EyeCatchingText>
          </Flex>
          <Text textStyle="detail" color="text-03" textAlign="center" mb={3}>
            {t("yolo::Winnings’ Value")}
            <Popover
              label={
                <HStack justifyContent="space-between">
                  <Box>
                    <Amount textProps={{ color: "text-inverse" }} amount={totalPotValueDisplay} network={network} />
                    <Text textStyle="helper" color="text-03">
                      {t("yolo::Total Entries’ Value")}
                    </Text>
                  </Box>
                  <Box>
                    <Amount textProps={{ color: "text-inverse" }} amount={feesAmountDisplay} network={network} />
                    <Text textStyle="helper" color="text-03">
                      {t(`yolo::Fee (${currentRoundFeesPercentage * 100}%)`)}
                    </Text>
                  </Box>
                </HStack>
              }
            >
              <span>
                <HelpIcon boxSize={4} color="text-03" />
              </span>
            </Popover>
          </Text>

          <SimpleGrid columns={2} gap={4}>
            <Box>
              <Amount network={network} amount={userEntriesValueDisplay} textProps={{ textStyle: "body" }} />
              <Text textStyle="helper" color="text-03" textAlign="center">
                {t("yolo::Your Entries’ Value")}
              </Text>
            </Box>
            <Box>
              <Text textAlign="center" bold>
                {formatNumberToLocale(winChance, 0, 1)}%
              </Text>
              <Text textStyle="helper" color="text-03" textAlign="center">
                {t("yolo::Your Win Chance")}
              </Text>
            </Box>
          </SimpleGrid>
        </Container>

        <Flex justifyContent="center" alignItems="center" mb={10}>
          <Text as="span" color="text-03" textStyle="detail" mr={1}>
            {t("yolo::Provably fair with {{vrf}}:", { vrf: network === "blast" ? "Gelato" : "Chainlink" })}
          </Text>
          <ExternalLink href={network === "blast" ? VRF_GELATO_URL : VRF_CHAINLINK_URL} textStyle="detail">
            {t("yolo::Verify")}
          </ExternalLink>
        </Flex>

        <Button onClick={onComplete} width="100%">
          {t("yolo::Continue")}
        </Button>
      </ModalBody>
    </>
  );
};
