import React from "react";
import NextLink from "next/link";
import { useTranslation } from "next-i18next";
import { Box, Flex, VStack } from "@chakra-ui/react";
import { ArrowLeftIcon, Text, useGameSupportedNetworks } from "@looksrare/uikit";
import { useYoloConfig } from "../../config";
import { useYoloQueryParams } from "../../utils";
import { ClaimWinnings } from "./ClaimWinnings";
import { Filters, Props } from "./Filters";
import { DepositsV2Check } from "./DepositsV2Check";
import { DepositsV1Check } from "./DepositsV1Check";

export const Header = ({ activeStatusFilter, setStatusFilter, toggleUserFilter }: Props) => {
  const { network } = useYoloQueryParams();
  const { basePath, contract } = useYoloConfig();
  const supportedNetworks = useGameSupportedNetworks();

  const { t } = useTranslation();
  return (
    <Box pb={4}>
      <Flex
        justifyContent="space-between"
        alignItems={{ base: "flex-start", md: "center" }}
        flexDirection={{ base: "column", md: "row" }}
        mb={4}
      >
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          alignItems="normal"
          width={{ base: "100%", md: "fit-content" }}
          mb={{ base: 4, md: 0 }}
        >
          <Text
            as={NextLink}
            href={`/${basePath}/${network}`}
            display="flex"
            alignItems="center"
            color="link-01"
            textStyle="detail"
            bold
            flex="1"
            width="fit-content"
            mb={{ base: 2, md: 4 }}
          >
            <ArrowLeftIcon boxSize={5} mr={4} />
            {t("yolo::Current Round")}
          </Text>
          <Filters
            activeStatusFilter={activeStatusFilter}
            setStatusFilter={setStatusFilter}
            toggleUserFilter={toggleUserFilter}
          />
        </Flex>
        <ClaimWinnings />
      </Flex>

      <VStack spacing={4}>
        <DepositsV2Check contractVersion={contract.version} />
        {
          /* Only show for FE */
          supportedNetworks.length > 1 && <DepositsV1Check />
        }
      </VStack>
    </Box>
  );
};
