import { groupBy } from "lodash";
import { useAccount } from "wagmi";
import { Round } from "../types";

/**
 * Return the total in game value of the assets deposited by the connected user for a specific round
 * @param round
 * @returns BigInt
 */
export const useConnectedUserEntriesValue = (round: Round) => {
  const { address } = useAccount();
  const connectedUserDeposits = groupBy(round.deposits, "depositor.address")[address || ""];

  if (!connectedUserDeposits) {
    return 0n;
  }

  const connectedUserEntriesValue =
    BigInt(round.valuePerEntry.toString()) *
    connectedUserDeposits.reduce((acc, deposit) => acc + BigInt(deposit.numberOfEntries), 0n);

  return connectedUserEntriesValue;
};
