import { memo } from "react";
import { Flex } from "@chakra-ui/react";
import { getUnixTime } from "date-fns";
import { useTranslation } from "next-i18next";
import { formatUsd, fromDecimals, useCoinPrices } from "@looksrare/utils";
import { Text } from "@looksrare/uikit";
import { Round, RoundStatus, YoloSupportedNetworks } from "../../../types";
import { getNetworkFromYoloContractName } from "../../../utils";
import { NetworkNativeTokenIcon } from "../../NetworkNativeTokenIcon";
import { AmountProps, AnimatedAmount } from "../../Amount";
import { useYoloConfig } from "../../../config";
import { pulse } from "./style";

interface Props {
  round: Round;
}

export const AnimatedAmountMemo = memo(
  ({
    totalValue,
    network,
    ...props
  }: { totalValue: string; network: YoloSupportedNetworks } & Omit<AmountProps, "amount">) => {
    return (
      <AnimatedAmount
        network={network}
        height={{ base: 10, sm: 16 }}
        amount={totalValue}
        textProps={{ textStyle: "display-01", ml: 1 }}
        icon={() => <NetworkNativeTokenIcon network={network} boxSize={11} />}
        {...props}
      />
    );
  },
  (p, n) => p.totalValue === n.totalValue
);
AnimatedAmountMemo.displayName = "AnimatedAmountMemo";

export const CenterActive = ({ round }: Props) => {
  const { t } = useTranslation();
  const {
    roulette: { CenterActiveMain },
    referenceToken,
  } = useYoloConfig();
  const supportsUsdPrice = referenceToken.symbol === "ETH";
  const coinPriceQuery = useCoinPrices({ enabled: supportsUsdPrice });
  const ethPriceUsd = coinPriceQuery.data ? coinPriceQuery.data.eth.price : 0;
  const totalValue = Number(fromDecimals(round.potValue, { shouldCommify: false }));
  const usdPrice = formatUsd(totalValue * ethPriceUsd);
  const isRoundPlayed = !!round.cutoffTime;
  const network = getNetworkFromYoloContractName(round.contract);
  return (
    <Flex flexDirection="column" alignItems="center">
      <CenterActiveMain onChainId={round.onChainId} network={network} totalValue={totalValue} />
      {isRoundPlayed && (
        <>
          {supportsUsdPrice && round.status === RoundStatus.Open && round.cutoffTime > getUnixTime(Date.now()) && (
            <Text textStyle="detail" color="text-02" bold>
              {usdPrice}
            </Text>
          )}
          {round.status === RoundStatus.Open && round.cutoffTime <= getUnixTime(Date.now()) && (
            <Text animation={`${pulse} 2s infinite`} color="link-01" bold textAlign="center">
              {t("yolo::Generating Randomness...")}
            </Text>
          )}
          {(round.status === RoundStatus.Drawing || round.status === RoundStatus.Drawn) && (
            <Text animation={`${pulse} 2s infinite`} color="link-01" bold>
              {t("yolo::Drawing Winner...")}
            </Text>
          )}
        </>
      )}
    </Flex>
  );
};
