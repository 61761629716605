import React, { useEffect, useState } from "react";
import { Box, IconButton, ModalBody } from "@chakra-ui/react";
import { CloseIcon, Video } from "@looksrare/uikit";
import { useSoundEffect } from "@looksrare/utils";
import { useYoloConfig } from "../../../config";

interface Props {
  onComplete: () => void;
  onClose: () => void;
}

export const AnimationView: React.FC<Props> = ({ onComplete, onClose }) => {
  const [isLoaded, setLoaded] = useState(false);
  const { isMuted } = useYoloConfig();

  const { play: playWinSound } = useSoundEffect({ path: "/sounds/yolo/winner-animation.mp3", volume: 1, isMuted });

  useEffect(() => {
    playWinSound({ restartIfAlreadyPlaying: false });
  }, [playWinSound]);

  return (
    <ModalBody p={0}>
      <Box pos="relative" borderRadius="dialog" overflow="hidden" opacity={isLoaded ? 1 : 0}>
        <Video
          src="/videos/jackpot/pepe-the-frog-dance.mp4"
          autoPlay
          muted
          playsInline
          onEnded={onComplete}
          onLoadedData={() => setLoaded(true)}
          controls={false}
        />
      </Box>

      <IconButton
        size="xs"
        variant="ghost"
        colorScheme="secondary"
        aria-label="Close modal"
        onClick={onClose}
        icon={<CloseIcon fill="text-01" />}
        pos="absolute"
        right={4}
        top={4}
      />
    </ModalBody>
  );
};
