import { Box, Flex, forwardRef, TagLabel, TagLeftIcon, TagProps, useColorModeValue } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { LevelsIcon, RaritySniperIcon, Tag, Text, LogoRoundIcon } from "@looksrare/uikit";
import { commify, formatNumberToLocale, getBigIntishPercent } from "@looksrare/utils";
import { Collection, RarityData, RarityProvider } from "../../types";
import { rarityLevelToColor, useRarityLevel, useRarityText } from "../../utils";

interface RarityTagProps extends TagProps {
  rarity: RarityData[];
  totalSupply: Collection["totalSupply"];
}

export const RarityTag = forwardRef(({ rarity, totalSupply, ...props }: RarityTagProps, ref) => {
  const { t } = useTranslation();

  const looksrareRarityData = rarity && rarity.find((r) => r.provider === RarityProvider.LOOKSRARE);
  const raritySniperData = rarity && rarity.find((r) => r.provider === RarityProvider.RARITY_SNIPER);
  const rarityData = looksrareRarityData || raritySniperData;

  const rankDisplay = (!!rarityData && commify(rarityData.rank.toString())) || t("Details");
  const rarityLevel = useRarityLevel(rarityData?.rank, totalSupply, "token");
  const rarityColor = rarityLevelToColor(rarityLevel);
  const iconAndTextColor = useColorModeValue(`${rarityColor}.400`, `${rarityColor}.300`);
  const borderColor = useColorModeValue(`${rarityColor}.200`, `${rarityColor}.600`);

  return (
    <Tag size="sm" variant="outline" ref={ref} borderColor={borderColor} {...props}>
      <TagLeftIcon mr={1}>
        <LevelsIcon color={iconAndTextColor} />
      </TagLeftIcon>
      <TagLabel color={iconAndTextColor}>{rankDisplay}</TagLabel>
    </Tag>
  );
});
export const RaritySniperRarityTag = forwardRef(({ rarity, totalSupply, ...props }: RarityTagProps, ref) => {
  const { t } = useTranslation();

  const raritySniperRarityData = rarity && rarity.find((r) => r.provider === RarityProvider.RARITY_SNIPER);

  const rankDisplay = (!!raritySniperRarityData && commify(raritySniperRarityData.rank.toString())) || t("Details");
  const rarityLevel = useRarityLevel(raritySniperRarityData?.rank, totalSupply, "token");
  const rarityColor = rarityLevelToColor(rarityLevel);
  const iconAndTextColor = useColorModeValue(`${rarityColor}.400`, `${rarityColor}.300`);
  const borderColor = useColorModeValue(`${rarityColor}.200`, `${rarityColor}.600`);

  return (
    <Tag h={7} gap={1} size="md" variant="outline" ref={ref} borderColor={borderColor} {...props}>
      <TagLeftIcon mr={1}>
        <RaritySniperIcon color={iconAndTextColor} />
      </TagLeftIcon>
      <TagLabel color={iconAndTextColor}>{rankDisplay}</TagLabel>
    </Tag>
  );
});
export const LooksrareRarityTag = forwardRef(({ rarity, totalSupply, ...props }: RarityTagProps, ref) => {
  const { t } = useTranslation();

  const looksrareRarityData = rarity && rarity.find((r) => r.provider === RarityProvider.LOOKSRARE);

  const rankDisplay = (!!looksrareRarityData && commify(looksrareRarityData.rank.toString())) || t("Details");
  const rarityLevel = useRarityLevel(looksrareRarityData?.rank, totalSupply, "token");
  const rarityColor = rarityLevelToColor(rarityLevel);
  const iconAndTextColor = useColorModeValue(`${rarityColor}.400`, `${rarityColor}.300`);
  const borderColor = useColorModeValue(`${rarityColor}.200`, `${rarityColor}.600`);

  return (
    <Tag h={7} gap={1} size="md" variant="outline" ref={ref} borderColor={borderColor} {...props}>
      <TagLeftIcon mr={1}>
        <LogoRoundIcon color={iconAndTextColor} />
      </TagLeftIcon>
      <TagLabel color={iconAndTextColor}>{rankDisplay}</TagLabel>
    </Tag>
  );
});

/**
 * Compose RarityTag and the text that often accompanies it.
 */
export const RarityTagAndText = ({ rarity, totalSupply, ...tagProps }: RarityTagProps) => {
  const { t } = useTranslation();

  const looksrareRarityData = rarity && rarity.find((r) => r.provider === RarityProvider.LOOKSRARE);
  const raritySniperRarityData = rarity && rarity.find((r) => r.provider === RarityProvider.RARITY_SNIPER);
  const rarityData = looksrareRarityData || raritySniperRarityData;

  const rarityPercentage = totalSupply && rarityData?.rank ? getBigIntishPercent(totalSupply, rarityData?.rank) : null;
  const rarityLevel = useRarityLevel(rarityData?.rank, totalSupply, "token");
  const rarityColor = rarityLevelToColor(rarityLevel);
  const rarityText = useRarityText(rarityLevel);
  const rarityTextColor = useColorModeValue(`${rarityColor}.400`, `${rarityColor}.300`);

  return (
    <Flex wrap="wrap" alignItems="center" gap={2}>
      <Flex gap={2}>
        {!!raritySniperRarityData && (
          <RaritySniperRarityTag size="md" rarity={rarity} totalSupply={totalSupply} {...tagProps} />
        )}
        {!!looksrareRarityData && (
          <LooksrareRarityTag size="md" rarity={rarity} totalSupply={totalSupply} {...tagProps} />
        )}
      </Flex>
      {!!rarityPercentage && (
        <Flex gap={2} alignItems="center">
          <Text color={rarityTextColor} textStyle="detail" bold>
            {rarityText}
          </Text>

          <Box w="px" h="px" borderRadius="50%" bgColor="text-03" />
          <Text color="text-03" textStyle="detail">
            {t("Top {{percent}}%", { percent: formatNumberToLocale(rarityPercentage, 0) })}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};
