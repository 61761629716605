import { useMemo } from "react";
import { Address } from "viem";
import flatten from "lodash/flatten";
import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
  useInfiniteQuery,
} from "@tanstack/react-query";
import last from "lodash/last";
import { type BigIntish, type TokenStandard, type Pagination } from "@looksrare/utils";
import { gql } from "graphql-request";
import { ImageData, Round } from "../../types";
import { useWhitelistedCurrencies } from "./hooks";
import { graphql } from "./graphql";

export interface CollectionFilterItem {
  name: string;
  address: Address;
  totalSupply: BigIntish | null;
  volume: {
    volume24h: BigIntish | null;
  };
  owned: BigIntish;
  logo?: ImageData;
  isVerified: boolean;
  isHidden: boolean | null;
  floor: {
    floorPriceGlobal: BigIntish | null;
  };
}

interface GetUserRelativeCollectionsProps {
  address: Address;
  filter?: {
    addresses?: Address[];
    type?: TokenStandard;
    isVerified?: true;
    isHidden?: boolean | null;
  };
  pagination?: Pagination;
  sort: "HOLDING_VALUE_DESC";
  search?: {
    term: string;
  };
}

const getUserRelativeCollections = async ({
  address,
  filter,
  pagination,
  sort,
  search,
}: GetUserRelativeCollectionsProps): Promise<CollectionFilterItem[]> => {
  const query = gql`
    query GetUserRelativeCollections(
      $address: Address!
      $filter: CollectionFilterInput
      $pagination: PaginationInput
      $sort: RelativeCollectionSortInput
      $search: SearchFilterInput
    ) {
      user(address: $address) {
        relativeCollections(filter: $filter, pagination: $pagination, sort: $sort, search: $search) {
          name
          address
          totalSupply
          owned
          isVerified
          isHidden
          volume {
            volume24h
          }
          logo {
            src
            contentType
          }
          floor {
            floorPriceGlobal
          }
        }
      }
    }
  `;

  const res: { user: { relativeCollections: CollectionFilterItem[] } } = await graphql(query, {
    address,
    filter,
    pagination,
    sort,
    search,
  });
  return res.user.relativeCollections;
};

export const useInfiniteUserRelativeCollections = (
  { pagination, sort, filter, search, address }: GetUserRelativeCollectionsProps,
  enabled = true
) => {
  const maxSupportedPageSize = 20;
  return useInfiniteQuery<
    CollectionFilterItem[],
    Error,
    InfiniteData<CollectionFilterItem[], Pagination>,
    unknown[],
    Pagination
  >({
    queryKey: ["yolo", "user-collections", address, pagination, sort, filter, search],
    queryFn: ({ pageParam }) => getUserRelativeCollections({ pagination: pageParam, address, filter, sort, search }),
    getNextPageParam: (lastPage: CollectionFilterItem[]): Pagination | undefined => {
      if (lastPage.length < maxSupportedPageSize) {
        return undefined;
      }
      const lastCollection = last(lastPage);
      const cursor = lastCollection?.address;
      return { first: maxSupportedPageSize, cursor };
    },
    initialPageParam: { first: maxSupportedPageSize },
    staleTime: 1 * 60 * 1000, // 1 minute
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled,
  });
};

interface UserWhitelistedCollectionsReturn {
  collections: CollectionFilterItem[];
  hasMoreCollections: boolean;
  fetchNextCollections: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<InfiniteData<CollectionFilterItem[], Pagination>, Error>>;
  isFetching: boolean;
}

/**
 * Fetch collections owned by the current user and that are whitelisted
 * Used during the deposit process
 */
export const useUserWhitelistedCollections = (
  userAddress: Address,
  round: Round,
  enabled = true
): UserWhitelistedCollectionsReturn => {
  const { data: whitelistedCurrencies } = useWhitelistedCurrencies(round.contract);
  const {
    data: userCollections,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useInfiniteUserRelativeCollections(
    {
      address: userAddress,
      sort: "HOLDING_VALUE_DESC",
      filter: {
        isHidden: false,
        isVerified: true,
        type: "ERC721",
      },
    },
    enabled
  );
  const validCollections = useMemo(() => {
    if (!userCollections || !whitelistedCurrencies) {
      return [];
    }
    const FLOOR_LIMIT = BigInt(round.valuePerEntry) * 2n;
    return flatten(userCollections.pages).filter(
      (c) =>
        whitelistedCurrencies.includes(c.address) &&
        c.floor.floorPriceGlobal &&
        BigInt(c.floor.floorPriceGlobal) >= FLOOR_LIMIT
    );
  }, [round.valuePerEntry, userCollections, whitelistedCurrencies]);

  return {
    collections: validCollections,
    hasMoreCollections: hasNextPage,
    fetchNextCollections: fetchNextPage,
    isFetching,
  };
};
