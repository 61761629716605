import { formatNumberToLocale } from "./formatNumberToLocale";

/**
 * Formats a number in a user-friendly percentage string, with up to 2 decimals and including the percentage symbol
 * @param percentage - A number between 0 and 100
 * */
export const formatPercentage = (percentage: number) => {
  if (percentage > 0 && percentage < 0.01) {
    return `<${formatNumberToLocale(0.01, 0, 2)}%`;
  }
  if (percentage > 99.99 && percentage < 100.0) {
    return `>${formatNumberToLocale(99.99, 0, 2)}%`;
  }
  return `${formatNumberToLocale(percentage, 0, 2)}%`;
};
