import React, { useEffect } from "react";
import Image from "next/image";
import { formatEther } from "viem";
import { Flex, FlexProps, SlideFade, Spinner } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { usePreviousValue } from "@looksrare/utils";
import { Text } from "@looksrare/uikit";
import { usePendingTxsSub, PendingTxPayload } from "../../utils/api/realtime";
import { useReloadSound } from "../../utils/sounds";
import { useCurrentRoundStore } from "../../currentRoundStore";
import { YoloSupportedNetworks } from "../../types";
import { useYoloQueryParams } from "../../utils/chains";
import { NetworkNativeTokenIcon } from "../NetworkNativeTokenIcon";

const HitmanPure = React.memo(
  ({
    network,
    pending,
    ...props
  }: { network: YoloSupportedNetworks; pending: PendingTxPayload | null } & FlexProps) => {
    const { t } = useTranslation();
    const isSniping = pending && pending.count > 0;
    return (
      <Flex
        width="100%"
        position="relative"
        justifyContent="flex-end"
        alignItems="center"
        borderTop="solid 1px"
        transition="all 0.2s ease-in-out"
        borderColor={isSniping ? "red.400" : "transparent"}
        bg={
          isSniping
            ? "url('/images/yolo/hitman-bg.svg'), linear-gradient(92.65deg, #2D0709 30.4%, #DA1E28 99.99%), linear-gradient(0deg, #FA4D56, #FA4D56)"
            : "ui-glass"
        }
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        p={4}
        {...props}
      >
        <Image
          src="/images/yolo/hitmanpepe.png"
          width={96}
          height={72}
          alt="Sniper"
          style={{ position: "absolute", left: 0, bottom: 0 }}
        />
        {isSniping ? (
          <Flex alignItems="center" justifyContent="flex-end">
            <Text color="gray.50" textStyle="detail" bold mr={2}>
              {pending.count} {pending.count === 1 ? "SNIPER" : "SNIPERS"}
            </Text>
            <Flex alignItems="center" mr={2}>
              <NetworkNativeTokenIcon network={network} boxSize={4} width={2} />
              <Text color="gray.50" textStyle="detail" bold ml={1}>
                {formatEther(BigInt(pending.value.toString()))}
              </Text>
            </Flex>
            <Spinner size="sm" speed="0.65s" borderWidth="2px" thickness="1px" color="gray.50" />
          </Flex>
        ) : (
          <Text textAlign="right" bold>
            {t("yolo::All Quiet...")}
          </Text>
        )}
      </Flex>
    );
  }
);
HitmanPure.displayName = "HitmanPure";

interface Props extends FlexProps {
  onReceive?: () => void;
  enable: boolean;
}

export const Hitman = ({ enable, onReceive, ...props }: Props) => {
  const play = useReloadSound();
  const pendingsTxs = usePendingTxsSub(onReceive);
  const prevPendingsTxs = usePreviousValue(pendingsTxs);
  const { network } = useYoloQueryParams();

  useEffect(() => {
    if (pendingsTxs && prevPendingsTxs && pendingsTxs.count > prevPendingsTxs.count) {
      play();
    }
  }, [pendingsTxs, prevPendingsTxs, play]);

  const duration = useCurrentRoundStore((state) => state.duration);

  const isLastSeconds = duration?.minutes === 0 && duration?.seconds !== undefined && duration.seconds <= 30;

  const showHitman = enable && isLastSeconds;

  return (
    <SlideFade in={showHitman} unmountOnExit>
      <HitmanPure pending={pendingsTxs} {...props} network={network} />
    </SlideFade>
  );
};
