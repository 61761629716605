import { BigIntish, toDecimals } from "@looksrare/utils";

export const getRoundPlayedEmoji = (value?: number) => {
  if (!value) {
    return "";
  }

  if (value <= 100) {
    return "🐭";
  }
  if (value <= 500) {
    return "🐵";
  }
  if (value <= 1000) {
    return "🐯";
  }
  return "🐲";
};

export const getRoundWonEmoji = (value?: number) => {
  if (!value) {
    return "";
  }

  if (value <= 10) {
    return "💸";
  }
  if (value <= 100) {
    return "🏆";
  }
  if (value <= 500) {
    return "🚀";
  }
  return "💎";
};

export const getBiggestWinEmoji = (value?: BigIntish) => {
  if (!value) {
    return "";
  }

  const biggestWinWei = BigInt(value.toString());
  if (biggestWinWei <= toDecimals("0.1")) {
    return "🦐";
  }
  if (biggestWinWei <= toDecimals("1")) {
    return "🐠";
  }
  if (biggestWinWei <= toDecimals("10")) {
    return "🦈";
  }
  return "🐳";
};

export const getLuckiestWinEmoji = (value?: number) => {
  if (!value) {
    return "";
  }

  if (value <= 10) {
    return "🪄";
  }
  if (value <= 100) {
    return "🍀";
  }
  if (value <= 500) {
    return "🤯";
  }
  return "👑";
};
