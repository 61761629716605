import { Address, getAddress } from "viem";
import { Box, IconButton, HStack, IconButtonProps, AspectRatio, BoxProps } from "@chakra-ui/react";
import { CloseFilledIcon, CloudinaryImage, SectionPlaceholder, Text } from "@looksrare/uikit";
import {
  formatNumberToLocale,
  formatToSignificant,
  fromDecimals,
  twapToUsdPrice,
  useCoinPrices,
  useGetCurrencyConfig,
  useMultipleTokenEthTwap,
  multiplyWeiByNumber,
  getChainIdFromSupportedNetwork,
  defaultTwapWindow,
} from "@looksrare/utils";
import { truncateToContractPrecision } from "../../utils/truncate";
import { useYoloQueryParams } from "../../utils";
import { useYoloConfig } from "../../config";
import { NetworkNativeTokenIcon } from "../NetworkNativeTokenIcon";
import { YoloSupportedNetworks } from "../../types";
import { Amount } from "../Amount";
import { useYoloCartStore } from "./assetsState";

const SharedPricePreview = ({
  ethPriceDisplay,
  usdPriceDisplay,
  network,
  ...props
}: {
  ethPriceDisplay: string;
  usdPriceDisplay: string;
  network: YoloSupportedNetworks;
} & BoxProps) => {
  return (
    <Box {...props}>
      <Amount network={network} amount={ethPriceDisplay} justifyContent="end" />
      <Text textStyle="helper" color="text-03" textAlign="right">
        ${usdPriceDisplay}
      </Text>
    </Box>
  );
};

const SharedRemoveIconButton = (props: IconButtonProps) => (
  <IconButton boxSize={9} className="show-on-hover" variant="ghost" colorScheme="secondary" size="xs" {...props}>
    <CloseFilledIcon boxSize={5} />
  </IconButton>
);

const sharedRowProps = {
  alignItems: "center",
  cursor: "default",
  justifyContent: "space-between",
  p: 2,
  borderRadius: "mini",
};

const showOnHoverStyles = {
  ".show-on-hover": { display: "none" },
  "&:hover": {
    bg: "hover-ui",
    ".hide-on-hover": { display: "none" },
    ".show-on-hover": { display: "initial" },
  },
};

export const UserCart = () => {
  const { network } = useYoloQueryParams();
  const { collections, tokens, ethAmountWei, ethAmountRoundsCount, removeNFT, removeEth, removeErc20 } =
    useYoloCartStore();
  const getCurrencyConfig = useGetCurrencyConfig(getChainIdFromSupportedNetwork(network));
  const { hideLogoInCart, referenceToken } = useYoloConfig();
  const { data: prices } = useCoinPrices();
  const tokenAddresses = Object.keys(tokens) as Address[];
  const twapQueries = useMultipleTokenEthTwap(tokenAddresses, defaultTwapWindow, {
    enabled: referenceToken.symbol === "ETH",
  });
  const ethPrice = prices?.eth.price || 0;
  const totalEthAmountWei = BigInt(ethAmountWei.toString()) * BigInt(ethAmountRoundsCount);
  const hasEth = totalEthAmountWei > 0n;
  const isCartEmpty = !hasEth && tokenAddresses.length === 0 && Object.keys(collections).length === 0;

  return (
    <Box maxHeight="434px" overflowY="auto" flexGrow={1} px={3}>
      {isCartEmpty && (
        <SectionPlaceholder hideLogo={hideLogoInCart} height="100%" logoProps={{ marginBottom: 0 }}>
          <Text color="text-03">Nothing Selected</Text>
        </SectionPlaceholder>
      )}

      {hasEth && (
        <HStack {...sharedRowProps} sx={showOnHoverStyles}>
          <NetworkNativeTokenIcon network={network} boxSize={8} />
          <Box flexGrow={1}>
            <Text textStyle="detail" bold>
              {formatToSignificant(totalEthAmountWei, referenceToken.maxDisplayDecimals)} ETH
            </Text>
            {ethAmountRoundsCount > 1 && (
              <Text textStyle="helper" bold color="support-warning">
                x{ethAmountRoundsCount} rounds
              </Text>
            )}
          </Box>
          <SharedRemoveIconButton className="show-on-hover" aria-label="Remove ETH" onClick={removeEth} />
          <SharedPricePreview
            className="hide-on-hover"
            ethPriceDisplay={formatToSignificant(totalEthAmountWei, 2)}
            usdPriceDisplay={formatToSignificant(
              multiplyWeiByNumber(totalEthAmountWei, ethPrice),
              referenceToken.maxDisplayDecimals,
              { decimals: 18 }
            )}
            network={network}
          />
        </HStack>
      )}
      {Object.entries(tokens).map(([addressString, token], tokenIndex) => {
        const address = getAddress(addressString);
        const { decimals: tokenDecimals, icon: Icon } = getCurrencyConfig(address);
        const tokenTotalAmountFloat =
          parseFloat(fromDecimals(token.amount, { decimals: tokenDecimals })) * token.roundsCount;

        const tokenTwapData = twapQueries?.[tokenIndex]?.data as bigint | undefined;
        const tokenPriceUsd = tokenTwapData ? twapToUsdPrice(tokenTwapData, ethPrice) : 0;
        const tokenValueEthTruncated = truncateToContractPrecision(
          (tokenPriceUsd / ethPrice) * tokenTotalAmountFloat,
          referenceToken.maxDisplayDecimals
        );

        return (
          <HStack key={address + token.amount} {...sharedRowProps} sx={showOnHoverStyles}>
            <HStack>
              {Icon && <Icon boxSize={8} />}
              <Box>
                <Text textStyle="detail" flexGrow={1} bold>
                  {formatToSignificant(token.amount, 2, { decimals: tokenDecimals })} {token.name}
                </Text>
                {token.roundsCount > 1 && (
                  <Text textStyle="helper" bold color="support-warning">
                    x{token.roundsCount} rounds
                  </Text>
                )}
              </Box>
            </HStack>
            <SharedRemoveIconButton
              className="show-on-hover"
              aria-label="Remove Token"
              onClick={() => removeErc20({ address: address })}
            />
            {referenceToken.symbol === "ETH" ? (
              <SharedPricePreview
                className="hide-on-hover"
                ethPriceDisplay={formatNumberToLocale(tokenValueEthTruncated)}
                usdPriceDisplay={formatNumberToLocale(tokenPriceUsd * tokenTotalAmountFloat)}
                network={network}
              />
            ) : (
              <Amount network={network} amount={formatNumberToLocale(tokenTotalAmountFloat, 0, 2)} />
            )}
          </HStack>
        );
      })}
      {Object.entries(collections).map(([addressString, collection]) => {
        const address = getAddress(addressString);
        return Object.values(collection.nfts).map((nft) => (
          <HStack key={address + nft.tokenId} {...sharedRowProps} sx={showOnHoverStyles}>
            <Box>
              <AspectRatio ratio={1}>
                <Box
                  position="relative"
                  boxSize={8}
                  sx={{ img: { color: "transparent" } }}
                  borderRadius="button"
                  overflow="hidden"
                >
                  <CloudinaryImage
                    src={nft.image}
                    alt={address + nft.tokenId}
                    layout="fill"
                    objectFit="contain"
                    sizes="32px"
                  />
                </Box>
              </AspectRatio>
            </Box>
            <HStack justifyContent="space-between" alignItems="center" flexGrow={1} spacing={1}>
              <Box>
                <Text textStyle="detail" bold>
                  #{nft.tokenId}
                </Text>
                <Text textStyle="helper" color="text-03">
                  {collection.name}
                </Text>
              </Box>
              <SharedRemoveIconButton
                className="show-on-hover"
                aria-label="Remove NFT"
                onClick={() => removeNFT({ collectionAddress: address, tokenId: nft.tokenId })}
              />

              <SharedPricePreview
                className="hide-on-hover"
                ethPriceDisplay={formatNumberToLocale(collection.price, 0, 2)}
                usdPriceDisplay={formatNumberToLocale(collection.price * ethPrice)}
                network={network}
              />
            </HStack>
          </HStack>
        ));
      })}
    </Box>
  );
};
