import React from "react";
import { useTranslation } from "next-i18next";
import { Flex, Switch } from "@chakra-ui/react";
import { useAccount } from "wagmi";
import { Text, ButtonToggle, Button } from "@looksrare/uikit";
import { roundStatusFilters, RoundStatusFilters } from "./useFilters";

export interface Props {
  activeStatusFilter: RoundStatusFilters;
  setStatusFilter: (i: number) => void;
  toggleUserFilter: () => void;
}

export const Filters = ({ activeStatusFilter, setStatusFilter, toggleUserFilter }: Props) => {
  const { address } = useAccount();
  const { t } = useTranslation();
  return (
    <Flex
      flexDirection={{ base: "column", sm: "row" }}
      alignItems={{ sm: "center" }}
      justifyContent="space-between"
      flex="2"
      mr={8}
    >
      <ButtonToggle>
        {roundStatusFilters(t).map(({ label }, index) => {
          const isActive = activeStatusFilter.label === label;
          return (
            <Button
              key={label}
              variant={isActive ? "solid" : "outline"}
              color={isActive ? "link-01" : "link-02"}
              onClick={() => setStatusFilter(index)}
            >
              {label}
            </Button>
          );
        })}
      </ButtonToggle>
      {!!address && (
        <Flex alignItems="center" ml={{ sm: 3 }}>
          <Switch defaultChecked={false} onChange={toggleUserFilter} />
          <Text textStyle="detail" color="text-02" ml={3}>
            {t("yolo::Only Your Rounds")}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};
