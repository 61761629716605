import { useCallback, useEffect, useMemo, useState } from "react";
import { NextSeo } from "next-seo";
import { useAccount } from "wagmi";
import {
  BigIntish,
  formatToSignificant,
  getFormattedTimer,
  useCountdown,
  useOnAccountChange,
  useSoundEffect,
  useWidgetBotCrate,
} from "@looksrare/utils";
import { intervalToDuration } from "date-fns";
import { useWidgetBotOptions } from "@looksrare/uikit";
import { Layout } from "../components/Layout";
import {
  useRound,
  getCurrentRound,
  getNetworkFromYoloContractName,
  useInvalidateCurrentParticipantFutureEntries,
} from "../utils";
import { Main } from "../components/round";
import { Enter } from "../components/depositAssets";
import { useCurrentRoundStore } from "../currentRoundStore";
import { YoloContractName, Round } from "../types";
import { useYoloConfig } from "../config";
import { useYoloCartStore } from "../components/depositAssets/assetsState";

enum View {
  MAIN,
  ENTER,
}

/**
 * Updates the store and the page title with the current round timer.
 * This logic is abstracted into a component to avoid re-rendering other containers of the `useCountdown` hook
 */
const GameClock = ({
  roundDurationSecs = 0,
  cutoffTime,
  potValue,
  enabled,
}: {
  roundDurationSecs?: number;
  cutoffTime: number | null;
  potValue: BigIntish;
  enabled: boolean;
}) => {
  const { isMuted } = useYoloConfig();
  const setDuration = useCurrentRoundStore((state) => state.setDuration);
  const durationCountdown = useCountdown((cutoffTime || 0) * 1000);
  const { play: playCountdown, stop: stopCountdown } = useSoundEffect({
    path: "/sounds/yolo/countdown.mp3",
    volume: 1,
    isMuted,
  });

  const duration = useMemo(() => {
    // Only update the duration if the timer is enabled
    if (enabled) {
      return durationCountdown;
    }
    const now = Date.now();
    return intervalToDuration({ start: now, end: now + roundDurationSecs * 1000 });
  }, [durationCountdown, enabled, roundDurationSecs]);

  useEffect(() => {
    setDuration(duration);
  }, [duration, enabled, roundDurationSecs, setDuration]);

  const durationInSeconds = (duration?.minutes ?? 0) * 60 + (duration?.seconds ?? 0);
  const titleDuration =
    (duration?.minutes !== undefined && duration.minutes >= 0) ||
    (duration?.seconds !== undefined && duration.seconds >= 0)
      ? getFormattedTimer(duration)
      : "00:00";

  useEffect(() => {
    if (durationInSeconds <= 10 && durationInSeconds > 0) {
      playCountdown({ restartIfAlreadyPlaying: false });
    } else {
      stopCountdown();
    }
  }, [durationInSeconds, playCountdown, stopCountdown]);

  return <NextSeo title={`${titleDuration} | Ξ${formatToSignificant(potValue)} | YOLO`} />;
};

interface CurrentRoundViewProps {
  round: Round;
}

/**
 * View for current round
 * Automatically fetches new rounds when they start
 */
export const CurrentRoundView = ({ round: initialRound }: CurrentRoundViewProps) => {
  useWidgetBotCrate(useWidgetBotOptions());

  const [activeView, setActiveView] = useState(View.MAIN);
  const { address } = useAccount();
  const [currentRoundId, setCurrentRoundId] = useState(initialRound.onChainId);
  const [isRoundQueryEnabled, setIsRoundQueryEnabled] = useState(true);
  const contractNetwork = getNetworkFromYoloContractName(initialRound.contract);
  const {
    isMuted,
    contract: { version, getYoloContractNameFromNetworkAndVersion },
  } = useYoloConfig();
  const { reset } = useYoloCartStore();
  const contractName = getYoloContractNameFromNetworkAndVersion(contractNetwork, version) as YoloContractName; // Always defined because V2 is latest and deployed on all supported networks;
  const { data: updatedRound, refetch } = useRound(currentRoundId, contractName, {
    refetchInterval: isRoundQueryEnabled ? 5_000 : false,
  });
  const invalidateCurrentParticipantFutureEntries = useInvalidateCurrentParticipantFutureEntries();
  const round = useMemo(() => updatedRound ?? initialRound, [initialRound, updatedRound]);

  const { play: playBgMusic } = useSoundEffect({
    path: "/sounds/yolo/bg_track.mp3",
    volume: 0.3,
    isMuted,
  });

  useEffect(() => {
    playBgMusic({ loop: true });
  }, [playBgMusic]);

  // Reset cart on wallet or network change
  useOnAccountChange(useYoloCartStore.getState().reset);
  useEffect(() => {
    if (!!contractNetwork) {
      useYoloCartStore.getState().reset();
    }
  }, [contractNetwork]);

  // Close the enter view if the round is drawing and they have it open
  useEffect(() => {
    if (activeView === View.ENTER && round.status !== "OPEN") {
      setActiveView(View.MAIN);
    }
  }, [activeView, setActiveView, round.status]);

  /** End of round callback
   * - Run timer from 10 to 0
   * - Fetch latest round
   * - Set new round id
   */
  const [timeBeforeNextRound, setTimeBeforeNextRound] = useState(0);
  const finishRound = useCallback(
    (callback: () => void) => {
      // Stop tracking round progress once it's finished
      setIsRoundQueryEnabled(false);

      // Need a closure to not fuck up the render
      let timer = 10;
      const interval = setInterval(async () => {
        if (timer >= 1) {
          setTimeBeforeNextRound(timer--);
        } else {
          clearInterval(interval);
          setTimeBeforeNextRound(0);
          const currentRound = await getCurrentRound([contractName]);
          setCurrentRoundId(currentRound.onChainId);
          setIsRoundQueryEnabled(true);
          invalidateCurrentParticipantFutureEntries();
          callback();
        }
      }, 1000);
    },
    [contractName, invalidateCurrentParticipantFutureEntries]
  );

  return (
    <>
      <GameClock
        roundDurationSecs={round.roundDuration}
        enabled={!!round.cutoffTime}
        cutoffTime={round.cutoffTime}
        potValue={round.potValue}
      />
      <Layout position="relative" withBackgroundImage height="100%" display="flex">
        {!!address && activeView === View.ENTER ? (
          <Enter
            round={round}
            userAddress={address}
            onClose={async () => {
              setActiveView(View.MAIN);
              await refetch();
              reset();
            }}
          />
        ) : (
          <Main
            round={round}
            onEnter={() => setActiveView(View.ENTER)}
            refetch={refetch}
            finishRound={finishRound}
            timeBeforeNextRound={timeBeforeNextRound}
            isHistoricRound={false}
          />
        )}
      </Layout>
    </>
  );
};
