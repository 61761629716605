import { HelpIcon, Text } from "@looksrare/uikit";
import { Trans } from "next-i18next";
import { getUnixTime } from "date-fns";
import { Flex, Skeleton } from "@chakra-ui/react";
import { getPointsMultiplier, getRoundPercentElapsed } from "@looksrare/utils";
import { multiplierThresholds, useYoloConfig } from "../../../config";
import { getIsCloseToMultiplierThreshold } from "../../../utils/gems/getIsCloseToMultiplierThreshold";

interface Props {
  cutoffTime: number | null;
  onHelpClick: () => void;
  roundDuration?: number;
}

export const PointsMultiplierText = ({ cutoffTime, roundDuration, onHelpClick }: Props) => {
  const {
    points: { enabled: pointsEnabled, label: pointsLabel, Icon: PointsIcon, accentColor: pointsAccentColor },
  } = useYoloConfig();

  if (!roundDuration) {
    return <Skeleton width="60px" height="30px" />;
  }

  const currentSeconds = getUnixTime(new Date());
  const secondsElapsedAsDecimal = !cutoffTime
    ? 0
    : getRoundPercentElapsed({
        currentSeconds,
        roundDuration,
        roundCutoffTime: cutoffTime,
      });

  const multiplier = getPointsMultiplier(secondsElapsedAsDecimal, multiplierThresholds);

  const isCloseToMultiplierThreshold = !cutoffTime
    ? true
    : getIsCloseToMultiplierThreshold({
        currentSeconds,
        roundDuration,
        roundCutoffTime: cutoffTime,
      });

  if (!pointsEnabled) {
    return null;
  }

  return (
    <Flex justifyContent="center" alignItems="center">
      {!!PointsIcon && <PointsIcon boxSize={3} mr={1} />}
      <Trans i18nKey="yolo::transPointsMultiplierText2">
        <Text textStyle="detail">
          {"Enter now to earn"}{" "}
          <Text
            display="inline"
            textStyle="detail"
            bold
            color={pointsAccentColor}
            sx={{
              ...(isCloseToMultiplierThreshold
                ? {
                    animation: "pulse 1.5s ease-in-out infinite",
                    "@keyframes pulse": {
                      "0%": {
                        opacity: 1,
                      },
                      "50%": {
                        opacity: 0.5,
                      },
                      "100%": {
                        opacity: 1,
                      },
                    },
                  }
                : undefined),
            }}
          >
            <>{{ multiplier }}</>x <>{{ pointsLabel }}</>!
          </Text>
        </Text>
      </Trans>
      <HelpIcon cursor="pointer" color="text-03" boxSize={3} ml={1} onClick={onHelpClick} />
    </Flex>
  );
};
