import { useTranslation } from "next-i18next";
import { useAccount } from "wagmi";
import { divideWeiByNumber, fromDecimals, toDecimals } from "@looksrare/utils";
import { useAssertNetworkDisclosure } from "@looksrare/utils";
import { Button, Text } from "@looksrare/uikit";
import { getRoundLimits } from "../../utils/getRoundLimits";
import { Round } from "../../types";
import { EnterYoloModal } from "../modals/EnterYoloModal";
import { truncateToContractPrecision } from "../../utils/truncate";
import { getNetworkFromYoloContractName } from "../../utils";
import { getTotalDepositsByAddress } from "../../utils/getTotalDepositsByAddress";
import { useYoloConfig } from "../../config";

interface Props {
  round: Round;
  cartValue: bigint;
  totalNumOfItems: number;
  ethAmountRoundsCount: number;
  onEnter: () => void;
}

export const EnterButton = ({ round, cartValue, totalNumOfItems, ethAmountRoundsCount, onEnter }: Props) => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const { referenceToken } = useYoloConfig();
  const network = getNetworkFromYoloContractName(round.contract);
  const { isOpen, onClose, onOpen } = useAssertNetworkDisclosure({ network });
  const notEnoughValue = cartValue < BigInt(round.valuePerEntry);
  const tooManyItems = totalNumOfItems + round.deposits.length > round.maximumNumberOfDeposits;

  const { cannotEnter, maxNumberOfDepositsReached, maxNumberOfParticipantsReached, message } = getRoundLimits({
    round,
    maxDeposit: referenceToken.maxDeposit,
    address,
  });

  const hasReachedMaxDeposit = (() => {
    if (!!referenceToken.maxDeposit && !!address) {
      // Total amount of deposits in the current round so far
      const totalRoundDeposits = getTotalDepositsByAddress(address, round.deposits);
      // Entry per round
      const entryPerRound = divideWeiByNumber(cartValue, ethAmountRoundsCount);
      // The max amount a user can enter the current round dictates what they can enter in future rounds
      const maxPerRound =
        toDecimals(referenceToken.maxDeposit.toString(), referenceToken.decimals) - totalRoundDeposits;
      return entryPerRound > maxPerRound;
    }
    return false;
  })();
  const isDisabled =
    notEnoughValue ||
    tooManyItems ||
    cannotEnter ||
    maxNumberOfParticipantsReached ||
    maxNumberOfDepositsReached ||
    hasReachedMaxDeposit;

  const buttonText = (() => {
    if (message) {
      return message;
    }

    if (notEnoughValue) {
      return (
        <>
          {t("yolo::Minimum Entry")}
          {" • "}
          <Text color="text-inverse-03" m={1}>
            {fromDecimals(round.valuePerEntry)} {referenceToken.name}
          </Text>
        </>
      );
    }

    if (tooManyItems) {
      return (
        <>
          {t("yolo::Maximum Items")}
          {" • "}
          <Text color="text-inverse-03" m={1}>
            {totalNumOfItems + round.deposits.length}
          </Text>
        </>
      );
    }

    if (hasReachedMaxDeposit) {
      return <>{t("yolo::Max Entry of {{maxDeposit}} ETH", { maxDeposit: referenceToken.maxDeposit })}</>;
    }

    const truncatedCartValueEth = truncateToContractPrecision(
      parseFloat(fromDecimals(cartValue)),
      referenceToken.maxPrecision
    );
    return (
      <>
        {t("yolo::Confirm Entry ")}
        {" • "}
        <Text textStyle="detail" color="text-inverse-03" m={1}>
          {truncatedCartValueEth} {referenceToken.name}
        </Text>
      </>
    );
  })();

  return (
    <>
      <EnterYoloModal round={round} isOpen={isOpen} onClose={onClose} onEnter={onEnter} cartValue={cartValue} />
      <Button
        width="100%"
        onClick={onOpen}
        isDisabled={isDisabled}
        data-id="yolo-deposit-confirm-entry-btn"
        colorScheme="brand"
      >
        {buttonText}
      </Button>
    </>
  );
};
