import { Box, BoxProps } from "@chakra-ui/react";
import { BigIntish } from "@looksrare/utils";
import { Round } from "../types";
import { useYoloConfig } from "../config";
import { RoundOverview } from "./RoundOverview";

interface Props extends BoxProps {
  round: Round;
  userEntriesValue: bigint;
  totalPotValue: BigIntish;
  highlight?: boolean;
}

export const MobileStickyBar: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  round,
  userEntriesValue,
  totalPotValue,
  highlight,
  ...props
}) => {
  const { mobileStickyBarProps } = useYoloConfig();
  return (
    <Box bg="ui-01" width="100%" p={4} {...props} {...mobileStickyBarProps}>
      <RoundOverview
        mb={2}
        round={round}
        userEntriesValue={userEntriesValue}
        totalPotValue={totalPotValue}
        highlight={highlight}
      />
      {children}
    </Box>
  );
};
