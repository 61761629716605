import { BoxProps } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import map from "lodash/map";
import { useAccount, usePublicClient } from "wagmi";
import {
  ReservoirOracleFloorPriceMessage,
  useCurrentChainId,
  useInvalidateTokenBalance,
  useHandleModalStep,
  useCoinPrices,
  useSendAnalyticsEvent,
  fromDecimals,
  DataLayerEventNames,
  useAssertNetwork,
} from "@looksrare/utils";
import { useCallback } from "react";
import { getUnixTime } from "date-fns";
import { Address } from "viem";
import { ETH_AVG_BLOCK_TIME_MS } from "@looksrare/config";
import { AutoTransactionStepRow, TransactionSetter, useToast } from "@looksrare/uikit";
import { getOracleFloorData } from "@looksrare/utils/oracles/reservoir";
import { useQueryClient } from "@tanstack/react-query";
import { useYoloStore } from "../../../utils/yoloStore";
import { DepositCalldata, GasMultiplier, Round, TokenType, TokenTypeContractId } from "../../../types";
import { defaultOracleMessage, TWAP_WINDOW_BY_VERSION, useYoloConfig } from "../../../config";
import { YoloCartAssets as Assets } from "../../depositAssets/assetsState";
import { getNetworkFromYoloContractName } from "../../../utils";
import { useGetGasPrice } from "../../../utils/api/realtime";

/**
 * YOLO contract only accepts message signed within a validity time, defined as a contract variable.
 * A user might take too long to enter the round, so we need to revalidate the oracle signature before sending the
 * transaction. It's quite the edge case, so should be ok te be handled here instead of changing how tracking assets works
 */
const useRevalidateOracleSignature = () => {
  const chainId = useCurrentChainId();
  const {
    contract: { version, useReadOracleSignatureValidityPeriod },
  } = useYoloConfig();
  const getOracleSignatureValidity = useReadOracleSignatureValidityPeriod();

  return useCallback(
    async (collections: Assets["collections"]) => {
      const validitySeconds = await getOracleSignatureValidity();

      const expiredCollectionsOracleSignatures = Object.entries(collections).reduce<Address[]>(
        (acc, [address, collection]) => {
          const buffer = (ETH_AVG_BLOCK_TIME_MS / 1000) * 1.5; // Allow for a whole block plus some slow connection buffer
          if (Number(collection.message.timestamp) + validitySeconds + buffer < getUnixTime(Date.now())) {
            acc.push(address as Address);
          }
          return acc;
        },
        []
      );

      const oracleMessagesByAddress: Record<Address, ReservoirOracleFloorPriceMessage> = {};

      await Promise.all(
        expiredCollectionsOracleSignatures.map(async (address) => {
          const res = await getOracleFloorData(address, chainId, TWAP_WINDOW_BY_VERSION[version]);
          oracleMessagesByAddress[address] = res.message;
          return res;
        })
      );

      return oracleMessagesByAddress;
    },
    [chainId, getOracleSignatureValidity, version]
  );
};

interface Props extends BoxProps {
  onComplete: () => void;
  isStepActive: boolean;
  assets: Assets;
  round: Round;
  cartValue: bigint;
}

export const StepSendToPotsSingleRound = ({ isStepActive, onComplete, assets, round, cartValue }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { address: userAddress } = useAccount();
  const { toast } = useToast();
  const revalidateCollectionOracleSignatures = useRevalidateOracleSignature();
  //Prepare success follow-ups
  const { data: prices } = useCoinPrices();
  const sendAnalyticsEvent = useSendAnalyticsEvent();
  const invalidateBalances = useInvalidateTokenBalance();
  const {
    contract: { useDeposit },
  } = useYoloConfig();
  const deposit = useDeposit();
  const publicClient = usePublicClient();
  const { gasMultiplier } = useYoloStore();
  const gasWei = useGetGasPrice();

  const network = getNetworkFromYoloContractName(round.contract); // Ignore gas price on L2s
  const shouldUseCustomGasPrice = network === "ethereum" && gasMultiplier === GasMultiplier.FAST && !!gasWei;

  const assertNetwork = useAssertNetwork({ network });

  const useHandleTransaction = (setTransaction: TransactionSetter) => {
    return useHandleModalStep({
      onSubmit: async () => {
        await assertNetwork();

        if (!publicClient) {
          throw new Error("No public client found");
        }
        const revalidatedCollectionOracleSignatures = await revalidateCollectionOracleSignatures(assets.collections);

        const nftDeposits = map(assets.collections, (collection, address): DepositCalldata => {
          const revalidatedOracle = revalidatedCollectionOracleSignatures[address as Address];
          // Replace oracle signature just in time, if it expired
          const reservoirOracleFloorPrice = revalidatedOracle || collection.message;
          return {
            tokenAddress: address,
            tokenIdsOrAmounts: collection.nfts.map((nft) => nft.tokenId),
            tokenType: TokenTypeContractId[TokenType.ERC721],
            reservoirOracleFloorPrice,
            minimumEntries: collection.minimumEntries,
          };
        });

        const deposits: DepositCalldata[] = nftDeposits.concat(
          map(
            assets.tokens,
            (erc20, address): DepositCalldata => ({
              tokenAddress: address,
              tokenIdsOrAmounts: [erc20.amount],
              tokenType: TokenTypeContractId[TokenType.ERC20],
              reservoirOracleFloorPrice: defaultOracleMessage,
              minimumEntries: erc20.minimumEntries,
            })
          )
        );

        const hash = await deposit(
          round.onChainId,
          deposits,
          assets.ethAmountWei,
          shouldUseCustomGasPrice ? BigInt(Math.round(gasWei * gasMultiplier)) : undefined
        );
        setTransaction(hash);
        const receipt = await publicClient.waitForTransactionReceipt({ hash });

        if (receipt.status === "success") {
          const totalPriceEth = fromDecimals(cartValue);
          const totalPriceUsd = prices?.eth ? parseFloat(totalPriceEth) * prices?.eth.price : 0;
          sendAnalyticsEvent({
            event: DataLayerEventNames.YOLO_DEPOSIT,
            connectedWalletAddress: userAddress,
            totalPriceEth,
            totalPriceUsd: totalPriceUsd.toString(),
          });

          setTimeout(() => {
            invalidateBalances();
            queryClient.invalidateQueries({ queryKey: ["tokens", userAddress] }); // @TODO migrate token queries or revisit invalidation
          }, 3000); //@NOTE let indexer catch up

          setTransaction(undefined);
        } else {
          throw new Error(`${receipt.transactionHash} failed`);
        }
      },
      onSuccess: () => {
        onComplete();
        toast({
          title: t("yolo::Entered Round!"),
          description: t("yolo::You’ve successfully entered your assets to the current round. Good luck! 🤞👀"),
        });
      },
    });
  };

  return (
    <AutoTransactionStepRow
      useHandleTransaction={useHandleTransaction}
      isStepActive={isStepActive}
      ctaText={t("yolo::Confirm transaction in wallet")}
    />
  );
};
